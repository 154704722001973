import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import RestAPI from 'System/State/rest-api';
import { createChangeSchedulePayload } from 'Utilities/api-transform-utility';
import moment from 'moment';
import User from 'Models/User';

const addResourceTimelineToProject = createAsyncThunk(
    'projects/addResourceTimelineToProject',
    async (resourceTimeline, { rejectWithValue }) => {
        record('addResourceTimelineToProject', {
            id: resourceTimeline.id,
            startDate: resourceTimeline.startDate?.toLocaleDateString(),
            endDate: resourceTimeline.endDate?.toLocaleDateString(),
        });

        try {
            await RestAPI.post(
                `/projects/${resourceTimeline.projectId}/resource-timelines`,
                { body: resourceTimeline },
            );
            return { ...resourceTimeline };
        } catch (ex) {
            return rejectWithValue(ex);
        }
    },
);

const deleteResourceTimelineFromProject = createAsyncThunk(
    'projects/deleteResourceTimelineFromProject',
    async (resourceTimeline) => {
        record('deleteProjectResourceTimeline', {
            id: resourceTimeline.id,
            startDate: resourceTimeline.startDate,
            endDate: resourceTimeline.endDate,
            projectId: resourceTimeline.projectId,
            projectName: resourceTimeline.projectName,
        });

        await RestAPI.del(
            `/projects/${resourceTimeline.projectId}/resource-timelines/${resourceTimeline.id}`,
        );

        return { ...resourceTimeline };
    },
);

const getProjectResourceTimelineDetail = createAsyncThunk(
    'projects/getProjectResourceTimelineDetail',
    async (
        { resourceTimelineId, projectId },
        { getState, rejectWithValue },
    ) => {
        try {
            const { loggedInUser } = getState().user || {};
            const user = loggedInUser ? new User(loggedInUser) : null;

            const endpoint = user?.isAllowedFinancials
                ? `/projects/${projectId}/resource-timelines/withFinancials/${resourceTimelineId}`
                : `/projects/${projectId}/resource-timelines/${resourceTimelineId}`;

            const response = await RestAPI.get(endpoint);
            return { ...response };
        } catch (error) {
            return rejectWithValue({
                projectId: projectId,
                timelineId: resourceTimelineId,
            });
        }
    },
);

const updateProjectResourceTimeline = createAsyncThunk(
    'projects/updateProjectResourceTimeline',
    async (resourceTimeline) => {
        record('updateProjectResourceTimeline', {
            id: resourceTimeline.id,
            startDate: resourceTimeline.startDate.toLocaleDateString(),
            endDate: resourceTimeline.endDate.toLocaleDateString(),
            projectId: resourceTimeline.projectId,
            projectName: resourceTimeline.projectName,
        });

        await RestAPI.put(
            `/projects/${resourceTimeline.projectId}/resource-timelines/${resourceTimeline.id}`,
            { body: resourceTimeline },
        );

        return { ...resourceTimeline };
    },
);

const updateResourceTimelineEndDate = createAsyncThunk(
    'projects/updateResourceTimelineEndDate',
    async ({ project, resourceTimeline, newDate }, { rejectWithValue }) => {
        record('updateResourceTimelineEndDate', {
            id: resourceTimeline.id,
            name: resourceTimeline.name,
        });

        if (moment(newDate).isAfter(project.estimatedCompletionDate))
            return rejectWithValue();

        if (!resourceTimeline?.isPinned) resourceTimeline.endDate = newDate;

        await RestAPI.put(`/projects/${project.id}/change-schedule`, {
            body: createChangeSchedulePayload(project),
        });

        return { ...resourceTimeline };
    },
);

const updateResourceTimelineStartDate = createAsyncThunk(
    'projects/updateResourceTimelineStartDate',
    async ({ project, resourceTimeline, newDate }, { rejectWithValue }) => {
        record('updateResourceTimelineStartDate', {
            id: resourceTimeline.id,
            name: resourceTimeline.name,
        });

        if (moment(newDate).isBefore(project.estimatedStartDate))
            return rejectWithValue();
        if (!resourceTimeline?.isPinned) resourceTimeline.startDate = newDate;

        await RestAPI.put(`/projects/${project.id}/change-schedule`, {
            body: createChangeSchedulePayload(project),
        });

        return { ...resourceTimeline };
    },
);

export const resourceTimelineActionsAsync = {
    addResourceTimelineToProject,
    deleteResourceTimelineFromProject,
    getProjectResourceTimelineDetail,
    updateProjectResourceTimeline,
    updateResourceTimelineEndDate,
    updateResourceTimelineStartDate,
};
