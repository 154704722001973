import Basic from './Basic';

export default class KeyResult extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        // the title of the key results
        this.title = obj.title || '';

        // specifies whether the key result was accomplished or not
        this.isAccomplished = obj.isAccomplished || false;
    }
}
