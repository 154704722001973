import {
    addToOrUpdateInList,
    getItemByItemId,
    getUpdatedList,
    removeItemFromListById,
    removeItemFromListByPropName,
} from 'Utilities/reducer-utils';
import { createSlice } from '@reduxjs/toolkit';
import { companyActionsAsync } from './actions';
import { coreActionsAsync } from 'System/State/CoreState/actions';
import { projectActionsAsync } from '../ProjectState/actions';
import { userActionsAsync } from '../UserState/actions';
import { integrationActionsAsync } from './integration-actions';
import { paymentActionsAsync } from '../PaymentState/actions';

const { appStart, offline } = coreActionsAsync;

const {
    addBudget,
    deleteBudget,
    disableUser,
    enableUser,
    generateSampleData,
    getBudgetDetail,
    getUserDetail,
    getUserSentimentById,
    getUserSentiments,
    removeSampleData,
    updateBudget,
    updateTenant,
    updateUser,
} = companyActionsAsync;

const { addLOBToProject, addCharacteristicToProject } = projectActionsAsync;

const {
    addUserCharacteristic,
    deleteUserCharacteristic,
    getUserCharacteristicDetail,
} = userActionsAsync;

const { cancelSubscription } = paymentActionsAsync;

const { getIntegrationDetails } = integrationActionsAsync;

// const {} = integrationActionsAsync;

const initialState = {
    budgetPaneIsVisible: false,
    selectedBudget: null,
    selectedUser: null,
    tenant: null,
    users: [],
    budgets: [],
    linesOfBusiness: [],
    characteristics: [],
    allUserSentiments: [],
};

export const CompanySlice = createSlice({
    name: 'company',
    initialState: initialState,
    reducers: {
        resetSlice() {
            return initialState;
        },
        selectBudget(state, action) {
            state.selectedBudget = action.payload;
            state.budgetPaneIsVisible = true;
        },
        selectUser(state, action) {
            state.selectedUser = action.payload;
        },
    },
    extraReducers: {
        [addBudget.fulfilled]: (state, action) => {
            state.budgets.push({ ...action.payload });
        },
        [addCharacteristicToProject.fulfilled]: (state, action) => {
            state.characteristics = addToOrUpdateInList(
                state.characteristics,
                action.payload,
            );
        },
        [addLOBToProject.fulfilled]: (state, action) => {
            state.linesOfBusiness = addToOrUpdateInList(
                state.linesOfBusiness,
                action.payload,
            );
        },
        [addUserCharacteristic.fulfilled]: (state, action) => {
            const foundUser = getItemByItemId(
                state.users,
                action.payload.userId,
            );
            state.users = getUpdatedList(state.users, {
                ...foundUser,
                characteristics: [...foundUser.characteristics, action.payload],
            });
        },
        [appStart.fulfilled]: (state, action) => {
            const { budgets, characteristics, linesOfBusiness, tenant, users } =
                action.payload.data;
            if (budgets) state.budgets = budgets;
            if (tenant) state.tenant = tenant;
            if (users) state.users = users;
            if (linesOfBusiness) state.linesOfBusiness = linesOfBusiness;
            if (characteristics) state.characteristics = characteristics;
        },
        [offline.fulfilled]: (state, action) => {
            const { budgets, characteristics, linesOfBusiness, tenant, users } =
                action.payload.data;
            if (budgets) state.budgets = budgets;
            if (tenant) state.tenant = tenant;
            if (users) state.users = users;
            if (linesOfBusiness) state.linesOfBusiness = linesOfBusiness;
            if (characteristics) state.characteristics = characteristics;
        },
        [deleteBudget.fulfilled]: (state, action) => {
            state.budgets = removeItemFromListById(
                state.budgets,
                action.payload,
            );
        },
        [cancelSubscription.fulfilled]: (state, action) => {
            state.tenant = {
                ...state.tenant,
                isSubscriptionCanceled: true,
                subscriptionCanceledDate: new Date(),
            };
        },
        [deleteUserCharacteristic.fulfilled]: (state, action) => {
            const foundUser = getItemByItemId(
                state.users,
                action.payload.userId,
            );
            state.users = getUpdatedList(state.users, {
                ...foundUser,
                characteristics: removeItemFromListById(
                    foundUser.characteristics,
                    action.payload.id,
                ),
            });
        },
        [disableUser.fulfilled]: (state, action) => {
            let updatedUser = getItemByItemId(state.users, action.payload);
            if (updatedUser) {
                updatedUser.isActive = false;
                state.users = getUpdatedList(state.users, updatedUser);
            }
        },
        [enableUser.fulfilled]: (state, action) => {
            let updatedUser = getItemByItemId(state.users, action.payload);
            if (updatedUser) {
                updatedUser.isActive = true;
                state.users = getUpdatedList(state.users, updatedUser);
            }
        },
        [generateSampleData.fulfilled]: (state) => {
            state.tenant.sampleDataInUse = true;
        },
        [getBudgetDetail.fulfilled]: (state, action) => {
            state.budgets = addToOrUpdateInList(
                state.budgets,
                action.payload.data,
            );
        },
        [getBudgetDetail.rejected]: (state, action) => {
            state.budgets = removeItemFromListById(
                state.budgets,
                action.payload,
            );
        },
        [getUserCharacteristicDetail.fulfilled]: (state, action) => {
            const foundUser = getItemByItemId(
                state.users,
                action.payload.data.userId,
            );
            if (foundUser)
                foundUser.characteristics = addToOrUpdateInList(
                    foundUser.characteristics,
                    action.payload.data,
                );
        },
        [getUserCharacteristicDetail.rejected]: (state, action) => {
            const foundUser = getItemByItemId(
                state.users,
                action.payload.userId,
            );
            if (foundUser) {
                foundUser.characteristics = removeItemFromListByPropName(
                    foundUser.characteristics,
                    action.payload.characteristicId,
                    'characteristicId',
                );
            }
        },
        [getUserDetail.fulfilled]: (state, action) => {
            state.users = addToOrUpdateInList(state.users, action.payload.data);
        },
        [getUserDetail.rejected]: (state, action) => {
            state.users = removeItemFromListById(state.users, action.payload);
        },
        [getUserSentimentById.fulfilled]: (state, action) => {
            const foundUser = getItemByItemId(
                state.users,
                action.payload.userId,
            );
            if (foundUser) {
                foundUser.sentiments = addToOrUpdateInList(
                    foundUser.sentiments,
                    action.payload,
                );
            }

            state.users = addToOrUpdateInList(state.users, foundUser);
        },
        [getUserSentiments.fulfilled]: (state, action) => {
            state.allUserSentiments = action.payload.data;
        },
        [removeSampleData.fulfilled]: (state) => {
            state.tenant.sampleDataInUse = false;
        },
        [updateBudget.fulfilled]: (state, action) => {
            state.budgets = getUpdatedList(state.budgets, action.payload);
        },
        [updateTenant.fulfilled]: (state, action) => {
            state.tenant = action.payload;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.users = getUpdatedList(state.users, action.payload);
        },
        [getIntegrationDetails.fulfilled]: (state, action) => {
            state.tenant.integrations = addToOrUpdateInList(
                state.tenant.integrations,
                action.payload.data,
            );
        },
    },
});

export const companyActions = {
    ...companyActionsAsync,
    resetSlice: CompanySlice.actions.resetSlice,
    selectBudget: CompanySlice.actions.selectBudget,
    selectUser: CompanySlice.actions.selectUser,
    updateTenantSync: CompanySlice.actions.updateTenantSync,
};

export const integrationActions = {
    ...integrationActionsAsync,
};

export default CompanySlice.reducer;
