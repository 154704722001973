import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import RestAPI from 'System/State/rest-api';
import User from 'Models/User';

const addMilestone = createAsyncThunk(
    'milestones/addMilestone',
    async (milestone) => {
        record('addMilestone', { id: milestone.id, name: milestone.name });

        await RestAPI.post('/milestones', { body: milestone });

        return { ...milestone };
    },
);

const getMilestone = createAsyncThunk(
    'milestones/getMilestone',
    async (id, { getState, rejectWithValue }) => {
        try {
            const { loggedInUser } = getState().user || {};
            const user = loggedInUser ? new User(loggedInUser) : null;

            const endpoint = user?.isAllowedFinancials
                ? `/milestones/withFinancials/${id}/`
                : `/miletones/${id}`;
            return await RestAPI.get(endpoint);
        } catch (error) {
            return rejectWithValue(id);
        }
    },
);

const updateMilestone = createAsyncThunk(
    'milestones/updateMilestone',
    async (milestone) => {
        record('updateMilestone', { id: milestone.id, name: milestone.name });

        await RestAPI.put(`milestones/${milestone.id}`, { body: milestone });

        return { ...milestone };
    },
);

const deleteMilestone = createAsyncThunk(
    'milestones/deleteMilestone',
    async (milestone) => {
        record('deleteMilestone', { id: milestone.id, name: milestone.name });

        await RestAPI.del(`milestones/${milestone.id}`);

        return milestone.id;
    },
);

export const milestoneActionsAsync = {
    addMilestone,
    deleteMilestone,
    getMilestone,
    updateMilestone,
};
