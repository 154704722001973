import { createSelector } from 'reselect';
import Milestone from 'Models/Milestone';

const milestoneSelector = (state) => state.milestones?.list;
const selectedMilestoneId = (state) => state.milestones?.selectedMilestone;
const selectedProjectId = (state) => state.projects?.selectedProject;

const allMilestones = createSelector(milestoneSelector, (milestones) =>
    milestones?.length
        ? milestones.map((milestone) => new Milestone(milestone))
        : null,
);

const selectedProjectMilestones = createSelector(
    milestoneSelector,
    selectedProjectId,
    (milestones, projectId) => {
        const selectedProjectMilestones = [];
        if (projectId && milestones?.length) {
            milestones.forEach((milestone) => {
                if (milestone.projectId === projectId) {
                    selectedProjectMilestones.push(new Milestone(milestone));
                }
            });
            return selectedProjectMilestones;
        }
        return selectedProjectMilestones;
    },
);

const selectedMilestone = createSelector(
    allMilestones,
    selectedMilestoneId,
    (milestones, milestoneId) => {
        if (milestones?.length && milestoneId) {
            return new Milestone(
                milestones.find((milestone) => milestone.id === milestoneId),
            );
        }
        return null;
    },
);

export const milestoneSelectors = {
    allMilestones,
    selectedMilestone,
    selectedProjectMilestones,
};
