import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
    TextField,
} from '@mui/material';
import Milestone from 'Models/Milestone';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import { useSelector } from 'react-redux';
import { coreSelectors } from 'System/State/CoreState/selectors';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import FinancialField from 'System/UI/Components/FinancialField/FinancialField';
import Form from 'System/Forms/Components/Form';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { PaymentTier } from 'Models/PaymentTiers';

export default function MilestoneForm({ milestone, onSubmit }) {
    const showFinancials = useSelector(
        coreSelectors.showGlobalFinancialsIfAllowed,
    );
    const selectedProject = useSelector(projectSelectors.selectedProject);
    const tenant = useSelector(companySelectors.tenant);
    const billingFlag = useSelector(featureFlagSelectors.billingFlag);

    const milestoneSchema = yup.object().shape({
        name: yup.string().required('Required'),
        targetEventDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .required('Required'),
        financialValue: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be positive'),
        isAchieved: yup.bool(),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues:
                milestone ?? new Milestone({ projectId: selectedProject?.id }),
            validationSchema: milestoneSchema,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return (
        <Form onSubmit={handleSubmit} formId="milestone-form">
            <Stack spacing={2}>
                <TextField
                    name="name"
                    label="Name"
                    value={values.name}
                    fullWidth
                    error={showError('name')}
                    helperText={getError('name')}
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Stack direction="row" spacing={1}>
                    <DatePicker
                        name="targetEventDate"
                        format="MM/DD/YYYY"
                        label="Date"
                        fullWidth
                        required
                        error={showError('targetEventDate')}
                        helperText={getError('targetEventDate')}
                        value={values.targetEventDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    {showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
                        (!billingFlag)) ? (
                        <FinancialField
                            name="financialValue"
                            label="Financial Amount"
                            value={values.financialValue}
                            required
                            isError={showError('financialValue')}
                            errorMsg={getError('financialValue')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    ) : null}
                </Stack>

                {milestone ? (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isAchieved"
                                    checked={values.isAchieved}
                                    onChange={handleChange}
                                ></Checkbox>
                            }
                            label="We did it, milestone achieved!"
                        />
                    </FormGroup>
                ) : null}
            </Stack>
        </Form>
    );
}
