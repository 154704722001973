import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { phaseActions } from 'System/State/ProjectState/reducer';
import EditProjectPhaseForm from './EditProjectPhaseForm';

export default function EditProjectPhaseModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.editProjectPhaseNameModal) || false;
    const selectedPhase = useSelector(projectSelectors.selectedPhase);

    const handleClose = () => {
        dispatch(modalActions.close('editProjectPhaseNameModal'));
        dispatch(phaseActions.unselectProjectPhase());
    };

    const handleSubmit = (phase) => {
        dispatch(phaseActions.updateProjectPhase(phase));
        handleClose();
        dispatch(phaseActions.unselectProjectPhase());
    };

    if (!selectedPhase) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Edit Work Item Name"
            aria-describedby="Edit the name of a work item"
        >
            <DialogTitle>Edit Phase</DialogTitle>
            <DialogContent>
                <EditProjectPhaseForm
                    phase={selectedPhase}
                    onSubmit={handleSubmit}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="edit-project-phase-form"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
