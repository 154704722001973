import * as yup from 'yup';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Form from 'System/Forms/Components/Form';
import ResourceUnavailableTypePicker from 'System/UI/Pickers/ResourceUnavailablePeriodTypePicker/ResourceUnavailablePeriodTypePicker';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import ResoruceTotalUnavailableDays from 'System/UI/Components/ResourceTotalUnavailableDays/ResourceTotalUnavailableDays';

export default function NewResourceUnavailablePeriodForm({
    resource,
    onSubmit,
}) {
    const resourceUnavailablePeriodSchema = yup.object().shape({
        skilledResourceId: yup.string().required('Required'),
        reason: yup.string().required('Required'),
        startDate: yup
            .date()
            .typeError('Please enter a start date')
            .required('Required'),
        endDate: yup
            .date()
            .typeError('Please enter an end date')
            .required('Required'),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: {},
        validationSchema: resourceUnavailablePeriodSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    useEffect(() => {
        if (resource) {
            setFieldValue('skilledResourceId', resource.id, false);
        }
    }, [resource]);

    useEffect(() => {
        if (values.startDate) {
            setFieldValue('endDate', values.startDate, false);
        }
    }, [values.startDate]);

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                formId="resource-unavailable-period-form"
            >
                <Grid container>
                    <Grid item xs={3}>
                        <ResourceUnavailableTypePicker
                            onChange={handleChange}
                            value={values.reason}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {values.reason && (
                            <DatePicker
                                name="startDate"
                                format="MM/DD/YYYY"
                                label="Start Date"
                                fullWidth
                                required
                                value={values.startDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={showError('startDate')}
                                helperText={getError('startDate')}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {values.reason && (
                            <DatePicker
                                name="endDate"
                                format="MM/DD/YYYY"
                                label="End Date"
                                disabled={values.startDate ? false : true}
                                fullWidth
                                required
                                initialValues
                                value={values.endDate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={showError('endDate')}
                                helperText={getError('endDate')}
                            />
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        {values.endDate && (
                            <ResoruceTotalUnavailableDays
                                startDate={values.startDate}
                                endDate={values.endDate}
                            />
                        )}
                    </Grid>
                </Grid>
            </Form>
        </>
    );
}
