import Project from 'Models/Project';
import Resource from 'Models/Resource';
import { filterUtils } from 'Utilities/filter-utils';

const groupProjectsByTeam = (projectList) => {
    const groupedListByTeam = [];

    const noTeamObj = {
        teamName: 'No Team',
        teamId: 'none',
        projects: [],
    };

    const multiTeamObj = {
        teamName: 'Multiple Teams',
        teamId: 'multiple',
        projects: [],
    };

    if (projectList?.length) {
        projectList.forEach((project) => {
            if (project.teams?.length === 0) {
                noTeamObj.projects.push(project);
            } else if (project.teams?.length === 1) {
                project.teams.forEach((team) => {
                    const foundTeam = groupedListByTeam.find(
                        (item) => item.teamId === team.id,
                    );
                    if (foundTeam) {
                        foundTeam.projects.push(project);
                    } else if (!foundTeam) {
                        groupedListByTeam.push({
                            teamName: team.name,
                            teamId: team.id,
                            projects: [project],
                        });
                    }
                });
            } else if (project.teams?.length > 1) {
                multiTeamObj.projects.push(project);
            }
        });
    }

    if (noTeamObj.projects.length > 0) {
        groupedListByTeam.push(noTeamObj);
    }
    if (multiTeamObj.projects.length > 0) {
        groupedListByTeam.push(multiTeamObj);
    }
    return groupedListByTeam;
};

const projectMapperWithFilter = (projects, filterOptions, teams) => {
    const mapped = [];

    projects.forEach((project) => {
        //check if project passes filters before going through the various loops for casting
        const passesFilters = filterUtils.applyFilters(
            project,
            filterOptions,
            teams,
        );

        if (passesFilters) {
            const castProject = new Project(project);

            //budget filter applied separately because it relies on computed methods from classes, so once the project makes it through first filters, check budget filter
            if (
                filterUtils.applyBudgetFilter(
                    castProject,
                    filterOptions?.selectedBudgets,
                )
            ) {
                mapped.push(castProject);
            }
        }
    });
    return mapped;
};

const applyFilters = (resource, filterOptions, projects) => {
    let passesFilters = true;

    // projects
    if (filterOptions.selectedProjects?.length) {
        const foundProjects = projects.filter((project) =>
            filterOptions.selectedProjects?.includes(project.id),
        );

        passesFilters = foundProjects?.some((project) =>
            project?.resourceTimelines?.some((rtl) => rtl?.skilledResourceId === resource.id)
        );

        if (!passesFilters) {
            return passesFilters;
        }
    }

    // profiles
    if (filterOptions.selectedProfiles?.length) {
        passesFilters = filterOptions.selectedProfiles.some(
            (profileId) => profileId === resource.profileId,
        );

        if (!passesFilters) {
            return passesFilters;
        }
    }

    // teams
    if (filterOptions.selectedTeams?.length) {
        passesFilters = filterOptions.selectedTeams.some(
            (teamId) => teamId === resource.teamId,
        );

        if (!passesFilters) {
            return passesFilters;
        }
    }

    return passesFilters;
};

const resourceMapper = (resources, filterOptions, projects) => {
    const mapped = [];
    resources.forEach((resource) => {
        const passesFilters = applyFilters(resource, filterOptions, projects);

        if (passesFilters) {
            mapped.push(new Resource(resource));
        }
    });
    return mapped;
};

const workItemMapperWithFilter = (workItems, filterOptions, projectKeys) => {
    const mapped = [];

    workItems?.forEach((workItem) => {
        const passesFilters = filterUtils.applyWorkItemFilters(
            workItem,
            filterOptions,
            projectKeys,
        );

        if (passesFilters) {
            const found = mapped.find((x) => x.id === workItem.id);
            if (!found) mapped.push(workItem);
        }
    });

    return mapped;
};

export const selectorUtils = {
    groupProjectsByTeam,
    projectMapperWithFilter,
    workItemMapperWithFilter,
    resourceMapper,
};
