import { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FooterContent from './FooterContent';

const footerStyles = makeStyles((theme) => ({
    footerContainer: {
        backgroundColor: theme.palette.primaryDark.main,
        color: '#FFF',
        margin: 'auto',
        marginBottom: '-16px',
        padding: '200px 5px 50px',
        [theme.breakpoints.down('md')]: {
            paddingTop: 100,
        },

        '& div:not(:last-child)': {
            paddingLeft: 30,

            '& :first-child': {
                fontWeight: 'bold',
                color: '#fff',
            },

            '& a': {
                display: 'block',
                margin: '10px 0',
                color: '#fff',
                textDecoration: 'none',

                '&:hover': {
                    filter: 'brightness(200%)',
                },
            },
        },

        '& #footerCopyright': {
            marginTop: 20,
            textAlign: 'center',

            '& p': {
                color: '#fff',
                fontSize: '12pt',
            },

            '& a': {
                color: 'inherit',
                display: 'inline',
                textDecoration: 'none',

                '&:hover': {
                    filter: 'brightness(75%)',
                },
            },
        },
    },
}));

export default function MarketingFooter() {
    const classes = footerStyles();

    const calculateFooterOffset = (footerOffset = 0, minMarginTop = 0) => {
        const header = document.getElementsByTagName('header')[0];
        const footer = document.getElementById('pageFooter');
        const content = document.getElementsByTagName('main')[0];

        if (!header || !content) {
            footer.style.marginTop = `${minMarginTop}px`;
            return;
        }

        const headerHeight = getAbsoluteHeight(header);
        const pageFooterHeight = getAbsoluteHeight(footer, false);
        const pageContentHeight = getAbsoluteHeight(content);

        const offsetHeight =
            window.outerHeight -
            (headerHeight +
                pageFooterHeight +
                pageContentHeight +
                footerOffset);

        if (offsetHeight < minMarginTop) {
            footer.style.marginTop = `${minMarginTop}px`;
            return;
        }

        footer.style.marginTop = offsetHeight + 'px';

        function getAbsoluteHeight(el, includeTop = true) {
            var styles = window.getComputedStyle(el);
            var margin =
                (includeTop ? parseFloat(styles['marginTop']) : 0) +
                parseFloat(styles['marginBottom']);

            return Math.ceil(el.offsetHeight + margin);
        }
    };

    useEffect(calculateFooterOffset, []);

    return (
        <Box id="pageFooter">
            <Grid className={classes.footerContainer} container spacing={0}>
                <Grid
                    id="footerCopyright"
                    style={{ margin: '0 auto 30px' }}
                    item
                    xs={12}
                >
                    <FooterContent />
                </Grid>
            </Grid>
        </Box>
    );
}
