import {
    addItemToList,
    addToOrUpdateInList,
    getItemByItemId,
    getUpdatedList,
    removeItemFromListById,
} from 'Utilities/reducer-utils';
import { createSlice } from '@reduxjs/toolkit';
import { profileActionsAsync } from './actions';
import { coreActionsAsync } from 'System/State/CoreState/actions';

const { appStart, offline } = coreActionsAsync;

const { addProfile, deleteProfile, getProfileDetail, updateProfile } =
    profileActionsAsync;

const initialState = {
    list: [],
    selectedProfile: null,
    profilePaneIsVisible: false,
};

const addItemToListWithFinancials = (items, newItem) => {
    if (items && newItem) {
        let item = getItemByItemId(items, newItem.id);

        if (!item) {
            return [...items, newItem];
        } else if (
            (item && newItem.standardHourlyCostRate) ||
            (item && newItem.standardHourlyBillRate)
        ) {
            item.standardHourlyCostRate = newItem.standardHourlyCostRate;
            item.standardHourlyBillRate = newItem.standardHourlyBillRate;
            return items;
        }
    }
    return items;
};

export const ProfileSlice = createSlice({
    name: 'profiles',
    initialState: initialState,
    reducers: {
        addProfileSync(state, action) {
            state.list = addItemToList(state.list, action.payload);
        },
        deleteProfileSync(state, action) {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        resetSlice() {
            return initialState;
        },
        selectProfile(state, action) {
            state.selectedProfile = action.payload;
            state.profilePaneIsVisible = true;
        },
        unselectProfile(state) {
            state.selectedProfile = null;
        },
        toggleProfilePane(state) {
            state.profilePaneIsVisible = !state.profilePaneIsVisible;
        },
        updateProfileSync(state, action) {
            state.list = getUpdatedList(state.list, action.payload);
        },
    },
    extraReducers: {
        [addProfile.fulfilled]: (state, action) => {
            state.list = addItemToListWithFinancials(
                state.list,
                action.payload,
            );
        },
        [appStart.fulfilled]: (state, action) => {
            const { profiles } = action.payload.data;
            if (profiles) state.list = profiles;
        },
        [offline.fulfilled]: (state, action) => {
            const { profiles } = action.payload.data;
            if (profiles) state.list = profiles;
        },
        [deleteProfile.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [getProfileDetail.fulfilled]: (state, action) => {
            state.list = addToOrUpdateInList(state.list, action.payload.data);
        },
        [getProfileDetail.rejected]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [updateProfile.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
    },
});

export const profileActions = {
    ...ProfileSlice.actions,
    ...profileActionsAsync,
};

export default ProfileSlice.reducer;
