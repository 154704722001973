import { v4 as uuidv4 } from 'uuid';

export default class Basic {
    constructor(
        id,
        created,
        createdBy,
        createdByName,
        lastUpdated,
        lastUpdatedBy,
    ) {
        // unique identifier
        this.id = id || uuidv4();

        // when the data was created (we accept it being passed in for offline scenarios)
        this.created = created ? new Date(created) : new Date();

        // who created this data (user id = email address)
        this.createdBy = createdBy;

        // who created this data (name)
        this.createdByName = createdByName;

        // when the data was last updated
        this.lastUpdated = lastUpdated ? new Date(lastUpdated) : null;

        // who was the last user to change data (even though we store history entries, it's still good to know who to blame :)
        this.lastUpdatedBy = lastUpdatedBy;
    }
}
