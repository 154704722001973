import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import Dashboard from 'Models/Dashboard';
import Form from 'System/Forms/Components/Form';

export default function DashboardForm({
    dashboard = new Dashboard(),
    onSubmit,
}) {
    const dashboardSchema = yup.object().shape({
        name: yup.string().required('Required'),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: dashboard,
            validationSchema: dashboardSchema,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return dashboard ? (
        <Form onSubmit={handleSubmit} formId="dashboard-form">
            <TextField
                name="name"
                label="Name"
                error={showError('name')}
                helperText={getError('name')}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                autoFocus
                required
            />
        </Form>
    ) : null;
}
