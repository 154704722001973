import { MenuItem, Menu } from '@mui/material';
import { GetPriorityType } from 'System/Pages/WorkItemDetailPage/Components/WorkItemPriority';
import { useState } from 'react';

export default function WorkItemPriorityPicker({ selectedPriority, onChange }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (option) => {
        onChange(option);
        handleClose();
    };

    const workItemPriorityOptions = [0, 1, 2, 3, 4];

    return (
        <>
            <div>
                <div
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {GetPriorityType(selectedPriority ?? 0, false, true)}
                </div>

                <Menu
                    pt={3}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            borderRadius: '16px',
                        },
                    }}
                >
                    {workItemPriorityOptions?.map((option) => (
                        <MenuItem
                            key={option}
                            value={option}
                            onClick={() => handleSelect(option)}
                        >
                            {GetPriorityType(option, true, true)}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </>
    );
}
