import Basic from './Basic';

export default class LayoutItem extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.i = obj.id;

        this.name = obj.name || null;

        this.widgetId = obj.widgetId || null;

        this.dashboardId = obj.dashboardId || null;

        this.x = obj.x || 0;

        this.y = obj.y || 0;

        this.w = obj.width || obj.w || obj.defaultWidth || 6;

        // hack: this throws an error that height is required, yet it performs the way we intend by dynamically
        // sizing the height to the content of the item for specific widgets
        if (!obj.widgetIsAutoExpandHeight) {
            this.h = obj.height || obj.h || obj.defaultHeight || 8;

            if (obj.minH) {
                this.minH = obj.minH;
            }

            if (obj.maxH) {
                this.maxH = obj.maxH;
            }
        } else {
            this.h = obj.height;
        }

        if (obj.minW) {
            this.minW = obj.minW;
        }

        if (obj.maxW) {
            this.maxW = obj.maxW;
        }
    }
}
