import {
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';

export default function TransactionCanceledModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const open = useSelector(modalSelectors.transactionCanceledModal) || false;

    const billingFlag = useSelector(featureFlagSelectors.billingFlag);

    const handleClose = () => {
        navigate('/');
        dispatch(modalActions.close('transactionCanceledModal'));
    };

    const handleSelectPlan = () => {
        navigate('/');
        handleClose();
        dispatch(modalActions.open('subscriptionModal'));
    };

    return (
        <Dialog open={open} scroll="paper" fullWidth={true} maxWidth={'xs'}>
            <DialogTitle>Transaction Canceled</DialogTitle>
            <DialogContent>
                <Typography>
                    Looks like that didnt work. Please try again!
                </Typography>
                <Stack
                    direction="column"
                    justifyContent="center"
                    spacing={1}
                    pt={3}
                >
                    {billingFlag && (
                        <Button onClick={handleSelectPlan} variant="contained">
                            Select a plan
                        </Button>
                    )}

                    <Button onClick={handleClose}>Cancel</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
