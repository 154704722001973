import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Chip, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import UserGroupPicker from 'System/UI/Pickers/UserGroupPicker/UserGroupPicker';
import Form from 'System/Forms/Components/Form';
import { useSelector } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { paymentSelectors } from 'System/State/PaymentState/selectors';
import { PaymentTier } from 'Models/PaymentTiers';
import moment from 'moment';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';

export default function InviteUsersForm({ onSubmit, tier }) {
    const billingFlag = useSelector(featureFlagSelectors.billingFlag);
    const [newEmailValue, setNewEmailValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const readyForBilling = false;

    const addEmail = (emails, email) => {
        email = email.trim();
        return email && isValid(email) ? [...emails, email] : emails;
    };

    const isEmail = (email) =>
        /^[a-zA-Z0-9]+[\w+.-]*@[\w-]+(\.[\w-]+)+$/.test(email);

    const isInList = (email) => values.emails.includes(email);

    const isValid = (email) => {
        let errorMessage = null;

        if (!isEmail(email) && email.length) {
            errorMessage = `${email} is not a valid email address.`;
        }
        if (isInList(email)) {
            errorMessage = `${email} has already been added.`;
        }
        if (errorMessage) {
            setErrorMessage(errorMessage);
            return false;
        }
        return true;
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        const newEmails = paste.split(/[,; ]+/);

        if (!newEmails.length) {
            return;
        }

        const toBeAdded = newEmails.filter(
            (email) => !isInList(email) && isValid(email),
        );
        setFieldValue('emails', [...values.emails, ...toBeAdded], false);

        const invalidEmails = newEmails.filter((email) => !isEmail(email));
        if (invalidEmails.length) {
            setErrorMessage(
                `The following email address(es) are invalid:
                ${invalidEmails.join(', ')}`,
            );
        }
    };

    const handleEmailChange = (e) => {
        setNewEmailValue(e.target.value);
        if (!e.target.value) setErrorMessage(null);
    };

    const handleAddEmail = (e) => {
        e.preventDefault();
        setFieldValue('emails', addEmail(values.emails, newEmailValue), false);
        setNewEmailValue('');
        setErrorMessage('');
    };

    const handleKeyDown = (e) => {
        if (
            ['Enter', 'Tab', ',', ';', ' '].includes(e.key) &&
            isValid(newEmailValue)
        ) {
            handleAddEmail(e);
        }
    };

    const handleDelete = (toDelete) =>
        setFieldValue(
            'emails',
            values.emails.filter((email) => email !== toDelete),
            true,
        );

    const inviteUsersSchema = yup.object().shape({
        emails: yup.array().of(yup.string()).min(1),
        group: yup.string().required('Required'),
    });

    const initialValues = {
        emails: [],
        group: '',
    };

    const {
        values,
        errors,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema: inviteUsersSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onReset: () => {
            setNewEmailValue('');
        },
        onSubmit: (values) => {
            if (!errorMessage) {
                onSubmit(values);
                resetForm({ values: initialValues });
            }
        },
    });

    const handleEmailBlur = (e) => {
        if (isValid(newEmailValue)) {
            handleAddEmail(e);
        } else {
            handleBlur(e);
        }
    };
    /* Add this back in when ready to start collecting payments */
    const proPrice = useSelector(paymentSelectors.professionalPrice);
    const enterprisePrice = useSelector(paymentSelectors.enterprisePrice);
    const subscriptionInfo = useSelector(paymentSelectors.subscriptionInfo);
    const activeUsers = useSelector(companySelectors.activeUsersList);
    const getSubscriptionText = () => {
        let price;
        if (tier === PaymentTier.Pro) price = proPrice;
        if (tier === PaymentTier.Enterprise) price = enterprisePrice;

        const usersLength = activeUsers?.length + values?.emails.length;

        return `You will be charged a prorated amount at $${price}/month
        per user you invite immediately. Starting
        ${moment(subscriptionInfo?.currentPeriodEnd).format('M/DD/YYYY')}
        you will be charged for ${usersLength}
        user${usersLength > 1 ? 's' : ''} at $${price}/month per user for a
        total monthly estimate of $${usersLength * price}.`;
    };

    return (
        <Form onSubmit={handleSubmit} formId="invite-users-form">
            <Box marginBottom={1}>
                {values.emails.map((email) => (
                    <Chip
                        key={email}
                        sx={{ margin: 1 }}
                        onDelete={() => handleDelete(email)}
                        label={email}
                    />
                ))}
            </Box>

            <TextField
                placeholder="Enter email address(es)"
                value={newEmailValue}
                fullWidth
                autoFocus
                onPaste={handlePaste}
                error={Boolean(errorMessage)}
                helperText={errorMessage}
                onKeyDown={handleKeyDown}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
            />

            <UserGroupPicker
                name="group"
                id="group"
                required
                error={Boolean(errors.group)}
                helperText={errors.group}
                onChange={(e) => setFieldValue('group', e.target.value, false)}
                selectedGroup={values.group}
            />
            {readyForBilling && billingFlag && tier !== PaymentTier.Free && (
                <Typography variant="body2" mt={0}>
                    {getSubscriptionText()}
                </Typography>
            )}
        </Form>
    );
}
