import Basic from './Basic';
import moment from 'moment';
import UserSentiment from './UserSentiment';

export default class User extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );
        this.firstName = obj.firstName || '';
        this.lastName = obj.lastName || '';
        this.password = obj.password || '';
        this.phoneNumber = obj.phoneNumber || '';
        this.emailAddress = obj.emailAddress || '';
        this.group = obj.group || 'ReadOnlyNoFinance';
        this.tenantId = obj.tenantId;
        this.isCookieConsent = obj.isCookieConsent;
        this.isActive =
            obj.isActive === null || obj.isActive === undefined
                ? true
                : obj.isActive;
        this.isInitialLogin =
            obj.isInitialLogin === null || obj.isInitialLogin === undefined
                ? true
                : obj.isInitialLogin;
        this.lastLogin = obj.lastLogin
            ? moment(obj.lastLogin).format('MM/DD/YYYY hh:mm:ss a')
            : 'Unknown';

        this.characteristics = obj.characteristics || [];
        this.personalizations = obj.personalizations || {};
        this.sentiments = obj?.sentiments?.length
            ? obj.sentiments.map((sentiment) => new UserSentiment(sentiment))
            : [];
    }

    get isOwner() {
        const groupsAbleToAdmin = [User.groups.Customer];

        return groupsAbleToAdmin.includes(this.group);
    }

    get isAllowedAdmin() {
        const groupsAbleToAdmin = [User.groups.Admin, User.groups.Customer];

        return groupsAbleToAdmin.includes(this.group);
    }

    get isAllowedContribute() {
        const groupsAbleToEdit = [
            User.groups.Admin,
            User.groups.Contributor,
            User.groups.ContributorNoFinance,
            User.groups.Customer,
        ];

        return groupsAbleToEdit.includes(this.group);
    }

    get isAllowedFinancials() {
        const groupsAbleToViewFinance = [
            User.groups.Admin,
            User.groups.Contributor,
            User.groups.Customer,
            User.groups.ReadOnly,
        ];

        return groupsAbleToViewFinance.includes(this.group);
    }

    get formattedName() {
        return `${this.firstName} ${this.lastName}`;
    }

    get initials() {
        return this.formattedName
            ?.trim()
            .split(' ')
            .reduce((acc, curr, index, arr) => {
                if (index === 0 || index === arr.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
    }

    static groups = {
        Admin: 'Admin', // internal system administrators
        Customer: 'Customer', // tenant owner
        Contributor: 'Contributor', // update project information including finance
        ContributorNoFinance: 'ContributorNoFinance', // update project information
        ReadOnly: 'ReadOnly',
        ReadOnlyNoFinance: 'ReadOnlyNoFinance',
    };

    get resourceTimelineTable() {
        return 0;
    }
    static ResourceTimelineTable = 0;
    static JobProfileTable = 1;
    static ProjectTable = 2;
    static WorkItemTable = 3;
    static ResourceTable = 4;
    static ResourceWorkItemTable = 5;
    static LabelSummaryTable = 6;
    static UserTable = 7;
    static TeamTable = 8;
}
