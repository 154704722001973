import { createSelector } from 'reselect';

const options = (state) => state.modal?.options;
const modals = (state) => state.modal?.modals;

const budgetDetailModal = createSelector(
    modals,
    (modals) => modals?.budgetDetailModal,
);

const companyDetailModal = createSelector(
    modals,
    (modals) => modals?.companyDetailModal,
);

const confirmLogoutModal = createSelector(
    modals,
    (modals) => modals?.confirmLogoutModal,
);

const costDetailModal = createSelector(
    modals,
    (modals) => modals?.costDetailModal,
);

const editProjectNameModal = createSelector(
    modals,
    (modals) => modals?.editProjectNameModal,
);

const epicDetailModal = createSelector(
    modals,
    (modals) => modals?.epicDetailModal,
);

const inviteUsersModal = createSelector(
    modals,
    (modals) => modals?.inviteUsersModal,
);

const linkProjectToJiraModal = createSelector(
    modals,
    (modals) => modals?.linkProjectToJiraModal,
);

const milestoneDetailModal = createSelector(
    modals,
    (modals) => modals?.milestoneDetailModal,
);

const myProfileModal = createSelector(
    modals,
    (modals) => modals?.myProfileModal,
);

const newBudgetModal = createSelector(
    modals,
    (modals) => modals?.newBudgetModal,
);

const newDashboardModal = createSelector(
    modals,
    (modals) => modals?.newDashboardModal,
);

const newEpicModal = createSelector(modals, (modals) => modals?.newEpicModal);

const newMilestoneModal = createSelector(
    modals,
    (modals) => modals?.newMilestoneModal,
);

const newNonLaborExpenseModal = createSelector(
    modals,
    (modals) => modals?.newNonLaborExpenseModal,
);

const newProfileModal = createSelector(
    modals,
    (modals) => modals?.newProfileModal,
);

const newProjectModal = createSelector(
    modals,
    (modals) => modals?.newProjectModal,
);

const newProjectResourceTimelineModal = createSelector(
    modals,
    (modals) => modals?.newProjectResourceTimelineModal,
);

const newResourceModal = createSelector(
    modals,
    (modals) => modals?.newResourceModal,
);

const newTeamModal = createSelector(modals, (modals) => modals?.newTeamModal);

const newWorkItemModal = createSelector(
    modals,
    (modals) => modals?.newWorkItemModal,
);

const nonLaborExpenseDetailModal = createSelector(
    modals,
    (modals) => modals?.nonLaborExpenseDetailModal,
);

const profileDetailModal = createSelector(
    modals,
    (modals) => modals?.profileDetailModal,
);

const progressDetailModal = createSelector(
    modals,
    (modals) => modals?.progressDetailModal,
);

const projectDetailModal = createSelector(
    modals,
    (modals) => modals?.projectDetailModal,
);

const resendCodeModal = createSelector(
    modals,
    (modals) => modals?.resendCodeModal,
);

const resourceDetailModal = createSelector(
    modals,
    (modals) => modals?.resourceDetailModal,
);

const resourceTimelineDetailModal = createSelector(
    modals,
    (modals) => modals?.resourceTimelineDetailModal,
);

const scheduleDetailModal = createSelector(
    modals,
    (modals) => modals?.scheduleDetailModal,
);

const teamDetailModal = createSelector(
    modals,
    (modals) => modals?.teamDetailModal,
);

const unavailablePeriodModal = createSelector(
    modals,
    (modals) => modals?.unavailablePeriodModal,
);

const userDetailModal = createSelector(
    modals,
    (modals) => modals?.userDetailModal,
);

const walkthroughModal = createSelector(
    modals,
    (modals) => modals?.walkthroughModal,
);

const editWorkItemNameModal = createSelector(
    modals,
    (modals) => modals?.editWorkItemNameModal,
);

const editProjectPhaseNameModal = createSelector(
    modals,
    (modals) => modals?.editProjectPhaseNameModal,
);

const newProjectPhaseModal = createSelector(
    modals,
    (modals) => modals?.newProjectPhaseModal,
);

const welcomeToFairviewModal = createSelector(
    modals,
    (modals) => modals?.welcomeToFairviewModal,
);

const subscriptionModal = createSelector(
    modals,
    (modals) => modals?.subscriptionModal,
);

const transactionCanceledModal = createSelector(
    modals,
    (modals) => modals?.transactionCanceledModal,
);

const upgradeSuccessfulModal = createSelector(
    modals,
    (modals) => modals.upgradeSuccessfulModal,
);

const cancelSubscriptionModal = createSelector(
    modals,
    (modals) => modals?.cancelSubscriptionModal,
);

const copyProjectModal = createSelector(
    modals,
    (modals) => modals?.copyProjectModal,
);

const makePrivateModal = createSelector(
    modals,
    (modals) => modals?.makePrivateModal,
);

const showProjectSelectorForNewWorkItem = createSelector(
    options,
    (opts) => opts?.showProjectSelectorNewWorkItem ?? false,
);

export const modalSelectors = {
    nonLaborExpenseDetailModal,
    budgetDetailModal,
    cancelSubscriptionModal,
    companyDetailModal,
    confirmLogoutModal,
    costDetailModal,
    editProjectNameModal,
    epicDetailModal,
    inviteUsersModal,
    linkProjectToJiraModal,
    milestoneDetailModal,
    myProfileModal,
    newBudgetModal,
    newDashboardModal,
    newEpicModal,
    newMilestoneModal,
    newNonLaborExpenseModal,
    newProfileModal,
    newProjectModal,
    newProjectResourceTimelineModal,
    newResourceModal,
    newTeamModal,
    newWorkItemModal,
    newProjectPhaseModal,
    progressDetailModal,
    profileDetailModal,
    projectDetailModal,
    resendCodeModal,
    resourceDetailModal,
    resourceTimelineDetailModal,
    scheduleDetailModal,
    subscriptionModal,
    teamDetailModal,
    transactionCanceledModal,
    unavailablePeriodModal,
    upgradeSuccessfulModal,
    userDetailModal,
    walkthroughModal,
    editWorkItemNameModal,
    editProjectPhaseNameModal,
    welcomeToFairviewModal,
    copyProjectModal,
    showProjectSelectorForNewWorkItem,
    makePrivateModal,
};
