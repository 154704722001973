import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '35px 25px',
        [theme.breakpoints.down('lg')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.down('md')]: {
            margin: 0,
            textAlign: 'left',
        },

        '& h3': {
            marginBottom: 10,
            fontSize: '30pt',
            fontWeight: 'bold',
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
                fontSize: '28pt',
                textAlign: 'left',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '26pt',
            },
        },
        '& p': {
            fontSize: '16pt',
            textAlign: 'center',
            marginBottom: 30,
            padding: '1rem 10rem',
            [theme.breakpoints.down('md')]: {
                fontSize: '15pt',
                textAlign: 'left',
                padding: 0,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '13pt',
            },
        },
        '& h4': {
            fontWeight: '600',
            marginBottom: 3,
        },
        '& img': {
            width: '100%',
            maxHeight: 650,
        },
        '& ul': {
            listStyleType: 'none',
            paddingRight: 40,
            [theme.breakpoints.down('md')]: {
                padding: 0,
            },
        },
        '& a': {
            color: '#F37A42',
            fontSize: '15pt',
            fontWeight: '600',
            textDecoration: 'none',
            paddingLeft: 40,
            [theme.breakpoints.down('md')]: {
                padding: 0,
                fontSize: '13pt',
            },
        },
    },
    innerContainer: {
        margin: 'auto',
        maxWidth: 1200,
        [theme.breakpoints.down('md')]: {
            '& div:last-child': {
                order: '-1',
            },
        }
    },
    list: {
        paddingBottom: 25,
    },
    listItem: {
        textAlign: 'left',
        fontSize: '15pt',
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: '13pt',
        },
    },
    arrow: {
        marginBottom: '-3px',
    },
    alternate: {
        background: '#C4C4C41A',
    },
}));

export default function Feature(props) {
    const { title, subtitle, imgUrl, copy, linkText, alternate, videoUrl, videoPreviewImageUrl } = props;
    const classes = useStyles();
    const appUrl = process.env.REACT_APP_URL;

    return (
        <Grid container className={`${classes.root} ${alternate ? classes.alternate : ''}`}>
            <Grid container className={classes.innerContainer}>
                <Grid item md={12}>
                    <Typography component="h3" variant="h2">{title}</Typography>
                    <Typography component="p">{subtitle}</Typography>
                </Grid>
                <Grid item md={6} className={classes.list}>
                    <ul>
                        {copy?.map(data => (
                            <li className={classes.listItem} key={data.id}>
                                <h4>{data.point}</h4>
                                <div>{data.description}</div>
                            </li>
                        ))}
                    </ul>
                    <a href={appUrl + "/signup"} aria-label="Sign up">
                        {linkText} <ArrowForwardIcon fontSize='small' className={classes.arrow} />
                    </a>
                </Grid>
                <Grid item md={6}>
                    {!videoUrl && <img src={imgUrl} alt={props.title}></img>}
                    
                    {videoUrl && 
                    <video autoPlay loop muted playsInline preload="metadata" poster={videoPreviewImageUrl} width="100%">
                        <source
                            src={videoUrl + "#t=0.5"}
                            type="video/mp4"
                        />
                    </video>}
                </Grid>
            </Grid>
        </Grid>
    );
}
