import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Stack,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { projectNonLaborExpenseActions } from 'System/State/ProjectState/reducer';
import NonLaborExpenseForm from 'System/Forms/NonLaborExpenseForm/NonLaborExpenseForm';
import { useState } from 'react';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';

export default function NonLaborExpenseDetailModal() {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(modalSelectors.nonLaborExpenseDetailModal);
    const selectedNonLaborExpense = useSelector(
        projectSelectors.selectedNonLaborExpense,
    );
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleClose = () =>
        dispatch(modalActions.close('nonLaborExpenseDetailModal'));

    const handleSubmit = (cost) => {
        dispatch(
            projectNonLaborExpenseActions.updateProjectNonLaborExpense(cost),
        );
        handleClose();
    };

    const handleDelete = (cost) => {
        dispatch(
            projectNonLaborExpenseActions.deleteNonLaborExpenseFromProject(
                cost,
            ),
        );
        handleClose();
    };

    if (!selectedNonLaborExpense) return null;

    return (
        <>
            <Dialog
                open={isModalOpen}
                onClose={handleClose}
                scroll="paper"
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="Additional Cost Detail"
                aria-describedby="Update an additional cost"
            >
                <DialogTitle>Additional Cost Detail</DialogTitle>
                <DialogContent>
                    <NonLaborExpenseForm
                        nonLaborExpense={selectedNonLaborExpense}
                        onSubmit={handleSubmit}
                    />
                </DialogContent>

                <DialogActions>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ width: '100%' }}
                    >
                        <Button
                            variant="outlined"
                            color="tertiary"
                            onClick={() => {
                                setDeleteModalOpen(true);
                            }}
                        >
                            Delete
                        </Button>
                        <Stack direction="row" spacing={1}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                form="additional-cost-form"
                            >
                                Save Cost
                            </Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>

            <ConfirmDeleteModal
                isModalOpen={isDeleteModalOpen}
                parentType="Additional Cost"
                textConfirmation={false}
                confirmDelete={() => {
                    setDeleteModalOpen(false);
                    handleDelete(selectedNonLaborExpense);
                }}
                cancelDelete={() => {
                    setDeleteModalOpen(false);
                }}
            />
        </>
    );
}
