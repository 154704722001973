import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    panes: {
        epicDetailPane: false,
        settingsPane: false,
        jiraDetailPane: false,
    },
    settingsPaneSelectedTabIndex: 0,
    projectDetailPaneSelectedTabIndex: 0,
};

export const PaneSlice = createSlice({
    name: 'pane',
    initialState: initialState,
    reducers: {
        open(state, action) {
            // state.panes = { ...initialState.panes };
            state.panes[action.payload] = true;
        },
        close(state, action) {
            state.panes[action.payload] = false;
        },
        closeAll() {
            return initialState;
        },
        openSettingsWithSelectedTab(state, action) {
            state.panes.settingsPane = true;
            state.settingsPaneSelectedTabIndex = action.payload;
        },
        setSettingsSelectedTab(state, action) {
            state.settingsPaneSelectedTabIndex = action.payload;
        },
        setProjectDetailSelectedTab(state, action) {
            state.settingsPaneSelectedTabIndex = action.payload;
        },
    },
});

export const paneActions = {
    ...PaneSlice.actions,
};

export default PaneSlice.reducer;
