import { createSelector } from 'reselect';
import Profile from 'Models/Profile';

const profileSelector = (state) => state.profiles;
const selectedProfileId = (state) => state.profiles?.selectedProfile;

const allProfiles = createSelector(profileSelector, (profiles) => {
    return profiles?.list?.length
        ? profiles.list
              .slice()
              .sort((a, b) => {
                  if (a.name < b.name) {
                      return -1;
                  }
                  if (a.name > b.name) {
                      return 1;
                  }
                  return 0;
              })
              .map((profile) => new Profile(profile))
        : [];
});

const selectedProfile = createSelector(
    allProfiles,
    selectedProfileId,
    (profiles, profileId) => {
        return profiles?.length && profileId
            ? profiles.find((profile) => profile.id === profileId)
            : null;
    },
);

export const profileSelectors = {
    allProfiles,
    selectedProfile,
};
