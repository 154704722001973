import { TextField, InputAdornment } from '@mui/material';

const FinancialField = ({
    value = 0,
    label,
    name,
    isError,
    errorMsg,
    onChange,
    onBlur,
    required,
}) => (
    <TextField
        name={name}
        label={label}
        fullWidth
        required={required}
        value={value}
        error={isError}
        helperText={errorMsg}
        onChange={onChange}
        onBlur={onBlur}
        InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
    />
);

export default FinancialField;
