import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modals: {
        budgetDetailModal: false,
        cancelSubscriptionModal: false,
        companyDetailModal: false,
        confirmLogoutModal: false,
        costDetailModal: false,
        editProjectNameModal: false,
        editWorkItemNameModal: false,
        editProjectPhaseNameModal: false,
        epicDetailModal: false,
        inviteUsersModal: false,
        linkProjectToJiraModal: false,
        milestoneDetailModal: false,
        myProfileModal: false,
        newNonLaborExpenseModal: false,
        newBudgetModal: false,
        newDashboardModal: false,
        newEpicModal: false,
        newMilestoneModal: false,
        newProjectModal: false,
        newProfileModal: false,
        newProjectResourceTimelineModal: false,
        newResourceModal: false,
        newTeamModal: false,
        newWorkItemModal: false,
        newProjectPhaseModal: false,
        nonLaborExpenseDetailModal: false,
        progressDetailModal: false,
        profileDetailModal: false,
        projectDetailModal: false,
        projectFinancialModal: false,
        resendCodeModal: false,
        resourceDetailModal: false,
        resourceTimelineDetailModal: false,
        scheduleDetailModal: false,
        subscriptionModal: false,
        teamDetailModal: false,
        transactionCanceledModal: false,
        unavailablePeriodModal: false,
        upgradeSuccessfulModal: false,
        userDetailModal: false,
        walkthroughModal: false,
        welcomeToFairviewModal: false,
        copyProjectModal: false,
        makePrivateModal: false,
    },
    options: {
        showProjectSelectorNewWorkItem: false,
    },
};

export const ModalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        open(state, action) {
            state.modals[action.payload] = true;
        },
        close(state, action) {
            state.modals[action.payload] = false;
        },
        closeAll() {
            return initialState;
        },
        setProjectSelectorForWorkItemsVisibility(state, action) {
            state.options.showProjectSelectorNewWorkItem =
                action?.payload ?? false;
        },
    },
});

export const modalActions = {
    ...ModalSlice.actions,
};

export default ModalSlice.reducer;
