import Basic from './Basic';

export default class WorkItemHistory extends Basic {
    static workItemCreatedId = 0;
    static nameChangedId = 1;
    static descriptionChangedId = 2;
    static statusChangedId = 3;
    static typeChangedId = 4;
    static priorityChangedId = 5;
    static pointsEstimateChangedId = 6;
    static resourceAssignedId = 7;
    static resourceUnassignedId = 8;
    static labelAddedId = 9;
    static labelRemovedId = 10;
    static resolutionDateChanged = 11;
    static hoursCompletedChangedId = 12;
    static hoursEstimateChangedId = 13;
    static integrationIdChangedId = 14;
    static integrationKeyChangedId = 15;
    static workItemDisabledId = 16;
    static projectEpicChangedId = 17;
    static projectPhaseChangedId = 18;
    static projectChangedId = 19;
    static fairviewSourceId = 0;
    static jiraSourceId = 1;
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.newValue = obj.newValue || '';

        this.previousValue = obj.previousValue || '';

        this.source = obj.source;

        this.sourceName = obj.sourceName || '';

        this.type = obj.type;

        this.typeName = obj.typeName || '';

        this.workItemId = obj.workItemId;
    }

    get jiraSourceId() {
        return WorkItemHistory.jiraSourceId;
    }

    get workItemCreatedId() {
        return 0;
    }

    get nameChangedId() {
        return 1;
    }

    get descriptionChangedId() {
        return 2;
    }

    get statusChangedId() {
        return 3;
    }

    get typeChangedId() {
        return 4;
    }

    get priorityChangedId() {
        return 5;
    }

    get pointsEstimateChangedId() {
        return 6;
    }

    get resourceAssignedId() {
        return 7;
    }

    get resourceUnassignedId() {
        return 8;
    }

    get labelAddedId() {
        return 9;
    }

    get labelRemovedId() {
        return 10;
    }

    get resolutionDateChanged() {
        return 11;
    }

    get hoursCompletedChangedId() {
        return 12;
    }

    get hoursEstimateChangedId() {
        return 13;
    }

    get integrationIdChangedId() {
        return 14;
    }

    get integrationKeyChangedId() {
        return 15;
    }

    get workItemDisabledId() {
        return 16;
    }

    get projectEpicChangedId() {
        return 17;
    }

    get projectPhaseChangedId() {
        return 18;
    }

    get projectChangedId() {
        return 19;
    }

    get projectCopied() {
        return 20;
    }
}
