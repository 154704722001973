import { useSelector } from 'react-redux';
import { Tooltip, Chip } from '@mui/material';
import { projectSelectors } from 'System/State/ProjectState/selectors';

export default function ProjectKeyChip({ tooltipPlacement }) {
    const project = useSelector(projectSelectors.selectedProject);

    return project ? (
        <Tooltip title={project.name} placement={tooltipPlacement || 'bottom'}>
            <Chip label={project.projectKey || project.abbreviatedName} />
        </Tooltip>
    ) : null;
}
