import { useSelector } from 'react-redux';
import { MenuItem, Menu } from '@mui/material';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { GetTypeItem } from 'System/Pages/WorkItemDetailPage/Components/WorkItemType';
import { useState } from 'react';

export default function WorkItemTypePicker({ selectedType, onChange }) {
    const workItemTypeOptions = useSelector(
        projectSelectors.workItemTypeOptions,
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (type) => {
        onChange(type);
        handleClose();
    };

    return (
        <>
            <div>
                <div
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    {GetTypeItem(selectedType ?? 0)}
                </div>

                <Menu
                    pt={3}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            borderRadius: '16px',
                        },
                    }}
                >
                    {workItemTypeOptions?.map((option) => (
                        <MenuItem
                            key={option.type}
                            value={option.type}
                            onClick={() => handleSelect(option.type)}
                        >
                            {GetTypeItem(option.type, true)}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        </>
    );
}
