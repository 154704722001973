import { Box, CircularProgress, Dialog } from '@mui/material';
import LoadingImage from '../../assets/loading_image.svg';

export default function AppLoadingModal({ open }) {
    return (
        <Dialog
            open={open}
            sx={{ overflow: 'hidden' }}
            PaperProps={{
                sx: { borderRadius: 3 },
            }}
            scroll="body"
        >
            <Box position="relative" sx={{ background: '#52A3FF' }}>
                <img style={{ width: '100%' }} src={LoadingImage} />
                <Box position="absolute" bottom={40} left="46%">
                    <CircularProgress sx={{ color: 'white' }} />
                </Box>
            </Box>
        </Dialog>
    );
}
