import { Stack, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SectionHeader = ({ text, tooltipText }) => (
    <Stack direction="row" spacing={1}>
        <Typography variant="h5">{text}</Typography>
        {tooltipText ? (
            <Tooltip title={tooltipText} arrow placement="top-start">
                <InfoOutlinedIcon fontSize="small" sx={{ color: '#999' }} />
            </Tooltip>
        ) : null}
    </Stack>
);

export default SectionHeader;
