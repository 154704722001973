import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Stack,
} from '@mui/material';

export default function MismatchedDatesModal({
    isModalOpen = false,
    selectedProject,
    newProjectResourceTimeline,
    onCancel,
    onConfirmKeepDates,
    onConfirmExtendDates,
}) {
    const dateFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    };

    const handleClose = () => {
        onCancel();
    };

    const getText = () => {
        return <>The timeline you added has timeline dates that are oustide of the project&apos;s current start and end dates. Choose one of the options below to continue.</>;
    };

    const getProjectDates = () => {
        const startDate = selectedProject?.estimatedStartDate?.toLocaleDateString('en-US', dateFormatOptions);
        const endDate = selectedProject?.estimatedCompletionDate?.toLocaleDateString('en-US', dateFormatOptions);
        return <>Project Dates: {startDate} - {endDate} </>;
    };

    const getTimelineDates = () => {
        const startDate = newProjectResourceTimeline?.startDate?.toLocaleDateString('en-US', dateFormatOptions);
        const endDate = newProjectResourceTimeline?.endDate?.toLocaleDateString('en-US', dateFormatOptions);
        return <>Timeline Dates: {startDate} - <span style={{ color: 'red' }}>{endDate}</span> </>;
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby="Confirm Action"
            aria-describedby="Confirm the request"
        >
            <DialogTitle>
                {'Mismatched Dates'}
            </DialogTitle>
            <DialogContent sx={{ paddingTop: '0 !important' }}>
                <Stack spacing={2}>
                    <Typography variant="body1">{getText()}</Typography>
                    <Typography variant="body1">{getProjectDates()}</Typography>
                    <Typography variant="body1">{getTimelineDates()}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ marginBottom: 1 }}>
                <Button
                    onClick={onCancel}
                >
                    {'Go Back'}
                </Button>

                <Button
                    variant="outlined"
                    onClick={onConfirmKeepDates}
                >
                    {'Keep Mismatched Dates'}
                </Button>

                <Button
                    variant="contained"
                    onClick={onConfirmExtendDates}
                >
                    {'Extend Project Dates'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}