import { TextField, MenuItem } from '@mui/material';
import ColorUtility from 'Utilities/color-utility';

export default function ColorPicker(props) {
    const { name, onChange, fullWidth } = props;
    const selectedColor = props.selectedColor || '';
    const colors = ColorUtility.getShortSpectrum();

    const handleInputChange = (e) => {
        onChange({
            target: {
                name: name,
                value: e.target.value,
            },
        });
    };

    return (
        <TextField
            name={name}
            value={selectedColor}
            onChange={handleInputChange}
            label="Color"
            sx={{ width: fullWidth ? '100%' : '175px' }}
            select
            SelectProps={{
                labelId: 'color-label',
                displayEmpty: true,
            }}
        >
            <MenuItem key="#fff" value="#fff"></MenuItem>
            {colors.map((color) => (
                <MenuItem key={color} value={color}>
                    <span
                        style={{
                            borderRadius: 5,
                            borderColor: '#ccc',
                            width: 50,
                            backgroundColor: color,
                        }}
                    >
                        &nbsp;
                    </span>
                </MenuItem>
            ))}
        </TextField>
    );
}
