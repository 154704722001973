import { combineReducers, configureStore, isPlain } from '@reduxjs/toolkit';
import { splitReducer } from '@splitsoftware/splitio-redux';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import coreSliceReducer from './CoreState/reducer';
import profileSliceReducer from './ProfileState/reducer';
import projectSliceReducer from './ProjectState/reducer';
import resourceSliceReducer from './ResourceState/reducer';
import teamSliceReducer from './TeamState/reducer';
import companySliceReducer from './CompanyState/reducer';
import userSliceReducer from './UserState/reducer';
import summarySliceReducer from './SummaryState/reducer';
import dashboardSliceReducer from './DashboardState/reducer';
import modalSliceReducer from './ModalState/reducer';
import paneSliceReducer from './PaneState/reducer';
import milestoneSliceReducer from './MilestoneState/reducer';
import paymentSliceReducer from './PaymentState/reducer';
import insightsSliceReducer from './InsightsState/reducer';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['featureFlags'],
};

const staticReducers = combineReducers({
    core: coreSliceReducer,
    dashboards: dashboardSliceReducer,
    profiles: profileSliceReducer,
    projects: projectSliceReducer,
    resources: resourceSliceReducer,
    teams: teamSliceReducer,
    summary: summarySliceReducer,
    company: companySliceReducer,
    milestones: milestoneSliceReducer,
    user: userSliceReducer,
    modal: modalSliceReducer,
    pane: paneSliceReducer,
    insights: insightsSliceReducer,
    featureFlags: splitReducer,
    payments: paymentSliceReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'core/appPurge') {
        state = undefined;
    }
    return staticReducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                isSerializable: (value) =>
                    isPlain(value) || value instanceof Date,
                ignoredActionPaths: ['payload', 'meta.arg'],
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
                ignoredPaths: [
                    'insights',
                    'user.loggedInUser',
                    'user.cognitoUserForForcedPassword',
                    'projects.filterOptions.selectedBudgets',
                ],
            },
        }),
    // middleware: [thunk],
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
