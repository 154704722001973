import { createAsyncThunk } from '@reduxjs/toolkit';
import RestAPI from '../rest-api';

const addDashboard = createAsyncThunk(
    'dashboards/addDashboard',
    async (dashboard) => {
        await RestAPI.post('/dashboards', { body: dashboard });
        return { ...dashboard };
    },
);

const getDashboardDetail = createAsyncThunk(
    'dashboards/getDashboardDetail',
    async (dashboard) => {
        return await RestAPI.get(`/dashboards/${dashboard.id}`);
    },
);

const selectDashboard = createAsyncThunk(
    'dashboards/selectDashboad',
    async (dashboard) => {
        await RestAPI.patch('/me/personalize', {
            body: { lastSelectedDashboardId: dashboard.id },
        });
        
        return structuredClone(dashboard);
    },
);

const updateDashboard = createAsyncThunk(
    'dashboards/updateDashboard',
    async (dashboard) => {
        await RestAPI.put(`/dashboards/${dashboard.id}`, { body: dashboard });
        return { ...dashboard };
    },
);

const disableDashboard = createAsyncThunk(
    'dashboards/disableDashboard',
    async (dashboardId) => {
        await RestAPI.del(`/dashboards/${dashboardId}`);
        return dashboardId;
    },
);

const deleteDashboard = createAsyncThunk(
    'dashboards/deleteDashboard',
    async (dashboardId) => {
        await RestAPI.del(`/dashboards/${dashboardId}/permanent`);
        return dashboardId;
    },
);

const pinDashboard = createAsyncThunk(
    'dashboards/pinDashboard',
    async (dashboardId) => {
        await RestAPI.put(`/dashboards/${dashboardId}/pin`);
        return dashboardId;
    },
);

export const dashboardActionsAsync = {
    addDashboard,
    deleteDashboard,
    disableDashboard,
    getDashboardDetail,
    selectDashboard,
    updateDashboard,
    pinDashboard,
};
