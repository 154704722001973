import './Editor.css';
import { AtomicBlockUtils, Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useState, useRef } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { coreActionsAsync } from 'System/State/CoreState/actions';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import Toolbar from './Components/Toolbar/Toolbar';

const useStyles = makeStyles(() => ({
    rteEditor: {
        border: '0.5px solid rgba(0,0,0,0.2)',
        borderRadius: '8px',
        padding: '5px 5px 10px 10px ',
        transition: 'all 0.3s',
        zIndex: 1000,
        '&:hover': {
            border: '0.5px solid rgba(0,0,0,0.5)',
        },
    },
    rteEditorClosed: {
        minHeight: '150px',
        overflow: 'auto',
        borderRadius: '8px',
        padding: '5px 5px 10px 10px ',
        transition: 'all 0.3s',
        cursor: 'default',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export default function RichTextEditor({
    parentId = null,
    editMode = false,
    editorState,
    setEditorState,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editorRef = useRef(null);

    const [isHovered, setIsHovered] = useState(false);
    const [, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: (item) => {
            handleAddedFiles(item?.files);
        },
        collect: (monitor) => {
            monitor.isOver() ? setIsHovered(true) : setIsHovered(false);
        },
    });

    const focusEditor = () => {
        if (editMode) {
            editorRef.current.focus();
        }
    };

    const handleKeyCommand = (command) => {
        var newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onTab = (e) => {
        e.preventDefault();
        const newState = RichUtils.onTab(e, editorState, 4);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        } else {
            return 'not-handled';
        }
    };

    const handleAddedFiles = async (files) => {
        if (files?.length > 0) {
            const file = files[0];
            const fileData = await uploadInlineAttachment(file);
            if (fileData) {
                switch (fileData?.data?.mimeType?.split('/')[0]) {
                    case 'image':
                        createAttachmentEntity(fileData, 'image');
                        break;
                    case 'video':
                        createAttachmentEntity(fileData, 'video');
                        break;
                }
            }
        }
    };

    const insertAtomicBlock = (contentStateWithEntity) => {
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });

        setEditorState(
            AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '),
        );
    };

    const createAttachmentEntity = (file, entityType) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            entityType,
            'IMMUTABLE',
            {
                id: file?.data.id,
                filename: file?.data.filename,
                mimetype: file?.data.mimeType,
            },
        );
        insertAtomicBlock(contentStateWithEntity);
    };

    const generateBinaryFileAsync = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    const uploadAttachment = async (file) => {
        const uploadPresignedUrl = await dispatch(
            coreActionsAsync.requestAttachmentUploadLink(file),
        );
        const headers = {
            'Content-Type': file.mimeType,
            'x-amz-tagging': 'TemporaryWorkItemAttachment=true',
        };

        const options = {
            headers: headers,
        };

        await axios.put(
            uploadPresignedUrl?.payload?.data,
            file.attachment,
            options,
        );
    };

    const uploadInlineAttachment = (file) => {
        return new Promise((resolve) => {
            setTimeout(async () => {
                const generate = await generateBinaryFileAsync(file);
                let binary = atob(generate?.split(',')[1]);

                let array = [];
                for (var i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }

                let blobData = new Blob([new Uint8Array(array)], {
                    type: file.type,
                    size: file.size,
                });

                const attachment = {
                    attachmentId: uuidv4(),
                    businessEntityId: parentId,
                    filename: file?.name,
                    mimeType: file?.type,
                    isTemporary: true,
                    attachment: blobData,
                };

                await uploadAttachment(attachment);

                resolve({
                    data: {
                        // url: url,
                        id: attachment.attachmentId,
                        filename: attachment.filename,
                        mimeType: attachment.mimeType,
                    },
                });
            }, 750);
        });
    };

    const inlineStyles = {
        HIGHLIGHT: {
            backgroundColor: '#FFAB00',
        },
        CODEBLOCK: {
            backgroundColor: '#f6f8fa',
            borderRadius: '4px',
            padding: '10px',
            fontFamily: 'monospace',
            fontSize: '14px',
            lineHeight: '1.5',
            color: '#333',
        },
        LINK: {
            color: 'red',
        },
    };

    const blockStyleFn = (contentBlock) => {
        const type = contentBlock.getType();
        switch (type) {
            case 'blockQuote':
                return 'blockQuote';
            default:
                break;
        }
    };

    return (
        <>
            <Box
                className={
                    editMode ? classes.rteEditor : classes.rteEditorClosed
                }
                ref={drop}
                sx={{
                    border:
                        isHovered && editMode
                            ? '0.5px solid #FDCA08 !important'
                            : '',
                }}
            >
                {editMode && (
                    <Toolbar
                        editorState={editorState}
                        setEditorState={setEditorState}
                        focusEditor={focusEditor}
                        handleAddedFiles={handleAddedFiles}
                    />
                )}

                <div style={{ minHeight: '100px' }} onClick={focusEditor}>
                    <Editor
                        readOnly={!editMode}
                        ref={editorRef}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        onChange={setEditorState}
                        customStyleMap={inlineStyles}
                        blockStyleFn={blockStyleFn}
                        handlePastedFiles={handleAddedFiles}
                        onTab={onTab}
                        spellCheck
                    />
                </div>
            </Box>
        </>
    );
}
