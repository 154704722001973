export const toSet = (arr = [], keySelector = (item) => item) =>
    arr.reduce((acc, cur) => acc.add(keySelector(cur)), new Set());

export const toMap = (
    arr = [],
    keySelector = (item) => item?.id,
    valueSelector = (item) => item,
) =>
    arr.reduce(
        (acc, cur) => acc.set(keySelector(cur), valueSelector(cur)),
        new Map(),
    );

export const toGroupMap = (
    arr = [],
    keySelector = (item) => item?.id,
    valueSelector = (item) => item,
) =>
    arr.reduce((acc, cur) => {
        const group = keySelector(cur);
        const existing = acc.get(group);
        const item = valueSelector(cur);

        return acc.set(group, !existing ? [item] : [...existing, item]);
    }, new Map());
