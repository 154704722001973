import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelectors } from 'System/State/UserState/selectors';
import { companySelectors } from 'System/State/CompanyState/selectors';
import MarketingLayout from 'Marketing/Layout/MarketingLayout';
import HomePage from 'Marketing/Pages/HomePage/HomePage';
import { PaymentTier } from 'Models/PaymentTiers';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import { Container } from '@mui/material';

const MyProfilePage = React.lazy(() =>
    import('System/Pages/MyProfilePage/MyProfilePage'),
);
const RequestDemoPage = React.lazy(() =>
    import('Marketing/Pages/RequestDemoPage/RequestDemoPage'),
);

const HealthPage = React.lazy(() =>
    import('Marketing/Pages/HealthPage/HealthPage'),
);
const PricingPage = React.lazy(() =>
    import('Marketing/Pages/PricingPage/PricingPage'),
);
const PrivacyPolicyPage = React.lazy(() =>
    import('Marketing/Pages/PrivacyPolicyPage/PrivacyPolicyPage'),
);
const TermsAndConditionsPage = React.lazy(() =>
    import('Marketing/Pages/TermsAndConditionsPage/TermsAndConditionsPage'),
);
const CustomerServicePolicyPage = React.lazy(() =>
    import(
        'Marketing/Pages/CustomerServicePolicyPage/CustomerServicePolicyPage'
    ),
);
const SecurityPolicyPage = React.lazy(() =>
    import('Marketing/Pages/SecurityPolicyPage/SecurityPolicyPage'),
);

const ContactUsPage = React.lazy(() =>
    import('System/Pages/ContactUsPage/ContactUsPage'),
);
const ContactUsSuccessPage = React.lazy(() =>
    import('System/Pages/ContactUsSuccessPage/ContactUsSuccessPage'),
);
const ForgotPasswordPage = React.lazy(() =>
    import('System/Pages/ForgotPasswordPage/ForgotPasswordPage'),
);
const ForcePasswordChangePage = React.lazy(() =>
    import('System/Pages/ForcePasswordChangePage/ForcePasswordChangePage'),
);
const ResetPasswordPage = React.lazy(() =>
    import('System/Pages/ResetPasswordPage/ResetPasswordPage'),
);
const SignupPage = React.lazy(() =>
    import('System/Pages/SignupPage/SignupPage'),
);
const SignupVerificationPage = React.lazy(() =>
    import('System/Pages/SignupVerificationPage/SignupVerificationPage'),
);
const SignOutPage = React.lazy(() =>
    import('System/Pages/SignOutPage/SignOutPage'),
);
const SignInPage = React.lazy(() =>
    import('System/Pages/SignInPage/SignInPage'),
);
const DashboardPage = React.lazy(() =>
    import('System/Pages/DashboardPage/DashboardPage'),
);
const ProjectsPage = React.lazy(() =>
    import('System/Pages/ProjectsPage/ProjectsPage'),
);
const ProjectDetailPage = React.lazy(() =>
    import('System/Pages/ProjectDetailPage/ProjectDetailPage'),
);
const DashboardEditPage = React.lazy(() =>
    import('System/Pages/DashboardEditPage/DashboardEditPage'),
);
const BrandPage = React.lazy(() => import('System/Pages/BrandPage/BrandPage'));
const EmailInvitePage = React.lazy(() =>
    import('System/Pages/EmailInvitePage/EmailInvitePage'),
);
const EmailWelcomePage = React.lazy(() =>
    import('System/Pages/EmailWelcomePage/EmailWelcomePage'),
);
const AppLayout = React.lazy(() => import('System/Layout/AppLayout'));
const ErrorPage = React.lazy(() => import('System/Pages/ErrorPage/ErrorPage'));
const NotFoundPage = React.lazy(() =>
    import('System/Pages/NotFoundPage/NotFoundPage'),
);

const ReportsPage = React.lazy(() =>
    import('System/Pages/ReportsPage/ReportsPage'),
);
const MonthlyExpenseReportPage = React.lazy(() =>
    import(
        'System/Pages/ReportsPage/Reports/MonthlyExpenseReport/MonthlyExpenseReport'
    ),
);
const MonthlyRevenueMarginReportPage = React.lazy(() =>
    import(
        'System/Pages/ReportsPage/Reports/MonthlyRevenueMarginReport/MonthlyRevenueMarginReport'
    ),
);
const SentimentReportPage = React.lazy(() =>
    import('System/Pages/ReportsPage/Reports/SentimentReport/SentimentReport'),
);
const IdpResponsePage = React.lazy(() =>
    import('System/Pages/IdpResponsePage/IdpResponsePage'),
);
const ResourcesPage = React.lazy(() =>
    import('System/Pages/ResourcesPage/ResourcesPage'),
);
const ResourceDetailPage = React.lazy(() =>
    import('System/Pages/ResourceDetailPage/ResourceDetailPage'),
);
const SettingsPage = React.lazy(() =>
    import('System/Pages/SettingsPage/SettingsPage'),
);
const WorkItemsPage = React.lazy(() =>
    import('System/Pages/WorkItemsPage/WorkItemsPage'),
);
const WorkItemDetailPage = React.lazy(() =>
    import('System/Pages/WorkItemDetailPage/WorkItemDetailPage'),
);
const CharacteristicsReportPage = React.lazy(() =>
    import(
        'System/Pages/ReportsPage/Reports/CharacteristicsReport/CharacteristicsReport'
    ),
);

const widePageStyle = { padding: '0px' };

const authenticatedRoutesArray = [
    { path: '/profile', element: <MyProfilePage /> },
    {
        path: '/projects',
        element: (
            <Container maxWidth={false} style={widePageStyle}>
                <ProjectsPage />
            </Container>
        ),
    },
    { path: '/projects/:projectId', element: <ProjectDetailPage /> },
    { path: '/projects/:projectId/:tab', element: <ProjectDetailPage /> },
    {
        path: '/projects/:projectId/work-items/:workItemId',
        element: <WorkItemDetailPage />,
    },
    { path: '/work-items/:workItemId', element: <WorkItemDetailPage /> },
    { path: '/resources/:resourceId', element: <ResourceDetailPage /> },
    { path: '/resources', element: <ResourcesPage /> },
    { path: '/settings', element: <SettingsPage /> },
    { path: '/dashboard', element: <DashboardPage /> },
    { path: '/dashboard-edit', element: <DashboardEditPage /> },
    { path: '/work-items', element: <WorkItemsPage /> },
    { path: '/payment-success', element: <DashboardPage /> },
    { path: '/payment-canceled', element: <DashboardPage /> },
];

const proReportRoutes = [
    { path: '/reports', element: <ReportsPage /> },
    { path: '/reports/sentiment', element: <SentimentReportPage /> },
    {
        path: '/reports/characteristics',
        element: <CharacteristicsReportPage />,
    },
];

const enterpriseReportRoutes = [
    { path: '/reports/monthly-expense', element: <MonthlyExpenseReportPage /> },
    {
        path: '/reports/monthly-revenue-margin',
        element: <MonthlyRevenueMarginReportPage />,
    },
];

const authPaths = authenticatedRoutesArray.map((route) => route.path);
const professionalReportPaths = proReportRoutes.map((route) => route.path);
const enterpriseReportPaths = enterpriseReportRoutes.map((route) => route.path);
export const restrictedPaths = [
    ...authPaths,
    ...professionalReportPaths,
    ...enterpriseReportPaths,
];

export default function AppRouter({ isAuthenticated }) {
    const location = useLocation();
    const createReturnUrl = (inputString) => {
        const resultString = `/login?returnUrl=${inputString}`;
        return resultString;
    };

    const tenant = useSelector(companySelectors.tenant);
    const billingFlag = useSelector(featureFlagSelectors.billingFlag);
    const loggedInUser = useSelector(userSelectors.loggedInUser);

    return (
        <Routes>
            {/* Marketing Routes */}
            <Route element={<MarketingLayout />}>
                {!isAuthenticated && (
                    <>
                        <Route path="/" element={<HomePage />}></Route>
                    </>
                )}
                <Route
                    path="/login/*"
                    element={isAuthenticated ? <DashboardPage /> : <SignInPage />}
                />
                <Route
                    path="/signup"
                    element={<SignupPage isAuthenticated={isAuthenticated} />}
                />
                <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                />
                <Route path="/home" element={<HomePage />}></Route>
                <Route
                    path="/request-demo"
                    element={<RequestDemoPage />}
                ></Route>
                <Route path="/health" element={<HealthPage />}></Route>
                <Route path="/pricing" element={<PricingPage />}></Route>
                <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicyPage />}
                ></Route>
                <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditionsPage />}
                ></Route>
                <Route
                    path="/customer-service-policy"
                    element={<CustomerServicePolicyPage />}
                ></Route>
                <Route
                    path="/security-policy"
                    element={<SecurityPolicyPage />}
                ></Route>
            </Route>

            {/* App Routes */}
            <Route element={<AppLayout />}>
                {isAuthenticated && (
                    <>
                        <Route path="/" element={<DashboardPage />} />
                    </>
                )}
                <Route path="/brand" element={<BrandPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route
                    path="/contact-us-success"
                    element={<ContactUsSuccessPage />}
                />
                <Route path="/email-invite" element={<EmailInvitePage />} />
                <Route path="/email-welcome" element={<EmailWelcomePage />} />
                <Route
                    path="/force-password-change"
                    element={<ForcePasswordChangePage />}
                />
                <Route path="/reset-password" element={<ResetPasswordPage />} />
                <Route
                    path="/signup-verification"
                    element={<SignupVerificationPage />}
                />
                <Route path="/logout" element={<SignOutPage />} />
                <Route
                    path="/idpresponse"
                    element={<IdpResponsePage />}
                ></Route>
                <Route path="/error" element={<ErrorPage />} />
                <Route
                    path="/projects"
                    element={isAuthenticated
                        ? <Container maxWidth={false} style={widePageStyle}>
                            <ProjectsPage />
                        </Container>
                        : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/profile"
                    element={isAuthenticated ? <MyProfilePage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/projects/:projectId"
                    element={isAuthenticated ? <ProjectDetailPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/projects/:projectId/:tab"
                    element={isAuthenticated ? <ProjectDetailPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/projects/:projectId/work-items/:workItemId"
                    element={isAuthenticated ? <WorkItemDetailPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/work-items/:workItemId"
                    element={isAuthenticated ? <WorkItemDetailPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/resources/:resourceId"
                    element={isAuthenticated ? <ResourceDetailPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/resources"
                    element={isAuthenticated ? <ResourcesPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/settings"
                    element={isAuthenticated ? <SettingsPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/dashboard"
                    element={isAuthenticated ? <DashboardPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/dashboard-edit"
                    element={isAuthenticated ? <DashboardEditPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/work-items"
                    element={isAuthenticated ? <WorkItemsPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/payment-success"
                    element={isAuthenticated ? <DashboardPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                <Route
                    path="/payment-canceled"
                    element={isAuthenticated ? <DashboardPage /> : <Navigate to={createReturnUrl(location.pathname)} replace={true} />}
                />
                { /* Professional Tier Routes */}
                <Route
                    path="/reports"
                    element={isAuthenticated &&
                        ((billingFlag &&
                            tenant?.tier !== PaymentTier.Free) ||
                            !billingFlag)
                        ? <ReportsPage />
                        : isAuthenticated
                            ? <Navigate to={"/"} />
                            : <Navigate to={createReturnUrl(location.pathname)} />}
                />
                <Route
                    path="/reports/sentiment"
                    element={isAuthenticated &&
                        ((billingFlag &&
                            tenant?.tier !== PaymentTier.Free) ||
                            !billingFlag)
                        ? <SentimentReportPage />
                        : isAuthenticated
                            ? <Navigate to={"/"} />
                            : <Navigate to={createReturnUrl(location.pathname)} />}
                />
                <Route
                    path="/reports/characteristics"
                    element={isAuthenticated &&
                        ((billingFlag &&
                            tenant?.tier !== PaymentTier.Free) ||
                            !billingFlag)
                        ? <CharacteristicsReportPage />
                        : isAuthenticated
                            ? <Navigate to={"/"} />
                            : <Navigate to={createReturnUrl(location.pathname)} />}
                />
                { /* Enterprise Tier Routes */}
                <Route
                    path="/reports/monthly-expense"
                    element={isAuthenticated && loggedInUser?.isAllowedFinancials &&
                        ((billingFlag &&
                            tenant?.tier === PaymentTier.Enterprise) ||
                            !billingFlag)
                        ? <MonthlyExpenseReportPage />
                        : isAuthenticated
                            ? <Navigate to={"/"} />
                            : <Navigate to={createReturnUrl(location.pathname)} />}
                />
                <Route
                    path="/reports/monthly-revenue-margin"
                    element={isAuthenticated && loggedInUser?.isAllowedFinancials &&
                        ((billingFlag &&
                            tenant?.tier === PaymentTier.Enterprise) ||
                            !billingFlag)
                        ? <MonthlyRevenueMarginReportPage />
                        : isAuthenticated
                            ? <Navigate to={"/"} />
                            : <Navigate to={createReturnUrl(location.pathname)} />}
                />
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes >
    );
}
