import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function FooterContent() {
    const blogUrl = process.env.REACT_APP_BLOG_URL;

    return (
        <>
            <Typography component="p">
                &copy; {new Date().getFullYear()} Fairview, Inc. All rights
                reserved. <br />
                <Link to="/privacy-policy">Privacy Policy</Link> |&nbsp;
                <Link to="/terms-and-conditions">Terms of Service</Link> |&nbsp;
                <Link to="/security-policy">Security Policy</Link> |&nbsp;
                <Link to="/customer-service-policy">
                    Customer Service Policy
                </Link>
            </Typography>
            <Typography component="p">
                <a href={blogUrl} target="_blank" rel="noreferrer">
                    Blog
                </a>{' '}
                |&nbsp;
                <Link to="/pricing">Pricing</Link> |&nbsp;
                <Link to="/contact-us">Contact Us</Link>
            </Typography>
        </>
    );
}
