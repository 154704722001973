import axios from 'axios';
import cognitoUtility from 'Utilities/cognito-utility';

const dateTransformer = (data) => {
    if (data instanceof Date) {
        return new Date(data.toLocaleDateString());
    }
    if (Array.isArray(data)) {
        return data.map((val) => dateTransformer(val));
    }
    if (typeof data === 'object' && data !== null) {
        return Object.fromEntries(
            Object.entries(data).map(([key, val]) => [
                key,
                dateTransformer(val),
            ]),
        );
    }
    return data;
};

export default class RestAPI {
    static baseURL = process.env.REACT_APP_API_URL;

    static api = axios.create({
        baseURL: this.baseURL,
        timeout: 30000,
        transformRequest: [dateTransformer].concat(
            axios.defaults.transformRequest,
        ),
    });

    static async getAuthHeader() {
        const cognitoUser = await cognitoUtility.getCurrentUserWithSession();

        let session;
        try {
            session = cognitoUser.getSignInUserSession();
        } catch (error) {
            if (!session) return {};
            if (error.toLowerCase() === 'no current user') return {};
        }
        return {
            Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
        };
    }

    static async del(endpoint, options = {}) {
        const config = {
            headers: await this.getAuthHeader(),
            params: options.params,
        };

        return await this.api.delete(endpoint, config);
    }

    static async get(endpoint, options = {}) {
        const config = {
            headers: await this.getAuthHeader(),
            params: options.params,
        };

        if (options.timeout) {
            config.timeout = options.timeout;
        }

        return await this.api.get(endpoint, config);
    }

    static async getFile(endpoint) {
        const config = {
            headers: await this.getAuthHeader(),
            responseType: 'blob',
        };

        return await this.api.get(endpoint, config);
    }

    static async patch(endpoint, options = {}) {
        const config = {
            headers: await this.getAuthHeader(),
        };

        const { body = {} } = options;

        return await this.api.patch(endpoint, body, config);
    }

    static async post(endpoint, options = {}) {
        const config = {
            headers: await this.getAuthHeader(),
        };

        const { body = {} } = options;

        return await this.api.post(endpoint, body, config);
    }

    static async postFormData(
        endpoint,
        options = {},
        errorCallback = null,
        setIsLoading = null,
    ) {
        setIsLoading = setIsLoading || (() => { });
        errorCallback = errorCallback || (() => { });
        const config = {
            headers: {
                ...(await this.getAuthHeader()),
                'Content-Type': 'multipart/form-data',
            },
            transformRequest: (formData) => formData, // This is required to make form data work
        };

        const { body = {} } = options;

        try {
            setIsLoading(true);
            const response = await this.api.post(endpoint, body, config);
            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            errorCallback(error?.response?.data?.errors);
            throw error;
        }
    }

    static async put(endpoint, options = {}) {
        const config = {
            headers: await this.getAuthHeader(),
        };

        const { body = {} } = options;

        return await this.api.put(endpoint, body, config);
    }
}
