import * as yup from 'yup';
import { useFormik } from 'formik';
import { Stack, TextField } from '@mui/material';
import Form from 'System/Forms/Components/Form';

export default function ChangeProjectNameForm({
    name = '',
    projectKey = '',
    onSubmit,
}) {
    const schema = yup.object().shape({
        name: yup.string().required(),
        projectKey: yup.string().required().max(10),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: { name, projectKey },
            validationSchema: schema,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return (
        <Form onSubmit={handleSubmit} formId="change-project-name-form">
            <Stack spacing={2}>
                <TextField
                    name="name"
                    label="Name"
                    autoFocus
                    required
                    fullWidth
                    error={showError('name')}
                    helperText={getError('name')}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextField
                    name="projectKey"
                    label="Project Abbreviation"
                    error={showError('projectKey')}
                    helperText={getError('projectKey')}
                    value={values.projectKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    autoFocus
                    required
                />
            </Stack>
        </Form>
    );
}
