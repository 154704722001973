import Basic from './Basic';

export default class NonLaborExpense extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.amountPerEach = obj.amountPerEach || null;

        this.description = obj.description || '';

        this.effectiveDate = obj.effectiveDate
            ? new Date(obj.effectiveDate)
            : new Date();

        this.projectId = obj.projectId || null;

        this.quantity = obj.quantity || 1;
    }

    get totalCost() {
        if (this.amountPerEach && this.quantity) {
            return this.amountPerEach * this.quantity;
        }

        return null;
    }
}
