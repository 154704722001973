import { useFormik } from 'formik';
import * as yup from 'yup';
import { Divider, Stack, Typography } from '@mui/material';
import FinancialField from 'System/UI/Components/FinancialField/FinancialField';
import ReadOnlyFinancialField from 'System/UI/Components/FinancialField/ReadOnlyFinancialField';
import SectionHeader from 'System/Modals/Components/SectionHeader';
import Form from 'System/Forms/Components/Form';

export default function ProjectFinancialsForm({
    project,
    onSubmit,
    isRevenueTracked,
}) {
    const projectFinancialsSchema = yup.object().shape({
        estimatedCost: yup
            .number()
            .typeError('Must be positive number')
            .min(0, 'Must be positive number'),
        estimatedValue: yup
            .number()
            .typeError('Must be positive number')
            .min(0, 'Must be positive number'),
        actualCost: yup
            .number()
            .typeError('Must be positive number')
            .min(0, 'Must be positive number'),
        actualValue: yup
            .number()
            .typeError('Must be positive number')
            .min(0, 'Must be positive number'),
    });

    const {
        values: {
            estimatedCost,
            estimatedValue,
            estimatedMarginAmount,
            actualValue,
            actualCost,
            actualMarginAmount,
        },
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
    } = useFormik({
        initialValues: project,
        validationSchema: projectFinancialsSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    const mathPadding = { paddingTop: '7px' };

    return project ? (
        <Form onSubmit={handleSubmit} formId="project-financials-form">
            <Stack spacing={2}>
                <SectionHeader
                    text="Estimated Financials"
                    tooltipText="Financials estimated before the work is planned"
                />

                <Stack direction="row" spacing={1}>
                    <FinancialField
                        name="estimatedCost"
                        label="Estimated Budget"
                        value={estimatedCost}
                        isError={showError('estimatedCost')}
                        errorMsg={getError('estimatedCost')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <FinancialField
                        name="estimatedValue"
                        label="Estimated Value"
                        value={estimatedValue}
                        isError={showError('estimatedValue')}
                        errorMsg={getError('estimatedValue')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <ReadOnlyFinancialField
                        name="estimatedMargin"
                        label="Return On Investment"
                        value={estimatedMarginAmount}
                    />
                </Stack>

                <Divider />

                <SectionHeader
                    text="Planned Financials"
                    tooltipText="Calculated financials once work is scoped and resources are assigned"
                />

                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                >
                    <ReadOnlyFinancialField
                        label="Estimated Value"
                        value={project.estimatedValue}
                    />

                    <Typography component="h3" sx={mathPadding}>
                        -
                    </Typography>

                    <ReadOnlyFinancialField
                        label="Planned Cost"
                        value={project.plannedCost}
                    />

                    <Typography component="h3" sx={mathPadding}>
                        =
                    </Typography>

                    <ReadOnlyFinancialField
                        label="Planned Margin"
                        value={project.plannedROI}
                    />
                </Stack>

                {isRevenueTracked && (
                    <Stack direction="row" spacing={1}>
                        <ReadOnlyFinancialField
                            label="Planned Revenue"
                            value={project.plannedRevenue}
                        />

                        <Typography component="h3" sx={mathPadding}>
                            -
                        </Typography>

                        <ReadOnlyFinancialField
                            label="Planned Cost"
                            value={project.plannedCost}
                        />

                        <Typography component="h3" sx={mathPadding}>
                            =
                        </Typography>

                        <ReadOnlyFinancialField
                            label="Planned Margin"
                            value={project.plannedMarginAmount}
                        />
                    </Stack>
                )}

                <Divider />

                <SectionHeader
                    text="Actual Financials"
                    tooltipText="Financials after money has actually been spent"
                />

                <Stack direction="row" spacing={1}>
                    <FinancialField
                        name="actualValue"
                        label="Actual Value"
                        value={actualValue}
                        isError={showError('actualValue')}
                        errorMsg={getError('actualValue')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Typography component="h3" sx={mathPadding}>
                        -
                    </Typography>

                    <FinancialField
                        name="actualCost"
                        label="Actual Cost"
                        value={actualCost}
                        isError={showError('actualCost')}
                        errorMsg={getError('actualCost')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Typography component="h3" sx={mathPadding}>
                        =
                    </Typography>

                    <ReadOnlyFinancialField
                        label="Actual Margin or ROI"
                        value={actualMarginAmount}
                    />
                </Stack>
            </Stack>
        </Form>
    ) : null;
}
