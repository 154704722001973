import { createAsyncThunk } from '@reduxjs/toolkit';
import RestAPI from '../rest-api';

export const generateSampleData = createAsyncThunk(
    'company/generateSampleData',
    async (tenantId) => {
        console.log('Generating sample data.', tenantId);
        await RestAPI.post('/admin/sample-data');
        return true;
    },
);

export const removeSampleData = createAsyncThunk(
    'company/removeSampleData',
    async (tenantId) => {
        console.log('Removing sample data.', tenantId);
        await RestAPI.del('/admin/sample-data');
        return true;
    },
);
