import { Fragment, useEffect, useState } from 'react';
import { Container, Grid, TextField, Typography, Button } from '@mui/material';
import Feature from './Components/Feature/Feature';
import RequestDemoButton from 'Marketing/Shared/RequestDemoButton/RequestDemoButton';
import HeroImage from '../../assets/hero.svg';
import WorkforceImage from '../../assets/workforce.svg';
import PersonalInsightsImage from '../../assets/personalized-insights.svg';
import ProjectFinancesImage from '../../assets/project-finances.svg';
import RoadmapImage from '../../assets/roadmap.svg';
import BudgetImage from '../../assets/budget-planning.svg';
import { v4 as uuidv4 } from 'uuid';
import HeroSection from './Components/HeroSection/HeroSection';
import RainbowImage from '../../assets/rainbow-clipped.svg';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        marginTop: -26,
        padding: '2vh 0 0',
        fontSize: '18pt',
        height: 900,
        overflow: 'hidden',

        '& svg': {
            position: 'absolute',
        },
    },
    heroContent: {
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 50,
        },

        '& p': {
            fontSize: '18pt',
            padding: '2rem 2rem 4rem 0',
            [theme.breakpoints.up('lg')]: {
                marginBottom: 20,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '16pt',
                padding: '1rem 1rem 1rem 0',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14pt',
                marginTop: '1rem',
                marginBottom: 0,
            },
        },
        '& h2': {
            marginBottom: 18,
            fontSize: '46pt',
            fontWeight: 'bold',
            [theme.breakpoints.up('lg')]: {
                paddingTop: 125,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '42pt',
                paddingTop: '2vh',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '30pt',
                marginBottom: 0,
                marginTop: '1vh',
                paddingTop: '2vh',
            },
        },
    },
    heroImage: {
        marginTop: 125,
        height: 475,
        objectPosition: 'top',
        [theme.breakpoints.down('lg')]: {
            maxHeight: 350,
            marginTop: 30,
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: 250,
        },
    },
    sky: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    bodySection: {
        height: 350,
        textAlign: 'center',
        paddingTop: 30,
        [theme.breakpoints.down(420)]: {
            height: 450,
        },
        [theme.breakpoints.down(370)]: {
            height: 500,
        },

        '& h2': {
            fontSize: '36pt',
            fontWeight: '600',
            lineHeight: '1.2',
            [theme.breakpoints.down('sm')]: {
                fontSize: '28pt',
            },
        },
        '& p': {
            fontSize: '18pt',
            padding: '1rem 10rem',
            [theme.breakpoints.down(1000)]: {
                fontSize: '15pt',
                padding: 0,
                paddingTop: '1rem',
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '14pt',
            },
        },
    },
    featureContainer: {
        position: 'relative',

        '& h2': {
            fontSize: '36pt',
            fontWeight: '600',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: '30pt',
            },
        },
    },
    divider: {
        height: 8,
        width: 80,
        backgroundColor: '#F37A42',
        borderRadius: 5,
        margin: '10px auto',
    },
    emailInput: {
        width: '25rem',
        [theme.breakpoints.down(655)]: {
            width: '100%',
        },

        '& .MuiOutlinedInput-root': {
            height: 56,
            borderRadius: 10,
            paddingBottom: 0,
        },
    },
    form: {
        paddingTop: 10,
        '& #signupFormButton': {
            fontSize: '12pt',
            fontWeight: '700',
            textAlign: 'center',
            minWidth: '11rem',
            height: 56,
            display: 'inline-block',
            padding: '8px 10px',
            borderRadius: '10px',
            textTransform: 'none',
            boxShadow: 'none',
            marginLeft: '10px',
            [theme.breakpoints.down(655)]: {
                marginTop: 10,
                marginLeft: '0px',
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },

            '&:hover': {
                backgroundColor: '#006BAF',
                boxShadow: 'none',
            },
        },
    },
    rainbow: {
        display: 'flex',
        justifyContent: 'end',
    },
}));

export default function Home() {
    useEffect(() => {
        document.title = 'Fairview Project Planning';
    }, []);

    const classes = useStyles();
    const appUrl = process.env.REACT_APP_URL;

    const [email, setEmail] = useState();

    function DividerLine() {
        return <div className={classes.divider}></div>;
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = `${appUrl}/signup?email=${email}`;
        e.target.reset();
    };

    const workforceCopy = [
        {
            point: 'Match skillsets to project needs',
            description:
                'Stack the deck on project success by staffing the optimal mix of skillsets.',
            id: uuidv4(),
        },
        {
            point: 'Encourage career development with project opportunities',
            description:
                'Help your staff grow with opportunities they are passionate about leading to long term success.',
            id: uuidv4(),
        },
        {
            point: 'Visualize frustration and joy, at scale',
            description:
                'Projects ebb and flow; identify potential stressors to mediate and wins to celebrate.',
            id: uuidv4(),
        },
    ];

    const roadmapCopy = [
        {
            point: 'Create a strategic plan for the future',
            description:
                'Build a foundation for budget approval and talent acquisition.',
            id: uuidv4(),
        },
        {
            point: 'Create rough or detailed project plans',
            description:
                'Outline the work, expectations, and invididual effort for project planning and tracking.',
            id: uuidv4(),
        },
        {
            point: 'Identify and track data-driven milestones',
            description:
                'Whether it involves billing, compliance, marketing, or simply motivation.  Milestones help get things done.',
            id: uuidv4(),
        },
        {
            point: 'Hold accountable to objectives and key result outcomes',
            description:
                "What are the levers to pull now to ensure project success and meet your OKR's?",
            id: uuidv4(),
        },
    ];

    const budgetCopy = [
        {
            point: 'Align on budget allocations, requests, and changes',
            description:
                'Prepare for budget planning by matching dollars to specific roadmap projects.',
            id: uuidv4(),
        },
        {
            point: 'Clearly see budget overages across entire roadmap and specific projects',
            description:
                'Budgets often involve several folks, ensure alignment by creating budget transparency.',
            id: uuidv4(),
        },
        {
            point: 'Determine people and skillset needs and plan talent acquisition accordingly',
            description:
                'Projects need people to be successful, ensure you have the proper budget for the ones you need.',
            id: uuidv4(),
        },
    ];

    const financialCopy = [
        {
            point: 'Gain financial insights into your roadmap',
            description:
                'View financial costs in aggregation and over time across your organization.',
            id: uuidv4(),
        },
        {
            point: 'Gain financial insights into your project',
            description:
                'Understand financial cost, revenue or ROI, and potential value creation within a project.',
            id: uuidv4(),
        },
        {
            point: 'Gain financial insights into your people',
            description:
                'Explore financial impact of specific people or contracted partners.',
            id: uuidv4(),
        },
        {
            point: 'Proper security and restrictions based on user role',
            description:
                'Provide project visibility while restricting financials to only those allowed to view them.',
            id: uuidv4(),
        },
    ];

    const personalizedCopy = [
        {
            point: 'Access a library of data, tools, insights, and charts',
            description:
                'Fairview maintains a growing list of modules to provide the information in various useful ways.',
            id: uuidv4(),
        },
        {
            point: 'Customized dashboards to view what you need',
            description:
                'All of us are different; customize Fairview to your needs so it works the way you need.',
            id: uuidv4(),
        },
        {
            point: 'Integrations available to support the workflow you need',
            description:
                'Popular integrations exist to JIRA with more on the way.  Let us know what integrations are most important to you.',
            id: uuidv4(),
        },
    ];

    return (
        <Fragment>
            <Container
                maxWidth={false}
                disableGutters
                className={classes.headerContainer}
            >
                <HeroSection />
                <Container maxWidth="lg" className={classes.heroContent}>
                    <Grid container spacing={1}>
                        <Grid item lg={6}>
                            <Typography component="h2" variant="h2">
                                People-focused project planning.
                            </Typography>

                            <Typography component="p">
                                Fairview is project management that balances
                                timelines, budgets <i>and</i> people.
                                You&apos;ll have control of what to do now, next
                                quarter, next year, and beyond.
                            </Typography>
                            <RequestDemoButton />
                        </Grid>

                        <Grid item lg={6}>
                            <img
                                src={HeroImage}
                                alt="fairview dashboard"
                                className={classes.heroImage}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Container>

            <Container
                maxWidth="lg"
                className={`${classes.sky} ${classes.bodySection}`}
            >
                <Typography component="h2">
                    Fairview is teamwork + planning all-in-one.
                </Typography>

                <Typography component="p">
                    Knowing what your talented workforce wants to work on, what
                    they&apos;re skilled at, and what they dread allows you to
                    create the most effective and passionate project teams.
                </Typography>
            </Container>

            <Container
                maxWidth={false}
                disableGutters
                className={classes.featureContainer}
            >
                <DividerLine />
                <Typography component="h2">Our Features</Typography>

                <Feature
                    title="It all centers around people"
                    subtitle="Connecting the right people to the right projects to invigorate both."
                    imgUrl={WorkforceImage}
                    copy={workforceCopy}
                    linkText="Start Allocating Resources"
                    videoUrl="https://d3kq3n39rskv1i.cloudfront.net/people_centered_mute.mp4"
                    videoPreviewImageUrl="https://d3kq3n39rskv1i.cloudfront.net/people_centered.png"
                ></Feature>

                <Feature
                    title="Plan short and long term roadmaps"
                    subtitle="Create powerful, yet easy to understand roadmaps everyone from team members to executives can get behind."
                    imgUrl={RoadmapImage}
                    copy={roadmapCopy}
                    linkText="Start Roadmapping"
                    alternate={true}
                    videoUrl="https://d3kq3n39rskv1i.cloudfront.net/roadmaps_mute.mp4"
                    videoPreviewImageUrl="https://d3kq3n39rskv1i.cloudfront.net/roadmaps.png"
                ></Feature>

                <Feature
                    title="Plan budgets to meet your goals"
                    subtitle="Plan and track your financial spend easier than ever before. Project team members, finance, and leaders have alignment on how dollars are being spent and easy to visualize ROI."
                    imgUrl={BudgetImage}
                    copy={budgetCopy}
                    linkText="Start budgeting"
                    videoUrl="https://d3kq3n39rskv1i.cloudfront.net/budgets_mute.mp4"
                    videoPreviewImageUrl="https://d3kq3n39rskv1i.cloudfront.net/budgets.png"
                ></Feature>

                <Feature
                    title="Understand financial insights"
                    subtitle="Get the insights you need with interchangeable modules focused on cost, revenue, margin, and ROI."
                    imgUrl={ProjectFinancesImage}
                    copy={financialCopy}
                    linkText="Analyze financials"
                    alternate={true}
                ></Feature>

                <Feature
                    title="Personalize and make it your own"
                    subtitle="Fairview enables informed decision making. Get the insights you need with interchangeable data, insights, tools, and charts on your dashboard."
                    imgUrl={PersonalInsightsImage}
                    copy={personalizedCopy}
                    linkText="Get personalized insights"
                    videoUrl="https://d3kq3n39rskv1i.cloudfront.net/customization_mute.mp4"
                    videoPreviewImageUrl="https://d3kq3n39rskv1i.cloudfront.net/customization.png"
                ></Feature>
            </Container>

            <Container maxWidth={false} className={classes.bodySection}>
                <DividerLine />
                <Typography component="h2">Sign up now for free</Typography>

                <Typography component="p">
                    Getting started is fast, free, and easy.
                </Typography>

                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        label="Enter your email address"
                        type="email"
                        className={classes.emailInput}
                        onChange={handleEmailChange}
                    />
                    <Button
                        variant="contained"
                        id="signupFormButton"
                        type="submit"
                    >
                        Try for Free
                    </Button>
                </form>
            </Container>

            {/* <Container maxWidth="lg">
                    <Testimonial
                        title="I'm not just the president of Fairview, I'm also a client!"
                        name="Nicholas Barger (President of Fairview and MadeLabs)"
                        testimonial="Fairview helped us become more proactive about keeping projects on track and profitable in our consulting practice!"
                        imgUrl={Testimonial1Image} />
            </Container> */}
            <div className={classes.rainbow}>
                <img src={RainbowImage} alt="rainbow"></img>
            </div>
        </Fragment>
    );
}
