import Basic from './Basic';
import ResourceTimeline from './ResourceTimeline';
import UnavailablePeriod from './ResourceUnavailablePeriod';
export default class Resource extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.defaultHourlyBillRate = Number(obj.defaultHourlyBillRate) || 0;

        this.defaultAverageVelocity = Number(obj.defaultAverageVelocity) || 0;

        // the actual hourly rate negotiated for this resource
        // this is by default assigned to the resource timelines
        // Note: deprecated underscore backer vars for front-end validation
        this.defaultHourlyCostRate = Number(obj.defaultHourlyCostRate) || 0;

        // what company the resource is from, currently freeform text
        this.company = obj.company || '';

        // the textual description of this resource
        this.description = obj.description || '';

        // bool, is resource active/inactive -- used for soft delete
        this.isActive =
            obj.isActive === null || obj.isActive === undefined
                ? true
                : obj.isActive;

        //bool, is resource terminated
        this.isTerminated = obj.isTerminated || false;

        // allow labels to be applied to a resource
        this.labels = obj.labels || [];

        // the name of the peron or team this resource represents
        this.name = obj.name || '';

        // specifies the profile id that this resource is from
        this.profileId = obj.profileId || null;

        // specifies the profile name of this resource
        this.profileName = obj.profileName || null;

        //color inherited from profile by default
        this.color = obj.color || '';

        this.teamId = obj.teamId || null;

        this.teamName = obj.teamName || '';

        // the collection of period of work this resource is allocated assigned to project
        this.skilledResourceTimelines = [];
        if (obj.skilledResourceTimelines?.length) {
            obj.skilledResourceTimelines.forEach((resourceTimeline) => {
                this.skilledResourceTimelines.push(
                    new ResourceTimeline(resourceTimeline),
                );
            });
        }

        this.skilledResourceUnavailablePeriods = [];
        if (obj.skilledResourceUnavailablePeriods?.length) {
            obj.skilledResourceUnavailablePeriods.forEach(
                (unavailablePeriod) => {
                    this.skilledResourceUnavailablePeriods.push(
                        new UnavailablePeriod(unavailablePeriod),
                    );
                },
            );
        }

        // track changes to this resource
        this.history = obj.history;

        // the number of hours per day this resource works typically
        this.hoursPerDay = obj.hoursPerDay || 8;

        // the number of days worked per week typically
        this.daysPerWeek = obj.daysPerWeek || 5;

        this.hireDate = obj.hireDate ? new Date(obj.hireDate) : null;
        this.terminationDate = obj.terminationDate
            ? new Date(obj.terminationDate)
            : null;
        this.userId = obj.userId || null;
        this.actualSalary = Number(obj.actualSalary) || 0;
    }

    get initials() {
        return this.name
            ?.trim()
            .split(' ')
            .reduce((acc, curr, index, arr) => {
                if (index === 0 || index === arr.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
    }

    get defaultHourlyMargin() {
        return this.defaultHourlyBillRate - this.defaultHourlyCostRate;
    }

    get plannedCost() {
        var total = 0;

        if (this.skilledResourceTimelines?.length) {
            this.skilledResourceTimelines.forEach((resourceTimeline) => {
                total += resourceTimeline.totalCost;
            });
        }

        return total;
    }

    // total cost spent on resources based on today
    get totalCostToDate() {
        var total = 0;

        if (this.skilledResourceTimelines?.length) {
            this.skilledResourceTimelines.forEach((resourceTimeline) => {
                total += resourceTimeline.totalCostToDate;
            });
        }

        return total;
    }

    // todo: add something here to read the resource timelines
    // and describe when a resource is over allocated

    get startDateOfFirstAssignedWork() {
        if (
            this.skilledResourceTimelines?.length &&
            this.skilledResourceTimelines[0]
        ) {
            let earliestStartDate;
            if (
                this.skilledResourceTimelines &&
                this.skilledResourceTimelines[0]
            ) {
                earliestStartDate = this.skilledResourceTimelines[0].startDate;
                this.skilledResourceTimelines.forEach((resourceTimeline) => {
                    if (resourceTimeline.startDate < earliestStartDate) {
                        earliestStartDate = resourceTimeline.startDate;
                    }
                });
            }

            return earliestStartDate;
        } else {
            return null;
        }
    }

    get endDateOfLastAssignedWork() {
        if (
            this.skilledResourceTimelines?.length &&
            this.skilledResourceTimelines[0]
        ) {
            var latestEndDate;
            if (
                this.skilledResourceTimelines &&
                this.skilledResourceTimelines[0]
            ) {
                latestEndDate = this.skilledResourceTimelines[0].endDate;
                this.skilledResourceTimelines.forEach((resourceTimeline) => {
                    if (resourceTimeline.endDate > latestEndDate) {
                        latestEndDate = resourceTimeline.endDate;
                    }
                });
            }

            return latestEndDate;
        } else {
            return null;
        }
    }

    get totalDays() {
        if (this.skilledResourceTimelines) {
            var totalDays = 0;

            if (this.skilledResourceTimelines?.length) {
                this.skilledResourceTimelines.forEach((resourceTimeline) => {
                    totalDays += resourceTimeline.totalDays;
                });
            }

            return totalDays;
        } else {
            return 0;
        }
    }

    get totalCost() {
        if (this.skilledResourceTimelines) {
            var totalCost = 0;
            let filteredResourceTimelines =
                this.skilledResourceTimelines.filter(
                    (skilledResource) => skilledResource.isActive === true,
                );
            if (filteredResourceTimelines?.length) {
                filteredResourceTimelines.forEach((resourceTimeline) => {
                    totalCost += resourceTimeline.totalCost;
                });
            }

            return totalCost;
        } else {
            return 0;
        }
    }

    get totalRevenue() {
        if (this.skilledResourceTimelines) {
            var totalRevenue = 0;
            let filteredResourceTimelines =
                this.skilledResourceTimelines.filter(
                    (skilledResource) => skilledResource.isActive === true,
                );
            if (filteredResourceTimelines?.length) {
                filteredResourceTimelines.forEach((resourceTimeline) => {
                    totalRevenue += resourceTimeline.totalRevenue;
                });
            }

            return totalRevenue;
        } else {
            return 0;
        }
    }

    get totalHours() {
        if (this.skilledResourceTimelines) {
            var totalHours = 0;
            let filteredResourceTimelines =
                this.skilledResourceTimelines.filter(
                    (skilledResource) => skilledResource.isActive === true,
                );
            if (filteredResourceTimelines?.length) {
                filteredResourceTimelines.forEach((resourceTimeline) => {
                    totalHours += resourceTimeline.totalHours;
                });
            }
            return totalHours;
        } else {
            return 0;
        }
    }

    get margin() {
        return this.totalCost && this.totalRevenue
            ? this.totalRevenue - this.totalCost
            : null;
    }

    get marginAsPercentage() {
        return this.margin && this.totalRevenue
            ? Number.parseFloat(
                (this.margin / this.totalRevenue) * 100,
            ).toFixed(2)
            : 0;
    }

    get isLinkedToUser() {
        return this.userId !== null;
    }
}
