import { MenuItem, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { workItemActions } from 'System/State/ProjectState/reducer';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles(() => ({
    menu: {
        borderRadius: '8px',
    },
    menuItem: {
        paddingTop: 2.5,
        paddingBottom: 2.5,
        paddingLeft: 5,
        paddingRight: 5,
        borderBottom: '0.5px solid rgb(0,0,0,0.05)',
    },
    chip: {
        borderRadius: '16px',
        padding: '5px 7.5px ',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        zIndex: 2,
        marginBottom: '5px',
        gap: '5px',
        // boxShadow: '1px 0px 8px rgb(0,0,0,0.2)',
        fontSize: '14px',
    },
    icon: {
        width: '15px',
    },
}));

export function GetStatusItem(workItemStatusId, isMenuItem = false) {
    const classes = useStyles();

    switch (workItemStatusId) {
        case 0:
            return (
                <div style={{ background: '#66DD99' }} className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <RadioButtonUncheckedIcon
                            className={classes.icon}
                            fontSize="small"
                        />
                        To-Do
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 1:
            return (
                <div style={{ background: '#FFECB1' }} className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <AccessTimeIcon
                            className={classes.icon}
                            fontSize="small"
                        />
                        In Progress
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 2:
            return (
                <div style={{ background: '#F1B5AD' }} className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <CheckCircleOutlineIcon
                            className={classes.icon}
                            fontSize="small"
                        />
                        Done
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
    }
}

export default function WorkItemStatus({ workItem }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSwitchStatus = (statusId) => {
        if (statusId === workItem.status) {
            handleClose();
        } else {
            let updatedWorkItem = {
                ...workItem,
                status: statusId,
                hoursComplete:
                    statusId === workItem.doneStatusId
                        ? workItem?.hoursEstimate
                        : 0,
            };

            const request = {
                updatedWorkItem: updatedWorkItem,
                originalWorkItem: workItem,
            };
            dispatch(workItemActions.updateWorkItemStatus(request));
            handleClose();
        }
    };
    return (
        <div>
            <div
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={classes.selectedChip}
            >
                {GetStatusItem(workItem?.status)}
            </div>

            <Menu
                pt={3}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menu}
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                    },
                }}
            >
                <MenuItem
                    onClick={() => handleSwitchStatus(workItem.todoStatusId)}
                    className={classes.menuItem}
                >
                    {GetStatusItem(workItem.todoStatusId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() => handleSwitchStatus(workItem.inProgressId)}
                    className={classes.menuItem}
                >
                    {GetStatusItem(workItem.inProgressId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() => handleSwitchStatus(workItem.doneStatusId)}
                    className={classes.menuItem}
                >
                    {GetStatusItem(workItem.doneStatusId, true)}
                </MenuItem>
            </Menu>
        </div>
    );
}
