import { MenuItem, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { workItemActions } from 'System/State/ProjectState/reducer';
import WorkItem from 'Models/WorkItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WorkItemTask from '../../../assets/work_item_task.svg';
import WorkItemBug from '../../../assets/work_item_bug.svg';
import WorkItemFeature from '../../../assets/work_item_feature.svg';

const useStyles = makeStyles(() => ({
    menu: {
        borderRadius: '8px',
    },
    menuItem: {
        paddingTop: 2.5,
        paddingBottom: 2.5,
        paddingLeft: 5,
        paddingRight: 5,
        borderBottom: '0.5px solid rgb(0,0,0,0.05)',
    },
    chip: {
        borderRadius: '16px',
        padding: '5px 7.5px ',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        zIndex: 2,
        marginBottom: '5px',
        gap: '5px',
        // boxShadow: '1px 0px 8px rgb(0,0,0,0.2)',
        fontSize: '14px',
        fontWeight: 500,
    },
    iconImg: {
        width: '15px',
    },
}));

export function GetTypeItem(workItemTypeId, isMenuItem = false) {
    const classes = useStyles();

    switch (workItemTypeId) {
        case 2:
            return (
                <div
                    style={{
                        background: ' #F1B5AD',
                    }}
                    className={classes.chip}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img className={classes.iconImg} src={WorkItemBug} />
                        Problem
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 0:
            return (
                <div
                    style={{
                        background: '#AFDAED',
                    }}
                    className={classes.chip}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img className={classes.iconImg} src={WorkItemTask} />
                        Task
                    </div>

                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 1:
            return (
                <div
                    style={{
                        background: '#66DD99',
                    }}
                    className={classes.chip}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img
                            className={classes.iconImg}
                            src={WorkItemFeature}
                        />
                        Feature
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
    }
}

export default function WorkItemType({ workItem }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSwitchStatus = (typeId) => {
        if (typeId === workItem.type) {
            handleClose();
        } else {
            let updatedWorkItem = {
                ...workItem,
                type: typeId,
            };
            dispatch(workItemActions.updateProjectWorkItem(updatedWorkItem));
            handleClose();
        }
    };
    return (
        <div>
            <div
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={classes.selectedChip}
            >
                {GetTypeItem(workItem?.type)}
            </div>

            <Menu
                pt={3}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menu}
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                    },
                }}
            >
                <MenuItem
                    onClick={() => handleSwitchStatus(WorkItem.problemTypeId)}
                    className={classes.menuItem}
                >
                    {GetTypeItem(WorkItem.problemTypeId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() => handleSwitchStatus(WorkItem.taskTypeId)}
                    className={classes.menuItem}
                >
                    {GetTypeItem(WorkItem.taskTypeId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() => handleSwitchStatus(WorkItem.featureTypeId)}
                    className={classes.menuItem}
                >
                    {GetTypeItem(WorkItem.featureTypeId, true)}
                </MenuItem>
            </Menu>
        </div>
    );
}
