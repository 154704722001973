import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Typography,
} from '@mui/material';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { epicActions, workItemActions } from 'System/State/ProjectState/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import EpicForm from 'System/Forms/EpicForm/EpicForm';
import { paneActions } from 'System/State/PaneState/reducer';
import InfoTooltip from 'System/UI/Components/InfoTooltip/InfoTooltip';
import { ProjectDetailTabs } from 'Models/ProjectDetailTabs';

export default function NewEpicModal() {
    const dispatch = useDispatch();

    const open = useSelector(modalSelectors.newEpicModal) || false;
    const project = useSelector(projectSelectors.selectedProject);
    const selectedWorkItem = useSelector(projectSelectors.selectedWorkItem);
    const newEpicModalIsForWorkItemReporter = useSelector(projectSelectors.newEpicModalIsForWorkItemReporter);
    const isNewEpicForWorkItem = useSelector(projectSelectors.isNewEpicForWorkItem);

    const handleClose = () => {
        if (newEpicModalIsForWorkItemReporter) {
            dispatch(epicActions.setNewEpicModalIsForWorkItemReporter(false));
        }
        if (isNewEpicForWorkItem) {
            dispatch(epicActions.setIsNewEpicForWorkItem(false));
        }
        dispatch(modalActions.close('newEpicModal'));
    };

    const handleSubmit = (epic) => {
        dispatch(epicActions.addEpic(epic))
            .then(() => {
                if (newEpicModalIsForWorkItemReporter) {
                    dispatch(epicActions.selectEpicForWorkItemReporter({ epicId: epic?.id }));
                }
                if (isNewEpicForWorkItem) {
                    dispatch(epicActions.selectEpic({ epicId: epic?.id }));
                }
            })
            .then(() => {
                if (newEpicModalIsForWorkItemReporter) {
                    const updatedWorkItem = { ...selectedWorkItem, projectEpicId: epic?.id };
                    dispatch(workItemActions.updateProjectWorkItem(updatedWorkItem));
                }
            });

        if (!isNewEpicForWorkItem && !newEpicModalIsForWorkItemReporter) {
            dispatch(paneActions.setProjectDetailSelectedTab(ProjectDetailTabs.Epic));
        }

        handleClose();
    };

    return project ? (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Add Epic"
            aria-describedby="Add a new epic to the project"
        >
            <DialogTitle>
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography variant="h3">Add Epic</Typography>
                    <InfoTooltip
                        toolTipText={
                            'Epics are big chunks of work that are related.'
                        }
                    />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <EpicForm project={project} onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="epic-form"
                >
                    Save Epic
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
}
