import {
    Avatar,
    InputAdornment,
    Stack,
    TextField,
    IconButton,
    Menu,
    MenuItem,
    Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';

export const WorkItemAssigneeListPopper = ({
    anchor,
    assignedResources,
    unassignedResources,
    onResourceAssigned,
    onResourceUnassigned,
    handleClose,
}) => {
    const [search, setSearch] = useState('');
    const [filteredAssignedResources, setFilteredAssignedResources] =
        useState(assignedResources);
    const [filteredUnassignedResources, setFilteredUnassignedResources] =
        useState(unassignedResources);

    useEffect(() => {
        if (!search) {
            setFilteredAssignedResources(assignedResources);
            setFilteredUnassignedResources(unassignedResources);
            return;
        }

        setFilteredAssignedResources(
            assignedResources.filter((x) =>
                x.name.toLowerCase()?.includes(search.toLowerCase()),
            ),
        );
        setFilteredUnassignedResources(
            unassignedResources.filter((x) =>
                x.name.toLowerCase()?.includes(search.toLowerCase()),
            ),
        );
    }, [search, assignedResources, unassignedResources]);

    return (
        <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: '350px',
                    padding: '10px',
                    borderRadius: '15px',
                },
            }}
        >
            {
                <TextField
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    placeholder="Search"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {search && (
                                    <IconButton
                                        aria-label="clear"
                                        onClick={() => setSearch('')}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                )}
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ marginBottom: '5px', maxWidth: 250 }}
                ></TextField>
            }
            {filteredAssignedResources.map((resource) => (
                <MenuItem
                    key={resource.id}
                    onClick={() => onResourceUnassigned(resource.id)}
                    sx={{
                        justifyContent: 'space-between',
                    }}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Avatar
                            alt={resource.name}
                            sx={{
                                width: '24px',
                                height: '24px',
                                fontSize: '12px',
                                marginRight: '10px',
                            }}
                        >
                            <span>{resource.initials}</span>
                        </Avatar>
                        <span>{resource.name}</span>
                    </Stack>
                    <IconButton sx={{ padding: 0 }}>
                        <ClearIcon />
                    </IconButton>
                </MenuItem>
            ))}
            {filteredAssignedResources?.length > 0 &&
            filteredUnassignedResources?.length > 0 ? (
                <Divider sx={{ marginTop: 0 }}></Divider>
            ) : null}
            {filteredUnassignedResources.map((resource) => (
                <MenuItem
                    key={resource.id}
                    onClick={() => onResourceAssigned(resource.id)}
                >
                    <Avatar
                        alt={resource.name}
                        sx={{
                            width: '24px',
                            height: '24px',
                            fontSize: '12px',
                            marginRight: '10px',
                        }}
                    >
                        <span>{resource.initials}</span>
                    </Avatar>
                    <span>{resource.name}</span>
                </MenuItem>
            ))}
        </Menu>
    );
};
