import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { useDispatch } from 'react-redux';
import { profileActions } from 'System/State/ProfileState/reducer';
import ProfileForm from 'System/Forms/ProfileForm/ProfileForm';

export default function NewProfileModal() {
    const open = useSelector(modalSelectors.newProfileModal) || false;
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(modalActions.close('newProfileModal'));
    };

    const handleSubmit = (profile) => {
        dispatch(profileActions.addProfile(profile)).then(() =>
            dispatch(profileActions.selectProfile(profile.id)),
        );
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Create new job profile</DialogTitle>
            <DialogContent>
                <ProfileForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit" form="profile-form">
                    Save Job Profile
                </Button>
            </DialogActions>
        </Dialog>
    );
}
