import { TextField, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';

export default function StatusPicker({
    selectedStatus,
    onChange,
    fullWidth,
    label,
}) {
    const statusOptions = useSelector(projectSelectors.statusOptions);

    return (
        <TextField
            name="status"
            value={selectedStatus}
            label={label}
            select
            fullWidth={fullWidth}
            SelectProps={{
                labelId: 'status-label',
                displayEmpty: true,
            }}
            onChange={onChange}
        >
            {statusOptions?.map((option) => (
                <MenuItem key={option.status} value={option.status}>
                    {option.statusName}
                </MenuItem>
            ))}
        </TextField>
    );
}
