import { createSelector } from 'reselect';
import { selectorUtils } from 'Utilities/selector-utils';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import Resource from 'Models/Resource';
import { teamSelectors } from '../TeamState/selectors';
import { resourceUtils } from 'Utilities/resource-utility';
import UnavailablePeriod from 'Models/ResourceUnavailablePeriod';
import { toMap } from '../../../Utilities/list-utils';

const { resourceMapper } = selectorUtils;
const { allProjects, allWorkItems, allPublicProjects } = projectSelectors;
const { allTeams } = teamSelectors;

const resourceState = (state) => state.resources;
const resources = (state) => state.resources?.list;
const filterOptionsSelector = (state) => state.resources?.filterOptions;
const selectedResourceId = (state) => state.resources?.selectedResource;
const users = (state) => state.company?.users;

const filterOptions = createSelector(filterOptionsSelector, (filterOptions) =>
    filterOptions ? filterOptions : null,
);

const allResources = createSelector(resources, (resources) =>
    resources?.length
        ? resources
            .slice()
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
            .map((resource) => new Resource(resource))
        : [],
);

const allResourcesMap = createSelector(resources, (resources) => {
    const resourcesMap = new Map();

    resources?.forEach((re) =>
        resourcesMap.set(re.id, new Resource(re)),
    );

    return resourcesMap;
});

//Returns all resources
const sortedResources = createSelector(allResources, (resources) =>
    resources?.length
        ? resources.slice().sort((x, y) => x.isTerminated - y.isTerminated)
        : [],
);

//Returns all resources with isTerminated set to false
const activeResources = createSelector(allResources, (resources) =>
    resources?.length
        ? resources.filter((resource) => !resource.isTerminated)
        : [],
);


const allPublicProjectActiveResources = createSelector(allResources, allPublicProjects, (resources, projects) => {
    const matchingResources = projects
      ?.flatMap((project) =>
        project.resourceTimelines?.flatMap((timeline) =>
          timeline.skilledResourceId ? [timeline.skilledResourceId] : []
        )
      )
      .map((resourceId) => {
        const matchingResource = resources.find((resource) => resource.id === resourceId && !resource.isTerminated);
        return matchingResource;
      })
      .filter(Boolean); // Removes any undefined/null values

    return matchingResources || [];
  });





const filteredResources = createSelector(
    allResources,
    allProjects,
    filterOptions,
    (resources, projects, filterOptions) =>
        resources?.length && filterOptions && projects?.length
            ? resourceMapper(resources, filterOptions, projects)
            : [],
);

const selectedResource = createSelector(
    allResources,
    selectedResourceId,
    (resources, resourceId) =>
        resources?.length && resourceId
            ? resources.find((resource) => resource.id === resourceId)
            : null,
);

const selectedResourceUser = createSelector(
    users,
    selectedResource,
    (users, resource) =>
        users?.length && resource
            ? users.find((user) => user.id === resource.userId)
            : null,
);

const selectedResourceCharacteristics = createSelector(
    selectedResourceUser,
    (user) => (user ? user.characteristics : null),
);

const selectedResourceCharacteristicsByType = createSelector(
    selectedResourceCharacteristics,
    (characteristics) => {
        if (characteristics?.length) {
            const byType = {};

            characteristics.forEach((c) => {
                const key = c.type.toLowerCase();
                if (!Object.hasOwn(byType, key)) {
                    byType[key] = [];
                }
                byType[key].push(c);
            });
            return byType;
        }
        return {};
    },
);

const showSelectedResourceCostWarning = createSelector(
    selectedResource,
    (resource) => resource?.totalCost > resource?.totalRevenue || false,
);

const resourcesByTeam = createSelector(
    sortedResources,
    allTeams,
    (resources, teams) => {
        if (!resources?.length || !teams?.length) return [];

        const unassigned = {
            teamName: 'Unassigned',
            teamId: null,
            teamResources: [],
        };

        const sorted = [];

        teams.forEach((team) => {
            sorted.push({
                teamName: team.name,
                teamId: team.id,
                teamResources: [],
            });
        });

        resources?.forEach((resource) => {
            if (!resource?.teamId) unassigned.teamResources.push(resource);
            else if (sorted.length) {
                const found = sorted.find(
                    (item) => item.teamId === resource.teamId,
                );
                if (found) {
                    found.teamResources.push(resource);
                }
            }
        });

        sorted.push(unassigned);
        return sorted;
    },
);

const selectedResourceWorkItems = createSelector(
    allWorkItems,
    allResources,
    selectedResourceId,
    (workItems, resources, resourceId) => {
        if (!workItems?.length) return [];

        const selectedItems = [];

        workItems.forEach((item) => {
            if (item.assignedSkilledResources.includes(resourceId)) {
                selectedItems.push({
                    ...item,
                    resourceNames: item.assignedSkilledResources?.length
                        ? item.assignedSkilledResources.map((id) =>
                            resourceUtils.getInitialsFromName(
                                resources.find(
                                    (resource) => resource.id === id,
                                )?.name,
                            ),
                        )
                        : [],
                });
            }
        });
        return selectedItems;
    },
);

const selectedResourceUnavailablePeriods = createSelector(
    selectedResource,
    (resource) =>
        resource?.skilledResourceUnavailablePeriods.map(
            (unavailablePeriod) => new UnavailablePeriod(unavailablePeriod),
        ),
);

const upcomingUnavailablePeriods = createSelector(
    selectedResourceUnavailablePeriods,
    (unavailablePeriods) => {
        if (unavailablePeriods) {
            const today = new Date();
            return unavailablePeriods
                .filter(
                    (unavailablePeriod) => unavailablePeriod.endDate > today,
                )
                .sort((a, b) => {
                    if (a.startDate < b.startDate) {
                        return -1;
                    }
                    if (a.startDate > b.startDate) {
                        return 1;
                    }
                    return 0;
                });
        } else return [];
    },
);

const passedUnavailablePeriods = createSelector(
    selectedResourceUnavailablePeriods,
    (unavailablePeriods) => {
        if (unavailablePeriods) {
            const today = new Date();
            return unavailablePeriods
                .filter(
                    (unavailablePeriod) => unavailablePeriod.endDate < today,
                )
                .sort((a, b) => {
                    if (a.startDate < b.startDate) {
                        return -1;
                    }
                    if (a.startDate > b.startDate) {
                        return 1;
                    }
                    return 0;
                });
        } else return [];
    },
);

const verticalGanttMonthHeight = createSelector(
    resourceState,
    (state) => state.verticalTimelineMonthHeight,
);

//Returns resources with Cost Warning
const resourcesListCostWarning = createSelector(allResources, (resources) =>
    resources?.length
        ? resources.filter(
            (resource) => resource?.totalCost > resource?.totalRevenue,
        )
        : [],
);

const allResourceInitialsMap = createSelector(resources, (resources) =>
    toMap(
        resources,
        (x) => x.id,
        (x) => new Resource(x).initials,
    ),
);

export const resourceSelectors = {
    allResources,
    allResourcesMap,
    allResourceInitialsMap,
    sortedResources,
    resourcesByTeam,
    filteredResources,
    selectedResource,
    selectedResourceWorkItems,
    showSelectedResourceCostWarning,
    selectedResourceCharacteristicsByType,
    filterOptions,
    selectedResourceUnavailablePeriods,
    upcomingUnavailablePeriods,
    passedUnavailablePeriods,
    activeResources,
    verticalGanttMonthHeight,
    resourcesListCostWarning,
    allPublicProjectActiveResources,
};
