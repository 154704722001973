import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    Stack,
    Typography,
    Divider,
} from '@mui/material';
import ResourcePicker from 'System/UI/Pickers/ResourcePicker/ResourcePicker';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import Form from 'System/Forms/Components/Form';
import ProjectProfilePicker from 'System/UI/Pickers/ProjectProfilePicker/ProjectProfilePicker';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    typography: {
        fontStyle: 'italic',
        margin: '8px',
    },
    disabledTypography: {
        fontStyle: 'italic',
        margin: '8px',
        opacity: 0.2,
    },
    divider: {
        height: 8,
        width: '100%',
        borderRadius: 5,
        margin: '10px auto',
    },
}));

export default function NewResourceTimelineForm({
    resourceTimeline,
    onSubmit,
    selectedProject,
    selectedResource,
    selectedProfile,
    allResources,
    quantity,
    setQuantity,
    handleFormChange
}) {
    const classes = useStyles();
    const areProjectDatesSet =
        !!selectedProject.estimatedStartDate &&
        !!selectedProject.estimatedCompletionDate;

    const resourceTimelineSchema = yup.object().shape({
        startDate: yup
            .date()
            .typeError('Please enter a start date')
            .required('Required'),
        endDate: yup
            .date()
            .typeError('Please enter an end date')
            .required('Required'),
        isPinned: yup.bool(),
    });

    useEffect(() => {
        handleSelectResource();
    }, [selectedResource]);

    useEffect(() => {
        handleSelectProfile();
    }, [selectedProfile]);

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: resourceTimeline,
        validationSchema: resourceTimelineSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: values => {
            onSubmit(values);
        },
    });

    useEffect(() => {
        const formData = {
            startDate: values.startDate,
            endDate: values.endDate,
            isPinned: values.isPinned,
        };
        handleFormChange(formData);
    }, [values]);

    const handleSelectResource = () => {
        setFieldValue(
            'skilledResourceId',
            selectedResource?.id ? selectedResource.id : '',
            false,
        );
        setFieldValue(
            'skilledResourceName',
            selectedResource?.name ? selectedResource.name : '',
            false,
        );
        setFieldValue(
            'hoursPerDay',
            selectedResource?.hoursPerDay ? selectedResource.hoursPerDay : 8,
            false,
        );
        setFieldValue(
            'daysPerWeek',
            selectedResource?.daysPerWeek ? selectedResource.daysPerWeek : 5,
            false,
        );
        setFieldValue(
            'actualHourlyCostRate',
            selectedResource?.defaultHourlyCostRate
                ? selectedResource.defaultHourlyCostRate
                : 0,
            false,
        );
        setFieldValue(
            'actualHourlyBillRate',
            selectedResource?.defaultHourlyBillRate
                ? selectedResource.defaultHourlyBillRate
                : 0,
            false,
        );
        setFieldValue(
            'averageVelocity',
            selectedResource?.defaultAverageVelocity
                ? selectedResource.defaultAverageVelocity
                : null,
            false,
        );
        setQuantity(0);
    };

    const handleSelectProfile = () => {
        setFieldValue(
            'projectProfileId',
            selectedProfile?.id ? selectedProfile.id : null,
            false,
        );
        if (selectedProfile) {
            handleSetQuantity(1);
        }
    };

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    const handleSetQuantity = (quantity) => {
        setFieldValue(
            'quantity',
            quantity ? quantity : 0,
            false,
        );
        setQuantity(quantity);
    };

    return resourceTimeline ? (
        <Form onSubmit={handleSubmit} formId="new-resource-timeline-form">
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Stack spacing={2}>
                <Stack>
                    <Typography variant="body2" className={classes[selectedProfile ? 'disabledTypography' : 'typography']}>
                        Add an existing resource
                    </Typography>
                    <ResourcePicker
                        name="skilledResourceId"
                        selectedResource={selectedResource}
                        label="Resources"
                        disabled={!!selectedProfile}
                        onChange={handleSelectResource}
                    />
                </Stack>

                <Stack>
                    <Typography variant="body2" className={classes[selectedResource ? 'disabledTypography' : 'typography']}>
                        Or add placeholder profiles
                    </Typography>
                    <ProjectProfilePicker
                        name="projectProfileId"
                        selectedProfile={selectedProfile}
                        label="Profiles"
                        disabled={!!selectedResource}
                        quantity={quantity}
                        setQuantity={handleSetQuantity}
                        showError={showError}
                        getError={getError}
                    />
                </Stack>

                {areProjectDatesSet && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isPinned"
                                    checked={values.isPinned}
                                    onChange={handleChange}
                                ></Checkbox>
                            }
                            label="Match the start and end dates to the project"
                        />
                    </FormGroup>
                )}
                <Divider />
                <Stack direction="row" spacing={1}>
                    <DatePicker
                        name="startDate"
                        format="MM/DD/YYYY"
                        label="Start Date"
                        disabled={values.isPinned && areProjectDatesSet}
                        fullWidth
                        required
                        value={values.startDate}
                        error={showError('startDate')}
                        helperText={getError('startDate')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <DatePicker
                        name="endDate"
                        format="MM/DD/YYYY"
                        label="End Date"
                        disabled={values.isPinned && areProjectDatesSet}
                        fullWidth
                        required
                        error={showError('endDate')}
                        helperText={getError('endDate')}
                        value={values.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                {!areProjectDatesSet && (
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                        Setting these dates will automatically set the project
                        estimated start and end dates to match.
                    </Typography>
                )}
            </Stack>
        </Form>
    ) : null;
}
