import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import RestAPI from 'System/State/rest-api';

const getInsights = createAsyncThunk(
    'insights',
    async (_, { rejectWithValue }) => {
      try {
          return await RestAPI.get('/insights');
      } catch (error) {
          return rejectWithValue();
      }
  },
);

const getInsightDetail = createAsyncThunk(
    'insights/getInsightDetail',
    async (insightId, { rejectWithValue }) => {
        try {
            return await RestAPI.get(`/insights/${insightId}`);
        } catch (error) {
            return rejectWithValue();
        }
    },
);

const changeInsightPriority = createAsyncThunk(
  'insights/changeInsightPriority', async ({ insight, newPriority }, { rejectWithValue }) => {
        record('changeInsightPriority', {
            insightId: insight.id,
            priority: newPriority
        });
        try {
            var response = await RestAPI.put(
                `/insights/${insight.id}/change-priority`,
                { body: { priority: newPriority } },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue();
        }
  },
);

const hideUserInsight = createAsyncThunk(
  'insights/hideUserInsight', async ({ insight }, { rejectWithValue }) => {
        record('hideUserInsight', {
            insightId: insight.id
        });
        try {
            var response = await RestAPI.put(
                `/insights/${insight.id}/hide`,
                { body: null },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue();
        }
  },
);

const showUserInsight = createAsyncThunk(
  'insights/showUserInsight', async ({ insight }, { rejectWithValue }) => {
        record('showUserInsight', {
            insightId: insight.id
        });

        try {
            var response = await RestAPI.put(
                `/insights/${insight.id}/show`,
                { body: null },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue();
        }
  },
);

export const insightsActionsAsync = {
  getInsights,
  getInsightDetail,
  changeInsightPriority,
  hideUserInsight,
  showUserInsight
};