import {
    Stack,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    Divider,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { projectActions } from 'System/State/ProjectState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import ProjectDetailForm from 'System/Forms/ProjectForms/ProjectDetailsForm';
import ProjectTags from './Components/ProjectTags';

export default function ProjectDetailModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.projectDetailModal) || false;
    const project = useSelector(projectSelectors.selectedProject);

    const handleClose = () => {
        dispatch(modalActions.close('projectDetailModal'));
    };

    const handleSubmit = (project) => {
        dispatch(projectActions.updateProject(project));
        handleClose();
    };

    if (project) {
        return (
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                fullWidth={true}
                maxWidth={'sm'}
                aria-labelledby="Project Details"
                aria-describedby="Update project details"
            >
                <DialogTitle>Project Summary</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <ProjectDetailForm
                            project={project}
                            onSubmit={handleSubmit}
                        />
                        <Divider />
                        <ProjectTags />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                        sx={{ width: '100%' }}
                    >
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            form="project-detail-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        );
    } else {
        return null;
    }
}
