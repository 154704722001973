import Basic from './Basic';

export default class Profile extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        // a generic name of the profile (Full Stack Developer)
        this.name = obj.name || '';

        // the standard hourly rate budgeted for this profile
        this.standardHourlyCostRate = Number(obj.standardHourlyCostRate) || 0;

        this.standardHourlyBillRate = Number(obj.standardHourlyBillRate) || 0;

        this.standardMinimumSalary = Number(obj.standardMinimumSalary) || 0;

        this.standardMaximumSalary = Number(obj.standardMaximumSalary) || 0;

        this.color = obj.color || '';
    }
}
