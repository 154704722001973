import { useState } from 'react';
import {
    Stack,
    DialogContent,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
} from '@mui/material';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { milestoneSelectors } from 'System/State/MilestoneState/selectors';
import { milestoneActions } from 'System/State/MilestoneState/reducer';
import MilestoneForm from 'System/Forms/MilestoneForm/MilestoneForm';

export default function MilestoneDetailModal() {
    const open = useSelector(modalSelectors.milestoneDetailModal) || false;
    const dispatch = useDispatch();
    const milestone = useSelector(milestoneSelectors.selectedMilestone);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleClose = () => {
        dispatch(modalActions.close('milestoneDetailModal'));
    };

    const handleDelete = (milestone) => {
        dispatch(milestoneActions.deleteMilestone(milestone));
        handleClose();
    };

    const handleSubmit = (milestone) => {
        dispatch(milestoneActions.updateMilestone(milestone));
        handleClose();
    };

    return milestone ? (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Milestone Details"
            aria-describedby="Update milestone details"
        >
            <DialogTitle>Milestone Details</DialogTitle>
            <DialogContent>
                <MilestoneForm milestone={milestone} onSubmit={handleSubmit} />

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="Milestone"
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        handleDelete(milestone);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <Button
                        variant="outlined"
                        color="tertiary"
                        onClick={() => {
                            setDeleteModalOpen(true);
                        }}
                    >
                        Delete
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            form="milestone-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
