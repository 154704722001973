import { Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default function FeatureListItem({ text, bold = false }) {
    return (
        <Stack direction="row" alignItems="center" spacing={2}>
            <CheckIcon fontSize="small" />
            <Typography fontWeight={bold ? 600 : 500}>{text}</Typography>
        </Stack>
    );
}
