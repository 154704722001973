import {
    addItemToList,
    addToOrUpdateInList,
    getUpdatedList,
    removeItemFromListById,
} from 'Utilities/reducer-utils';
import { createSlice } from '@reduxjs/toolkit';
import { teamActionsAsync } from './actions';
import { coreActionsAsync } from 'System/State/CoreState/actions';

const { appStart, offline } = coreActionsAsync;

const { addTeam, deleteTeam, disableTeam, getTeamDetail, updateTeam } =
    teamActionsAsync;

const initialState = {
    list: [],
    selectedTeam: null,
    teamPaneIsVisible: false,
};

export const TeamSlice = createSlice({
    name: 'teams',
    initialState: initialState,
    reducers: {
        addTeamSync(state, action) {
            state.list = addItemToList(state.list, action.payload);
        },
        deleteTeamSync(state, action) {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        resetSlice() {
            return initialState;
        },
        selectTeam(state, action) {
            state.selectedTeam = action.payload;
        },
        unselectTeam(state) {
            state.selectedTeam = null;
        },
        updateTeamSync(state, action) {
            state.list = getUpdatedList(state.list, action.payload);
        },
    },
    extraReducers: {
        [addTeam.fulfilled]: (state, action) => {
            state.list = addItemToList(state.list, action.payload);
        },
        [appStart.fulfilled]: (state, action) => {
            const { teams } = action.payload.data;
            if (teams) state.list = teams;
        },
        [offline.fulfilled]: (state, action) => {
            const { teams } = action.payload.data;
            if (teams) state.list = teams;
        },
        [deleteTeam.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [disableTeam.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [getTeamDetail.fulfilled]: (state, action) => {
            state.list = addToOrUpdateInList(state.list, action.payload.data);
        },
        [getTeamDetail.rejected]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [updateTeam.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
    },
});

export const teamActions = {
    ...TeamSlice.actions,
    ...teamActionsAsync,
};

export default TeamSlice.reducer;
