import Project from 'Models/Project';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { projectActionsAsync } from './actions';
import { record } from 'Utilities/record-utility';
import RestAPI from '../rest-api';
import actionUtils from 'Utilities/action-utils';
import moment from 'moment';
import { createChangeSchedulePayload } from 'Utilities/api-transform-utility';

const {
    changeProjectEstimatedCompletionDate,
    changeProjectEstimatedStartDate,
} = actionUtils;

const addEpic = createAsyncThunk('projects/addEpic',
    async (epic, { rejectWithValue }) => {
        try {
            let response = await RestAPI.post(`/projects/${epic.projectId}/epics`, { body: epic });
            record('addEpic', { id: response.data, name: epic.name });
            return { ...epic, id: response.data };
        } catch (error) {
            return rejectWithValue({
                error: error.response?.data?.errors,
            });
        }   
    },
);

const deleteEpic = createAsyncThunk('projects/deleteEpic', async (epic) => {
    record('deleteEpic', { id: epic.id, name: epic.name });
    await RestAPI.del(`/projects/${epic.projectId}/epics/${epic.id}`);
    return { ...epic };
});

const getEpicDetail = createAsyncThunk(
    'projects/getEpicDetail',
    async ({ epicId, projectId }, { rejectWithValue }) => {
        try {
            return await RestAPI.get(`projects/${projectId}/epics/${epicId}`);
        } catch (error) {
            return rejectWithValue({
                projectId,
                epicId,
            });
        }
    },
);

const moveEpicToExistingProject = createAsyncThunk(
    'projects/moveEpicToExistingProject',
    async ({ epic, newProjectId }) => {
        await RestAPI.put(
            `/projects/${epic.projectId}/epics/${epic.id}/move-epic`,
            { body: { newProjectId } },
        );
        return {
            epic,
            newProjectId,
        };
    },
);

const updateEpic = createAsyncThunk('project/updateEpic', async (epic) => {
    record('updateEpic', { id: epic.id, name: epic.name });
    await RestAPI.put(`/projects/${epic.projectId}/epics/${epic.id}`, {
        body: epic,
    });
    return { ...epic };
});

const upgradeToProject = createAsyncThunk(
    'projects/updateToProject',
    async (epic, { dispatch }) => {
        //TODO: move to new API pattern
        const newProject = new Project(epic);
        record('upgradeToProject', { id: epic.id, name: epic.name });
        dispatch(deleteEpic(epic));
        dispatch(projectActionsAsync.addProject(newProject));
    },
);

const updateEpicEstimatedCompletionDate = createAsyncThunk(
    'projects/updateEpicEstimatedCompletionDate',
    async ({ project, epic, newDate }) => {
        record('updateEpicEstimatedCompletionDate', {
            id: epic.id,
            name: epic.name,
        });

        epic.estimatedCompletionDate = newDate;
        if (moment(newDate).isAfter(project?.estimatedCompletionDate)) {
            changeProjectEstimatedCompletionDate(project, newDate);
        }
        await RestAPI.put(`/projects/${project.id}/change-schedule`, {
            body: createChangeSchedulePayload(project),
        });

        return { ...project };
    },
);

const updateEpicEstimatedStartDate = createAsyncThunk(
    'projects/updateEpicEstimatedStartDate',
    async ({ project, epic, newDate }) => {
        record('updateEpicEstimatedStartDate', {
            id: epic.id,
            name: epic.name,
        });

        epic.estimatedStartDate = newDate;
        if (moment(newDate).isBefore(project?.estimatedStartDate)) {
            changeProjectEstimatedStartDate(project, newDate);
        }

        await RestAPI.put(`/projects/${project.id}/change-schedule`, {
            body: createChangeSchedulePayload(project),
        });

        return { ...project };
    },
);

export const epicActionsAsync = {
    addEpic,
    deleteEpic,
    getEpicDetail,
    moveEpicToExistingProject,
    updateEpic,
    upgradeToProject,
    updateEpicEstimatedCompletionDate,
    updateEpicEstimatedStartDate,
};
