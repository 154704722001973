import moment from 'moment';

export default class Calculators {
    static daysShifted(originalDate, newDate) {
        return this.durationInCalendarDays(originalDate, newDate) - 1;
    }

    static diffInCalendarDays(earlier, later) {
        let momentA = moment(earlier);
        let momentB = moment(later);
        return momentA.diff(momentB, 'days');
    }

    static durationInCalendarDays(startDate, endDate) {
        let momentA = moment(startDate);
        let momentB = moment(endDate);
        return momentB.diff(momentA, 'days') + 1;
    }

    static durationInWorkDays(startDate, endDate, numberOfWorkDaysPerWeek) {
        // https://stackoverflow.com/questions/28425132/how-to-calculate-number-of-working-days-between-two-dates-in-javascript-using
        const start = moment(startDate);
        const end = moment(endDate);
        const first = start.clone().endOf('week'); // end of first week
        const last = end.clone().startOf('week'); // start of last week
        const days = (last.diff(first, 'days') * 5) / 7; // this will always multiply of 7
        let wfirst = first.day() - start.day(); // check first week
        if (start.day() === 0) --wfirst; // -1 if start with sunday
        let wlast = end.day() - last.day(); // check last week
        if (end.day() === 6) --wlast; // -1 if end with saturday
        return wfirst + Math.floor(days) + wlast; // get the total
    }

    static dateIsGreater(date1, date2) {
        return date1 > date2;
    }

    static widthFromDates(startDate, endDate, uomWidth) {
        // determine how many days the project currently is
        var durationInDays = this.durationInCalendarDays(startDate, endDate);

        // get width in pixels
        var pixels = durationInDays * uomWidth;

        return pixels;
    }

    static xPosition(elementStartDate, canvasStartDate, uomWidth) {
        // todo: need to set up timeline as a reference

        var width = 0;
        if (elementStartDate > canvasStartDate) {
            width = this.widthFromDates(
                canvasStartDate,
                elementStartDate,
                uomWidth,
            );
        } else {
            width =
                this.widthFromDates(
                    elementStartDate,
                    canvasStartDate,
                    uomWidth,
                ) * -1;
        }

        return width;
    }

    static dateFromXPosition(x, canvasStartDate, uomWidth) {
        // get number of days by x position
        var numberOfDays = x / uomWidth - 1;

        // calculate new date
        var newDate = new Date(canvasStartDate);
        newDate.setDate(canvasStartDate.getDate() + numberOfDays);

        return newDate;
    }

    static dateRangeOverlaps(startDate, endDate, filterStart, filterEnd) {
        if (!startDate) {
            startDate = filterStart;
        }
        if (!endDate) {
            endDate = filterEnd;
        }
        if (!filterStart) {
            filterStart = startDate;
        }
        if (!filterEnd) {
            filterEnd = endDate;
        }

        if (endDate < filterStart || startDate > filterEnd) {
            return null;
        }

        return {
            startDate: startDate <= filterStart ? filterStart : startDate,
            endDate: endDate <= filterEnd ? endDate : filterEnd,
        };
    }

    //Gets all items in an array between two dates
    static isRangeBetweenDates(ranges, comparisonRange) {
        let returnedRanges = [];
        let comparisonStart = moment(comparisonRange?.startDate);
        let comparisonEnd = moment(comparisonRange?.endDate);
        ranges.forEach((range) => {
            let rangeStart = moment(range?.startDate);
            let rangeEnd = moment(range?.endDate);
            if (rangeStart <= comparisonEnd && rangeEnd >= comparisonStart) {
                returnedRanges.push(range);
            }
        });

        return returnedRanges;
    }

    static getFilteredDates(range, comparisonRange) {
        let rangeStart = moment(range?.startDate);
        let rangeEnd = moment(range?.endDate);
        let comparisonStart = moment(comparisonRange?.startDate);
        let comparisonEnd = moment(comparisonRange?.endDate);

        return {
            startDate:
                rangeStart <= comparisonStart ? comparisonStart : rangeStart,
            endDate: rangeEnd >= comparisonEnd ? comparisonEnd : rangeEnd,
        };
    }

    static getFilteredDatesProject(
        estimatedStartDate,
        estimatedCompletionDate,
        comparisonRange,
    ) {
        let rangeStart = moment(estimatedStartDate);
        let rangeEnd = moment(estimatedCompletionDate);
        let comparisonStart = moment(comparisonRange?.startDate);
        let comparisonEnd = moment(comparisonRange?.endDate);

        return {
            startDate:
                rangeStart <= comparisonStart ? comparisonStart : rangeStart,
            endDate: rangeEnd >= comparisonEnd ? comparisonEnd : rangeEnd,
        };
    }
}
