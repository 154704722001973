import {
    TextField,
    MenuItem,
    Stack,
    Autocomplete,
    IconButton,
    Tooltip,
} from '@mui/material';
import { profileSelectors } from 'System/State/ProfileState/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { userSelectors } from 'System/State/UserState/selectors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { profileActions } from 'System/State/ProfileState/reducer';

export default function ProfilePicker({ required, selectedProfile }) {
    const dispatch = useDispatch();
    const isAllowedAdmin = useSelector(userSelectors.isAllowedAdmin);
    const availableProfiles = useSelector(profileSelectors.allProfiles);

    return availableProfiles ? (
        <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            width="100%"
        >
            <Autocomplete
                options={availableProfiles}
                value={selectedProfile}
                getOptionLabel={(option) => option.name || ''}
                id="auto-complete"
                onChange={(_, profile) =>
                    dispatch(profileActions.selectProfile(profile?.id))
                }
                autoComplete
                required={required}
                noOptionsText={
                    isAllowedAdmin ? (
                        <MenuItem
                            onMouseDown={() =>
                                dispatch(modalActions.open('newProfileModal'))
                            }
                        >
                            Add Profile
                        </MenuItem>
                    ) : (
                        'No Options Found'
                    )
                }
                fullWidth
                includeInputInList
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={required}
                        fullWidth
                        label="Job Profile"
                    />
                )}
            />
            {isAllowedAdmin ? (
                <IconButton
                    onClick={() =>
                        dispatch(modalActions.open('newProfileModal'))
                    }
                >
                    <Tooltip
                        title="Can't find what you're looking for? Click here to add a new job profile to this company."
                        aria-label="add-new-profile"
                        enterDelay={300}
                        placement="top"
                    >
                        <AddCircleOutlineIcon />
                    </Tooltip>
                </IconButton>
            ) : null}
        </Stack>
    ) : null;
}
