import RestAPI from '../rest-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import { saveAs } from 'file-saver';
import User from 'Models/User';

const activateResource = createAsyncThunk(
    'resources/activateResource',
    async (resource) => {
        record('activateResource', { id: resource.id, name: resource.name });
        RestAPI.post(`/skilled-resources/${resource.id}/activate`);
        return { ...resource };
    },
);

const deactivateResource = createAsyncThunk(
    'resources/deactivateResource',
    async (resource) => {
        record('deactivateResource', { id: resource.id, name: resource.name });
        RestAPI.post(`/skilled-resources/${resource.id}/deactivate`);
        return { ...resource };
    },
);

const addResource = createAsyncThunk(
    'resources/addResource',
    async (resource) => {
        record('addResource', { id: resource.id, name: resource.name });
        await RestAPI.post('/skilled-resources', { body: resource });
        return { ...resource };
    },
);

// Flips isActive bool to false -- soft delete
const deleteResource = createAsyncThunk(
    'resources/deleteResource',
    async (resource) => {
        record('deleteResource', { id: resource.id, name: resource.name });
        RestAPI.del(`/skilled-resources/${resource.id}`);
        return { ...resource };
    },
);

const getResourceDetail = createAsyncThunk(
    'resources/getResourceDetail',
    async (resourceId, { getState, rejectWithValue }) => {
        try {
            const { loggedInUser } = getState().user || {};
            const user = loggedInUser ? new User(loggedInUser) : null;

            const endpoint = user?.isAllowedFinancials
                ? `/skilled-resources/withFinancials/${resourceId}`
                : `/skilled-resources/${resourceId}`;
            return await RestAPI.get(endpoint);
        } catch (error) {
            return rejectWithValue(resourceId);
        }
    },
);

const updateResource = createAsyncThunk(
    'resources/updateResource',
    async (resource) => {
        record('updateResource', { id: resource.id, name: resource.name });
        await RestAPI.put(`/skilled-resources/${resource.id}`, {
            body: {
                color: resource.color,
                company: resource.company,
                defaultHourlyBillRate: resource.defaultHourlyBillRate,
                defaultAverageVelocity: resource.defaultAverageVelocity,
                defaultHourlyCostRate: resource.defaultHourlyCostRate,
                daysPerWeek: resource.daysPerWeek,
                description: resource.description,
                hireDate: resource.hireDate,
                hoursPerDay: resource.hoursPerDay,
                name: resource.name,
                profileId: resource.profileId,
                teamId: resource.teamId,
                terminationDate: resource.terminationDate,
                userId: resource.userId,
                actualSalary: resource.actualSalary,
            },
        });

        return structuredClone(resource);
    },
);

const importResourcesFromExcel = createAsyncThunk(
    'resources/importResourcesFromExcel',
    async ({ file, errorCallback, loadingCallback }) => {
        record('importResourcesFromExcel', { file: file.name });

        const formData = new FormData();
        formData.append('files', file);

        return await RestAPI.postFormData(
            '/skilled-resources/skilledresourceimport',
            {
                body: formData,
            },
            errorCallback,
            loadingCallback,
        );
    },
);

const getResourcesImportTemplate = createAsyncThunk(
    'resources/getResourcesImportTemplate',
    async (thunkAPI) => {
        try {
            const response = await RestAPI.getFile(
                '/skilled-resources/skilledresourceimporttemplate',
            );

            saveAs(
                new Blob([response.data]),
                'fairview-resource-template.xlsx',
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

export const resourceActionsAsync = {
    activateResource,
    addResource,
    deactivateResource,
    deleteResource,
    getResourceDetail,
    updateResource,
    importResourcesFromExcel,
    getResourcesImportTemplate,
};
