import { useEffect } from 'react';
import { userSelectors } from '../../State/UserState/selectors';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

export default function GoogleAnalytics() {
    const loggedInUser = useSelector(userSelectors.loggedInUser);

    useEffect(() => {
        if (
            process.env.NODE_ENV === 'production' &&
            (loggedInUser?.isCookieConsent === true ||
                useCookies['cookieConsent'] === 'true')
        ) {
            ReactGA.initialize('G-MCGXPL6BTK'); // connected to adwords
            ReactGA.gtag('config', 'AW-11147069359');
        }
    }, []);

    return null;
}
