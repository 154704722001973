import Basic from './Basic';

export default class UserSentiment extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.userId = obj.userId;
        this.isActive = obj.isActive;
        this.sentiment = obj.sentiment;
        this.notes = obj.notes;
        this.canBeDeleted = obj.canBeDeleted;
    }
}
