import { Suspense, lazy } from 'react';
import { Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ErrorMessage from 'System/Forms/Components/ErrorMessage';
import Form from 'System/Forms/Components/Form';
import { phoneRegExp } from 'Utilities/phoneRegExp';

const PhoneNumberInput = lazy(() =>
    import('System/UI/Components/PhoneNumberInput/PhoneNumberInput'),
);

export default function MyProfileForm({ user, onSubmit }) {
    const userSchema = yup.object().shape({
        firstName: yup
            .string()
            .required('Required')
            .max(50, 'Cannot be more than 50 charcters'),
        lastName: yup
            .string()
            .required('Required')
            .max(50, 'Cannot be more than 50 charcters'),
        phoneNumber: yup
            .string()
            .matches(phoneRegExp, 'Invalid Phone Number')
            .required('Required'),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: user,
            validationSchema: userSchema,
            validateOnBlur: true,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return user ? (
        <Form onSubmit={handleSubmit} formId="my-profile-form">
            <Stack marginTop={2} spacing={2}>
                <TextField
                    name="firstName"
                    label="First Name"
                    value={values.firstName}
                    placeholder="First Name"
                    fullWidth
                    required
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />

                <TextField
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    placeholder="Last Name"
                    fullWidth
                    required
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                />

                <Suspense fallback={<div>Loading...</div>}>
                    <PhoneNumberInput
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        onBlur={handleBlur}
                        onChange={handleChange('phoneNumber')}
                        value={
                            (values.phoneNumber = values.phoneNumber?.replace(
                                /-| |\(|\)/g,
                                '',
                            ))
                        }
                        error={errors.phoneNumber && touched.phoneNumber}
                    ></PhoneNumberInput>
                    <ErrorMessage error={getError('phoneNumber')} />
                </Suspense>
            </Stack>
        </Form>
    ) : null;
}
