import { createTheme } from '@mui/material/styles';

const bodyFont = `"Work Sans", sans-serif`;
const headerFont = `"Work Sans", sans-serif`;
export const monthColor = '#F8F8F8';

let theme = createTheme();

theme = createTheme({
    palette: {
        primary: {
            main: '#FFAB00',
        },
        secondary: {
            main: '#FF6633',
        },
        tertiary: {
            main: '#500e28',
        },

        primaryDark: {
            main: '#09425A',
        },
        warning: {
            main: '#E83800',
        },
        boldBackground: {
            main: '#F5F5F5',
        },
        milestone: {
            main: '#6B29FF',
        },
        todayLine: {
            main: '#C4C1BB',
        },
        progress: {
            green: '#66DD99',
            red: '#FF6633',
        },
        overbudget: {
            main: '#FF0F0F',
        },
        outOfBudget: {
            main: '#AD0000',
        },
        behindSchedule: {
            main: '#AD0000',
        },
        onTrack: {
            main: '#00D060',
        },
        offTrack: {
            main: '#FFA800',
        },
        atRisk: {
            main: '#E83800',
        },
        characteristic: {
            main: '#F7D8AF',
            dark: '#FF9A36',
            opaque: '#F7D8AF',
        },
        label: {
            main: '#B0E8FA',
            dark: '#33C3FF',
            opaque: '#B0E8FA',
        },
        lineOfBusiness: {
            main: '#B2FDB7',
            dark: '#30db35',
            opaque: '#B2FDB7',
        },
        department: {
            main: '#B2FDB7',
            dark: '#30db35',
            opaque: '#B2FDB7',
        },
        loved: {
            main: '#A2F578',
            opaque: '#A2F57833'
        },
        wanted: {
            main: '#EE67F0',
            opaque: '#EE67F033',
        },
        skilled: {
            main: '#7CF2DE',
            opaque: '#7CF2DE33',
        },
        dreaded: {
            main: '#E90000',
            opaque: '#E9000033',
        },
        task: {
            main: '#108FFF',
        },
        feature: {
            main: '#18B42C',
        },
        problem: {
            main: '#FB0006',
        },
    },
    typography: {
        fontFamily: bodyFont,
        h1: {
            fontSize: '21pt',
            fontFamily: headerFont,
            marginBottom: 16,
        },
        h2: {
            fontSize: '21pt',
            fontFamily: headerFont,
            paddingTop: 32,
            fontWeight: 500,
            marginBottom: 16,
        },
        h3: {
            fontSize: '16pt',
        },
        h4: {
            fontSize: '14pt',
        },
        h5: {
            fontSize: '12pt',
            color: '#999',
        },
        h6: {
            fontSize: '10pt',
            fontWeight: 400,
        },
        body2: {
            marginTop: '16px',
            marginBottom: '16px',
        },
        body3: {
            fontSize: '9pt',
        },
        body4: {
            color: '#09425A',
            paddingBottom: 16,
        },
        financialSmall: {
            fontSize: '10pt',
            fontWeight: 600,
        },
        financial: {
            fontWeight: 500,
        },
        label: {
            fontSize: '9pt',
            whiteSpace: 'nowrap',
        },
        subHeader: {
            '& a': {
                color: '#FF6633',
                fontWeight: '600',
            },
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    zIndex: 1300,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                // used for primary actions
                contained: {
                    fontSize: '0.9rem',
                    textTransform: 'none',
                    backgroundColor: '#FDCA08',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#fdd408',
                        boxShadow: 'none',
                    },
                },

                // used for confirming a destructive action
                containedTertiary: {
                    fontSize: '0.9rem',
                    color: '#fff',
                    backgroundColor: '#df4b35',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: '#da2626',
                    },
                },

                // default button
                text: {
                    color: '#333333',
                    fontSize: '0.9rem',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#F37A42',
                        backgroundColor: 'inherit',
                    },
                },

                // medium importance buttons
                outlined: {
                    border: '1px solid #FDCA08',
                    fontSize: '0.9rem',
                    textTransform: 'none',
                    color: '#333333',
                    '&:hover': {
                        borderColor: '#fdd408',
                        backgroundColor: '#fdd408',
                        boxShadow: 'none',
                    },
                },

                // used for initiating a destructive action
                outlinedTertiary: {
                    fontSize: '0.9rem',
                    color: '#333333',
                    border: '1px solid #df4b35',
                    textTransform: 'none',
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: '#df4b35',
                        border: '1px solid #df4b35',
                    },
                },

                //used for toggling between two views
                outlinedText: {
                    border: '1px solid grey',
                    borderRadius: '4px',
                    fontSize: '0.8rem',
                    textTransform: 'none',
                    color: '#333333',
                    '&:hover': {
                        borderColor: '#FDCA08',
                        backgroundColor: '#FDCA08',
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    paddingLeft: 12,
                    paddingRight: 12,
                    border: '1px solid #ccc',
                    boxShadow: 'none',
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingTop: '16px',
                    paddingBottom: '16px',
                },
            },
        },
        MuiGrid: {
            defaultProps: {
                spacing: 2,
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    // border: 0,
                    backgroundColor: '#fff',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    ['& + .MuiDialogContent-root']: {
                        paddingTop: '12px !important',
                    },
                    fontSize: '1.25rem',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingRight: 24,
                    paddingLeft: 24,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    marginTop: 5,
                    marginBottom: 5,
                },
            },
        },
        MuiDrawer: {
            defaultProps: {
                anchor: 'right',
                //variant: "persistent",
                transitionDuration: { enter: 400, exit: 300 },
                elevation: 8,
                sx: {
                    //width: '100%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        [theme.breakpoints.down('sm')]: {
                            width: '100%',
                        },
                        [theme.breakpoints.up('md')]: {
                            width: '85%',
                        },

                        boxSizing: 'border-box',
                        padding: 3,
                        background: '#F4F4F4',
                        boxShadow:
                            '1px 2px 4px -1px rgb(0 0 0 / 20%), -2px 0px 5px 0px rgb(0 0 0 / 14%), 3px 10px 10px 0px rgb(0 0 0 / 12%)',
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
                color: '#333333',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#DCF3DB',
            },
            barColorPrimary: {
                backgroundColor: '#67CC66',
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
                InputLabelProps: {
                    shrink: true,
                    focused: false,
                },
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    maxWidth: 500,
                },
            },
        },
    },
});

export default theme;
