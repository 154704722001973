import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { useDispatch } from 'react-redux';
import { projectActions } from 'System/State/ProjectState/reducer';
import NewProjectForm from 'System/Forms/ProjectForms/NewProjectForm';
import { userSelectors } from 'System/State/UserState/selectors';
import { paneActions } from 'System/State/PaneState/reducer';
import { useNavigate } from 'react-router-dom';

export default function NewProjectModal() {
    const open = useSelector(modalSelectors.newProjectModal) || false;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClose = () => {
        dispatch(modalActions.close('newProjectModal'));
    };

    const handleSubmit = (project) => {
        dispatch(projectActions.addProject(project));
        navigate(`/projects/${project.id}`);

        handleClose();
    };

    const handleImportClick = () => {
        dispatch(paneActions.openSettingsWithSelectedTab(5));
        handleClose();
    };

    const isAllowedAdmin = useSelector(userSelectors.isAllowedAdmin);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add Project</DialogTitle>
            <DialogContent>
                <NewProjectForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                {isAllowedAdmin && (
                    <Button
                        sx={{ color: 'red', border: 'none' }}
                        onClick={() => handleImportClick()}
                    >
                        Import Projects
                    </Button>
                )}
                <div>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        type="submit"
                        form="new-project-form"
                    >
                        Save Project
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
