import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { companyActions } from 'System/State/CompanyState/reducer';
import CompanyForm from 'System/Forms/CompanyForm/CompanyForm';

export default function CompanyDetailModal() {
    const dispatch = useDispatch();
    const tenant = useSelector(companySelectors.tenant);
    const open = useSelector(modalSelectors.companyDetailModal) || false;

    const handleClose = () => {
        dispatch(modalActions.close('companyDetailModal'));
    };

    const handleSubmit = (tenant) => {
        dispatch(companyActions.updateTenant(tenant));
        handleClose();
    };

    return tenant ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Company Details"
            aria-describedby="Update company details"
        >
            <DialogTitle>Company Details</DialogTitle>
            <DialogContent>
                <CompanyForm tenant={tenant} onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="company-form"
                >
                    Save Details
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
}
