import { useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import DisplayUtils from 'Utilities/display-utils';

export default function TagInput({ type, title, options, onAdd, outlined }) {
    const [value, setValue] = useState('');
    const [key, setKey] = useState(0);

    const handleChange = (_, val) => {
        const value = val?.trim();
        if (value) {
            onAdd(value);
            setValue('');
            setKey(key + 1);
        }
    };

    const getDisplayCase = (type) =>
        type
            .replace(/([A-Z])/g, ' $1')
            ?.replace(/^./, (str) => str.toUpperCase());

    const getOptionLabel = (option) => `${DisplayUtils.toTitleCase(option)}`;

    const styles = outlined
        ? {
            borderColor: '#ccc',
            borderWidth: 2,
            borderStyle: `solid`,
        }
        : {
            background: (theme) => theme.palette[type]?.main,
        };

    return (
        <Box
            spacing={1}
            width={180}
            sx={{
                ...styles,
                borderRadius: 50,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '32px',
                padding: 0,
                paddingLeft: '12px',
                paddingRight: '12px',
                backgroundColor: (theme) => theme.palette[type]?.opaque,
                borderColor: (theme) => theme.palette[type]?.main,
            }}
        >
            <Autocomplete
                key={key}
                options={options}
                inputValue={value}
                freeSolo
                fullWidth
                disableClearable
                onChange={handleChange}
                getOptionLabel={getOptionLabel}
                sx={{
                    '& MuiInput-root': {
                        paddingBottom: '0px !important',
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        onChange={(e) => setValue(e.target.value)}
                        placeholder={`Add ${getDisplayCase(title || type)}`}
                        variant="standard"
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            sx: {
                                '& .MuiInput-input': {
                                    fontSize: '13px',
                                    backgroundColor: (theme) =>
                                        theme.palette[type]?.opaque,
                                },
                            },
                            style: {
                                padding: '0px',
                            },
                        }}
                    />
                )}
            />
        </Box>
    );
}
