import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { COGNITO } from './constants';

const cognitoUserPool = new CognitoUserPool({
    UserPoolId: COGNITO.UserPoolId,
    ClientId: COGNITO.ClientId,
});

const idpCognitoUserPool = new CognitoUserPool({
    UserPoolId: COGNITO.UserPoolId,
    ClientId: COGNITO.IdpClientId,
});

const getCognitoUser = (username) => {
    return new CognitoUser({
        Username: username,
        Pool: cognitoUserPool,
    });
};

const getCognitoUserFromPool = (username, userPool) => {
    return new CognitoUser({
        Username: username,
        Pool: userPool,
    });
};

const getCurrentUser = () => {
    const user = cognitoUserPool.getCurrentUser();
    if (!user) return null;

    return user;
};

const getCurrentUserSession = (user) => {
    if (!user) return null;
    const session = user.getSession((err, res) => {
        if (err) return err;
        return res;
    });
    return session;
};

const getCurrentUserWithSession = async () => {
    const user = await getCurrentUserFromUserPool();
    if (!user) return null;

    const session = await getUserSession(user);
    user.setSignInUserSession(session);

    return user;
};

const getCurrentUserFromUserPool = async () => {
    return new Promise((resolve, reject) => {
        resolve(
            cognitoUserPool.getCurrentUser() ||
            idpCognitoUserPool.getCurrentUser(),
        );
        reject();
    });
};

const getUserSession = async (cognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((err, result) => {
            if (err) {
                reject(err);
            }
            resolve(result);
        });
    });
};

const authenticateUser = async (cognitoUser, cognitoAuthenticationDetails) => {
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            onSuccess: (result) => resolve(result),
            onFailure: (error) =>
                error.code === 'UserNotConfirmedException'
                    ? resolve('CONFIRMATION_REQUIRED')
                    : reject(error),
            newPasswordRequired: () => resolve('NEW_PASSWORD_REQUIRED'),
        });
    });
};

const getUserAttributes = async (cognitoUser) => {
    return new Promise((resolve, reject) => {
        cognitoUser.getUserAttributes((err, result) => {
            if (err) {
                reject(err);
            }
            resolve(result);
        });
    });
};

const forgotPassword = async (username) => {
    const cognitoUser = getCognitoUser(username);
    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: resolve,
            onFailure: reject,
        });
    });
};

const forgotPasswordConfirmed = async (username, code, newPassword) => {
    const cognitoUser = getCognitoUser(username);
    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(code, newPassword, {
            onSuccess: resolve,
            onFailure: (err) => reject(err),
        });
    });
};

const changePassword = async (oldPassword, newPassword) => {
    const cognitoUser = await getCurrentUserWithSession();
    return new Promise((resolve, reject) => {
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
            if (err) {
                reject(err);
            }
            resolve(result);
        });
    });
};

const resendCode = async (username) => {
    const cognitoUser = getCognitoUser(username);
    return new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode((err) => {
            if (err) {
                reject(err);
            }
            resolve();
        });
    });
};

const confirmSignUp = (username, code) => {
    const cognitoUser = getCognitoUser(username);
    if (!cognitoUser) return null;
    return new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                reject(err);
            } else {
                resolve(result);
            }
        });
    });
};

const completeNewPasswordRequirement = async (
    cognitoUser,
    newPassword,
    attributes,
) => {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(newPassword, attributes, {
            onSuccess: (result) => resolve(result),
            onFailure: (err) => reject(err),
        });
    });
};

const cognitoUtility = {
    cognitoUserPool,
    idpCognitoUserPool,
    authenticateUser,
    changePassword,
    completeNewPasswordRequirement,
    confirmSignUp,
    getCognitoUser,
    getCognitoUserFromPool,
    getCurrentUserFromUserPool,
    getUserAttributes,
    getCurrentUser,
    getCurrentUserSession,
    getCurrentUserWithSession,
    forgotPassword,
    forgotPasswordConfirmed,
    resendCode,
};

export default cognitoUtility;
