import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { record } from 'Utilities/record-utility';
import { coreActionsAsync } from '../CoreState/actions';
import RestAPI from '../rest-api';
import FileSaver from 'file-saver';
import { saveAs } from 'file-saver';

const addAttachmentToWorkItem = createAsyncThunk(
    'projects/addAttachmentToWorkItem',
    async (attachment, { dispatch }) => {
        const presignedUrl = await dispatch(
            coreActionsAsync.requestAttachmentUploadLink(attachment),
        );

        if (presignedUrl?.payload.data) {
            var options = {
                headers: {
                    'Content-Type': attachment.mimeType,
                },
            };

            await axios.put(
                presignedUrl?.payload.data,
                attachment.attachment,
                options,
            );

            await RestAPI.post(
                `projects/work-items/${attachment.businessEntityId}/attachments`,
                {
                    body: attachment,
                },
            );

            dispatch(getWorkItemAttachments(attachment.businessEntityId));
        }
    },
);

const addAttachmentToNewWorkItem = createAsyncThunk(
    'projects/addAttachmentToWorkItem',
    async (attachment, { dispatch }) => {
        const presignedUrl = await dispatch(
            coreActionsAsync.requestAttachmentUploadLink(attachment),
        );

        if (presignedUrl?.payload.data) {
            var options = {
                headers: {
                    'Content-Type': attachment.mimeType,
                },
            };

            await axios.put(
                presignedUrl?.payload.data,
                attachment.attachment,
                options,
            );

            try {
                return await RestAPI.post(
                    `projects/work-items/${attachment.businessEntityId}/attachments`,
                    {
                        body: attachment,
                    },
                );
            } catch (error) {
                return false;
            }
        }
    },
);

const getWorkItemAttachments = createAsyncThunk(
    'projects/getWorkItemAttachments',
    async (workItemId) => {
        return await RestAPI.get(
            `projects/work-items/${workItemId}/attachments`,
        );
    },
);

//Returns a single attachment
const getWorkItemAttachment = createAsyncThunk(
    'projects/getWorkItemAttachment',
    async (request) => {
        const response = await RestAPI.get(
            `projects/work-items/${request.workItemId}/attachments/${request.attachmentId}`,
        );

        return response;
    },
);

const getWorkItemHistory = createAsyncThunk(
    'projects/getWorkItemHistory',
    async ({ workItem }) => {
        const response = await RestAPI.get(
            `projects/work-items/${workItem?.id}/history`,
        );
        return {
            projectId: workItem.projectId,
            workItemId: workItem.id,
            history: response.data,
        };
    },
);

const downloadWorkItemAttachment = createAsyncThunk(
    'projects/downloadWorkItemAttachment',
    async (attachment, { dispatch }) => {
        const presignedUrl = await dispatch(
            coreActionsAsync.requestAttachmentDownloadLink(attachment),
        );
        if (presignedUrl.payload.data) {
            await axios.get(presignedUrl.payload.data);
            await FileSaver.saveAs(
                presignedUrl.payload.data,
                attachment.filename,
            );

            return attachment.id;
        }
    },
);

const deleteWorkItemAttachment = createAsyncThunk(
    'projects/deleteWorkItemAttachment',
    async (attachment, { dispatch }) => {
        const { businessEntityId, filename, id } = attachment;

        record('deleteWorkItemAttachment', {
            id: id,
            name: filename,
            businessEntityId: businessEntityId,
        });

        await RestAPI.del(
            `projects/work-items/${businessEntityId}/attachments/${id}`,
        ).then((res) => {
            if (res.status === 200)
                dispatch(getWorkItemAttachments(businessEntityId));
        });

        return { ...attachment };
    },
);

const addWorkItemToProject = createAsyncThunk(
    'projects/addWorkItemToProject',
    async (workItem, { rejectWithValue }) => {
        record('addWorkItemToProject', {
            id: workItem.id,
            name: workItem.name,
        });
        try {
            await RestAPI.post(`/projects/${workItem.projectId}/work-items`, {
                body: workItem,
            });
        } catch (error) {
            return rejectWithValue({
                error: error.response?.data?.errors,
            });
        }

        return { ...workItem };
    },
);

const changeWorkItemRank = createAsyncThunk(
    'projects/changeWorkItemRank',
    async ({ workItem, newRank }) => {
        record('changeWorkItemRank', {
            id: workItem.id,
            name: workItem.name,
            rank: newRank,
        });

        var response = await RestAPI.put(
            `/projects/${workItem?.projectId}/work-items/${workItem?.id}/change-rank`,
            { body: { rank: newRank } },
        );

        return {
            workItem: { ...workItem, rank: newRank },
            affectedWorkItems: response.data,
        };
    },
);

const deleteWorkItemFromEpic = createAsyncThunk(
    'projects/deleteWorkItemFromEpic',
    async (workItem) => {
        record('deleteWorkItemFromEpic', {
            id: workItem.id,
            name: workItem.name,
            projectEpic: workItem.projectEpic,
        });

        await RestAPI.del();

        return { ...workItem };
    },
);

const deleteWorkItemFromProject = createAsyncThunk(
    'projects/deleteWorkItemFromProject',
    async (workItem) => {
        record('deleteWorkItemFromProject', {
            id: workItem.id,
            name: workItem.name,
            projectId: workItem.projectId,
        });

        await RestAPI.del(
            `/projects/${workItem.projectId}/work-items/${workItem.id}`,
        );

        return { ...workItem };
    },
);

const getWorkItemDetail = createAsyncThunk(
    'projects/getWorkItemDetail',
    async ({ workItemId, projectId }, { rejectWithValue }) => {
        try {
            const response = await RestAPI.get(
                `/projects/${projectId}/work-items/${workItemId}`,
            );
            return { ...response };
        } catch (error) {
            return rejectWithValue({
                projectId,
                workItemId,
            });
        }
    },
);

const updateEpicWorkItem = createAsyncThunk(
    'projects/updateEpicWorkItem',
    async (workItem) => {
        record('updateEpicWorkItem', {
            id: workItem.id,
            name: workItem.name,
            projectEpicId: workItem.projectEpicId,
        });

        await RestAPI.put();

        return { ...workItem };
    },
);

const updateProjectWorkItem = createAsyncThunk(
    'projects/updateProjectWorkItem',
    async (workItem, { dispatch }) => {
        record('updateProjectWorkItem', {
            id: workItem.id,
            name: workItem.name,
            projectId: workItem.projectId,
        });

        await RestAPI.put(
            `/projects/${workItem.projectId}/work-items/${workItem.id}`,
            { body: workItem },
        );

        return structuredClone(workItem);
    },
);

const updateWorkItemStatus = createAsyncThunk(
    'projects/updateWorkItemStatus',
    async (request) => {
        record('updateProjectWorkItemStatus', {
            id: request.updatedWorkItem.id,
            name: request.updatedWorkItem.name,
            projectId: request.updatedWorkItem.projectId,
        });

        const payload = { status: request.updatedWorkItem.status };

        await RestAPI.put(
            `/projects/${request.updatedWorkItem.projectId}/work-items/${request.updatedWorkItem.id}/status`,
            { body: payload },
        );

        return structuredClone(request.updatedWorkItem);
    },
);

const updateProjectWorkItemDescription = createAsyncThunk(
    'projects/updateProjectWorkItemDescription',
    async (workItem) => {
        record('updateProjectWorkItemDescription', {
            id: workItem.id,
            name: workItem.name,
            projectId: workItem.projectId,
        });

        const payload = { description: workItem?.description };
        await RestAPI.put(
            `/projects/${workItem.projectId}/work-items/${workItem.id}/description`,
            { body: payload },
        );

        return structuredClone(workItem);
    },
);

const addLabelToWorkItem = createAsyncThunk(
    'projects/addLabelToWorkItem',
    async ({ workItem, label }) => {
        const response = await RestAPI.post(
            `/projects/${workItem.projectId}/work-items/${workItem.id}/labels`,
            { body: { name: label } },
        );
        return { label, id: response.data };
    },
);

const removeLabelFromWorkItem = createAsyncThunk(
    'projects/removeLabelFromWorkItem',
    async ({ workItem, label }) => {
        const response = await RestAPI.del(
            `/projects/${workItem.projectId}/work-items/${workItem.id}/labels/${label.id}`,
        );
        return { response };
    },
);

const addCommentToWorkItem = createAsyncThunk(
    'projects/addCommentToWorkitem',
    async ({ workItem, commentInput }) => {
        const response = await RestAPI.post(
            `/projects/${workItem.projectId}/work-items/${workItem.id}/comments`,
            { body: commentInput },
        );

        return { response };
    },
);

const importWorkItemsFromExcel = createAsyncThunk(
    'projects/importWorkItemsFromExcel',
    async ({ file, errorCallback, loadingCallback }) => {
        record('importWorkItemsFromExcel', { file: file.name });

        const formData = new FormData();
        formData.append('files', file);

        return await RestAPI.postFormData(
            '/projects/work-items-import',
            { body: formData },
            errorCallback,
            loadingCallback,
        );
    },
);

const getWorkItemsImportTemplate = createAsyncThunk(
    'projects/getWorkItemsImportTemplate',
    async (thunkAPI) => {
        try {
            const response = await RestAPI.getFile(
                '/projects/bulk-work-items-import-template',
            );

            saveAs(
                new Blob([response.data]),
                'fairview-work-items-template.xlsx',
            );
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

const selectWorkItem = createAsyncThunk(
    'projects/selectWorkItem',
    async (id, { dispatch }) => {
        try {
            await RestAPI.put(`/me/record-work-item/${id}`);
        } catch {
            // Absorb error
        }

        dispatch(getWorkItemAttachments(id));

        return id;
    },
);

const bulkDeleteWorkItem = createAsyncThunk(
    'projects/bulkDeleteWorkItem',
    async (payload, { dispatch }) => {
        record('bulkDeleteWorkItem', {
            workItemIds: payload.workItemIds,
            projectId: payload.projectId,
        });

        const bulkDeleteDTO = {
            workItemIds: payload.workItemIds,
            epicId: payload.projectEpicId,
        };

        return await RestAPI.post(
            `/projects/${payload.projectId}/work-items/bulk-delete`,
            { body: bulkDeleteDTO },
        );
    },
);

const bulkMoveWorkItem = createAsyncThunk(
    'projects/bulkMoveWorkItem',
    async (payload, { dispatch }) => {
        record('bulkMoveWorkItem', {
            workItemIds: payload.workItemIds,
            projectId: payload.projectId,
            targetProjectId: payload.targetProjectId,
        });

        const bulkMoveDTO = {
            workItemIds: payload.workItemIds,
            targetProjectId: payload.targetProjectId,
        };

        return await RestAPI.put(
            `/projects/${payload.projectId}/work-items/bulk-change-project`,
            { body: bulkMoveDTO },
        );
    },
);

const bulkUpdateWorkItemEpic = createAsyncThunk(
    'projects/bulkUpdateWorkItemEpic',
    async (payload, { dispatch }) => {
        record('bulkUpdateWorkItemEpic', {
            workItemIds: payload.workItemIds,
            projectId: payload.projectId,
            projectEpicId: payload.projectEpicId,
            projectEpicName: payload.projectEpicName,
        });

        const bulkUpdateDTO = {
            workItemIds: payload.workItemIds,
            epicId: payload.projectEpicId,
        };

        return await RestAPI.put(
            `/projects/${payload.projectId}/work-items/bulk-update-epic`,
            { body: bulkUpdateDTO },
        );
    },
);

const bulkUpdateWorkItemPhase = createAsyncThunk(
    'projects/bulkUpdateWorkItemPhase',
    async (payload) => {
        record('bulkUpdateWorkItemPhase', {
            workItemIds: payload.workItemIds,
            projectId: payload.projectId,
            projectPhaseId: payload.projectPhaseId,
            projectPhaseName: payload.projectPhaseName,
        });

        const bulkUpdateDTO = {
            workItemIds: payload.workItemIds,
            projectPhaseId: payload.projectPhaseId,
        };

        return await RestAPI.put(
            `projects/${payload.projectId}/work-items/bulk-update-phase`,
            { body: bulkUpdateDTO },
        );
    },
);

const bulkUpdateWorkItemStatus = createAsyncThunk(
    'projects/bulkUpdateWorkItemStatus',
    async (payload, { dispatch }) => {
        record('bulkUpdateWorkItemStatus', {
            projectId: payload.projectId,
            workItemIds: payload.workItemIds,
            status: payload.statusId,
        });

        const bulkUpdateDTO = {
            workItemIds: payload.workItemIds,
            status: payload.statusId,
        };

        return await RestAPI.put(
            `/projects/${payload.projectId}/work-items/bulk-update-status`,
            { body: bulkUpdateDTO },
        );
    },
);

export const workItemActionsAsync = {
    addWorkItemToProject,
    addLabelToWorkItem,
    addAttachmentToWorkItem,
    addAttachmentToNewWorkItem,
    bulkDeleteWorkItem,
    bulkMoveWorkItem,
    bulkUpdateWorkItemEpic,
    bulkUpdateWorkItemPhase,
    bulkUpdateWorkItemStatus,
    changeWorkItemRank,
    deleteWorkItemAttachment,
    deleteWorkItemFromEpic,
    downloadWorkItemAttachment,
    deleteWorkItemFromProject,
    getWorkItemDetail,
    getWorkItemAttachments,
    getWorkItemAttachment,
    getWorkItemHistory,
    updateEpicWorkItem,
    updateProjectWorkItem,
    updateWorkItemStatus,
    updateProjectWorkItemDescription,
    removeLabelFromWorkItem,
    addCommentToWorkItem,
    importWorkItemsFromExcel,
    getWorkItemsImportTemplate,
    selectWorkItem,
};
