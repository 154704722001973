import Basic from './Basic';

export default class Team extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.name = obj.name || '';
        this.skilledResources = obj.skilledResources || [];
    }
}
