export const DEFAULT_BAR_COLOR = '#FFDD99';

export default class ColorUtility {
    // get approved array of warm colors
    static getFullSpectrum() {
        const colors = [
            '#FF5a00',
            '#FF6400',
            '#FF6e00',
            '#FF7800',
            '#FF8200',
            '#FF8c00',
            '#FF9600',
            '#FFa000',
            '#FFaa00',
            '#FFb400',
            '#FFbe00',
            '#FFc800',
            '#FFd200',
            '#FFdc00',
            '#FFe600',
            '#FFf000',
            '#b0ff00',
            '#8aff00',
            '#65ff00',
            '#3eff00',
            '#17ff00',
            '#00ff10',
            '#00ff36',
            '#00ff5c',
            '#00ff83',
            '#00ffa8',
            '#00ffd0',
            '#00fff4',
            '#00e4ff',
            '#00d4ff',
            '#00c4ff',
            '#00b4ff',
            '#00a4ff',
            '#0094ff',
            '#0084ff',
            '#0074ff',
            '#0064ff',
            '#0054ff',
            '#0044ff',
            '#0032ff',
            '#0022ff',
            '#0012ff',
            '#0002ff',
            '#0000ff',
            '#0100ff',
            '#0200ff',
            '#0300ff',
            '#0400ff',
            '#0500ff',
        ];

        return colors;
    }

    static getShortSpectrum() {
        const colors = [
            '#FF5A00',
            '#FFAB00',
            '#FFDC00',
            '#8AFF00',
            '#43A047',
            '#00FFF4',
            '#0064FF',
            '#8E24AA',
            '#B0BEC5',
        ];

        return colors;
    }

    static getPairedShortSpectrum() {
        const colors = [
            '#FFBD99',
            '#FFDD99',
            '#FFF199',
            '#D0FF99',
            '#AFDEB1',
            '#99FFFB',
            '#99C1FF',
            '#D99BEA',
            '#DFE5E8',
        ];

        return colors;
    }

    static getMatchedPair(assignedColor) {
        assignedColor.toUpperCase();
        const assignedColors = this.getShortSpectrum();
        const pairedColors = this.getPairedShortSpectrum();
        const idx = assignedColors.indexOf(assignedColor);
        if (idx === -1) {
            if (assignedColor)
                // console.log(`Error: Could not find color ${assignedColor}`);
                return pairedColors[1]; // alter once unmatched pair bug is solved
            // throw Error('You must pass a color from the short (assigned) color spectrum');
        }

        return pairedColors[idx];
    }

    static getWarmSpectrum() {
        return this.getFullSpectrum().slice(0, 18);
    }

    static getCoolSpectrum() {
        return this.getFullSpectrum().reverse().slice(0, 18);
    }

    static getSkippingSpectrum(spacesToSkip) {
        let array = this.getFullSpectrum();
        let newArray = [];
        for (let i = 0; i < this.getFullSpectrum().length; i++) {
            if ((i + 1) % spacesToSkip === 0) {
                newArray.push(array[i]);
            }
        }
        return newArray;
    }

    static getRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    static getRandomColorPaired() {
        const colors = this.getPairedShortSpectrum();
        return colors[Math.floor(Math.random() * colors.length)];
    }

    // Project : primary, Epic: secondary, resource : tertiary
    static getGanttColors() {
        return [
            { project: '#DB5776', epic: '#BD284C', resource: '#E996AA' },
            { project: '#FF7B33', epic: '#E55100', resource: '#FFAD80' },
            { project: '#FFC44C', epic: '#E59A00', resource: '#FFDD99' },
            { project: '#FFE333', epic: '#E5C600', resource: '#FFEE80' },
            { project: '#6FC373', epic: '#43A047', resource: '#A5D9A7' },
            { project: '#3383FF', epic: '#005AE5', resource: '#80B1FF' },
            { project: '#C057DB', epic: '#9E28BD', resource: '#D796E9' },
        ];
    }

    static getProgressOpacityColor(color) {
        switch (color) {
            case '#DB5776':
                return '#db577680';
            case '#FF7B33':
                return '#ff7b3380';
            case '#FFC44C':
                return '#ffc44c80';
            case '#FFE333':
                return '#ffe33380';
            case '#6FC373':
                return '#6fc37380';
            case '#3383FF':
                return '#3383ff80';
            case '#C057DB':
                return '#c057db80';
            default:
                return '#ffdd9980';
        }
    }
}

