import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, TextField, FormControlLabel, Checkbox } from '@mui/material';
import Project from 'Models/Project';
import BusinessValuePicker from 'System/UI/Pickers/BusinessValuePicker/BusinessValuePicker';
import LevelOfEffortPicker from 'System/UI/Pickers/LevelOfEffortPicker/LevelOfEffortPicker';
import StatusPicker from 'System/UI/Pickers/StatusPicker/StatusPicker';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import Form from 'System/Forms/Components/Form';
import InfoTooltip from 'System/UI/Components/InfoTooltip/InfoTooltip';

export default function NewProjectForm({ onSubmit }) {
    const newProjectSchema = yup.object().shape({
        name: yup.string().required('Required'),
        estimatedStartDate: yup.date().typeError('Invalid Date').nullable(),
        estimatedCompletionDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .min(
                yup.ref('estimatedStartDate'),
                'Must be after Est. Start Date',
            ),
        projectKey: yup.string().required('Required').max(10),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: new Project({ isPrivate: true }),
        validationSchema: newProjectSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    const handleProjectNameChange = (x) => {
        setFieldValue(
            'projectKey',
            new Project({ name: x.target.value }).abbreviatedName,
            true,
        );
        handleChange(x);
    };

    return (
        <Form onSubmit={handleSubmit} formId="new-project-form">
            <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                    <TextField
                        name="name"
                        label="Name"
                        error={showError('name')}
                        helperText={getError('name')}
                        value={values.name}
                        onChange={handleProjectNameChange}
                        onBlur={handleBlur}
                        fullWidth
                        autoFocus
                        required
                    />

                    <TextField
                        name="projectKey"
                        label="Project Abbreviation"
                        error={showError('projectKey')}
                        helperText={getError('projectKey')}
                        value={values.projectKey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        required
                    />
                </Stack>

                <Stack direction="row" spacing={1}>
                    <BusinessValuePicker
                        name="estimatedBusinessValue"
                        selectedBusinessValue={values.estimatedBusinessValue}
                        fullWidth
                        onChange={handleChange}
                    />

                    <LevelOfEffortPicker
                        name="estimatedLevelOfEffort"
                        selectedLevelOfEffort={values.estimatedLevelOfEffort}
                        fullWidth
                        onChange={handleChange}
                    />

                    <StatusPicker
                        fullWidth
                        label="Status"
                        selectedStatus={values.status}
                        onChange={handleChange}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <DatePicker
                        name="estimatedStartDate"
                        format="MM/DD/YYYY"
                        label="Est. Start"
                        error={showError('estimatedStartDate')}
                        helperText={getError('estimatedStartDate')}
                        value={values.estimatedStartDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />

                    <DatePicker
                        name="estimatedCompletionDate"
                        format="MM/DD/YYYY"
                        label="Est. Completion"
                        error={showError('estimatedCompletionDate')}
                        helperText={getError('estimatedCompletionDate')}
                        value={values.estimatedCompletionDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isPrivate"
                                checked={values.isPrivate}
                                onChange={handleChange}
                            ></Checkbox>
                        }
                        label="Make Private"
                        sx={{ width: '100%' }}
                    />
                    <InfoTooltip
                        toolTipText={
                            'A private project will only be visible to you. You can toggle this in the project actions'
                        }
                    />
                </Stack>
            </Stack>
        </Form>
    );
}
