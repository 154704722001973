import { TextField } from '@mui/material';
import Calculators from 'Utilities/calculators';

export default function ResoruceTotalUnavailableDays({ startDate, endDate }) {
    const totalUnavailableDays = () => {
        return Calculators.durationInWorkDays(startDate, endDate);
    };

    return (
        <TextField
            label="Total Days"
            fullWidth
            sx={{
                '& .MuiInputBase-root': {
                    '& > fieldset': {
                        borderColor: 'rgb(0,0,0,0) !important',
                    },
                },
            }}
            value={totalUnavailableDays()}
            InputProps={{
                readOnly: true,
            }}
        />
    );
}
