import WorkItem from './WorkItem';
import Basic from './Basic';
import Calc from 'Utilities/calculators';

export default class Epic extends Basic {
    epicDoneStatus = 3;
    workItemDoneStatus = WorkItem.doneStatusId;

    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.name = obj.name || '';

        // the description of the work
        this.description = obj.description || '';

        // the objective of the work (OKR model)
        this.objective = obj.objective || '';

        // an optional point estimate of the work
        this.pointsEstimate = obj.pointsEstimate || 0;

        // an optional point estimate of completed work on this item
        this.pointsComplete = obj.pointsComplete || 0;

        // an optional hour estimate of the work
        this.hoursEstimate = obj.hoursEstimate || 0;

        // an optional tracking of hours complete (work may be estimated at
        // one number, but tracked hours could exceed estimate or end early)
        this.hoursComplete = obj.hoursComplete || 0;

        this.status = Number.isInteger(obj.status) ? obj.status : 0;

        this.statusName = obj.statusName || '';

        // overrides the default background color for this work item
        this.color = obj.color || null;

        this.projectId = obj.projectId;

        // support integrations (JIRA)
        this.integrationId = obj.integrationId;
        this.integrationKey = obj.integrationKey;

        // the estimated start date (this is the date a team or person _thinks_ they will start)
        this.estimatedStartDate = obj.estimatedStartDate
            ? new Date(obj.estimatedStartDate)
            : null;

        // the estimated completion date (this is the date a team or person _thinks_ they will complete)
        this.estimatedCompletionDate = obj.estimatedCompletionDate
            ? new Date(obj.estimatedCompletionDate)
            : null;

        // the date the work actually starts
        this.actualStartDate = obj.actualStartDate
            ? new Date(obj.actualStartDate)
            : null;

        // the date the work was actually completed
        this.actualCompletionDate = obj.actualCompletionDate
            ? new Date(obj.actualCompletionDate)
            : null;

        // an optional collection of bugs associated with this work
        this.workItems = obj.workItems?.length
            ? obj.workItems.map((a) => new WorkItem(a))
            : [];
    }

    // the hours remaining against the estimate to complete this work
    get hoursRemaining() {
        return this.hoursEstimate - this.hoursComplete;
    }

    // duration in calendar days
    get estimatedDurationInCalendarDays() {
        if (this.estimatedStartDate && this.estimatedCompletionDate) {
            return Calc.durationInCalendarDays(
                this.estimatedStartDate,
                this.estimatedCompletionDate,
            );
        } else {
            return 0;
        }
    }

    // duration in work days (M-F)
    get estimatedDurationInWorkDays() {
        if (this.estimatedStartDate && this.estimatedCompletionDate) {
            return Calc.durationInWorkDays(
                this.estimatedStartDate,
                this.estimatedCompletionDate,
            );
        } else {
            return 0;
        }
    }

    // actual duration in work days (M-F)
    get actualDurationInWorkDays() {
        if (this.actualStartDate && this.actualCompletionDate) {
            return Calc.durationInWorkDays(
                this.actualStartDate,
                this.actualCompletionDate,
            );
        } else {
            return 0;
        }
    }

    // duration in calendar days
    get actualDurationInCalendarDays() {
        if (this.actualStartDate && this.actualCompletionDate) {
            return Calc.durationInCalendarDays(
                this.actualStartDate,
                this.actualCompletionDate,
            );
        } else {
            return 0;
        }
    }

    // the percentage complete of the work based on points
    get percentageCompleteByPoints() {
        if (this.totalPointsComplete && this.totalPoints) {
            return (
                ((this.totalPointsComplete / this.totalPoints) * 100).toFixed(
                    2,
                ) * 1
            );
        } else {
            return 0;
        }
    }

    get percentageCompleteByHours() {
        if (this.totalHoursComplete && this.totalHoursEstimate) {
            return (
                (
                    (this.totalHoursComplete / this.totalHoursEstimate) *
                    100
                ).toFixed(2) * 1
            );
        } else {
            return 0;
        }
    }

    // the total hours estimated derived from this work
    get totalHoursEstimate() {
        let total = this.hoursEstimate;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total += workItem.hoursEstimate;
            });
        }

        return total;
    }

    // the total hours complete derived from this work
    get totalHoursComplete() {
        let total = this.hoursComplete;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total += workItem.hoursComplete;
            });
        }

        return total;
    }

    // the total hours remaining derived from this work
    get totalHoursRemaining() {
        let total = this.hoursRemaining; // TODO: examine if this needs to include epic hoursRemaining or only for workItems

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total += workItem.hoursRemaining;
            });
        }

        return total;
    }

    // the total points estimated derived from this work
    get totalPoints() {
        let total = this.pointsEstimate || 0;

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total += workItem.pointsEstimate;
            });
        }

        return total;
    }

    // the total points complete derived from this work
    get totalPointsComplete() {
        let total = 0;

        if (this.status === this.epicDoneStatus) {
            total += this.pointsEstimate;
        }

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total +=
                    workItem.status === this.workItemDoneStatus
                        ? workItem.pointsEstimate
                        : 0;
            });
        }

        return total;
    }

    // the total points remaining derived from this work
    get totalPointsRemaining() {
        let total = 0;
        if (this.status !== this.epicDoneStatus) {
            total += this.pointsEstimate;
        }

        if (this.workItems?.length) {
            this.workItems.forEach((workItem) => {
                total +=
                    workItem.status !== this.workItemDoneStatus
                        ? workItem.pointsEstimate
                        : 0;
            });
        }

        return total;
    }

    // How many points needs to be completed per work day to stay on schedule
    get requiredVelocityPerDay() {
        return this.totalPoints / this.estimatedDurationInCalendarDays;
    }

    get progress() {
        return this.totalPoints > 0
            ? (this.totalPointsComplete / this.totalPoints) * 100
            : 0;
    }
}
