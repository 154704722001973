import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    Checkbox,
    Chip,
    Typography,
    Stack,
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import {
    phaseActions,
    projectActions,
    workItemActions,
} from 'System/State/ProjectState/reducer';
import { modalActions } from 'System/State/ModalState/reducer';
import { paneActions } from '../../State/PaneState/reducer';
import { epicActions } from 'System/State/ProjectState/reducer';
import { useNavigate } from 'react-router-dom';
import WorkItemForm from 'System/Forms/WorkItemForm/WorkItemForm';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import InfoIcon from '@mui/icons-material/Info';
import { v4 as uuidv4 } from 'uuid';
import { workItemActionsAsync } from 'System/State/ProjectState/work-item-actions';

export default function NewWorkItemModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const newWorkItemModalRef = useRef();
    const [newWorkItemAttachments, setNewWorkItemAttachments] = useState([]);
    const [project, setProject] = useState(null);

    const isModalOpen = useSelector(modalSelectors.newWorkItemModal);
    const selectedProject = useSelector(projectSelectors.selectedProject);
    const selectedPhase = useSelector(projectSelectors.selectedPhase);
    const showProjectSelector = useSelector(
        modalSelectors.showProjectSelectorForNewWorkItem,
    );

    useEffect(() => {
        if (selectedProject) {
            setProject(selectedProject);
        }
    }, [selectedProject]);

    const [isHovered, setIsHovered] = useState(false);
    const [, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: (item) => {
            if (item.files[0]) {
                handleCaptureDragAndDrop(item.files[0]);
            }
        },
        collect: (monitor) => {
            monitor.isOver({ shallow: true })
                ? setIsHovered(true)
                : setIsHovered(false);
        },
    });

    const generateBinaryFileAsync = async (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.readAsDataURL(file);
        });
    };

    const handleCaptureDragAndDrop = async (file) => {
        const newFile = await generateBinaryFileAsync(file);

        let binary = atob(newFile.split(',')[1]);

        let array = [];

        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }

        let blobData = new Blob([new Uint8Array(array)], {
            type: file.type,
        });

        const attachment = {
            attachmentId: uuidv4(),
            businessEntity: 'workItem',
            businessEntityId: newWorkItemModalRef.current.handleGetWorkItemId(),
            filename: file?.name,
            mimeType: file?.type,
            attachment: blobData,
        };

        await handleUpload(attachment);
    };

    const handleUpload = async (attachment) => {
        const uploadRequest = await dispatch(
            workItemActionsAsync.addAttachmentToNewWorkItem(attachment),
        );
        const uploadResponse = uploadRequest.payload.data;
        //bool if the attachment was uploaded properly or not
        if (uploadResponse !== false) {
            let requestBody = {
                workItemId: attachment.businessEntityId,
                attachmentId: uploadResponse,
            };
            const getRequest = await dispatch(
                workItemActionsAsync.getWorkItemAttachment(requestBody),
            );

            if (getRequest.payload.data) {
                setNewWorkItemAttachments([
                    ...newWorkItemAttachments,
                    getRequest.payload.data,
                ]);
            }
        }
    };

    const handleRemoveAttachment = (attachment) => {
        dispatch(workItemActions.deleteWorkItemAttachment(attachment));

        setNewWorkItemAttachments(
            newWorkItemAttachments.filter(
                (x) => x.attachmentId !== attachment.attachmentId,
            ),
        );
    };

    const [addAnotherWorkItem, setAddAnotherWorkItem] = useState(false);

    const toggleAddAnother = () => setAddAnotherWorkItem(!addAnotherWorkItem);

    const deselectItems = () => {
        dispatch(epicActions.unselectEpic());
        setAddAnotherWorkItem(false);
        setNewWorkItemAttachments([]);
        if (showProjectSelector) {
            dispatch(projectActions.selectProject(null));
        }
    };

    const handleClose = () => {
        if (selectedPhase) {
            dispatch(phaseActions.unselectProjectPhase());
        }
        deselectItems();
        dispatch(modalActions.close('newWorkItemModal'));
        dispatch(modalActions.setProjectSelectorForWorkItemsVisibility(false));
    };

    const handleSubmit = (workItem) => {
        dispatch(workItemActions.addWorkItemToProject(workItem))
            .then((response) => {
                if (!response.error) {
                    if (addAnotherWorkItem) {
                        newWorkItemModalRef.current.handleClearForm();
                        deselectItems();
                    } else {
                        navigate(
                            `/projects/${workItem.projectId}/work-items/${workItem.id}`,
                        );
                        handleClose();
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            ref={drop}
            scroll="paper"
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="New Work Item"
            aria-describedby="Add a new work item"
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Chip
                        icon={<InfoIcon />}
                        label="Drop Files to attach them to this work item"
                        sx={{
                            transition: 'all 0.5s',
                            display: isHovered ? 'inline-flex' : 'none',
                        }}
                    />

                    <Typography variant="h3"> Add New Work Item</Typography>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <WorkItemForm
                    ref={newWorkItemModalRef}
                    onSubmit={handleSubmit}
                    trackProjectsBy={selectedProject?.trackProjectsBy}
                    attachments={newWorkItemAttachments}
                    handleRemoveAttachment={handleRemoveAttachment}
                    showProjectSelector={showProjectSelector}
                />
            </DialogContent>

            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={addAnotherWorkItem}
                            onChange={toggleAddAnother}
                            name="addAnotherWorkItem"
                        />
                    }
                    label="Add Another Work Item"
                />
                <div>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        form="work-item-form"
                        disabled={!project && !selectedProject}
                    >
                        Save Work Item
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
