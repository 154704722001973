import { DatePicker } from '@mui/x-date-pickers';
import { FormControl, TextField } from '@mui/material';

export default function FairviewDatePicker(props) {
    const {
        name,
        value,
        label,
        disabled,
        format,
        fullWidth,
        error,
        helperText,
        required,
        onChange,
    } = props;

    const handleChange = (newDate) =>
        onChange({
            target: {
                name: name,
                value: newDate ? newDate.toDate() : null,
            },
        });

    return (
        <FormControl fullWidth>
            <DatePicker
                value={value}
                name={name}
                label={required ? `${label} *` : label}
                disabled={disabled}
                format={format}
                required={required}
                onChange={handleChange}
                fullWidth={fullWidth}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        helperText={helperText}
                    />
                )}
            ></DatePicker>
        </FormControl>
    );
}
