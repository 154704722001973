import * as yup from 'yup';
import { useFormik } from 'formik';
import Project from '../../../Models/Project';
import Form from '../Components/Form';
import {
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import BusinessValuePicker from '../../UI/Pickers/BusinessValuePicker/BusinessValuePicker';
import LevelOfEffortPicker from '../../UI/Pickers/LevelOfEffortPicker/LevelOfEffortPicker';
import StatusPicker from '../../UI/Pickers/StatusPicker/StatusPicker';
import DatePicker from '../../UI/Components/DatePicker/DatePicker';
import InfoTooltip from 'System/UI/Components/InfoTooltip/InfoTooltip';

const copyableItems = [
    { name: 'copyProjectSummary', label: 'Project Summary', selected: true },
    { name: 'copyKeyResults', label: 'Success Criteria', selected: true },
    { name: 'copyResources', label: 'Resources' },
    { name: 'copyWorkItems', label: 'Work Items' },
    { name: 'copyCharacteristics', label: 'Characteristics', selected: true },
    { name: 'copyLinesOfBusiness', label: 'Department' },
    { name: 'copyEpics', label: 'Epics' },
    { name: 'copyMilestones', label: 'Milestones' },
    { name: 'copyAdditionalCosts', label: 'Additional Costs' },
    { name: 'copyAttachments', label: 'Attachments' },
    { name: 'copyLabels', label: 'Labels' },
    { name: 'copyPhases', label: 'Phases' },
];

export default function CopyProjectForm({ onSubmit, originalProjectName }) {
    const copyProjectSchema = yup.object().shape({
        name: yup.string().required('Required'),
        estimatedStartDate: yup.date().typeError('Invalid Date').nullable(),
        estimatedCompletionDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .min(
                yup.ref('estimatedStartDate'),
                'Must be after Est. Start Date',
            ),
        projectKey: yup.string().required('Required').max(10),
    });

    const defaultProjectName = `Copy of ${originalProjectName}`;

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: {
            name: defaultProjectName,
            projectKey: new Project({ name: defaultProjectName })
                .abbreviatedName,
            estimatedBusinessValue: 2,
            estimatedLevelOfEffort: 3,
            status: 0,
            estimatedStartDate: null,
            estimatedCompletionDate: null,
            isPrivate: true,
            ...copyableItems.reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur.name]: !!cur.selected,
                }),
                {},
            ),
        },
        validationSchema: copyProjectSchema,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    const handleProjectNameChange = (x) => {
        setFieldValue(
            'projectKey',
            new Project({ name: x.target.value }).abbreviatedName,
            true,
        );
        handleChange(x);
    };

    return (
        <Form onSubmit={handleSubmit} formId="copy-project-form">
            <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                    <TextField
                        name="name"
                        label="Name"
                        error={showError('name')}
                        helperText={getError('name')}
                        value={values.name}
                        onChange={handleProjectNameChange}
                        onBlur={handleBlur}
                        autoFocus
                        required
                        sx={{ flexGrow: 1 }}
                    />
                    <TextField
                        name="projectKey"
                        label="Project Abbreviation"
                        error={showError('projectKey')}
                        helperText={getError('projectKey')}
                        value={values.projectKey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                    />
                </Stack>

                <Stack direction="row" spacing={1}>
                    <BusinessValuePicker
                        name="estimatedBusinessValue"
                        selectedBusinessValue={values.estimatedBusinessValue}
                        fullWidth
                        onChange={handleChange}
                    />

                    <LevelOfEffortPicker
                        name="estimatedLevelOfEffort"
                        selectedLevelOfEffort={values.estimatedLevelOfEffort}
                        fullWidth
                        onChange={handleChange}
                    />

                    <StatusPicker
                        fullWidth
                        label="Status"
                        selectedStatus={values.status}
                        onChange={handleChange}
                    />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <DatePicker
                        name="estimatedStartDate"
                        format="MM/DD/YYYY"
                        label="Est. Start"
                        error={showError('estimatedStartDate')}
                        helperText={getError('estimatedStartDate')}
                        value={values.estimatedStartDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />

                    <DatePicker
                        name="estimatedCompletionDate"
                        format="MM/DD/YYYY"
                        label="Est. Completion"
                        error={showError('estimatedCompletionDate')}
                        helperText={getError('estimatedCompletionDate')}
                        value={values.estimatedCompletionDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isPrivate"
                                checked={values.isPrivate}
                                onChange={handleChange}
                            ></Checkbox>
                        }
                        label="Make Private"
                        sx={{ width: '100%' }}
                    />
                    <InfoTooltip
                        toolTipText={
                            'A private project will only be visible to you. You can toggle this in the project actions'
                        }
                    />
                </Stack>

                <Typography sx={{ marginTop: '20px' }} variant="h5">
                    Select Items You Want to Copy:
                </Typography>

                <Stack sx={{ maxHeight: '200px' }} flexWrap="wrap">
                    {copyableItems.map(({ name, label }) => (
                        <FormControlLabel
                            key={name}
                            control={
                                <Checkbox
                                    name={name}
                                    checked={values[name]}
                                    onChange={handleChange}
                                ></Checkbox>
                            }
                            label={
                                <Typography variant="body1">{label}</Typography>
                            }
                        />
                    ))}
                </Stack>
            </Stack>
        </Form>
    );
}
