import Basic from './Basic';

export default class Phase extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.name = obj.name || '';

        this.startDate = obj.startDate ? new Date(obj.startDate) : null;

        this.endDate = obj.endDate ? new Date(obj.endDate) : null;

        this.projectId = obj.projectId || null;

        this.rank = obj.rank || 0;
    }
}
