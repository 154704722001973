import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { useDispatch } from 'react-redux';
import { resourceActions } from 'System/State/ResourceState/reducer';
import ResourceForm from 'System/Forms/ResourceForm/ResourceForm';
import { teamActions } from 'System/State/TeamState/reducer';
import { profileActions } from 'System/State/ProfileState/reducer';
import { userSelectors } from 'System/State/UserState/selectors';
import { paneActions } from 'System/State/PaneState/reducer';

export default function NewResourceModal() {
    const open = useSelector(modalSelectors.newResourceModal);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(modalActions.close('newResourceModal'));
        dispatch(teamActions.unselectTeam());
        dispatch(profileActions.unselectProfile());
    };

    const handleSubmit = (resource) => {
        dispatch(resourceActions.addResource(resource)).then(() =>
            dispatch(resourceActions.selectResource(resource.id)),
        );
        handleClose();
    };

    const handleImportClick = () => {
        dispatch(paneActions.openSettingsWithSelectedTab(5));
        handleClose();
    };

    const isAllowedAdmin = useSelector(userSelectors.isAllowedAdmin);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Add Resource"
            aria-describedby="Add a new resource"
        >
            <DialogTitle>Add Skilled Resource</DialogTitle>
            <DialogContent>
                <Typography
                    variant="body2"
                    sx={{
                        marginBottom: 4,
                        marginTop: 0,
                    }}
                >
                    Add either an existing user or a new skilled person to your
                    talent pool. You can assign them to projects later.
                </Typography>

                <ResourceForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
                {isAllowedAdmin && (
                    <Button
                        sx={{ color: 'red', border: 'none' }}
                        onClick={() => handleImportClick()}
                    >
                        Import Resources
                    </Button>
                )}
                <div>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        type="submit"
                        form="resource-form"
                    >
                        Save Skilled Resource
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
