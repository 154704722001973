import {
    addEpicUtility,
    addItemToList,
    addItemsToList,
    addToOrUpdateInList,
    getItemByItemId,
    getUpdatedList,
    removeItemFromListById,
    getProjectByEpicId,
    removeEpicUtility,
} from 'Utilities/reducer-utils';
import { createSlice } from '@reduxjs/toolkit';
import { projectActionsAsync } from './actions';
import { projectNonLaborExpenseActionsAsync } from 'System/State/ProjectState/non-labor-expense-actions';
import { epicActionsAsync } from './epic-actions';
import { workItemActionsAsync } from './work-item-actions';
import { resourceTimelineActionsAsync } from 'System/State/ProjectState/resource-timeline-actions';
import { coreActionsAsync } from 'System/State/CoreState/actions';
import { milestoneActionsAsync } from 'System/State/MilestoneState/actions';
import { phaseActionsAsync } from './phases-actions';

const { appStart, offline } = coreActionsAsync;
const { addMilestone, deleteMilestone } = milestoneActionsAsync;

const {
    addCommentToProject,
    addCharacteristicToProject,
    addLabelToProject,
    addLOBToProject,
    addProject,
    changeProjectRank,
    changeProjectStatus,
    deleteCommentFromProject,
    deleteProject,
    downloadProjectAttachment,
    getProjectCharacteristicDetail,
    getProjectCommentDetail,
    getProjectAttachments,
    getProjectDetail,
    linkProjectToJira,
    removeCharacteristicFromProject,
    removeLabelFromProject,
    removeLOBFromProject,
    updateProject,
    updateProjectEstimatedCompletionDate,
    updateProjectEstimatedStartDate,
    addProfileToProject,
    addSkilledResourceToProjectProfile,
    removeProjectProfile,
    getProjectProfileDetail,
} = projectActionsAsync;

const {
    addEpic,
    deleteEpic,
    getEpicDetail,
    moveEpicToExistingProject,
    updateEpic,
    updateEpicEstimatedCompletionDate,
    updateEpicEstimatedStartDate,
} = epicActionsAsync;

const {
    addNonLaborExpenseToProject,
    deleteNonLaborExpenseFromProject,
    getProjectNonLaborExpenseDetail,
    updateProjectNonLaborExpense,
} = projectNonLaborExpenseActionsAsync;

const {
    addWorkItemToProject,
    deleteWorkItemFromEpic,
    deleteWorkItemFromProject,
    getWorkItemDetail,
    updateEpicWorkItem,
    updateProjectWorkItem,
    updateWorkItemStatus,
    updateProjectWorkItemDescription,
    getWorkItemAttachments,
    getWorkItemHistory,
    downloadWorkItemAttachment,
    changeWorkItemRank,
    selectWorkItem,
} = workItemActionsAsync;

const {
    addResourceTimelineToProject,
    deleteResourceTimelineFromProject,
    getProjectResourceTimelineDetail,
    updateProjectResourceTimeline,
} = resourceTimelineActionsAsync;

const {
    getProjectPhaseDetail,
    disableProjectPhase,
    updateProjectPhase,
    changePhaseRank,
} = phaseActionsAsync;

const initialState = {
    filterOptions: {
        selectedColors: [],
        selectedElements: ['epics', 'today', 'progress', 'baseline'],
        selectedFinancials: 'plannedCost',
        selectedLabels: [],
        selectedLinesOfBusiness: [],
        selectedRisks: [],
        selectedResources: [],
        selectedProfiles: [],
        selectedTeams: [],
        selectedStatuses: [0, 1],
        selectedBudgets: [],
        selectedGroupBy: '',
        search: '',
    },
    list: [],
    selectedEpic: null,
    selectedEpicForWorkItemReporter: null,
    selectedMilestone: null,
    selectedNonLaborExpense: null,
    selectedProject: null,
    previouslySelectedProject: null,
    selectedProjectAttachments: [],
    selectedResourceTimeline: null,
    selectedWorkItem: null,
    selectedWorkItemAttachments: [],
    hasSelectedWorkItemDescriptionChanged: false,
    selectedProjectPhase: null,
    isMoveEpicModalOpen: false,
    newEpicModalIsForWorkItemReporter: false,
    resourceTimelinePaneIsVisible: false,
    summaryIsVisible: false,
    widthOfDayInPixels: 5,
    businessValueOptions: [],
    levelOfEffortOptions: [],
    riskOptions: [],
    statusOptions: [],
    workItemTypeOptions: [],
    workItemStatusOptions: [],
    workItemFilterOptions: {
        selectedTypes: [],
        selectedStatuses: [],
        selectedLabels: [],
        selectedAssignees: [],
        selectedPhases: [],
        selectedEpics: [],
        selectedProjects: [],
        search: '',
    },
    workItemsView: 'list',
    latestProjectId: null,
    isNewEpicForWorkItem: false,
};

export const ProjectSlice = createSlice({
    name: 'projects',
    initialState: initialState,
    reducers: {
        changeProjectListFilters(state, action) {
            state.filterOptions = action.payload;
        },
        changeWorkItemsView(state, action) {
            state.workItemsView = action.payload;
        },
        changeWorkItemFilters(state, action) {
            state.workItemFilterOptions = action.payload;
        },
        clearWorkItemFilters(state) {
            state.workItemFilterOptions = {
                selectedTypes: [],
                selectedStatuses: [],
                selectedLabels: [],
                selectedAssignees: [],
                selectedPhases: [],
                selectedEpics: [],
                selectedProjects: [],
                search: '',
            };
        },
        changeZoom(state, action) {
            state.widthOfDayInPixels = action.payload;
        },
        resetSlice() {
            return initialState;
        },
        toggleSummaryVisible(state) {
            state.summaryIsVisible = !state.summaryIsVisible;
        },
        selectEpicForWorkItemReporter(state, action) {
            state.selectedEpicForWorkItemReporter = action.payload?.epicId;
        },
        unselectEpicForWorkItemReporter(state) {
            state.selectedEpicForWorkItemReporter = null;
        },
        setNewEpicModalIsForWorkItemReporter(state, action) {
            state.newEpicModalIsForWorkItemReporter = action.payload;
        },
        selectEpic(state, action) {
            state.selectedEpic = action.payload?.epicId;
        },
        selectNonLaborExpense(state, action) {
            state.selectedNonLaborExpense = action.payload;
        },
        selectProject(state, action) {
            state.previouslySelectedProject = state.selectedProject;
            state.selectedProject = action.payload;
        },
        selectResourceTimeline(state, action) {
            state.selectedResourceTimeline = action.payload;
        },
        selectProjectPhase(state, action) {
            state.selectedProjectPhase = action.payload;
        },
        unselectEpic(state) {
            state.selectedEpic = null;
        },
        unselectNonLaborExpense(state) {
            state.selectedNonLaborExpense = null;
        },
        unselectProject(state) {
            state.selectedProject = null;
        },
        unselectWorkItem(state) {
            state.selectedWorkItem = null;
        },
        unselectProjectPhase(state) {
            state.selectedProjectPhase = null;
        },
        setLatestProjectId(state, action) {
            state.latestProjectId = action.payload;
        },
        setIsNewEpicForWorkItem(state, action) {
            state.isNewEpicForWorkItem = action.payload;
        },
        setHasSelectedWorkItemDescriptionChanged(state, action) {
            state.hasSelectedWorkItemDescriptionChanged = action.payload;
        },
    },
    extraReducers: {
        [addCommentToProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            state.list = getUpdatedList(state.list, {
                ...foundProject,
                comments: addItemToList(foundProject.comments, action.payload),
            });
        },
        [addEpic.fulfilled]: (state, action) => {
            addEpicUtility(state, action.payload);
            state.selectEpic = action.payload.id;
        },
        [addMilestone.fulfilled]: (state, action) => {
            if (action.payload.projectId) {
                const foundProject = getItemByItemId(
                    state.list,
                    action.payload.projectId,
                );

                foundProject.milestones = addItemToList(
                    foundProject.milestones,
                    action.payload,
                );

                state.list = getUpdatedList(state.list, foundProject);
            }
        },
        [addNonLaborExpenseToProject.fulfilled]: (state, action) => {
            const newCost = action.payload;
            const foundProject = getItemByItemId(state.list, newCost.projectId);

            const updatedProject = {
                ...foundProject,
                nonLaborExpenses: addItemToList(
                    foundProject.nonLaborExpenses,
                    newCost,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [addCharacteristicToProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                characteristics: [
                    ...foundProject.characteristics,
                    action.payload,
                ],
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [addLOBToProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                linesOfBusiness: [
                    ...foundProject.linesOfBusiness,
                    action.payload,
                ],
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [addLabelToProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                labels: [...foundProject.labels, action.payload],
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [addWorkItemToProject.fulfilled]: (state, action) => {
            const workItem = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                workItem.projectId,
            );

            const updatedProject = {
                ...foundProject,
                workItems: addItemToList(foundProject?.workItems, workItem),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [addProject.fulfilled]: (state, action) => {
            state.latestProjectId = action.payload?.id;
            state.list = [...state.list, action.payload];
        },
        [addResourceTimelineToProject.fulfilled]: (state, action) => {
            const newProjectResourceTimeline = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                newProjectResourceTimeline.projectId,
            );

            const updatedProject = {
                ...foundProject,
                resourceTimelines: addItemToList(
                    foundProject.resourceTimelines,
                    newProjectResourceTimeline,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [addProfileToProject.fulfilled]: (state, action) => {
            const { projectId, projectProfiles } = action.payload.id;

            const foundProject = getItemByItemId(
                state.list,
                projectId,
            );

            const updatedProject = {
                ...foundProject,
                projectProfiles: addItemsToList(foundProject.projectProfiles, ...projectProfiles),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [addSkilledResourceToProjectProfile.fulfilled]: (state, action) => {
            const { projectId, projectProfileId, skilledResourceTimeline } = action.payload.id;

            const foundProject = getItemByItemId(
                state.list,
                projectId,
            );

            foundProject.projectProfiles = removeItemFromListById(
                foundProject.projectProfiles,
                projectProfileId,
            );

            const updatedProject = {
                ...foundProject,
                resourceTimelines: addItemToList(
                    foundProject.resourceTimelines,
                    skilledResourceTimeline,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [appStart.fulfilled]: (state, action) => {
            const {
                projects,
                riskOptions,
                statusOptions,
                businessValues,
                levelOfEfforts,
                workItemTypes,
                workItemStatuses,
            } = action.payload.data;
            if (projects) state.list = projects;
            if (businessValues) state.businessValueOptions = businessValues;
            if (levelOfEfforts) state.levelOfEffortOptions = levelOfEfforts;
            if (riskOptions) state.riskOptions = riskOptions;
            if (statusOptions) state.statusOptions = statusOptions;
            if (workItemTypes) state.workItemTypeOptions = workItemTypes;
            if (workItemStatuses)
                state.workItemStatusOptions = workItemStatuses;
        },
        [offline.fulfilled]: (state, action) => {
            const {
                projects,
                riskOptions,
                statusOptions,
                businessValues,
                levelOfEfforts,
                workItemTypes,
                workItemStatuses,
            } = action.payload.data;
            if (projects) state.list = projects;
            if (businessValues) state.businessValueOptions = businessValues;
            if (levelOfEfforts) state.levelOfEffortOptions = levelOfEfforts;
            if (riskOptions) state.riskOptions = riskOptions;
            if (statusOptions) state.statusOptions = statusOptions;
            if (workItemTypes) state.workItemTypeOptions = workItemTypes;
            if (workItemStatuses)
                state.workItemStatusOptions = workItemStatuses;
        },
        [changeProjectRank.fulfilled]: (state, action) => {
            let updatedList = getUpdatedList(
                state.list,
                action.payload.project,
            );
            action.payload.affectedProjects?.forEach((affectedProject) => {
                let project = state.list.find(
                    (a) => a.id === affectedProject.projectId,
                );
                if (project) {
                    project.rank = affectedProject.rank;

                    updatedList = getUpdatedList(state.list, project);
                }
            });
            state.list = updatedList;
        },
        [changeWorkItemRank.fulfilled]: (state, action) => {
            let foundProject = getItemByItemId(
                state.list,
                action.payload.workItem?.projectId,
            );

            let workItemChanged = foundProject.workItems.find(
                (x) => x.id == action.payload.workItem.id,
            );

            if (workItemChanged) {
                workItemChanged.rank = action.meta?.arg.newRank;
            }

            state.list = getUpdatedList(state.list, foundProject);

            const affectedWorkItems = action.payload?.affectedWorkItems;
            if (affectedWorkItems) {
                affectedWorkItems.forEach((affectedItem) => {
                    let foundProject = getItemByItemId(
                        state.list,
                        affectedItem.projectId,
                    );
                    if (!foundProject) return;

                    let workItem = getItemByItemId(
                        foundProject.workItems,
                        affectedItem.workItemId,
                    );
                    if (!workItem) return;
                    workItem.rank = affectedItem.rank;

                    state.list = getUpdatedList(state.list, foundProject);
                });
            }
        },
        [changePhaseRank.fulfilled]: (state, action) => {
            let foundProject = getItemByItemId(
                state.list,
                action.payload.phase?.projectId,
            );

            let phaseChanged = foundProject.projectPhases.find(
                (x) => x.id == action.payload.phase.id,
            );

            if (phaseChanged) {
                phaseChanged.rank = action.meta?.arg.newRank;
            }

            action.payload.affectedPhases.forEach((affectedPhase) => {
                let phase = getItemByItemId(
                    foundProject.projectPhases,
                    affectedPhase.phaseId,
                );
                if (phase) {
                    phase.rank = affectedPhase.rank;
                }
            });

            state.list = getUpdatedList(state.list, foundProject);
        },
        [changeProjectStatus.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [deleteCommentFromProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            foundProject.comments = removeItemFromListById(
                foundProject.comments,
                action.payload.id,
            );
        },
        [deleteEpic.fulfilled]: (state, action) => {
            removeEpicUtility(state, action.payload);
        },
        [deleteMilestone.fulfilled]: (state, action) => {
            if (action.payload.projectId) {
                const foundProject = getItemByItemId(
                    state.list,
                    action.payload.projectId,
                );
                foundProject.milestones = removeItemFromListById(
                    foundProject.milestones,
                    action.payload,
                );

                state.list = getUpdatedList(state.list, foundProject);
            }
        },
        [deleteNonLaborExpenseFromProject.fulfilled]: (state, action) => {
            const projectNonLaborExpenseToDelete = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                projectNonLaborExpenseToDelete.projectId,
            );

            const updatedProject = {
                ...foundProject,
                nonLaborExpenses: removeItemFromListById(
                    foundProject.nonLaborExpenses,
                    projectNonLaborExpenseToDelete.id,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [removeProjectProfile.fulfilled]: (state, action) => {
            const { projectId, projectProfileId } = action.payload.id;

            const foundProject = getItemByItemId(
                state.list,
                projectId
            );

            const updatedProject = {
                ...foundProject,
                projectProfiles: removeItemFromListById(
                    foundProject.projectProfiles,
                    projectProfileId
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [deleteWorkItemFromEpic.fulfilled]: (state, action) => {
            const workItem = action.payload;

            const foundProject = getProjectByEpicId(
                state.list,
                workItem.epicId,
            );

            const foundEpic = getItemByItemId(
                foundProject.epics,
                workItem.epicId,
            );

            const updatedEpic = {
                ...foundEpic,
                workItems: removeItemFromListById(
                    foundEpic.workItems,
                    workItem.id,
                ),
            };

            const updatedProject = {
                ...foundProject,
                epics: getUpdatedList(foundProject.epics, updatedEpic),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [deleteWorkItemFromProject.fulfilled]: (state, action) => {
            const workItem = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                workItem.projectId,
            );

            const listWithItemRemoved = removeItemFromListById(
                foundProject.workItems,
                workItem.id,
            );

            const reorderdList = listWithItemRemoved
                .filter((a) => a.rank > workItem.rank)
                .map((workItem) => ({
                    ...workItem,
                    rank: workItem.rank - 1,
                }));

            const updatedProject = {
                ...foundProject,
                workItems: [
                    ...listWithItemRemoved.filter(
                        (a) => a.rank < workItem.rank,
                    ),
                    ...reorderdList,
                ],
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [deleteProject.fulfilled]: (state, action) => {
            const removedProjectRank = state.list.find(
                (a) => a.id === action.payload,
            ).rank;
            const listWithItemRemoved = removeItemFromListById(
                state.list,
                action.payload,
            );
            const reorderdList = listWithItemRemoved
                .filter((a) => a.rank > removedProjectRank)
                .map((project) => ({
                    ...project,
                    rank: project.rank - 1,
                }));
            state.list = [
                ...listWithItemRemoved.filter(
                    (a) => a.rank < removedProjectRank,
                ),
                ...reorderdList,
            ];
        },
        [deleteResourceTimelineFromProject.fulfilled]: (state, action) => {
            const projectResourceTimelineToDelete = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                projectResourceTimelineToDelete.projectId,
            );

            const updatedProject = {
                ...foundProject,
                resourceTimelines: removeItemFromListById(
                    foundProject.resourceTimelines,
                    projectResourceTimelineToDelete.id,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [disableProjectPhase.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            foundProject.workItems.forEach((workItem) => {
                if (workItem.projectPhaseId === action.payload.id) {
                    workItem.projectPhaseId = null;
                }
            });

            const updatedProject = {
                ...foundProject,
                projectPhases: removeItemFromListById(
                    foundProject.projectPhases,
                    action.payload.id,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [downloadProjectAttachment.pending]: (state, action) => {
            const attachmentId = action.meta.arg.id;
            const foundAttachment = state.selectedProjectAttachments.find(
                (attachment) => attachment.id === attachmentId,
            );
            if (foundAttachment) {
                foundAttachment.isLoading = true;
            }
        },
        [downloadProjectAttachment.fulfilled]: (state, action) => {
            const foundAttachment = state.selectedProjectAttachments.find(
                (attachment) => attachment.id === action.payload,
            );

            if (foundAttachment) {
                foundAttachment.isLoading = false;
            }
        },
        [downloadProjectAttachment.rejected]: (state, action) => {
            const foundAttachment = state.selectedProjectAttachments.find(
                (attachment) => attachment.id === action.payload,
            );

            if (foundAttachment) {
                foundAttachment.isLoading = false;
            }
        },
        [downloadWorkItemAttachment.pending]: (state, action) => {
            const attachmentId = action.meta.arg.id;
            const foundAttachment = state.selectedWorkItemAttachments.find(
                (attachment) => attachment.id === attachmentId,
            );
            if (foundAttachment) {
                foundAttachment.isLoading = true;
            }
        },
        [downloadWorkItemAttachment.fulfilled]: (state, action) => {
            const foundAttachment = state.selectedWorkItemAttachments.find(
                (attachment) => attachment.id === action.payload,
            );

            if (foundAttachment) {
                foundAttachment.isLoading = false;
            }
        },
        [downloadWorkItemAttachment.rejected]: (state, action) => {
            const foundAttachment = state.selectedWorkItemAttachments.find(
                (attachment) => attachment.id === action.payload,
            );

            if (foundAttachment) {
                foundAttachment.isLoading = false;
            }
        },
        [getEpicDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload?.data?.projectId,
            );
            if (foundProject)
                foundProject.epics = addToOrUpdateInList(
                    foundProject.epics,
                    action.payload.data,
                );
        },
        [getEpicDetail.rejected]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            if (foundProject)
                foundProject.epics = removeItemFromListById(
                    foundProject.epics,
                    action.payload.epicId,
                );
        },
        [getProjectCharacteristicDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.meta.arg?.projectId,
            );
            if (foundProject)
                foundProject.characteristics = addToOrUpdateInList(
                    foundProject.characteristics,
                    action.payload.data,
                );
        },
        [getProjectCharacteristicDetail.rejected]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            if (foundProject)
                foundProject.characteristics = removeItemFromListById(
                    foundProject.characteristics,
                    action.payload.characteristicId,
                );
        },
        [getProjectCommentDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload?.data?.projectId,
            );
            if (foundProject)
                foundProject.comments = addToOrUpdateInList(
                    foundProject.comments,
                    action.payload.data,
                );
        },
        [getProjectCommentDetail.rejected]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            if (foundProject)
                foundProject.comments = removeItemFromListById(
                    foundProject.comments,
                    action.payload.commentId,
                );
        },
        [getProjectDetail.fulfilled]: (state, action) => {
            state.list = addToOrUpdateInList(state.list, action.payload.data);
        },
        [getProjectDetail.rejected]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [getProjectAttachments.fulfilled]: (state, action) => {
            state.selectedProjectAttachments = action?.payload.data;
        },
        [getWorkItemAttachments.fulfilled]: (state, action) => {
            state.selectedWorkItemAttachments = action?.payload.data;
        },
        [getWorkItemHistory.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            if (foundProject) {
                const foundWorkItem = getItemByItemId(
                    foundProject.workItems,
                    action.payload.workItemId,
                );
                if (foundWorkItem) {
                    foundWorkItem.history = action.payload.history;
                }
            }
        },
        [getProjectNonLaborExpenseDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.data?.projectId,
            );
            if (foundProject)
                foundProject.nonLaborExpenses = addToOrUpdateInList(
                    foundProject.nonLaborExpenses,
                    action.payload.data,
                );
        },
        [getProjectNonLaborExpenseDetail.rejected]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            if (foundProject)
                foundProject.nonLaborExpenses = removeItemFromListById(
                    foundProject.nonLaborExpenses,
                    action.payload.costId,
                );
        },
        [getProjectProfileDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.data.projectId,
            );
            if (foundProject) {
                foundProject.projectProfiles = addItemsToList(
                    foundProject.projectProfiles,
                    action.payload.data,
                );
            }
        },
        [getProjectResourceTimelineDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.data?.projectId,
            );
            if (foundProject)
                foundProject.resourceTimelines = addToOrUpdateInList(
                    foundProject.resourceTimelines,
                    action.payload.data,
                );
        },
        [getProjectResourceTimelineDetail.rejected]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            if (foundProject)
                foundProject.resourceTimelines = removeItemFromListById(
                    foundProject.resourceTimelines,
                    action.payload.timelineId,
                );
        },
        [getWorkItemDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.data?.projectId,
            );
            if (foundProject)
                foundProject.workItems = addToOrUpdateInList(
                    foundProject.workItems,
                    action.payload.data,
                );
        },
        [getWorkItemDetail.rejected]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );
            if (foundProject)
                foundProject.workItems = removeItemFromListById(
                    foundProject.workItems,
                    action.payload.workItemId,
                );
        },
        [getProjectPhaseDetail.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action?.payload.data.projectId,
            );
            if (foundProject) {
                foundProject.projectPhases = addToOrUpdateInList(
                    foundProject.projectPhases,
                    action.payload.data,
                );
            }
        },
        [linkProjectToJira.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                integrationKey: [
                    ...foundProject.integrationKey,
                    action.payload,
                ],
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [moveEpicToExistingProject.fulfilled]: (state, action) => {
            const { epic, newProjectId } = action.payload;

            removeEpicUtility(state, epic);

            const movedEpic = {
                ...epic,
                projectId: newProjectId,
            };

            addEpicUtility(state, movedEpic);
        },
        [removeCharacteristicFromProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                characteristics: removeItemFromListById(
                    foundProject.characteristics,
                    action.payload.id,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [removeLabelFromProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                labels: removeItemFromListById(
                    foundProject.labels,
                    action.payload.id,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [removeLOBFromProject.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                linesOfBusiness: removeItemFromListById(
                    foundProject.linesOfBusiness,
                    action.payload.id,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [selectWorkItem.fulfilled]: (state, action) => {
            state.selectedWorkItem = action.payload;
        },
        [updateEpic.fulfilled]: (state, action) => {
            const foundProject = getItemByItemId(
                state.list,
                action.payload.projectId,
            );

            const updatedProject = {
                ...foundProject,
                epics: getUpdatedList(foundProject.epics, action.payload),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateEpicWorkItem.fulfilled]: (state, action) => {
            const workItem = action.payload;

            const foundProject = getProjectByEpicId(
                state.list,
                workItem.epicId,
            );

            const foundEpic = getItemByItemId(
                foundProject.epics,
                workItem.epicId,
            );

            const updatedEpic = {
                ...foundEpic,
                workItems: getUpdatedList(foundEpic?.workItems, workItem),
            };

            const updatedProject = {
                ...foundProject,
                epics: getUpdatedList(foundProject.epics, updatedEpic),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateProject.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [updateEpicEstimatedCompletionDate.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [updateEpicEstimatedStartDate.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [updateProjectEstimatedCompletionDate.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [updateProjectEstimatedStartDate.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [updateProjectNonLaborExpense.fulfilled]: (state, action) => {
            const updatedProjectNonLaborExpense = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                updatedProjectNonLaborExpense.projectId,
            );

            const updatedProject = {
                ...foundProject,
                nonLaborExpenses: getUpdatedList(
                    foundProject.nonLaborExpenses,
                    updatedProjectNonLaborExpense,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateProjectWorkItem.fulfilled]: (state, action) => {
            const workItem = action.payload;
            const foundProject = getItemByItemId(
                state.list,
                workItem.projectId,
            );

            const updatedProject = {
                ...foundProject,
                workItems: getUpdatedList(foundProject.workItems, workItem),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateWorkItemStatus.pending]: (state, action) => {
            const workItem = structuredClone(action.meta.arg.updatedWorkItem);
            const foundProject = getItemByItemId(
                state.list,
                workItem.projectId,
            );
            const updatedProject = {
                ...foundProject,
                workItems: getUpdatedList(foundProject.workItems, workItem),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateWorkItemStatus.rejected]: (state, action) => {
            const workItem = structuredClone(action.meta.arg.originalWorkItem);
            const foundProject = getItemByItemId(
                state.list,
                workItem.projectId,
            );
            const updatedProject = {
                ...foundProject,
                workItems: getUpdatedList(foundProject.workItems, workItem),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateProjectWorkItemDescription.fulfilled]: (state, action) => {
            const workItem = action.payload;
            const foundProject = getItemByItemId(
                state.list,
                workItem.projectId,
            );

            const updatedProject = {
                ...foundProject,
                workItems: getUpdatedList(foundProject.workItems, workItem),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateProjectResourceTimeline.fulfilled]: (state, action) => {
            const updatedProjectResourceTimeline = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                updatedProjectResourceTimeline.projectId,
            );

            const updatedProject = {
                ...foundProject,
                resourceTimelines: getUpdatedList(
                    foundProject.resourceTimelines,
                    updatedProjectResourceTimeline,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
        [updateProjectPhase.fulfilled]: (state, action) => {
            const updatedPhase = action.payload;

            const foundProject = getItemByItemId(
                state.list,
                updatedPhase.projectId,
            );

            const updatedProject = {
                ...foundProject,
                projectPhases: getUpdatedList(
                    foundProject.projectPhases,
                    updatedPhase,
                ),
            };

            state.list = getUpdatedList(state.list, updatedProject);
        },
    },
});

export const projectActions = {
    ...projectActionsAsync,
    changeProjectListFilters: ProjectSlice.actions.changeProjectListFilters,
    changeZoom: ProjectSlice.actions.changeZoom,
    resetSlice: ProjectSlice.actions.resetSlice,
    toggleSummaryVisible: ProjectSlice.actions.toggleSummaryVisible,
    selectProject: ProjectSlice.actions.selectProject,
    setLatestProjectId: ProjectSlice.actions.setLatestProjectId,
};

export const projectNonLaborExpenseActions = {
    ...projectNonLaborExpenseActionsAsync,
    selectNonLaborExpense: ProjectSlice.actions.selectNonLaborExpense,
    unselectNonLaborExpense: ProjectSlice.actions.unselectNonLaborExpense,
};

export const epicActions = {
    ...epicActionsAsync,
    selectEpic: ProjectSlice.actions.selectEpic,
    unselectEpic: ProjectSlice.actions.unselectEpic,
    setIsNewEpicForWorkItem: ProjectSlice.actions.setIsNewEpicForWorkItem,
    selectEpicForWorkItemReporter: ProjectSlice.actions.selectEpicForWorkItemReporter,
    unselectEpicForWorkItemReporter: ProjectSlice.actions.unselectEpicForWorkItemReporter,
    setNewEpicModalIsForWorkItemReporter: ProjectSlice.actions.setNewEpicModalIsForWorkItemReporter,
};

export const workItemActions = {
    ...workItemActionsAsync,
    changeWorkItemsView: ProjectSlice.actions.changeWorkItemsView,
    changeWorkItemFilters: ProjectSlice.actions.changeWorkItemFilters,
    clearWorkItemFilters: ProjectSlice.actions.clearWorkItemFilters,
    unselectWorkItem: ProjectSlice.actions.unselectWorkItem,
    setHasSelectedWorkItemDescriptionChanged:
        ProjectSlice.actions.setHasSelectedWorkItemDescriptionChanged,
};

export const resourceTimelineActions = {
    ...resourceTimelineActionsAsync,
    selectResourceTimeline: ProjectSlice.actions.selectResourceTimeline,
};

export const phaseActions = {
    ...phaseActionsAsync,
    selectProjectPhase: ProjectSlice.actions.selectProjectPhase,
    unselectProjectPhase: ProjectSlice.actions.unselectProjectPhase,
};

export default ProjectSlice.reducer;
