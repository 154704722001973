import * as yup from 'yup';
import { useFormik } from 'formik';
import { Grid, TextField } from '@mui/material';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import StatusPicker from 'System/UI/Pickers/StatusPicker/StatusPicker';
import { useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import Epic from 'Models/Epic';
import Form from 'System/Forms/Components/Form';

export default function EpicForm({ project, epic, onSubmit }) {
    const statusOptions = useSelector(projectSelectors.statusOptions);

    const initialValues =
        epic ||
        new Epic({
            projectId: project?.id,
            // estimatedStartDate: project?.estimatedStartDate,
            // estimatedCompletionDate: project?.estimatedCompletionDate,
        });

    const epicSchema = yup.object().shape({
        name: yup.string().required('Required'),
        status: yup.string().required('Required'),
        estimatedStartDate: yup.date().typeError('Invalid Date').nullable(),
        estimatedCompletionDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .min(
                yup.ref('estimatedStartDate'),
                'Must be after Est. Start Date',
            ),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema: epicSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    const getStatusName = (status) =>
        statusOptions.find((option) => option.status === status)?.statusName;

    return initialValues ? (
        <Form onSubmit={handleSubmit} formId="epic-form">
            <Grid container>
                <Grid item xs={epic ? 8 : 12}>
                    <TextField
                        name="name"
                        label="Name"
                        value={values.name}
                        placeholder="The epic name"
                        error={showError('name')}
                        helperText={getError('name')}
                        inputProps={{ maxLength: 100 }}
                        fullWidth
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Grid>

                {epic ? (
                    <Grid item xs={4}>
                        <StatusPicker
                            fullWidth
                            selectedStatus={values.status}
                            onChange={(e) => {
                                setFieldValue(
                                    'statusName',
                                    getStatusName(e.target.value),
                                    false,
                                );
                                handleChange(e);
                            }}
                        />
                    </Grid>
                ) : null}

                <Grid item xs={6}>
                    <DatePicker
                        name="estimatedStartDate"
                        format="MM/DD/YYYY"
                        label="Est. Start"
                        required
                        error={showError('estimatedStartDate')}
                        helperText={getError('estimatedStartDate')}
                        value={values.estimatedStartDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        name="estimatedCompletionDate"
                        format="MM/DD/YYYY"
                        label="Est. Completion"
                        required
                        error={showError('estimatedCompletionDate')}
                        helperText={getError('estimatedCompletionDate')}
                        value={values.estimatedCompletionDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Grid>
                {epic ? (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                name="objective"
                                multiline
                                rows={8}
                                label="Objective"
                                value={values.objective}
                                placeholder="The epic objective"
                                inputProps={{ maxLength: 5000 }}
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
                            <KeyResults
                                name="keyResults"
                                value={values.keyResults}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid> */}
                    </>
                ) : null}
            </Grid>
        </Form>
    ) : null;
}
