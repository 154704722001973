import { TextField, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';

export default function NonResourceUserPicker({
    name,
    label,
    onChange,
    selectedUser,
}) {
    const availableUsers = useSelector(companySelectors.nonResourceUsers);

    const handleInputChange = (e) => {
        onChange({
            target: {
                name: name,
                value: e.target.value,
            },
            entity: availableUsers.find(user => user.id === e.target.value)
        });
    };

    return (
        <TextField
            name={name}
            label={label}
            fullWidth
            select
            SelectProps={{
                labelId: 'userPicker-label',
                displayEmpty: true,
            }}
            value={selectedUser || ''}
            onChange={handleInputChange}
        >
            <MenuItem value="">Do not link to a user</MenuItem>
            {availableUsers.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                    {user.formattedName}{' '}
                    {user.emailAddress && (
                        <span>&nbsp;({user.emailAddress})</span>
                    )}
                </MenuItem>
            ))}
        </TextField>
    );
}
