import RestAPI from '../rest-api';
import { createAsyncThunk } from '@reduxjs/toolkit';

const createProjectPhase = createAsyncThunk(
    'projects/createProjectPhase',
    async (payload) => {
        await RestAPI.post(`/projects/${payload?.projectId}/phases`, {
            body: payload,
        });
        return payload.projectId;
    },
);

const getProjectPhaseDetail = createAsyncThunk(
    'projects/getProjectsPhases',
    async ({ phaseId, projectId }) => {
        const response = await RestAPI.get(
            `/projects/${projectId}/phases/${phaseId}`,
        );
        return response;
    },
);

const disableProjectPhase = createAsyncThunk(
    'projects/disableProjectPhase',
    async (phase) => {
        await RestAPI.del(`/projects/${phase.projectId}/phases/${phase.id}`);
        return { ...phase };
    },
);

const updateProjectPhase = createAsyncThunk(
    'projects/updateProjectPhase',
    async (phase) => {
        await RestAPI.put(`/projects/${phase.projectId}/phases/${phase.id}`, {
            body: phase,
        });

        return structuredClone(phase);
    },
);

const changePhaseRank = createAsyncThunk(
    'projects/changePhaseRank',
    async ({ phase, newRank }) => {
        var response = await RestAPI.put(
            `/projects/${phase.projectId}/phases/${phase.id}/change-rank`,
            { body: { rank: newRank } },
        );

        return {
            phase: { ...phase, rank: newRank },
            affectedPhases: response.data,
        };
    },
);

export const phaseActionsAsync = {
    createProjectPhase,
    getProjectPhaseDetail,
    disableProjectPhase,
    updateProjectPhase,
    changePhaseRank,
};
