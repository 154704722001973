import {
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import SectionHeader from 'System/Modals/Components/SectionHeader';
import * as yup from 'yup';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import ReadOnlyField from 'System/UI/Components/ReadOnlyField/ReadOnlyField';
import { useSelector } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { coreSelectors } from 'System/State/CoreState/selectors';
import FinancialField from 'System/UI/Components/FinancialField/FinancialField';
import ReadOnlyFinancialField from 'System/UI/Components/FinancialField/ReadOnlyFinancialField';
import Form from 'System/Forms/Components/Form';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import { PaymentTier } from 'Models/PaymentTiers';

const DurationTooltipText = (
    <Stack spacing={0}>
        <Stack direction="row">
            <Typography variant="body2" sx={{ margin: 1, fontWeight: 600 }}>
                Total Days:
            </Typography>

            <Typography sx={{ margin: 1 }} variant="body2">
                Days Worked/Week x Number Of Weeks
            </Typography>
        </Stack>

        <Stack direction="row">
            <Typography variant="body2" sx={{ margin: 1, fontWeight: 600 }}>
                Total Hours:
            </Typography>

            <Typography sx={{ margin: 1 }} variant="body2">
                Hours/Day x Total Days
            </Typography>
        </Stack>
    </Stack>
);

const FinancialsTooltipText = (
    <Stack spacing={0}>
        <Stack direction="row">
            <Typography variant="body2" sx={{ margin: 1, fontWeight: 600 }}>
                Total Revenue:
            </Typography>

            <Typography sx={{ margin: 1 }} variant="body2">
                Hourly Revenue x Total Hours
            </Typography>
        </Stack>

        <Stack direction="row">
            <Typography variant="body2" sx={{ margin: 1, fontWeight: 600 }}>
                Total Expense:
            </Typography>

            <Typography sx={{ margin: 1 }} variant="body2">
                Hourly Expense x Total Hours
            </Typography>
        </Stack>
    </Stack>
);

export default function ResourceTimelineDetailForm({
    resourceTimeline,
    onSubmit,
    project,
}) {
    const isRevenueTracked = useSelector(companySelectors.isRevenueTracked);
    const showFinancials = useSelector(
        coreSelectors.showGlobalFinancialsIfAllowed,
    );
    const tenant = useSelector(companySelectors.tenant);
    const billingFlag = useSelector(featureFlagSelectors.billingFlag);

    const setResourceTimelineDatesToProjectDates = (values) => {
        values.startDate = project.estimatedStartDate;
        values.endDate = project.estimatedCompletionDate;
    };

    // accommodate for previous bad data of resource timelines on form load
    // this shouldn't be needed in the future
    if (resourceTimeline.isPinned) {
        setResourceTimelineDatesToProjectDates(resourceTimeline);
    }

    const resourceTimelineSchema = yup.object().shape({
        hoursPerDay: yup
            .number()
            .typeError('Must be a number')
            .required('Required')
            .min(0, 'Must be a positive number')
            .max(24, "Can't be more than 24"),
        daysPerWeek: yup
            .number()
            .typeError('Must be a number')
            .required('Required')
            .min(0, 'Must be a positive number')
            .max(7, "Can't be more than 7"),
        actualHourlyCostRate: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be a positive number'),
        actualHourlyBillRate: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be a positive number'),
        averageVelocity: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be a positive number')
            .max(999, "Can't be more than 999"),
        percentageAllocated: yup
            .number()
            .typeError('Must be a number')
            .required('Required')
            .min(0, 'Must be a positive number')
            .max(100, "Can't be more than 100%"),
        startDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .required('Required')
            .when(['project', 'estimatedStartDate'], (project, estimatedStartDate, schema) => {
                return estimatedStartDate
                    ? schema.min(project.estimatedStartDate, 'Cannot precede project start')
                    : schema;
            }),
        endDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .required('Required')
            .when(['estimatedStartDate'], (estimatedStartDate, schema) => {
                return estimatedStartDate
                    ? schema.min(yup.ref('startDate'), 'Must be after Start Date')
                    : schema;
            })
            .when(['project', 'estimatedCompletionDate'], (project, estimatedCompletionDate, schema) => {
                return estimatedCompletionDate
                    ? schema.max(project.estimatedCompletionDate, 'Cannot exceed project end')
                    : schema;
            }),
    });

    const handleIsPinnedChanged = (e, isPinned) => {
        setFieldValue('isPinned', isPinned, false);

        if (isPinned) {
            setFieldValue('startDate', project.estimatedStartDate, false);
            setFieldValue('endDate', project.estimatedCompletionDate, false);
        }
    };

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: resourceTimeline,
        validationSchema: resourceTimelineSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return resourceTimeline ? (
        <Form onSubmit={handleSubmit} formId="resource-timeline-detail-form">
            <Stack spacing={2}>
                {/* <pre>{ JSON.stringify(project, null, 2) }</pre>
                <pre>{ JSON.stringify(values, null, 2) }</pre> */}
                <SectionHeader text="Utilization" />

                <Stack direction="row" spacing={1}>
                    <TextField
                        name="hoursPerDay"
                        label="Hours/Day"
                        fullWidth
                        required
                        value={
                            values.hoursPerDay === 0 ? '' : values.hoursPerDay
                        }
                        error={showError('hoursPerDay')}
                        helperText={getError('hoursPerDay')}
                        placeholder="8"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="daysPerWeek"
                        label="Days Worked/Week"
                        fullWidth
                        required
                        value={
                            values.daysPerWeek === 0 ? '' : values.daysPerWeek
                        }
                        error={showError('daysPerWeek')}
                        helperText={getError('daysPerWeek')}
                        placeholder="5"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="averageVelocity"
                        label="Avg. Velocity"
                        fullWidth
                        value={
                            values.averageVelocity === 0
                                ? ''
                                : values.averageVelocity
                        }
                        error={showError('averageVelocity')}
                        helperText={getError('averageVelocity')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="percentageAllocated"
                        label="% Allocation"
                        fullWidth
                        required
                        value={values.percentageAllocated}
                        error={showError('percentageAllocated')}
                        helperText={getError('percentageAllocated')}
                        placeholder="100"
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <Divider />

                <SectionHeader
                    text="Duration"
                    tooltipText={DurationTooltipText}
                />

                <Stack>
                    <Stack direction="row" spacing={1}>
                        <DatePicker
                            name="startDate"
                            format="MM/DD/YYYY"
                            label="Start Date"
                            fullWidth
                            required
                            disabled={values.isPinned}
                            value={values.startDate}
                            error={showError('startDate')}
                            helperText={getError('startDate')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <DatePicker
                            name="endDate"
                            format="MM/DD/YYYY"
                            label="End Date"
                            fullWidth
                            required
                            disabled={values.isPinned}
                            error={showError('endDate')}
                            helperText={getError('endDate')}
                            value={values.endDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <ReadOnlyField
                            label="Total Duration"
                            value={values.totalDays.toLocaleString() + ' days'}
                        />
                    </Stack>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="isPinned"
                                    checked={values.isPinned}
                                    onChange={handleIsPinnedChanged}
                                ></Checkbox>
                            }
                            label={
                                <Typography sx={{ margin: 0 }} variant="body2">
                                    Match the start and end dates to the project
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Stack>

                {showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
                    (!billingFlag)) ? (
                    <>
                        <Divider sx={{ root: { marginTop: 0 } }} />

                        <SectionHeader
                            text="Financials"
                            tooltipText={FinancialsTooltipText}
                        />

                        <Stack direction="row" spacing={1}>
                            {isRevenueTracked ? (
                                <FinancialField
                                    name="actualHourlyBillRate"
                                    label="Hourly Revenue"
                                    value={values.actualHourlyBillRate}
                                    isError={showError('actualHourlyBillRate')}
                                    errorMsg={getError('actualHourlyBillRate')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            ) : null}

                            <FinancialField
                                name="actualHourlyCostRate"
                                label="Hourly Expense"
                                value={values.actualHourlyCostRate}
                                isError={showError('actualHourlyCostRate')}
                                errorMsg={getError('actualHourlyCostRate')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            {isRevenueTracked ? (
                                <ReadOnlyFinancialField
                                    label="Total Revenue"
                                    value={values.totalRevenue}
                                />
                            ) : null}

                            <ReadOnlyFinancialField
                                label="Total Expense"
                                value={values.totalCost}
                            />
                        </Stack>
                    </>
                ) : null}
            </Stack>
        </Form>
    ) : null;
}
