import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { companyActions } from 'System/State/CompanyState/reducer';
import { companySelectors } from 'System/State/CompanyState/selectors';
import InviteUsersForm from 'System/Forms/UserForms/InviteUsersForm';

export default function InviteUsersModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.inviteUsersModal) || false;
    const tenant = useSelector(companySelectors.tenant);

    const handleClose = () => dispatch(modalActions.close('inviteUsersModal'));

    const handleSubmit = (values) => {
        dispatch(
            companyActions.inviteUsers({
                users: values.emails,
                tenantId: tenant.id,
                group: values.group,
            }),
        );
        handleClose();
    };

    return (
        <Dialog
            open={open}
            fullWidth
            onClose={handleClose}
            aria-labelledby="Invite Users"
            aria-describedby="Invite new users"
        >
            <DialogTitle>Invite Users</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Type or paste comma-separated email addresses and press
                    Enter
                </DialogContentText>

                <InviteUsersForm onSubmit={handleSubmit} tier={tenant?.tier} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    form="invite-users-form"
                >
                    Invite Users
                </Button>
            </DialogActions>
        </Dialog>
    );
}
