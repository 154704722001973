import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Typography,
    Stack,
} from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleIcon from '@mui/icons-material/Circle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import SubscriptionHeader from './Components/SubscriptionHeader';
import { PaymentTier } from 'Models/PaymentTiers';
import { useSelector } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { paymentSelectors } from 'System/State/PaymentState/selectors';
import moment from 'moment';

export default function ChangePlanModal({
    isOpen,
    closeModal,
    handleClick,
    oldTier,
    newTier,
}) {
    const subscriptionInfo = useSelector(paymentSelectors.subscriptionInfo);

    const periodEnd = moment(subscriptionInfo?.currentPeriodEnd).format(
        'M/DD/YYYY',
    );

    const activeUsers = useSelector(companySelectors.activeUsersList);

    const proPrice = useSelector(paymentSelectors.professionalPrice);
    const enterprisePrice = useSelector(paymentSelectors.enterprisePrice);

    const getTierInfo = (tier) => {
        switch (tier) {
            case PaymentTier.Pro:
                return {
                    color: '#f7edab',
                    name: 'Professional',
                    pricePerUser: proPrice,
                    whatHappensNext: [
                        {
                            id: 1,
                            text: `On ${periodEnd} your new plan starts and you'll be charged $${proPrice}/month + tax per user.`,
                        },
                        {
                            id: 2,
                            text: 'All your projects, resources, and users will continue to stay active.',
                        },
                        {
                            id: 3,
                            text: "You'll lose access to our enterprise only features.",
                        },
                    ],
                    paymentText: `You will be charged for ${activeUsers.length
                        } users at $${proPrice} per user for a total monthly estimate of $${activeUsers.length * proPrice
                        }/month`,
                };
            case PaymentTier.Enterprise:
                return {
                    color: '#F7D8AF',
                    name: 'Enterprise',
                    pricePerUser: enterprisePrice,
                    whatHappensNext: [
                        {
                            id: 1,
                            text: `On ${periodEnd} your new plan starts and you'll be charged $${enterprisePrice}/month + tax per user.`,
                        },
                        {
                            id: 2,
                            text: "You'll keep all the features you have now including adding detailed financial information.",
                        },
                        {
                            id: 3,
                            text: "You'll gain access to our enterprise only features.",
                        },
                    ],
                    paymentText: `You will be charged for ${activeUsers.length
                        } users at $${enterprisePrice} per user for a total monthly estimate of $${activeUsers.length * enterprisePrice
                        }/month`,
                };
            default:
                return {
                    color: 'lightgrey',
                    name: 'Free',
                    pricePerUser: 0,
                    whatHappensNext: [
                        {
                            id: 1,
                            text: 'Connected integrations will stop syncing.',
                        },
                    ],
                    paymentText:
                        'Fairview does not store payment information. In the case you want to re-subscribe, our payment processor might have your details saved.',
                };
        }
    };

    if (newTier === null) {
        return null;
    } else
        return (
            <Dialog open={isOpen} fullWidth maxWidth="lg">
                <DialogTitle>Change Plan</DialogTitle>
                <DialogContent>
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={3}
                        pb={2}
                    >
                        <SubscriptionHeader
                            planInfo={getTierInfo(oldTier)}
                            isCurrent
                        />
                        <ArrowForwardIosIcon />
                        <SubscriptionHeader planInfo={getTierInfo(newTier)} />
                    </Stack>
                    <Stack direction="row" pt={2}>
                        <Stack direction="column" width="45%">
                            <Typography variant="h4" fontWeight={600} pb={2}>
                                What happens next?
                            </Typography>

                            {getTierInfo(newTier)?.whatHappensNext.map(
                                (item) => (
                                    <Stack
                                        direction="row"
                                        alignItems="flex-start"
                                        key={item.id}
                                        spacing={1}
                                    >
                                        {newTier === PaymentTier.Free ? (
                                            <WarningAmberIcon
                                                color="red"
                                                sx={{
                                                    width: '15px',
                                                    fill: 'red',
                                                }}
                                            />
                                        ) : (
                                            <CircleIcon
                                                sx={{ width: '7.5px' }}
                                            />
                                        )}

                                        <Typography variant="body2">
                                            {' '}
                                            {item?.text}
                                        </Typography>
                                    </Stack>
                                ),
                            )}
                        </Stack>
                        <div style={{ width: '10%' }}></div>
                        <Stack direction="column" width="45%">
                            <Typography variant="h4" fontWeight={600} pb={2}>
                                Payment
                            </Typography>
                            <Typography variant="body2" mt={0}>
                                {getTierInfo(newTier)?.paymentText}
                            </Typography>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModal}>Cancel</Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        );
}
