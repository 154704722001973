import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#009EE2',
        color: '#ffffff',
        fontSize: '12pt',
        fontWeight: '700',
        textAlign: 'center',
        minWidth: '12rem',
        textDecoration: 'none',
        display: 'inline-block',
        padding: '8px 20px',
        borderRadius: '10px',

        '&:hover': {
            backgroundColor: '#006BAF',
        },
    },
}));

export default function RequestDemoButton({ buttonText = 'Request Demo' }) {
    const classes = useStyles();

    return (
        <Link className={classes.root} to="/request-demo">
            {buttonText}
        </Link>
    );
}
