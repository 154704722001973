import { useDispatch, useSelector } from 'react-redux';
import { coreActions } from 'System/State/CoreState/reducer';
import { Snackbar, Alert } from '@mui/material';
import { coreSelectors } from 'System/State/CoreState/selectors';

export default function GlobalAlert() {
    const dispatch = useDispatch();
    const isMessageVisible = useSelector(coreSelectors.isMessageVisible);
    const message = useSelector(coreSelectors.message);
    const messageType = useSelector(coreSelectors.messageType);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isMessageVisible}
            autoHideDuration={3000}
            onClose={() => {
                dispatch(coreActions.hideMessage());
            }}
        >
            <Alert
                onClose={() => {
                    dispatch(coreActions.hideMessage());
                }}
                severity={messageType}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}
