import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, TextField } from '@mui/material';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import FinancialField from 'System/UI/Components/FinancialField/FinancialField';
import Budget from 'Models/Budget';
import Form from 'System/Forms/Components/Form';

export default function BudgetForm({ budget = new Budget(), onSubmit }) {
    const budgetSchema = yup.object().shape({
        name: yup.string().required('Required'),
        startDate: yup.date().typeError('Invalid date').required('Required'),
        endDate: yup
            .date()
            .required('Required')
            .typeError('Invalid date')
            .min(yup.ref('startDate'), 'Must be after start date'),
        amount: yup
            .number()
            .typeError('Must be greater than 0')
            .min(1, 'Must be greater than 0')
            .required('Required'),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: budget,
            validationSchema: budgetSchema,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return budget ? (
        <Form onSubmit={handleSubmit} formId="budget-form">
            <Stack spacing={2}>
                <TextField
                    name="name"
                    label="Name"
                    autoFocus
                    required
                    fullWidth
                    error={showError('name')}
                    helperText={getError('name')}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Stack direction="row" alignItems="center" spacing={2}>
                    <DatePicker
                        name="startDate"
                        format="MM/DD/YYYY"
                        label="Start Date"
                        required
                        error={showError('startDate')}
                        helperText={getError('startDate')}
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <DatePicker
                        name="endDate"
                        format="MM/DD/YYYY"
                        label="End Date"
                        required
                        error={showError('endDate')}
                        helperText={getError('endDate')}
                        value={values.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <FinancialField
                    name="amount"
                    label="Amount"
                    value={values.amount}
                    required
                    isError={showError('amount')}
                    errorMsg={getError('amount')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Stack>
        </Form>
    ) : null;
}
