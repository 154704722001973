import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import LinkProjectToJiraForm from 'System/Forms/IntegrationForms/LinkProjectToJiraForm';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { projectActions } from 'System/State/ProjectState/reducer';

export default function linkProjectToJiraModal() {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(modalSelectors.linkProjectToJiraModal);
    const selectedProject = useSelector(projectSelectors.selectedProject);
    const selectedProjectIntegrationKey = useSelector(
        projectSelectors.selectedProjectIntegrationKey,
    );

    const handleClose = () =>
        dispatch(modalActions.close('linkProjectToJiraModal'));

    const handleSubmit = (linkProjectToJiraFormData) => {
        dispatch(
            projectActions.linkProjectToJira({
                jiraIntegrationKey:
                    linkProjectToJiraFormData.jiraIntegrationKey,
                projectId: selectedProject.id,
            }),
        );
        handleClose();
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Link Project to Jira"
            aria-describedby="Links this project to Jira"
        >
            <DialogTitle>Link Project To Jira</DialogTitle>

            <DialogContent>
                <LinkProjectToJiraForm
                    jiraIntegrationKey={selectedProjectIntegrationKey}
                    onSubmit={handleSubmit}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="link-project-to-jira-form"
                >
                    Link Project to Jira
                </Button>
            </DialogActions>
        </Dialog>
    );
}
