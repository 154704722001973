import {
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Tooltip,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    IconButton,
    Autocomplete,
    MenuItem,
} from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { EditorState, convertToRaw } from 'draft-js';
import WorkItemTypePicker from 'System/UI/Pickers/WorkItemTypePicker/WorkItemTypePicker';
import WorkItemStatusPicker from 'System/UI/Pickers/WorkItemStatusPicker/WorkItemStatusPicker';
import WorkItemPriorityPicker from 'System/UI/Pickers/WorkItemPriorityPicker/WorkItemPriorityPicker';
import EpicPicker from 'System/UI/Pickers/EpicPicker/EpicPicker';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import WorkItem from 'Models/WorkItem';
import Form from 'System/Forms/Components/Form';
import ResourceFilter from 'System/UI/Filters/ResourceFilter/ResourceFilter';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import RichTextEditor from 'System/UI/Components/RichTextEditor/RichTextEditor';
import RichTextEditorUtils from 'System/UI/Components/RichTextEditor/Utils';
import FileDeleteIcon from '@mui/icons-material/Delete';
import { modalActions } from 'System/State/ModalState/reducer';
import { userSelectors } from 'System/State/UserState/selectors';
import { epicActions, projectActions } from 'System/State/ProjectState/reducer';

const WorkItemForm = forwardRef(function WorkItemForm(
    {
        trackProjectsBy,
        onSubmit,
        attachments,
        handleRemoveAttachment,
        showProjectSelector,
    },
    ref,
) {
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        handleClearForm,
        handleGetWorkItemId,
    }));

    const selectedProject = useSelector(projectSelectors.selectedProject);
    const allProjects = useSelector(projectSelectors.allProjects);
    const isAllowedAdmin = useSelector(userSelectors.isAllowedAdmin);

    const chatGPTWorkItemDescriptionFlag = useSelector(
        featureFlagSelectors.chatGPTWorkItemDescriptionFlag,
    );
    const [isDescriptionHidden, setDescriptionHidden] = useState(false);
    const [chatGptDescriptionIsEnabled, setChatGptDescriptionIsEnabled] =
        useState(false);

    const workItemStatusOptions = useSelector(
        projectSelectors.workItemStatusOptions,
    );
    const workItemTypeOptions = useSelector(
        projectSelectors.workItemTypeOptions,
    );

    const selectedEpic = useSelector(projectSelectors.selectedEpic);
    const selectedPhase = useSelector(projectSelectors.selectedPhase);

    const handleClearForm = () => {
        if (!isDescriptionHidden) {
            setEditorState(
                EditorState.createEmpty(RichTextEditorUtils.decorator),
            );
        }
        resetForm({ values: new WorkItem() });
    };

    const handleGetWorkItemId = () => {
        return values.id;
    };

    const workItemSchema = yup.object().shape({
        name: yup.string().required('Required'),
        type: yup.number().required('Required').min(0).max(4),
        status: yup.number().required(),
        statusName: yup.string(),
        priority: yup.number().required('Required').min(0).max(5),
        pointsEstimate: yup
            .number()
            .nullable()
            .min(0, 'Must be positive number'),
        hoursEstimate: yup
            .number()
            .nullable()
            .min(0, 'Must be positive number'),
        hoursComplete: yup
            .number()
            .nullable()
            .min(0, 'Must be positive number'),
        projectEpicId: yup.string().nullable(),
        projectPhaseId: yup.string().nullable(),
        projectId: yup.string().required('Required'),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
    } = useFormik({
        initialValues: new WorkItem(),
        validationSchema: workItemSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const getStatusName = (status) =>
        workItemStatusOptions.find((option) => option.status === status)
            ?.statusName;

    const getTypeName = (type) =>
        workItemTypeOptions.find((option) => option.type === type)?.typeName;

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    const handleEpicChange = (value) => {
        dispatch(epicActions.selectEpic({ epicId: value }));
    };

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty(RichTextEditorUtils.decorator),
    );

    useEffect(() => {
        setFieldValue(
            'description',
            JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            false,
        );
    }, [editorState]);

    const handleDescriptionCheckboxChange = (e) => {
        setDescriptionHidden(e.target.checked);
        setFieldValue('autoGenerateDescription', e.target.checked, false);
    };

    useEffect(() => {
        setFieldValue('projectEpicId', selectedEpic?.id || null, false);
    }, [selectedEpic]);

    useEffect(() => {
        setFieldValue('projectPhaseId', selectedPhase?.id || null, false);
    }, [selectedPhase]);

    useEffect(() => {
        const currentProjectId = values?.projectId;
        setFieldValue('projectId', selectedProject?.id || null, false);
        if (showProjectSelector && selectedProject?.id !== currentProjectId) {
            dispatch(epicActions.unselectEpic());
        }
    }, [selectedProject]);

    useEffect(() => {
        setChatGptDescriptionIsEnabled(chatGPTWorkItemDescriptionFlag);

        // set initial state to hide description if chatGpt is enabled
        if (chatGPTWorkItemDescriptionFlag) {
            setDescriptionHidden(true);
            setFieldValue('autoGenerateDescription', false, false);
        }
    }, [chatGPTWorkItemDescriptionFlag]);

    return (
        <Form onSubmit={handleSubmit} formId="work-item-form">
            <Grid container>
                <Grid item xs={12}>
                    {showProjectSelector && (
                        <Autocomplete
                            sx={{ width: '300px' }}
                            options={allProjects}
                            value={selectedProject}
                            getOptionLabel={(opt) => opt?.name ?? ''}
                            onChange={(_, x) =>
                                dispatch(projectActions.selectProject(x?.id))
                            }
                            isOptionEqualToValue={(option, value) =>
                                option?.id === value?.id
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Select a project"
                                />
                            )}
                            noOptionsText={
                                isAllowedAdmin ? (
                                    <MenuItem
                                        onClick={() =>
                                            dispatch(
                                                modalActions.open(
                                                    'newProjectModal',
                                                ),
                                            )
                                        }
                                    >
                                        Add Project
                                    </MenuItem>
                                ) : (
                                    'No Options Found'
                                )
                            }
                        ></Autocomplete>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        name="name"
                        label="Name"
                        autoFocus
                        required
                        fullWidth
                        error={showError('name')}
                        helperText={getError('name')}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <WorkItemStatusPicker
                        selectedStatus={values.status}
                        onChange={(status) => {
                            setFieldValue(
                                'statusName',
                                getStatusName(status),
                                false,
                            );
                            setFieldValue('status', status, false);
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <WorkItemTypePicker
                        selectedType={values.type}
                        onChange={(type) => {
                            setFieldValue('typeName', getTypeName(type), false);
                            setFieldValue('type', type, false);
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <WorkItemPriorityPicker
                        selectedPriority={values.priority}
                        onChange={(priority) =>
                            setFieldValue('priority', priority, false)
                        }
                    />
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Stack
                        spacing={1}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        paddingBottom="5px"
                    >
                        <Tooltip
                            title="Points are an arbitrary way to estimate effort and is popular in Agile. Team members discuss a work item or 'story', and then each team member provides a fibonacci-based number that describes the effort of that work item such as: 1, 2, 3, 5, 8, 13. After further discussion, a consensus is made and the effort estimate is recorded."
                            arrow
                            placement="top-start"
                            disableHoverListener={
                                trackProjectsBy === 'Hours' ||
                                trackProjectsBy === 'WorkItems' ||
                                trackProjectsBy === 'Schedule'
                            }
                        >
                            <Typography variant="h6">
                                {trackProjectsBy != 'Hours'
                                    ? 'Points'
                                    : 'Hours'}
                            </Typography>
                        </Tooltip>

                        <TextField
                            name={
                                trackProjectsBy != 'Hours'
                                    ? 'pointsEstimate'
                                    : 'hoursEstimate'
                            }
                            onBlur={handleChange}
                            onChange={handleChange}
                            value={
                                trackProjectsBy != 'Hours'
                                    ? values.pointsEstimate
                                    : values.hoursEstimate
                            }
                            error={showError('pointsEstimate')}
                            helperText={getError('pointsEstimate')}
                            variant="standard"
                            inputProps={{
                                style: {
                                    textAlign: 'center',
                                    padding: '3px',
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: '#00000014',
                                    height: '31px',
                                    width: '31px',
                                },
                            }}
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </Stack>
                </Grid>

                <Grid item xs={6} sm={6}>
                    <EpicPicker onSelect={handleEpicChange} />
                </Grid>
                <Grid item xs={6} sm={6} justifyContent="center">
                    <ResourceFilter
                        name="assignedSkilledResources"
                        label="Assigned To"
                        fullWidth={true}
                        selectedResources={values.assignedSkilledResources}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    {chatGptDescriptionIsEnabled && (
                        <FormControlLabel
                            label="Attempt to auto-generate description from title using ChatGPT (may take up to 30 seconds)"
                            control={
                                <Checkbox
                                    checked={isDescriptionHidden}
                                    onChange={handleDescriptionCheckboxChange}
                                />
                            }
                        />
                    )}
                    {!isDescriptionHidden && (
                        <RichTextEditor
                            editorState={editorState}
                            setEditorState={setEditorState}
                            editMode
                            parentId={values.id}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {attachments?.length ? (
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Filename</TableCell>
                                        <TableCell>File Type</TableCell>
                                        <TableCell align="right">
                                            &nbsp;
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {attachments.map((attachment) => (
                                        <TableRow key={attachment.id}>
                                            <TableCell>
                                                {attachment.filename}
                                            </TableCell>
                                            <TableCell>
                                                {attachment.mimeType}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        handleRemoveAttachment(
                                                            attachment,
                                                        )
                                                    }
                                                >
                                                    <FileDeleteIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                </Grid>
            </Grid>
        </Form>
    );
});

export default WorkItemForm;
