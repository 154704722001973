import {
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    DialogActions,
    Link,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { useNavigate } from 'react-router-dom';
import fairviewLogo from '../../assets/logo-icon.svg';
import { paneActions } from 'System/State/PaneState/reducer';

export default function UpgradeSuccessfulModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const open = useSelector(modalSelectors.upgradeSuccessfulModal) || false;

    const handleClose = () => {
        navigate('/');
        dispatch(modalActions.close('upgradeSuccessfulModal'));
    };

    const handleContactUs = () => {
        handleClose();
        dispatch(paneActions.closeAll());
        navigate('/contact-us');
    };

    return (
        <Dialog open={open} scroll="paper" fullWidth={true} maxWidth={'md'}>
            <DialogTitle>Upgrade Plan</DialogTitle>
            <DialogContent>
                <Stack direction="row" alignItems="center">
                    <Stack direction="column" spacing={2} width="70%">
                        <Typography variant="h3" fontWeight={600}>
                            Success!
                        </Typography>
                        {/* <Typography>
                            You are on your way to planning like a pro with your
                            brand new Fairview Professional plan.
                        </Typography> */}
                        <Typography>
                            You are on your way to planning like a pro with your
                            brand new Fairview plan.
                        </Typography>
                        <Typography>
                            You can always manage your subscription and view
                            receipts by going to the Billing tab inside
                            settings.
                        </Typography>
                        <Typography>
                            Questions or feedback?{' '}
                            <Link
                                onClick={handleContactUs}
                                sx={{ cursor: 'pointer', color: '#FFAB00' }}
                            >
                                Contact Us.
                            </Link>{' '}
                        </Typography>
                    </Stack>
                    <Stack direction="row" width="30%" justifyContent="center">
                        <img src={fairviewLogo} style={{ width: '100px' }} />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
