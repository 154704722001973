import Basic from './Basic';

export default class Tenant extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        // the name of the tenant (generally this is a company or organization)
        this.name = obj.name || '';

        // the industry of the tenant
        this.industry = obj.industry || '';

        // the primary website domain of the tenant, used for url prefixing and approving users invited
        this.domain = obj.domain || '';

        // the physical mailing address of the tenant
        this.street1 = obj.street1 || '';
        this.street2 = obj.street2 || '';
        this.city = obj.city || '';
        this.state = obj.state || '';
        this.country = obj.country || '';
        this.zipCode = obj.zipCode || '';

        // the primary contact phone number to support the tenant (not used in MFA)
        this.phoneNumber = obj.phoneNumber || '';

        // the primary marketing website of the tenant
        this.website = obj.website || '';

        // the total annual budget (expected to be considered CapEx currently)
        this.budgets = obj.budgets || [];

        // the pool of all characteristics tracked for this tenant
        this.characteristics = obj.characteristics || [];

        // the pool of profiles used in this project
        this.profiles = obj.profiles || [];

        // the list of projects that this tenant is tracking
        this.projects = obj.projects || [];

        // the list of resources available on this tenant
        this.resources = obj.resources || [];

        // the list of integrations setup for this tenant (JIRA as an example)
        this.integrations = obj.integrations || [];

        // whether or not tenant is using sample data
        this.sampleDataInUse = obj.sampleDataInUse || false;

        // whether or not the tenant bills for their resources
        this.isRevenueTracked = obj.isRevenueTracked || false;

        // whether or not the tenant tracks projects by points by default
        this.trackProjectsByPoints = obj.trackProjectsByPoints || false;

        // duration in days after a tenant-owned work item is marked as 'Done' before it is archived
        this.archiveCompletedWorkItemThresholdInDays = obj.archiveCompletedWorkItemThresholdInDays;

        this.tier = obj.tier || 0;

        this.isSubscriptionCanceled = obj.isSubscriptionCanceled || false;

        this.subscriptionCanceledDate = obj.subscriptionCanceledDate
            ? new Date(obj.subscriptionCanceledDate)
            : null;
    }

    // the date to archive completed WIs
    get completedWorkItemArchiveDate() {
        const archiveTimerInMS = this.archiveCompletedWorkItemThresholdInDays * 24 * 60 * 60 * 1000;
        return new Date(new Date() - archiveTimerInMS);
    }

    // the annual spend accumulated from resources and expenses for all projects (from invoices)
    get actualAnnualSpend() {
        // todo: calc as rollup
        return 0;
    }

    // the annual spend accumulated from resources and expenses to date (from invoices)
    get actualYearToDateSpend() {
        // todo: calc as rollup based on today
        return 0;
    }

    // the estimated spend accumulated from resources and expenses to date (from work tracked)
    get estimatedYearToDateBudget() {
        // todo: calc as rollup based on today
        return 0;
    }

    // whether the estimated project expenses is over the annual budget
    get isEstimatedBudgetOverAnnualBudget() {
        // todo: figure this out
        return 0;
    }

    // whether the projected project expenses is over the annual budget
    get isProjectedBudgetOverAnnualBudget() {
        // todo: figure this out
        return 0;
    }

    // the projected spend accumulated from resources and expenses for the year (from work forecasted)
    get projectedAnnualSpend() {
        // todo: calc as rollup
        return 0;
    }
}
