import moment from 'moment';

const changeProjectEstimatedCompletionDate = (project, newDate) => {
    if (project.epics.length) {
        // compare by date
        project.epics.forEach((epic) => {
            if (
                moment(epic.estimatedCompletionDate).isSame(
                    project.estimatedCompletionDate,
                    'day',
                ) ||
                moment(newDate).isBefore(epic.estimatedCompletionDate)
            ) {
                epic.estimatedCompletionDate = newDate;
            }
        });
    }

    if (project.resourceTimelines.length) {
        // compare by date
        project.resourceTimelines.forEach((resourceTimeline) => {
            if (
                resourceTimeline.isPinned ||
                moment(resourceTimeline.endDate).isSame(
                    project.estimatedCompletionDate,
                    'day',
                ) ||
                moment(newDate).isBefore(resourceTimeline.endDate)
            ) {
                resourceTimeline.endDate = newDate;
            }
            if (moment(resourceTimeline.endDate).isSame(
                resourceTimeline.startDate,
                'day',
            )) {
                resourceTimeline.endDate = moment(resourceTimeline.endDate).add(1, 'day');
            }
        });
    }

    project.estimatedCompletionDate = newDate;
};

const changeProjectEstimatedStartDate = (project, newDate) => {
    if (project.epics.length) {
        // compare by date
        project.epics.forEach((epic) => {
            if (
                moment(epic.estimatedStartDate).isSame(
                    project.estimatedStartDate,
                    'day',
                ) ||
                moment(newDate).isAfter(epic.estimatedStartDate)
            ) {
                epic.estimatedStartDate = newDate;
            }
        });
    }

    if (project.resourceTimelines.length) {
        // compare by date
        project.resourceTimelines.forEach((resourceTimeline) => {
            if (
                resourceTimeline.isPinned ||
                moment(newDate).isAfter(resourceTimeline.startDate)
            ) {
                resourceTimeline.startDate = newDate;
            }
        });
    }

    project.estimatedStartDate = newDate;
};

const actionUtils = {
    changeProjectEstimatedCompletionDate,
    changeProjectEstimatedStartDate,
};

export default actionUtils;
