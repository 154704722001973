import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { projectActions } from 'System/State/ProjectState/reducer';
import ProjectFinancialsForm from 'System/Forms/ProjectForms/ProjectFinancialsForm';

export default function CostDetailModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.costDetailModal) || false;
    const project = useSelector(projectSelectors.selectedProject);
    const isRevenueTracked = useSelector(companySelectors.isRevenueTracked);

    const handleClose = () => {
        dispatch(modalActions.close('costDetailModal'));
    };

    const handleSubmit = (project) => {
        dispatch(projectActions.updateProject(project));
        handleClose();
    };

    return project ? (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Financial Details"
            aria-describedby="View financial details"
        >
            <DialogTitle>Financial Details</DialogTitle>
            <DialogContent>
                <ProjectFinancialsForm
                    project={project}
                    onSubmit={handleSubmit}
                    isRevenueTracked={isRevenueTracked}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="project-financials-form"
                >
                    Save Details
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
}
