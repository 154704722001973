import {
    Box,
    Button,
    Container,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    useMediaQuery,
} from '@mui/material';
import Logo from '../../assets/logo.svg';
import { makeStyles, useTheme } from '@mui/styles';
import RequestDemoButton from 'Marketing/Shared/RequestDemoButton/RequestDemoButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userSelectors } from 'System/State/UserState/selectors';
import { useEffect, useState } from 'react';
import { userActions } from 'System/State/UserState/reducer';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
        position: 'sticky',
        zIndex: 1,
        backgroundColor: '#fff',
    },
    link: {
        textDecoration: 'none',
        marginRight: 40,
        color: theme.palette.primaryDark.main,
        fontWeight: '600',
        [theme.breakpoints.down('md')]: {
            marginRight: 0,
        },

        '&:hover': {
            filter: 'brightness(225%)',
            cursor: 'pointer',
        },
    },
    button: {
        backgroundColor: '#009EE2',
        color: '#ffffff',
        fontSize: '12pt',
        fontWeight: '700',
        textAlign: 'center',
        minWidth: '12rem',
        textDecoration: 'none',
        display: 'inline-block',
        padding: '8px 20px',
        borderRadius: '10px',

        '&:hover': {
            backgroundColor: '#006BAF',
        },
    },
}));

export default function MarketingNavBar() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const user = useSelector(userSelectors.loggedInUser);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (!isMobile) {
            setAnchorEl(null);
        }
    }, [isMobile]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        dispatch(userActions.signoutUser()).then(() => navigate('/login'));
    };

    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Link to="/">
                        <img
                            src={Logo}
                            alt="Fairview"
                            style={{
                                height: '30px',
                                cursor: 'pointer',
                                flexGrow: 1,
                            }}
                        />
                    </Link>
                    <Toolbar disableGutters>
                        {isMobile ? (
                            <div>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-label="Dropdown menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MenuIcon
                                        htmlColor={
                                            theme.palette.primaryDark.main
                                        }
                                        fontSize="large"
                                    />
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    <MenuItem onClick={handleClose}>
                                        <Link
                                            className={classes.link}
                                            to="/pricing"
                                        >
                                            Pricing
                                        </Link>
                                    </MenuItem>
                                    {user && (
                                        <MenuItem onClick={handleClose}>
                                            <Link
                                                className={classes.link}
                                                to="/dashboard"
                                            >
                                                Dashboard
                                            </Link>
                                        </MenuItem>
                                    )}

                                    {!pathname.includes('/login') && (
                                        <MenuItem>
                                            <Link
                                                className={classes.link}
                                                to="/login"
                                            >
                                                Log In
                                            </Link>
                                        </MenuItem>
                                    )}
                                    {user && (
                                        <MenuItem
                                            onClick={handleLogout}
                                            className={classes.link}
                                        >
                                            Logout
                                        </MenuItem>
                                    )}
                                    {!user && (
                                        <MenuItem onClick={handleClose}>
                                            <Link
                                                className={classes.link}
                                                to="/signup"
                                            >
                                                Sign Up (free)
                                            </Link>
                                        </MenuItem>
                                    )}
                                    <MenuItem onClick={handleClose}>
                                        <Link
                                            className={classes.link}
                                            to="/request-demo"
                                        >
                                            Request Demo
                                        </Link>
                                    </MenuItem>
                                </Menu>
                            </div>
                        ) : (
                            <>
                                <Link className={classes.link} to="/signup">
                                    Sign Up for Free
                                </Link>
                                <Link className={classes.link} to="/pricing">
                                    Pricing
                                </Link>
                                {user ? (
                                    <>
                                        <Link
                                            onClick={handleLogout}
                                            className={classes.link}
                                        >
                                            Logout
                                        </Link>
                                        <Link
                                            className={classes.button}
                                            to="/dashboard"
                                        >
                                            Dashboard
                                        </Link>
                                    </>
                                ) : (
                                    <>
                                        {!pathname.includes('/login') && (
                                            <Link className={classes.link} to="/login">
                                                Log In
                                            </Link>
                                        )}
                                    </>
                                )}
                                <RequestDemoButton />
                            </>
                        )}
                    </Toolbar>
                </Stack>
            </Container>
        </Box>
    );
}
