import { AwsRum } from 'aws-rum-web';
import { useEffect } from 'react';

export default function CloudwatchRUM() {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            // Setup AWS Cloudwatch RUM
            // today not monitoring for performance for cost saving reasons only errors and xray
            try {
                const config = {
                    sessionSampleRate: 1,
                    guestRoleArn:
                        'arn:aws:iam::265266291751:role/RUM-Monitor-us-east-2-265266291751-3653064461861-Unauth',
                    identityPoolId:
                        'us-east-2:ba020003-6bc5-4aa8-a086-e6db09499ca1',
                    endpoint: 'https://dataplane.rum.us-east-2.amazonaws.com',
                    allowCookies: true,
                    enableXRay: true,
                    telemetries: [
                        'errors',
                        [
                            'http',
                            {
                                addXRayTraceIdHeader: true,
                                urlsToExclude: [
                                    /^https:\/\/sdk\.split\.io.*/,
                                    /^https:\/\/auth\.split\.io.*/,
                                    /^https:\/\/events\.split\.io.*/,
                                    /^https:\/\/edge\.fullstory\.com*/,
                                    /^https:\/\/rs\.fullstory\.com*/,
                                    /^https:\/\/api\.fairviewapp\.com\/bus\/*/,
                                ],
                            },
                        ],
                    ],
                };

                const APPLICATION_ID = '3983f001-59d4-4dee-9eed-003cee8b2b14';
                const APPLICATION_VERSION = '1.0.0';
                const APPLICATION_REGION = 'us-east-2';

                // eslint-disable-next-line no-unused-vars
                const awsRum = new AwsRum(
                    APPLICATION_ID,
                    APPLICATION_VERSION,
                    APPLICATION_REGION,
                    config,
                );
            } catch (error) {
                // Ignore errors thrown during CloudWatch RUM web client initialization
            }
        }
    }, []);

    return null;
}
