import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import CookieConsent from './System/UI/Components/CookieConsent/CookieConsent';
import GlobalAlert from './System/UI/Components/GlobalAlert/GlobalAlert';
import { LicenseInfo } from '@mui/x-license-pro';
import AppRouter from 'AppRouter/AppRouter';
import ScrollToTop from 'ScrollToTop';
import cognitoUtility from 'Utilities/cognito-utility';
import MobileBanner from 'System/UI/Components/MobileBanner/MobileBanner';
import { store } from 'System/State/store';
import { initSplitSdk } from '@splitsoftware/splitio-redux';
import { SPLIT_SDK_BROWSER_CONFIG } from 'Utilities/constants';
import LoadingSpinner from 'System/UI/Components/LoadingSpinner/LoadingSpinner';
import './App.css';
import GoogleAnalytics from 'System/Monitoring/GoogleAnalytics/GoogleAnalytics';
import FullStoryAnalytics from 'System/Monitoring/FullStoryAnalytics/FullStoryAnalytics';
import CloudwatchRUM from 'System/Monitoring/CloudwatchRUM/CloudwatchRUM';
import { userSelectors } from 'System/State/UserState/selectors';
import { coreActionsAsync } from 'System/State/CoreState/actions';
import { useLocalStorageTokensChangeListener } from 'Utilities/tokens-utility';
import { userActions } from 'System/State/UserState/reducer';

// Setup MUI Pro
LicenseInfo.setLicenseKey(
    '746b269d5032d0d1b79b963e490088c4Tz01MDE5NSxFPTE2OTM3NDg3MTgwOTksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

export default function App() {
    const dispatch = useDispatch();
    const isSplitReady = useSelector(featureFlagSelectors.isReady);
    const loggedInUser = useSelector(userSelectors.loggedInUser);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (!isSplitReady) {
            store.dispatch(
                initSplitSdk({
                    config: SPLIT_SDK_BROWSER_CONFIG,
                }),
            );
        }
    }, [isSplitReady]);

    useEffect(() => {
        const user = cognitoUtility.getCurrentUser();
        if (user) {
            const session = cognitoUtility.getCurrentUserSession(user);
            if (session) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
                dispatch(userActions.signoutUser());
            }
        } else {
            setIsAuthenticated(false);
        }
    }, [loggedInUser]);

    const handleTokenChange = (event) => {
        const tokensRemoved = event.oldValue !== null && event.newValue === null;
        if (tokensRemoved) {
            setIsAuthenticated(false);
            dispatch(coreActionsAsync.appPurge());
        }
    };
    useLocalStorageTokensChangeListener(handleTokenChange);

    return isSplitReady ? (
        <>
            <GoogleAnalytics />
            <FullStoryAnalytics />
            <CloudwatchRUM />

            <GlobalAlert />
            <LoadingSpinner />
            <BrowserRouter>
                <ScrollToTop />
                <Suspense fallback={<div>&nbsp;</div>}>
                    <AppRouter
                        isAuthenticated={isAuthenticated}
                    />
                </Suspense>
            </BrowserRouter>

            <CookieConsent />
            <MobileBanner />
        </>
    ) : null;
}
