import Basic from './Basic';
import WorkItemComment from './WorkItemComment';
import WorkItemHistory from './WorkItemHistory';

export default class WorkItem extends Basic {
    static todoStatusId = 0;
    static todoStatusName = 'Todo';
    static inProgressId = 1;
    static inProgressName = 'In Progress';
    static doneStatusId = 2;
    static doneStatusName = 'Done';
    static taskTypeId = 0;
    static featureTypeId = 1;
    static problemTypeId = 2;

    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.name = obj.name || '';

        this.description = obj.description || '';

        this.projectId = obj.projectId || null;

        this.projectEpicId = obj.projectEpicId || null;

        this.projectName = obj.projectName;

        this.projectKey = obj.projectKey;

        this.hoursComplete = obj.hoursComplete || 0;

        this.hoursEstimate = obj.hoursEstimate || 0;

        this.pointsEstimate = obj.pointsEstimate || 0;

        this.resolutionDate = obj.resolutionDate;

        this.integrationId = obj.integrationId;

        this.integrationKey = obj.integrationKey;

        this.projectPhaseId = obj.projectPhaseId || null;

        this.type = Number.isInteger(obj.type) ? obj.type : 1; // Feature Type

        this.typeName = obj.typeName || 'Task';

        this.status = Number.isInteger(obj.status) ? obj.status : 0; // Todo Status

        this.statusName = obj.statusName || 'Todo';

        this.priority = Number.isInteger(obj.priority) ? obj.priority : 2; // Medium Priority

        this.priorityName = obj.priorityName || 'Medium';

        this.rank = obj.rank || 0;

        this.number = obj.number || 0;

        this.comments = obj.comments?.length
            ? obj.comments.map((a) => new WorkItemComment(a))
            : [];

        this.labels = obj.labels || [];

        this.assignedSkilledResources = obj.assignedSkilledResources || [];

        this.resourceNames = obj.resourceNames || [];

        this.history = obj.history?.length
            ? obj.history.map((a) => new WorkItemHistory(a))
            : [];

        this.autoGenerateDescription = obj.autoGenerateDescription || false;
    }

    //make one for each status
    //make search to see if status is being used anywhere else

    // Status
    get todoStatusId() {
        return WorkItem.todoStatusId;
    }

    get inProgressId() {
        return WorkItem.inProgressId;
    }

    get doneStatusId() {
        return WorkItem.doneStatusId;
    }

    // Priority
    get highestPriorityId() {
        return 0;
    }

    get highPriorityId() {
        return 1;
    }

    get mediumPriorityId() {
        return 2;
    }

    get lowPriorityId() {
        return 3;
    }

    get lowestPriorityId() {
        return 4;
    }

    get hoursRemaining() {
        return this.hoursEstimate - this.hoursComplete;
    }
}
