import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    Stack,
} from '@mui/material';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { profileSelectors } from 'System/State/ProfileState/selectors';
import { profileActions } from 'System/State/ProfileState/reducer';
import ProfileForm from 'System/Forms/ProfileForm/ProfileForm';

export default function ProfileDetailModal() {
    const open = useSelector(modalSelectors.profileDetailModal) || false;
    const profile = useSelector(profileSelectors.selectedProfile);
    const dispatch = useDispatch();

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleClose = () => {
        dispatch(profileActions.unselectProfile());
        dispatch(modalActions.close('profileDetailModal'));
    };

    const handleDelete = () => {
        dispatch(profileActions.deleteProfile(profile));
        handleClose();
    };

    const handleSubmit = (values) => {
        dispatch(profileActions.updateProfile(values));
        handleClose();
    };

    return profile ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Job Profile Details"
            aria-describedby="Update job profile details"
        >
            <DialogTitle>Job Profile Details</DialogTitle>
            <DialogContent>
                <ProfileForm profile={profile} onSubmit={handleSubmit} />

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="Job Profile"
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        handleDelete(profile);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <Button
                        variant="outlined"
                        color="tertiary"
                        onClick={() => {
                            setDeleteModalOpen(true);
                        }}
                    >
                        Delete
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            form="profile-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
