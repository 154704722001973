import { createSelector } from 'reselect';
import { userSelectors } from 'System/State/UserState/selectors';

const coreSelector = (state) => state.core;
const { isAllowedFinancials } = userSelectors;

const visibilityOptions = createSelector(coreSelector, (core) => {
    if (core.visibilityOptions) {
        return core.visibilityOptions;
    } else {
        return null;
    }
});

const message = createSelector(coreSelector, (core) => {
    if (!!core?.message || core?.message === '') {
        return core.message;
    } else {
        return null;
    }
});

const messageType = createSelector(coreSelector, (core) => {
    if (core?.messageType) {
        return core.messageType;
    } else {
        return null;
    }
});

const isMessageVisible = createSelector(
    coreSelector,
    (core) => core?.isMessageVisible,
);

const isLoading = createSelector(coreSelector, (core) => {
    if (!core?.isLoading) {
        return;
    }
    return Object.values(core.isLoading).some((val) => val);
});

const hasPendingRequest = createSelector(coreSelector, (core) => {
    return core?.hasPendingRequest;
});

const showGlobalFinancialsIfAllowed = createSelector(
    coreSelector,
    isAllowedFinancials,
    (core, isAllowedFinancials) =>
        core?.showGlobalFinancials && isAllowedFinancials,
);

const walkThroughVisible = createSelector(
    coreSelector,
    (core) => core?.walkThroughVisible,
);

export const coreSelectors = {
    visibilityOptions,
    message,
    messageType,
    isLoading,
    hasPendingRequest,
    isMessageVisible,
    showGlobalFinancialsIfAllowed,
    walkThroughVisible,
};
