import {
    TextField,
    MenuItem,
    Stack,
    Autocomplete,
    IconButton,
    Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { userSelectors } from 'System/State/UserState/selectors';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useEffect, useState } from 'react';
import { epicActions } from 'System/State/ProjectState/reducer';

export default function EpicPicker({ onSelect }) {
    const dispatch = useDispatch();
    const [value, setValue] = useState(null);
    const isAllowedAdmin = useSelector(userSelectors.isAllowedAdmin);
    const projectEpics = useSelector(projectSelectors.selectedProjectEpics);
    const selectedEpic = useSelector(projectSelectors.selectedEpic);

    const handleEpicChange = (epic) => {
        onSelect(epic?.id);
        setValue(epic);
    };

    const handleOpenNewEpicModal = () => {
        dispatch(epicActions.setIsNewEpicForWorkItem(true));
        dispatch(modalActions.open('newEpicModal'));
    };

    useEffect(() => {
        if (!!selectedEpic && selectedEpic.id !== value?.id) {
            setValue(selectedEpic);
        }
        if (!selectedEpic) {
            setValue(null);
        }
    }, [selectedEpic]);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            width="100%"
        >
            <Autocomplete
                disabled={!projectEpics}
                options={projectEpics ? projectEpics : []}
                value={value}
                getOptionLabel={(option) => option.name || ''}
                id="auto-complete"
                onChange={(_, epic) => {
                    handleEpicChange(epic);
                }}
                isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                }
                autoComplete
                noOptionsText={
                    isAllowedAdmin ? (
                        <MenuItem
                            onMouseDown={() =>
                                handleOpenNewEpicModal()
                            }
                        >
                            Add Epic
                        </MenuItem>
                    ) : (
                        'No Options Found'
                    )
                }
                fullWidth
                includeInputInList
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label="Select an epic"
                    />
                )}
            />
            {isAllowedAdmin && (
                <IconButton
                    onClick={() =>
                        handleOpenNewEpicModal()
                    }
                    disabled={!projectEpics}
                >
                    <Tooltip
                        title="Can't find what you're looking for? Click here to add a new epic to this project."
                        aria-label="add-new-epic"
                        enterDelay={300}
                        placement="top"
                    >
                        <AddCircleOutlineIcon />
                    </Tooltip>
                </IconButton>
            )}
        </Stack>
    );
}
