import { useCallback, useState } from 'react';
import {
    Popover,
    Stack,
    TextField,
    IconButton,
    CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useDispatch } from 'react-redux';
import { coreActionsAsync } from 'System/State/CoreState/actions';
import { useEffect } from 'react';
import { ImagePreview } from '../ImagePreview';

export function findImageEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'image'
        );
    }, callback);
}

export function ImageBlock(props) {
    const dispatch = useDispatch();
    const [src, setSrc] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const { id, filename } = props.contentState
        .getEntity(props.entityKey)
        .getData();

    const getPresignedObjectUrl = async () => {
        const attachment = {
            attachmentId: id,
        };

        const downloadPresignedUrl = await dispatch(
            coreActionsAsync.requestAttachmentDownloadLink(attachment),
        );

        setSrc(downloadPresignedUrl?.payload.data);
    };

    useEffect(() => {
        if (src === null) {
            getPresignedObjectUrl();
        }
    }, [props.contentState, props.entityKey]);

    return (
        <>
            {src === null ? (
                <CircularProgress size="2rem" />
            ) : (
                <>
                    <img
                        src={src}
                        alt={filename}
                        style={{
                            maxWidth: '400px',
                            cursor: 'pointer',
                        }}
                        onClick={() => setPreviewOpen(true)}
                    />
                    <ImagePreview
                        src={src}
                        fileName={filename}
                        open={previewOpen}
                        setOpen={setPreviewOpen}
                    />
                </>
            )}
        </>
    );
}

export function HistoryImageBlock(props) {
    const { filename, mimetype } = useCallback(() => {
        return props.contentState.getEntity(props.entityKey).getData();
    }, [props.entityKey, props.contentState])();

    return (
        <>
            [{mimetype}: {filename}]
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        maxWidth: 350,
        zIndex: 100000,
    },
    button: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    input: {
        display: 'none',
    },
}));

export default function ImagePopover({ anchor, setAnchor, handleAddedFiles }) {
    const [data, setData] = useState({
        file: null,
    });
    const classes = useStyles();

    const handleClose = () => {
        setData({
            file: null,
        });
        setAnchor(null);
    };

    const handleAddImage = () => {
        if (data?.file) {
            const files = [data.file];
            handleAddedFiles(files);
            handleClose();
        }
    };

    return (
        <Popover
            anchorEl={anchor}
            open={anchor !== null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box className={classes.root}>
                <Stack direction="row" alignItems="center">
                    <TextField
                        sx={{ width: '100%' }}
                        disabled
                        value={data.file?.name || ''}
                        placeholder="Click icon to attach image"
                    />

                    <input
                        accept="image/*"
                        className={classes.input}
                        id="image-popover-button"
                        type="file"
                        onChange={(e) => {
                            setData({
                                ...data,
                                file: e.target.files[0],
                            });
                        }}
                    />
                    <label htmlFor="image-popover-button">
                        <IconButton
                            // color="primary"
                            aria-label="upload image"
                            component="span"
                        >
                            <AttachFileIcon className={classes.button} />
                        </IconButton>
                    </label>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <IconButton onClick={handleClose}>
                        <CloseIcon
                            fontSize="small"
                            className={classes.button}
                        />
                    </IconButton>
                    <IconButton onClick={handleAddImage}>
                        <DoneIcon fontSize="small" className={classes.button} />
                    </IconButton>
                </Stack>
            </Box>
        </Popover>
    );
}
