import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { phaseActions } from 'System/State/ProjectState/reducer';
import NewProjectPhaseForm from './NewProjectPhaseForm';

export default function NewProjectPhaseModal() {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(modalSelectors.newProjectPhaseModal);
    const selectedProject = useSelector(projectSelectors.selectedProject);

    const handleClose = () => {
        dispatch(modalActions.close('newProjectPhaseModal'));
    };

    const handleSubmit = (phase) => {
        phase.projectId = selectedProject.id;
        dispatch(phaseActions.createProjectPhase(phase));
        handleClose();
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="New Phase"
            aria-describedby="Add a new project phase"
        >
            <DialogTitle>Add Phase</DialogTitle>

            <DialogContent>
                <NewProjectPhaseForm onSubmit={handleSubmit} />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="new-project-phase-form"
                >
                    Save Phase
                </Button>
            </DialogActions>
        </Dialog>
    );
}
