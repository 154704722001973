import { Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { coreSelectors } from 'System/State/CoreState/selectors';
import NonResourceUserPicker from 'System/UI/Pickers/NonResourceUserPicker/NonResourceUserPicker';
import ProfilePicker from 'System/UI/Pickers/ProfilePicker/ProfilePicker';
import TeamPickerSingle from 'System/UI/Pickers/TeamPicker/TeamPickerSingle';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import { companySelectors } from 'System/State/CompanyState/selectors';
import Resource from 'Models/Resource';
import ReadOnlyField from 'System/UI/Components/ReadOnlyField/ReadOnlyField';
import Form from 'System/Forms/Components/Form';
import { profileSelectors } from 'System/State/ProfileState/selectors';
import { useEffect, useRef } from 'react';
import { teamSelectors } from 'System/State/TeamState/selectors';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import { PaymentTier } from 'Models/PaymentTiers';

export default function ResourceForm({
    resource = new Resource(),
    onSelectProfile,
    onSelectTeam,
    onSubmit,
}) {
    const showFinancials = useSelector(
        coreSelectors.showGlobalFinancialsIfAllowed,
    );
    const isRevenueTracked = useSelector(companySelectors.isRevenueTracked);
    const selectedProfile = useSelector(profileSelectors.selectedProfile);
    const selectedTeam = useSelector(teamSelectors.selectedTeam);
    const tenant = useSelector(companySelectors.tenant);
    const billingFlag = useSelector(featureFlagSelectors.billingFlag);

    const resourceSchema = yup.object().shape({
        name: yup.string().required('Required'),
        profileId: yup.string().required('Required'),
        profileName: yup.string().required('Required'),
        hoursPerDay: yup
            .number()
            .typeError('Must be a number')
            .required('Required')
            .min(0, 'Must be a positive number'),
        daysPerWeek: yup
            .number()
            .typeError('Must be a number')
            .required('Required')
            .min(0, 'Must be a positive number'),
        defaultAverageVelocity: yup
            .number()
            .typeError('Must be a number')
            .nullable()
            .min(0, 'Must be a positive number'),
        defaultHourlyCostRate: showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
            (!billingFlag))
            ? yup
                .number()
                .typeError('Must be a number')
                .nullable()
                .min(0, 'Must be a positive number')
            : null,
        defaultHourlyBillRate: showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
            (!billingFlag))
            ? yup
                .number()
                .typeError('Must be a number')
                .nullable()
                .min(0, 'Must be a positive number')
            : null,
        actualSalary: showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
            (!billingFlag))
            ? yup
                .number()
                .typeError('Must be a number')
                .nullable()
                .min(0, 'Must be a positive number')
            : null,
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: resource,
        validationSchema: resourceSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const handleSelectLinkedUser = (e) => {
        if (e.target.value) {
            setFieldValue('userId', e.target.value, false);
        } else {
            setFieldValue('userId', null, false);
        }
    };

    const handleSelectProfile = () => {
        setFieldValue(
            'profileId',
            selectedProfile?.id ? selectedProfile.id : '',
            false,
        );
        setFieldValue(
            'profileName',
            selectedProfile?.name ? selectedProfile.name : '',
            false,
        );
        setFieldValue(
            'defaultHourlyCostRate',
            selectedProfile?.standardHourlyCostRate
                ? selectedProfile.standardHourlyCostRate
                : 0,
            false,
        );
        setFieldValue(
            'defaultHourlyBillRate',
            selectedProfile?.standardHourlyBillRate
                ? selectedProfile.standardHourlyBillRate
                : 0,
            false,
        );
        setFieldValue(
            'actualSalary',
            values.actualSalary
                ? values.actualSalary
                : selectedProfile?.actualSalary,
            false,
        );
        setFieldValue(
            'color',
            selectedProfile?.color ? selectedProfile.color : '',
            false,
        );
    };

    const handleSelectTeam = () => {
        if (selectedTeam) {
            setFieldValue('teamId', selectedTeam.id, false);
            setFieldValue('teamName', selectedTeam.name, false);
        }
        if (selectedTeam === null) {
            setFieldValue('teamId', selectedTeam, false);
            setFieldValue('teamName', selectedTeam, false);
        }
    };

    useEffect(() => {
        handleSelectProfile();
    }, [selectedProfile]);

    useEffect(() => {
        handleSelectTeam();
    }, [selectedTeam]);

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            if (values.profileId) {
                onSelectProfile(values.profileId);
            }
            if (values.teamId) {
                onSelectTeam(values.teamId);
            }
        }
    }, [values.profileId, values.teamId]);

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return resource ? (
        <Form onSubmit={handleSubmit} formId="resource-form">
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            <Stack spacing={2}>
                <NonResourceUserPicker
                    name="userId"
                    label="Link to an Existing User"
                    selectedUser={values.userId}
                    onChange={handleSelectLinkedUser}
                />
                <TextField
                    name="name"
                    label="Name"
                    required
                    autoFocus
                    value={values.name}
                    fullWidth
                    error={showError('name')}
                    helperText={getError('name')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Stack spacing={1} direction="row">
                    <ProfilePicker
                        required={true}
                        selectedProfile={selectedProfile}
                        name="profile"
                        id="profile"
                    />

                    <TeamPickerSingle selectedTeam={selectedTeam} />
                </Stack>

                <Stack spacing={1} direction="row">
                    <DatePicker
                        name="hireDate"
                        format="MM/DD/YYYY"
                        label="Hire Date"
                        fullWidth
                        error={showError('hireDate')}
                        helperText={getError('hireDate')}
                        value={values.hireDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <DatePicker
                        name="terminationDate"
                        format="MM/DD/YYYY"
                        label="Termination Date"
                        fullWidth
                        error={showError('terminationDate')}
                        helperText={getError('terminationDate')}
                        value={values.terminationDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <Stack spacing={1} direction="row">
                    <TextField
                        name="company"
                        label="Company Employed By"
                        fullWidth
                        value={values.company || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="defaultAverageVelocity"
                        label="Avg. Velocity/Sprint"
                        type="number"
                        fullWidth
                        error={showError('defaultAverageVelocity')}
                        helperText={getError('defaultAverageVelocity')}
                        value={values.defaultAverageVelocity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <Stack direction="row" spacing={1}>
                    <TextField
                        name="hoursPerDay"
                        label="Hours/Day"
                        type="number"
                        fullWidth
                        required
                        error={showError('hoursPerDay')}
                        helperText={getError('hoursPerDay')}
                        value={values.hoursPerDay}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="daysPerWeek"
                        label="Days Worked/Week"
                        type="number"
                        fullWidth
                        required
                        value={values.daysPerWeek}
                        error={showError('daysPerWeek')}
                        helperText={getError('daysPerWeek')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    {showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
                        (!billingFlag)) ? (
                        <>
                            <TextField
                                name="defaultHourlyCostRate"
                                label="Hourly Expense"
                                type="number"
                                fullWidth
                                error={showError('defaultHourlyCostRate')}
                                helperText={getError('defaultHourlyCostRate')}
                                value={values.defaultHourlyCostRate}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                label="Actual Salary"
                                name="actualSalary"
                                type="number"
                                fullWidth
                                error={showError('actualSalary')}
                                helperText={getError('actualSalary')}
                                value={values.actualSalary}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            {isRevenueTracked && (
                                <TextField
                                    name="defaultHourlyBillRate"
                                    label="Hourly Revenue"
                                    type="number"
                                    fullWidth
                                    error={showError('defaultHourlyBillRate')}
                                    helperText={getError(
                                        'defaultHourlyBillRate',
                                    )}
                                    value={values.defaultHourlyBillRate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            )}
                        </>
                    ) : null}
                </Stack>

                <Stack spacing={1} direction="row">
                    <ReadOnlyField
                        label="Total Days"
                        value={resource.totalDays.toLocaleString()}
                    />
                    <ReadOnlyField
                        label="Total Hours"
                        value={resource.totalHours.toLocaleString()}
                    />

                    {showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
                        (!billingFlag)) ? (
                        <>
                            {isRevenueTracked && (
                                <ReadOnlyField
                                    label="Total Revenue"
                                    value={resource.totalRevenue.toLocaleString()}
                                />
                            )}

                            <ReadOnlyField
                                label="Total Expense"
                                value={resource.totalCost.toLocaleString()}
                            />
                        </>
                    ) : null}
                </Stack>
            </Stack>
        </Form>
    ) : null;
}
