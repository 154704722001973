import { Box, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { projectActions } from 'System/State/ProjectState/reducer';
import TagInput from 'System/UI/Components/TagInput/TagInput';
import ActionWarningModal from 'System/Modals/ActionWarningModal/ActionWarningModal';
import { useState } from 'react';
import { resourceSelectors } from 'System/State/ResourceState/selectors';
import { companySelectors } from 'System/State/CompanyState/selectors';
import DisplayUtils from 'Utilities/display-utils';

export default function ProjectTags() {
    const dispatch = useDispatch();

    const projectTags = useSelector(projectSelectors.selectedProjectTags).sort(
        (a, b) => a.tag.name.localeCompare(b.tag.name),
    );
    const availableLinesOfBusiness = useSelector(
        projectSelectors.availableLOBNamesForSelectedProject,
    ).sort();
    const availableCharacteristics = useSelector(
        projectSelectors.availableCharacteristicsNamesForSelectedProject,
    )
        .map((str) => str.charAt(0).toUpperCase() + str.substr(1))
        .sort();
    const availableLabels = useSelector(
        projectSelectors.availableLabelNamesForSelectedProject,
    ).sort();
    const selectedProject = useSelector(projectSelectors.selectedProject);
    const allResources = useSelector(resourceSelectors.allResources);
    const usersList = useSelector(companySelectors.usersList);
    const [isActionWarningModalOpen, setIsActionWarningModalOpen] =
        useState(false);
    const [conflictingResourceNames, setConflictingResourceNames] = useState(
        [],
    );
    const [characteristicToAdd, setcharacteristicToAdd] = useState();

    const handleRemove = ({ tag, type }) => {
        switch (type) {
            case 'lineOfBusiness':
                dispatch(
                    projectActions.removeLOBFromProject({
                        ...tag,
                        projectId: selectedProject.id,
                    }),
                );
                break;
            case 'label':
                dispatch(
                    projectActions.removeLabelFromProject({
                        ...tag,
                        projectId: selectedProject.id,
                    }),
                );
                break;
            case 'characteristic':
                dispatch(
                    projectActions.removeCharacteristicFromProject({
                        ...tag,
                        projectId: selectedProject.id,
                    }),
                );
                break;
            default:
                break;
        }
    };

    const checkForConflictsAndSubmit = (characteristicName) => {
        if (hasConflictingCharacteristics(characteristicName)) {
            setIsActionWarningModalOpen(true);
        } else {
            handleSubmit(characteristicName);
        }
    };

    function hasConflictingCharacteristics(characteristicName) {
        const resources = allResources.filter((resource) =>
            selectedProject.resourceTimelines.some(
                (rtl) => rtl.skilledResourceId === resource.id,
            ),
        );
        const users = usersList.filter((user) =>
            resources.some((resource) => resource.userId === user.id),
        );

        const matchingUsers = users.filter((user) =>
            user.characteristics.some(
                (char) =>
                    char.name.toLowerCase() ===
                        characteristicName.toLowerCase() &&
                    char.type === 'Dreaded',
            ),
        );

        if (!matchingUsers.length) {
            return false;
        }

        const conflictingNames = matchingUsers.map(
            (user) => `${user.firstName} ${user.lastName}`,
        );
        setConflictingResourceNames(conflictingNames);
        setcharacteristicToAdd(characteristicName);
        return true;
    }

    const handleSubmit = (characteristicName) => {
        dispatch(
            projectActions.addCharacteristicToProject({
                name: characteristicName,
                projectId: selectedProject.id,
            }),
        );
        setIsActionWarningModalOpen(false);
    };

    const insertedResourcesString = () => {
        if (conflictingResourceNames.length) {
            if (conflictingResourceNames.length === 1) {
                return conflictingResourceNames[0] + ' has ';
            } else if (conflictingResourceNames.length === 2) {
                return `${conflictingResourceNames[0]} and ${conflictingResourceNames[1]} have `;
            } else if (conflictingResourceNames.length > 2) {
                let result = '';
                for (let i = 0; i < conflictingResourceNames.length - 1; i++) {
                    result += conflictingResourceNames[i] + ', ';
                }
                result +=
                    'and ' +
                    conflictingResourceNames[
                        conflictingResourceNames.length - 1
                    ] +
                    ' have ';
                return result;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    return (
        <div>
            <Stack spacing={1}>
                <Stack direction="row" spacing={1}>
                    <TagInput
                        type="characteristic"
                        options={availableCharacteristics}
                        onAdd={(val) => {
                            checkForConflictsAndSubmit(val);
                        }}
                    />
                    <TagInput
                        type="label"
                        options={availableLabels}
                        onAdd={(val) => {
                            dispatch(
                                projectActions.addLabelToProject({
                                    name: val,
                                    projectId: selectedProject.id,
                                }),
                            );
                        }}
                    />
                    <TagInput
                        type="department"
                        options={availableLinesOfBusiness}
                        onAdd={(val) => {
                            dispatch(
                                projectActions.addLOBToProject({
                                    name: val,
                                    projectId: selectedProject.id,
                                }),
                            );
                        }}
                    />
                </Stack>

                <Stack direction="row" alignItems="flex-start">
                    <Box>
                        {projectTags?.map(({ tag, type }) => (
                            <Chip
                                key={tag.id}
                                sx={{
                                    margin: 1,
                                    background: (theme) =>
                                        theme.palette[type]?.main,
                                    '& .MuiChip-deleteIcon': {
                                        color: (theme) =>
                                            theme.palette[type]?.dark,
                                    },
                                }}
                                label={DisplayUtils.toTitleCase(tag.name)}
                                onDelete={() => handleRemove({ tag, type })}
                            />
                        ))}
                    </Box>
                </Stack>
            </Stack>
            <ActionWarningModal
                isModalOpen={isActionWarningModalOpen}
                customTitle="Characteristics Mismatch"
                customMessage={`${insertedResourcesString()} ${characteristicToAdd} as a dreaded characteristic of theirs.`}
                customCancelMessage="Change Characteristic"
                customProceedMessage="Ignore"
                confirmAction={() => handleSubmit(characteristicToAdd)}
                cancelAction={() => setIsActionWarningModalOpen(false)}
            />
        </div>
    );
}
