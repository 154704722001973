import Basic from './Basic';

export default class Integration extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.tenantId = obj.tenantId || '';

        // the friendly name of the integration
        this.name = obj.name || '';

        this.description = obj.description || '';

        // the primary website domain of the refernced by the integration
        this.domain = obj.domain || '';

        // specifies whether the integration is currently enabled or not
        this.isActive = obj.isActive || false;

        this.lastSynced = obj.lastSynced || null;

        this.integrationType = obj.integrationType || '';

        //Serialized JSON blob that holds integration specific fields
        this.metaInformationJson = JSON.parse(obj.metaInformationJson) || '';
    }
}
