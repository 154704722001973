import ScrollToTop from 'ScrollToTop';
import { Outlet } from 'react-router-dom';
import MarketingFooter from 'Layout/Footer/MarketingFooter';
import MarketingNavBar from './MarketingNavBar';
import { Box } from '@mui/material';
import Modals from 'System/Modals/Modals';

export default function MarketingLayout() {
    return (
        <Box
            backgroundColor="#fff"
            sx={{ background: 'linear-gradient(#fbf9f2, #fff)' }}
        >
            <ScrollToTop />
            <MarketingNavBar />
            <Outlet />
            <MarketingFooter />
            <Modals />
        </Box>
    );
}
