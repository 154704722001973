import { TextField, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';

export default function LevelOfEffortPicker({
    selectedLevelOfEffort: selectedLevelOfEffort,
    onChange,
}) {
    const options = useSelector(projectSelectors.levelOfEffortOptions);

    return (
        <TextField
            name="estimatedLevelOfEffort"
            value={selectedLevelOfEffort}
            label="Est. Level of Effort"
            select
            SelectProps={{
                labelId: 'level-of-effort-label',
                displayEmpty: true,
            }}
            onChange={onChange}
            fullWidth
        >
            {options?.map((option) => (
                <MenuItem
                    key={option.levelOfEffort}
                    value={option.levelOfEffort}
                >
                    {option.levelOfEffortName}
                </MenuItem>
            ))}
        </TextField>
    );
}
