import Basic from './Basic';

export default class Milestone extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        // specifies which project this milestone belongs to
        this.projectId = obj.projectId || null;

        // name of the milestone (displayed on the canvas)
        this.name = obj.name || '';

        // the date the milestone is due
        this.targetEventDate = obj.targetEventDate
            ? new Date(obj.targetEventDate)
            : new Date();

        // whether the milestone was achieved or not?
        this.isAchieved = obj.isAchieved || false;

        // if this milestone is a financial milestone, how much will be
        // accumulated or spent at this milestone
        this.financialValue = obj.financialValue || 0;

        // track the history of changes for this milestone
        this.history = obj.history || [];

        // support integrations (JIRA)
        this.integrationId = obj.integrationid || null;
        this.integrationKey = obj.integrationKey || null;
    }
}
