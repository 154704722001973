import { insightsActionsAsync } from './actions';
import { createSlice } from '@reduxjs/toolkit';
import {
  getItemByItemId,
  getUpdatedList,
  addToOrUpdateInList,
  removeItemFromListById
} from 'Utilities/reducer-utils';

const {
  getInsights,
  getInsightDetail,
  changeInsightPriority,
  hideUserInsight,
  showUserInsight
} = insightsActionsAsync;

const initialState = {
  list: []
};

export const InsightsSlice = createSlice({
  name: 'insights',
  initialState: initialState,
  reducers: {
  },
  extraReducers: {
      [getInsights.fulfilled]: (state, action) => {
        state.list = action.payload.data;
      },
      [getInsightDetail.fulfilled]: (state, action) => {
        state.list = addToOrUpdateInList(state.list, action.payload.data);
      },
      [getInsightDetail.rejected]: (state, action) => {
        state.list = removeItemFromListById(state.list, action.meta.arg);
      },
      [changeInsightPriority.fulfilled]: (state, action) => {
        if (action.payload.insightId) {
          const foundInsight = getItemByItemId(
              state.list,
              action.payload.insightId,
          );

          const updatedInsight = {
            ...foundInsight,
            priority: action.payload.priority
          };

          state.list = getUpdatedList(state.list, updatedInsight);  
        }
      },
      [hideUserInsight.fulfilled]: (state, action) => {
        if (action.payload.insightId) {
          const foundInsight = getItemByItemId(
              state.list,
              action.payload.insightId,
          );

          const updatedInsight = {
            ...foundInsight,
            isHidden: action.payload.isHidden
          };

          state.list = getUpdatedList(state.list, updatedInsight);
        }
      },
      [showUserInsight.fulfilled]: (state, action) => {
        if (action.payload.insightId) {
          const foundInsight = getItemByItemId(
              state.list,
              action.payload.insightId,
          );

          const updatedInsight = {
            ...foundInsight,
            isHidden: action.payload.isHidden
          };

          state.list = getUpdatedList(state.list, updatedInsight);
        }
      }
  },
});

export const insightsActions = {
  ...insightsActionsAsync
};

export default InsightsSlice.reducer;