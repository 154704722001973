import { useState } from 'react';
import KeyResult from 'Models/KeyResult';
import {
    Checkbox,
    FormControl,
    FormGroup,
    FormLabel,
    Stack,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles({
    keyResults: {
        marginTop: '15px',
        marginBottom: '30px',
        width: '100%',
    },
    keyResultContainer: {
        width: '100%',
    },
    keyResultInput: {
        minWidth: '420px',
    },
});

export default function KeyResults(props) {
    const { value, onChange, name } = props;
    const classes = useStyles();
    const [keyResults, changeKeyResults] = useState(
        value?.map((item) => new KeyResult(item)) || [],
    );

    const addKeyResult = () => {
        changeKeyResults([...keyResults, new KeyResult()]);
    };

    const deleteKeyResult = (id) => {
        const updatedKeyResults = [
            ...keyResults.filter((item) => {
                return item.id !== id;
            }),
        ];
        changeKeyResults(updatedKeyResults);

        onChange({
            target: {
                name: name,
                value: updatedKeyResults,
            },
        });
    };

    const handleCheckboxChange = (e) => {
        const updatedKeyResults = [
            ...keyResults.map((item) => {
                //Removing 'Checkbox' from id
                if (item.id === e.target.id.split('Checkbox')[0]) {
                    return new KeyResult({
                        ...item,
                        isAccomplished: e.target.checked,
                    });
                } else {
                    return item;
                }
            }),
        ];
        changeKeyResults(updatedKeyResults);

        onChange({
            target: {
                name: name,
                value: updatedKeyResults,
            },
        });
    };

    const handleInputChange = (e) => {
        const updatedKeyResults = [
            ...keyResults.map((item) => {
                //Removing 'Input' from id
                if (item.id === e.target.id.split('Input')[0]) {
                    return new KeyResult({
                        ...item,
                        title: e.target.value,
                    });
                } else {
                    return item;
                }
            }),
        ];

        changeKeyResults(updatedKeyResults);

        onChange({
            target: {
                name: name,
                value: updatedKeyResults,
            },
        });
    };

    return (
        <div className={classes.keyResults}>
            <FormControl className={classes.keyResultContainer}>
                <FormLabel>
                    <Stack direction="row" alignItems="center">
                        <Tooltip
                            placement="top"
                            arrow
                            title="Success criteria are measurable target metrics to track that align with the goals and success of the project."
                        >
                            <Typography variant="h5">Key Results</Typography>
                        </Tooltip>
                        <IconButton
                            aria-label="Add"
                            onClick={addKeyResult}
                            size="large"
                        >
                            <AddCircleOutlineIcon></AddCircleOutlineIcon>
                        </IconButton>
                    </Stack>
                </FormLabel>

                <FormGroup>
                    {keyResults
                        .sort((a, b) => {
                            if (a.title < b.title) {
                                return -1;
                            }
                            if (a.title > b.title) {
                                return 1;
                            }
                            return 0;
                        })
                        .map((keyResult) => {
                            return (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                    key={keyResult.id}
                                >
                                    <Checkbox
                                        id={keyResult.id + 'Checkbox'}
                                        checked={keyResult.isAccomplished}
                                        value={keyResult.title}
                                        onChange={handleCheckboxChange}
                                    />
                                    <TextField
                                        className={classes.keyResultInput}
                                        id={keyResult.id + 'Input'}
                                        value={keyResult.title}
                                        multiline
                                        variant="standard"
                                        inputProps={{ maxLength: 500 }}
                                        onChange={handleInputChange}
                                    ></TextField>
                                    <IconButton
                                        aria-label="Delete"
                                        onClick={deleteKeyResult.bind(
                                            this,
                                            keyResult.id,
                                        )}
                                        size="large"
                                    >
                                        <DeleteIcon></DeleteIcon>
                                    </IconButton>
                                </Stack>
                            );
                        })}
                </FormGroup>
            </FormControl>
        </div>
    );
}
