import Basic from './Basic';

export default class WorkItemComment extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.comment = obj.comment || '';

        this.workItemId = obj.workItemId;
    }
}
