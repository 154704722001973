import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import ResourceTimeline from 'Models/ResourceTimeline';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { projectActions, resourceTimelineActions } from 'System/State/ProjectState/reducer';
import NewResourceTimelineForm from 'System/Forms/ResourceTimelineForms/NewResourceTimelineForm';
import { resourceActions } from 'System/State/ResourceState/reducer';
import ActionWarningModal from 'System/Modals/ActionWarningModal/ActionWarningModal';
import { useEffect, useRef, useState } from 'react';
import { resourceSelectors } from 'System/State/ResourceState/selectors';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { paneActions } from 'System/State/PaneState/reducer';
import { profileSelectors } from 'System/State/ProfileState/selectors';
import { projectActionsAsync } from 'System/State/ProjectState/actions';
import { profileActions } from 'System/State/ProfileState/reducer';
import MismatchedDatesModal from '../MismatchedDatesModal/MismatchedDatesModal';

export default function NewProjectResourceTimelineModal() {
    const dispatch = useDispatch();
    const open =
        useSelector(modalSelectors.newProjectResourceTimelineModal) || false;
    const selectedProject = useSelector(projectSelectors.selectedProject);
    const selectedResource = useSelector(resourceSelectors.selectedResource);
    const selectedProfile = useSelector(profileSelectors.selectedProfile);
    const allResources = useSelector(resourceSelectors.allResources);
    const usersList = useSelector(companySelectors.usersList);
    const [isActionWarningModalOpen, setIsActionWarningModalOpen] =
        useState(false);
    const [isMismatchedDatesModalOpen, setIsMismatchedDatesModalOpen] =
        useState(false);
    const [conflictingCharacteristics, setConflictingCharacteristics] =
        useState([]);
    const [newResourceTimeline, setNewResourceTimeline] = useState();
    const [quantity, setQuantity] = useState(0);
    const [startDate, setStartDate] = useState(selectedProject?.startDate);
    const [endDate, setEndDate] = useState(selectedProject?.endDate);
    const [isPinned, setIsPinned] = useState(
        !!selectedProject?.startDate && !!selectedProject.endDate,
    );

    const newProjectResourceTimeline = new ResourceTimeline({
        projectId: selectedProject?.id,
        projectName: selectedProject?.name,
        startDate: selectedProject?.estimatedStartDate,
        endDate: selectedProject?.estimatedCompletionDate,
        isPinned: true, // when creating new, setting as default, but not in model intentionally
    });

    function hasConflictingCharacteristics(skilledResourceId) {
        const resource = allResources.find(
            (resource) => resource?.id === skilledResourceId,
        );
        if (!resource) {
            return false;
        }
        const user = usersList.find((user) => user.id === resource?.userId);
        if (!user) {
            return false;
        }

        const dreadedCharacteristics = user.characteristics.filter(
            (characteristic) => characteristic.type === 'Dreaded',
        );

        if (!dreadedCharacteristics.length) {
            return false;
        }

        const matchingCharacteristics = selectedProject.characteristics.filter(
            (projectCharacteristic) => {
                return dreadedCharacteristics.some((dreadedCharacteristic) => {
                    return (
                        projectCharacteristic.id ===
                        dreadedCharacteristic.characteristicId
                    );
                });
            },
        );

        if (matchingCharacteristics.length) {
            setConflictingCharacteristics(matchingCharacteristics);
            return true;
        }

        return false;
    }

    const hasConflictingDates = (resource) => {
        const { startDate, endDate } = resource;
        if (startDate < selectedProject?.estimatedStartDate) {
            // api validation will handle this case for now
            return false;
        }
        return selectedProject?.estimatedCompletionDate && endDate > selectedProject.estimatedCompletionDate;
    };

    const checkForConflictsAndSubmit = (resource) => {
        const { skilledResourceId, projectProfileId } = resource;
        // newProjectResourceTimeline doesn't have the necessary properties for use in the DOM or API call, therefore the new assignment.
        setNewResourceTimeline(structuredClone(resource));

        const conflictingCharacteristics = hasConflictingCharacteristics(resource.skilledResourceId);
        if (conflictingCharacteristics) {
            setIsActionWarningModalOpen(true);
        }

        const conflictingDates = hasConflictingDates(resource);
        if (conflictingDates) {
            setIsMismatchedDatesModalOpen(true);
        }

        if (!conflictingCharacteristics && !conflictingDates) {
            if (skilledResourceId) {
                handleSubmit(resource);
            }
            if (projectProfileId) {
                handleAddProfileToProject();
            }
        }
    };

    const closeModals = () => {
        setIsActionWarningModalOpen(false);
        setIsMismatchedDatesModalOpen(false);
    };

    const handleClose = () => {
        closeModals();
        dispatch(modalActions.close('newProjectResourceTimelineModal'));
        dispatch(resourceActions.unselectResource());
        dispatch(profileActions.unselectProfile());
    };

    const handleSubmit = (projectResourceTimeline) => {
        if (isMismatchedDatesModalOpen) {
            setIsActionWarningModalOpen(false);
            return;
        }
        dispatch(
            resourceTimelineActions.addResourceTimelineToProject(
                projectResourceTimeline,
            ),
        );
        dispatch(paneActions.setProjectDetailSelectedTab(0));
        handleClose();
    };

    const handleKeepDates = (resourceTimeline) => {
        if (resourceTimeline.skilledResourceId) {
            dispatch(
                resourceTimelineActions.addResourceTimelineToProject(
                    resourceTimeline,
                ),
            );
            dispatch(paneActions.setProjectDetailSelectedTab(0))
            handleClose();
        } else {
            handleAddProfileToProject();
        }
    };

    const handleExtendDates = (resourceTimeline, project) => {
        project.estimatedCompletionDate = resourceTimeline.endDate;
        if (resourceTimeline.skilledResourceId) {
            dispatch(projectActions.updateProjectSchedule(project))
                .then(() => dispatch(
                    resourceTimelineActions.addResourceTimelineToProject(
                        resourceTimeline,
                    ),
                ));
            dispatch(paneActions.setProjectDetailSelectedTab(0))
            handleClose();
        } else {
            dispatch(projectActions.updateProjectSchedule(project))
                .then(() => handleAddProfileToProject());
        }
    };

    const insertedCharacteristicsString = () => {
        if (conflictingCharacteristics.length) {
            if (conflictingCharacteristics.length === 1) {
                return (
                    conflictingCharacteristics[0].name +
                    ' as a dreaded characteristic '
                );
            } else if (conflictingCharacteristics.length === 2) {
                return `${conflictingCharacteristics[0].name} and ${conflictingCharacteristics[1].name} as dreaded characteristics `;
            } else if (conflictingCharacteristics.length > 2) {
                let result = '';
                for (
                    let i = 0;
                    i < conflictingCharacteristics.length - 1;
                    i++
                ) {
                    result += conflictingCharacteristics[i].name + ', ';
                }
                result +=
                    'and ' +
                    conflictingCharacteristics[
                        conflictingCharacteristics.length - 1
                    ].name +
                    ' as dreaded characteristics ';
                return result;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const handleFormChange = (formData) => {
        setStartDate(formData.startDate);
        setEndDate(formData.endDate);
        setIsPinned(formData.isPinned);
    };

    const handleAddProfileToProject = () => {
        if (startDate && endDate && quantity) {
            const input = {
                projectId: selectedProject?.id,
                profileId: selectedProfile?.id,
                startDate: startDate,
                endDate: endDate,
                quantity:
                    quantity > 5 ? 5 : quantity < 1 ? 0 : quantity,
                isPinned: isPinned,
            };
            dispatch(projectActionsAsync.addProfileToProject(input));
            dispatch(profileActions.selectProfile(''));
            handleClose();
        }
    };

    return newProjectResourceTimeline ? (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="Add Resource or Profile to Project"
                aria-describedby="Add a resource or profile to this project"
            >
                <DialogTitle>Add Resource/Profile to Project</DialogTitle>
                <DialogContent>
                    <NewResourceTimelineForm
                        resourceTimeline={newProjectResourceTimeline}
                        onSubmit={checkForConflictsAndSubmit}
                        selectedProject={selectedProject}
                        selectedResource={selectedResource}
                        selectedProfile={selectedProfile}
                        allResources={allResources}
                        quantity={quantity}
                        setQuantity={setQuantity}
                        handleFormChange={handleFormChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {(selectedResource || !selectedProfile) && (
                        <Button
                            variant="contained"
                            type="submit"
                            form="new-resource-timeline-form"
                        >
                            Save Resource
                        </Button>
                    )}
                    {selectedProfile && (
                        <Button
                            variant="contained"
                            type="submit"
                            form="new-resource-timeline-form"
                        >
                            Save Profile
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <ActionWarningModal
                isModalOpen={isActionWarningModalOpen}
                customTitle="Characteristics Mismatch"
                customMessage={`${newResourceTimeline?.skilledResourceName
                    } has ${insertedCharacteristicsString()} of theirs. Fairview recommends not assigning work with this label to this resource.`}
                customCancelMessage="Change Resource"
                customProceedMessage="Ignore"
                confirmAction={() => handleSubmit(newResourceTimeline)}
                cancelAction={() => closeModals()}
            />
            <MismatchedDatesModal
                isModalOpen={isMismatchedDatesModalOpen && !isActionWarningModalOpen}
                selectedProject={selectedProject}
                newProjectResourceTimeline={newResourceTimeline}
                onCancel={() => setIsMismatchedDatesModalOpen(false)}
                onConfirmKeepDates={() => handleKeepDates(newResourceTimeline)}
                onConfirmExtendDates={() => handleExtendDates(newResourceTimeline, selectedProject)}
            />
        </div>
    ) : null;
}
