import {
    addItemToListWithFinancials,
    addToOrUpdateInList,
    getItemByItemId,
    getUpdatedList,
    removeItemFromListById,
} from 'Utilities/reducer-utils';
import { createSlice } from '@reduxjs/toolkit';
import { resourceActionsAsync } from './actions';
import { resourceUnavailablePeriodActionsAsync } from './resource-unavailable-period-actions';
import { coreActionsAsync } from 'System/State/CoreState/actions';

const { appStart, offline } = coreActionsAsync;

const {
    activateResource,
    addResource,
    deactivateResource,
    deleteResource,
    getResourceDetail,
    updateResource,
} = resourceActionsAsync;

const { getUnavailablePeriods } = resourceUnavailablePeriodActionsAsync;

const initialState = {
    list: [],
    filterOptions: {
        selectedProfiles: [],
        selectedProjects: [],
        selectedTeams: [],
        activeSelection: null,
        search: '',
    },
    selectedResource: null,
    verticalTimelineMonthHeight: 45,
};

export const ResourceSlice = createSlice({
    name: 'resources',
    initialState: initialState,
    reducers: {
        changeResourceListFilters(state, action) {
            state.filterOptions = action.payload;
        },
        resetSlice() {
            return initialState;
        },
        selectResource(state, action) {
            state.selectedResource = action.payload;
        },
        unselectResource(state) {
            state.selectedResource = null;
        },
        changeZoom(state, action) {
            state.verticalTimelineMonthHeight = action.payload;
        },
    },
    extraReducers: {
        [activateResource.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [addResource.fulfilled]: (state, action) => {
            state.list = addItemToListWithFinancials(
                state.list,
                action.payload,
            );
        },
        [appStart.fulfilled]: (state, action) => {
            const { skilledResources } = action.payload.data;
            if (skilledResources) state.list = skilledResources;
        },
        [offline.fulfilled]: (state, action) => {
            const { skilledResources } = action.payload.data;
            if (skilledResources) state.list = skilledResources;
        },
        [deactivateResource.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [deleteResource.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [getResourceDetail.fulfilled]: (state, action) => {
            state.list = addToOrUpdateInList(state.list, action.payload.data);
        },
        [getResourceDetail.rejected]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [getUnavailablePeriods.fulfilled]: (state, action) => {
            const foundResource = getItemByItemId(
                state.list,
                state.selectedResource,
            );
            state.list = getUpdatedList(state.list, {
                ...foundResource,
                skilledResourceUnavailablePeriods: action.payload.data,
            });
        },
        [updateResource.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
    },
});

export const resourceActions = {
    ...ResourceSlice.actions,
    ...resourceActionsAsync,
    ...resourceUnavailablePeriodActionsAsync,
    changeZoom: ResourceSlice.actions.changeZoom,
};

export default ResourceSlice.reducer;
