import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { companyActions } from 'System/State/CompanyState/reducer';
import BudgetForm from 'System/Forms/BudgetForm/BudgetForm';

export default function NewBudgetModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.newBudgetModal) || false;

    const handleClose = () => {
        dispatch(modalActions.close('newBudgetModal'));
    };

    const handleSubmit = (values) => {
        dispatch(companyActions.addBudget(values));
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Add Budget"
            aria-describedby="Add a new budget to the project"
        >
            <DialogTitle>Add Budget</DialogTitle>
            <DialogContent>
                <BudgetForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit" form="budget-form">
                    Save Budget
                </Button>
            </DialogActions>
        </Dialog>
    );
}
