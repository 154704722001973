import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import { generateSampleData, removeSampleData } from './sample-data';
import RestAPI from '../rest-api';

const addBudget = createAsyncThunk('company/addBudget', async (budget) => {
    record('addBudget', {
        id: budget.id,
        startDate: budget.startDate.toLocaleString(),
        endDate: budget.endDate.toLocaleString(),
        amount: budget.amount,
    });

    await RestAPI.post('/budgets', { body: budget });

    return { ...budget };
});

const createSubscription = createAsyncThunk(
    'company/createSubscription',
    async (params, { dispatch, getState }) => {
        // todo
        // const { subscription } = params;
        // const response = await paymentApi.createSubscription(subscription);
        // const parsedResponse = parseSquareKeysToFairviewKeys(response.subscription);
        // const newPaymentInfo = {
        //     ...parsedResponse,
        //     billingAddress: subscription.billingAddress,
        //     email: subscription.email,
        //     familyName: subscription.familyName,
        //     givenName: subscription.givenName,
        //     tier: subscription.tier.toLowerCase(),
        //     lastFour: response.lastFour,
        //     cardType: response.cardType,
        //     expiry: response.expiry,
        // };
        // const tenant = getState().company.tenant;
        // const updatedTenant = {
        //     ...tenant,
        //     paymentInfo: newPaymentInfo,
        // };
        // return await dispatch(updateTenant(updatedTenant));
    },
);

const deleteBudget = createAsyncThunk(
    'company/deleteBudget',
    async (budget) => {
        record('deleteBudget', {
            id: budget.id,
            startDate: budget.startDate.toLocaleString(),
            endDate: budget.endDate.toLocaleString(),
            amount: budget.amount,
        });

        await RestAPI.del(`/budgets/${budget.id}`);

        return budget.id;
    },
);

const disableUser = createAsyncThunk('company/disableUser', async (userId) => {
    record('disableUser', { userId });

    await RestAPI.del(`/admin/users/${userId}`);

    return userId;
});

const enableUser = createAsyncThunk('company/enableUser', async (userId) => {
    record('enableUser', { userId });

    await RestAPI.post(`/admin/users/${userId}/enable`);

    return userId;
});

const getBudgetDetail = createAsyncThunk(
    'company/getBudgetDetail',
    async (budgetId, { getState, rejectWithValue }) => {
        if (getState().user?.loggedInUser?.isAllowedFinancials) {
            try {
                return await RestAPI.get(`/budgets/withFinancials/${budgetId}`);
            } catch (error) {
                return rejectWithValue(budgetId);
            }
        }
    },
);

const getTenant = createAsyncThunk('company/getTenant', async (id) => {
    await RestAPI.get('/me/tenant');

    return id;
});

const getUserDetail = createAsyncThunk(
    'user/getUserDetail',
    async (userId, { rejectWithValue }) => {
        try {
            return await RestAPI.get(`admin/users/${userId}`);
        } catch (error) {
            return rejectWithValue(userId);
        }
    },
);

const getUserSentimentById = createAsyncThunk(
    'user/getUserSentiment',
    async ({ userId, sentimentId }) => {
        const response = await RestAPI.get(
            `users/${userId}/${sentimentId}`,
        );
        return response.data;
    },
);

const inviteUsers = createAsyncThunk(
    'company/inviteUsers',
    async (params, { rejectWithValue }) => {
        record('inviteUsers', {
            users: params.users,
            tenantId: params.tenantId,
            group: params.group,
        });

        try {
            const { data: response } = await RestAPI.post(
                '/admin/users/invite',
                {
                    body: { emailAddresses: params.users, role: params.group },
                },
            );

            let result = [];
            response?.users?.forEach((user) =>
                result.push({
                    id: user.id,
                    groupName: params.group,
                    emailAddress: user.emailAddress,
                }),
            );

            return result;
        } catch (error) {
            return rejectWithValue({ error: error.response?.data?.errors });
        }
    },
);

const resendUserInvite = createAsyncThunk(
    'company/resendUserInvite',
    async (userId) => {
        record('resendUserInvite', {
            userId: userId,
        });

        await RestAPI.post(`/admin/users/resend-invite/${userId}`);
    },
);

const updateBudget = createAsyncThunk(
    'company/updateBudget',
    async (budget) => {
        record('updateBudget', {
            id: budget.id,
            startDate: budget.startDate.toLocaleString(),
            endDate: budget.endDate.toLocaleString(),
            amount: budget.amount,
        });

        await RestAPI.put(`/budgets/${budget.id}`, { body: budget });

        return { ...budget };
    },
);

const updateTenant = createAsyncThunk(
    'company/updateTenant',
    async (tenant) => {
        record('updateTenant', { id: tenant.id, name: tenant.name });

        await RestAPI.put('/admin/tenant', { body: tenant });

        return { ...tenant };
    },
);

const updateUser = createAsyncThunk('company/updateUser', async (user) => {
    record('updateUser', { user: user.username });

    await RestAPI.put(`/admin/users/${user.id}`, { body: user });

    return { ...user };
});

const getUserSentiments = createAsyncThunk(
    'company/getUserSentiments',
    async () => {
        return await RestAPI.get('/users/sentiments');
    },
);

export const companyActionsAsync = {
    addBudget,
    createSubscription,
    deleteBudget,
    disableUser,
    enableUser,
    generateSampleData,
    getBudgetDetail,
    getTenant,
    getUserDetail,
    getUserSentimentById,
    getUserSentiments,
    removeSampleData,
    inviteUsers,
    resendUserInvite,
    updateBudget,
    updateTenant,
    updateUser,
};
