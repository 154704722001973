import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import RestAPI from '../rest-api';

const addNonLaborExpenseToProject = createAsyncThunk(
    'projects/addNonLaborExpenseToProject',
    async (cost) => {
        record('addNonLaborExpenseToProject', {
            id: cost.id,
            description: cost.description,
        });

        await RestAPI.post(`/projects/${cost.projectId}/non-labor-expenses`, {
            body: cost,
        });

        return { ...cost };
    },
);

const deleteNonLaborExpenseFromProject = createAsyncThunk(
    'projects/deleteNonLaborExpenseFromProject',
    async (cost) => {
        record('deleteNonLaborExpenseFromProject', {
            id: cost.id,
            amountPerEach: cost.amountPerEach,
            description: cost.description,
            projectId: cost.projectId,
            quantity: cost.quantity,
        });

        await RestAPI.del(
            `/projects/${cost.projectId}/non-labor-expenses/${cost.id}`,
        );

        return { ...cost };
    },
);

const getProjectNonLaborExpenseDetail = createAsyncThunk(
    'projects/getProjectNonLaborExpenseDetail',
    async ({ costId, projectId }, { rejectWithValue }) => {
        try {
            return await RestAPI.get(
                `/projects/${projectId}/non-labor-expenses/${costId}`,
            );
        } catch (error) {
            return rejectWithValue({
                costId,
                projectId,
            });
        }
    },
);

const updateProjectNonLaborExpense = createAsyncThunk(
    'projects/updateProjectNonLaborExpense',
    async (cost) => {
        record('updateProjectNonLaborExpense', {
            id: cost.id,
            amountPerEach: cost.amountPerEach,
            description: cost.description,
            projectId: cost.projectId,
            quantity: cost.quantity,
        });

        await RestAPI.put(
            `/projects/${cost.projectId}/non-labor-expenses/${cost.id}`,
            { body: cost },
        );

        return { ...cost };
    },
);

export const projectNonLaborExpenseActionsAsync = {
    addNonLaborExpenseToProject,
    deleteNonLaborExpenseFromProject,
    getProjectNonLaborExpenseDetail,
    updateProjectNonLaborExpense,
};
