import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import RestAPI from '../rest-api';

const createIntegration = createAsyncThunk(
    'integration/createIntegration',
    async (newIntegration) => {
        record('addIntegration', { body: newIntegration });
        await RestAPI.post('/admin/integrations', { body: newIntegration });
        return { ...newIntegration };
    },
);

const activateIntegration = createAsyncThunk(
    'integration/enableIntegration',
    async (integration) => {
        record('activateIntegration', { body: integration?.id });
        await RestAPI.post(`admin/integrations/${integration?.id}/enable`);
        return integration;
    },
);

const deactivateIntegration = createAsyncThunk(
    'integration/disable',
    async (integration) => {
        record('deactivateIntegration', { body: integration?.id });
        await RestAPI.post(`admin/integrations/${integration?.id}/disable`);
        return integration;
    },
);

const updateIntegration = createAsyncThunk(
    'integration/updateIntegration',
    async (integration) => {
        record('updateIntegration', { body: integration });
        integration.metaInformationJson = JSON.stringify(
            integration.metaInformationJson,
        );
        await RestAPI.put(`admin/integrations/${integration?.id}`, {
            body: integration,
        });

        return { ...integration };
    },
);

const getIntegrationDetails = createAsyncThunk(
    'integration/getIntegrationDetails',
    async (integrationId, { rejectWithValue }) => {
        try {
            return await RestAPI.get(`admin/integrations/${integrationId}`);
        } catch (error) {
            return rejectWithValue(integrationId);
        }
    },
);

export const integrationActionsAsync = {
    createIntegration,
    activateIntegration,
    deactivateIntegration,
    getIntegrationDetails,
    updateIntegration,
};
