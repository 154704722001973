import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { teamActions } from 'System/State/TeamState/reducer';
import TeamForm from 'System/Forms/TeamForm/TeamForm';

export default function NewTeamModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.newTeamModal) || false;

    const handleClose = () => dispatch(modalActions.close('newTeamModal'));

    const handleSubmit = (team) => {
        dispatch(teamActions.addTeam(team)).then(() =>
            dispatch(teamActions.selectTeam(team.id)),
        );
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Add Team"
            aria-describedby="Add a new team"
        >
            <DialogTitle>Add Team</DialogTitle>
            <DialogContent>
                <TeamForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="team-form"
                >
                    Save Team
                </Button>
            </DialogActions>
        </Dialog>
    );
}
