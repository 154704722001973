import { useEffect, useState } from 'react';
import {
    Stack,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import { projectActions, resourceTimelineActions } from 'System/State/ProjectState/reducer';
import ResourceTimelineDetailForm from 'System/Forms/ResourceTimelineForms/ResourceTimelineDetailForm';
import ResourceAvatar from 'System/UI/Components/ResourceAvatar/ResourceAvatar';
import { resourceActions } from 'System/State/ResourceState/reducer';
import { resourceSelectors } from 'System/State/ResourceState/selectors';
import ProjectKeyChip from 'System/UI/Components/ProjectKeyChip/ProjectKeyChip';
import { useNavigate } from 'react-router-dom';
import MismatchedDatesModal from '../MismatchedDatesModal/MismatchedDatesModal';

export default function ResourceTimelineDetailModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const resourceTimeline = useSelector(
        projectSelectors.selectedResourceTimeline,
    );
    const resource = useSelector(resourceSelectors.selectedResource);
    const project = useSelector(projectSelectors.selectedProject);
    const open =
        useSelector(modalSelectors.resourceTimelineDetailModal) || false;

    const [newResourceTimeline, setNewResourceTimeline] = useState();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isMismatchedDatesModalOpen, setIsMismatchedDatesModalOpen] =
        useState(false);

    useEffect(() => {
        // autoselect the resource
        if (resourceTimeline?.skilledResourceId) {
            dispatch(
                resourceActions.selectResource(
                    resourceTimeline?.skilledResourceId,
                ),
            );
        }
    }, [resourceTimeline]);

    const handleClose = () => {
        dispatch(modalActions.close('resourceTimelineDetailModal'));
        setIsMismatchedDatesModalOpen(false);
    };

    const handleDelete = () => {
        dispatch(
            resourceTimelineActions.deleteResourceTimelineFromProject(
                resourceTimeline,
            ),
        );
        handleClose();
    };

    const handleSelectResource = (resource) => {
        dispatch(modalActions.close('resourceTimelineDetailModal'));
        navigate(`/resources/${resource.id}`);
    };

    const hasConflictingDates = (resource) => {
        const { startDate, endDate } = resource;
        if (startDate < project?.estimatedStartDate) {
            // api validation will handle this case for now
            return false;
        }
        return project?.estimatedCompletionDate && endDate > project.estimatedCompletionDate;
    };

    const handleSubmit = (values) => {
        setNewResourceTimeline(structuredClone(values));

        const conflictingDates = hasConflictingDates(values);
        if (conflictingDates) {
            setIsMismatchedDatesModalOpen(true);
        }

        if (!conflictingDates) {
            dispatch(resourceTimelineActions.updateProjectResourceTimeline(values));
            handleClose();
        }
    };

    const handleKeepDates = (resourceTimeline) => {
        dispatch(
            resourceTimelineActions.updateProjectResourceTimeline(
                resourceTimeline,
            ),
        );
        handleClose();
    };

    const handleExtendDates = (resourceTimeline, project) => {
        project.estimatedCompletionDate = resourceTimeline.endDate;
        dispatch(projectActions.updateProjectSchedule(project))
            .then(() => dispatch(
                resourceTimelineActions.updateProjectResourceTimeline(
                    resourceTimeline,
                ),
            ));
        handleClose();
    };

    return resourceTimeline && project ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Update Resource Timeline Details"
            aria-describedby="Update resource timeline details"
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                        <ResourceAvatar
                            resource={resource}
                            badgeSizeInPixels={35}
                            onSelectResource={handleSelectResource}
                        />
                        <span>
                            {resourceTimeline.skilledResourceName} Timeline
                            Details
                        </span>
                    </Stack>
                    <ProjectKeyChip tooltipPlacement="bottom-end" />
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginBottom: 2 }}>
                <ResourceTimelineDetailForm
                    project={project}
                    resourceTimeline={resourceTimeline}
                    onSubmit={handleSubmit}
                />

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="Resource Timeline"
                    customTitle="Remove Resource"
                    customMessage={
                        <>
                            Are you sure you&apos;d like to remove{' '}
                            <i>{resourceTimeline.skilledResourceName}</i> from{' '}
                            <i>{project.name}</i>? This will change any
                            projections associated with the{' '}
                            <i>{project.name}</i> project.
                        </>
                    }
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        handleDelete(resourceTimeline);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
                <MismatchedDatesModal
                    isModalOpen={isMismatchedDatesModalOpen}
                    selectedProject={project}
                    newProjectResourceTimeline={newResourceTimeline}
                    onCancel={() => setIsMismatchedDatesModalOpen(false)}
                    onConfirmKeepDates={() => handleKeepDates(newResourceTimeline)}
                    onConfirmExtendDates={() => handleExtendDates(newResourceTimeline, project)}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <Button
                        variant="outlined"
                        color="tertiary"
                        onClick={() => {
                            setDeleteModalOpen(true);
                        }}
                    >
                        Remove Resource
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            form="resource-timeline-detail-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
