import {
    createSlice,
    isFulfilled,
    isPending,
    isRejected,
} from '@reduxjs/toolkit';
import { coreActionsAsync } from './actions';
import { companyActionsAsync } from 'System/State/CompanyState/actions';
import { profileActionsAsync } from 'System/State/ProfileState/actions';
import { projectActionsAsync } from 'System/State/ProjectState/actions';
import { epicActionsAsync } from 'System/State/ProjectState/epic-actions';
import { resourceActionsAsync } from 'System/State/ResourceState/actions';
import { resourceTimelineActionsAsync } from 'System/State/ProjectState/resource-timeline-actions';
import { resourceUnavailablePeriodActionsAsync } from '../ResourceState/resource-unavailable-period-actions';
import { teamActionsAsync } from 'System/State/TeamState/actions';
import { userActionsAsync } from 'System/State/UserState/actions';
import { milestoneActionsAsync } from 'System/State/MilestoneState/actions';
import { dashboardActionsAsync } from 'System/State/DashboardState/actions';
import { workItemActionsAsync } from '../ProjectState/work-item-actions';
import { projectNonLaborExpenseActionsAsync } from '../ProjectState/non-labor-expense-actions';
import { integrationActionsAsync } from '../CompanyState/integration-actions';
import { phaseActionsAsync } from '../ProjectState/phases-actions';
import { paymentActionsAsync } from '../PaymentState/actions';
import { getErrorMessage, trimActionType } from 'Utilities/reducer-utils';

const { appStart, handleError, requestAttachmentUploadLink } = coreActionsAsync;

const {
    addBudget,
    createSubscription,
    deleteBudget,
    disableUser,
    enableUser,
    generateSampleData,
    inviteUsers,
    resendUserInvite,
    removeSampleData,
    updateBudget,
    updateTenant,
    updateUser,
} = companyActionsAsync;

const { addMilestone, deleteMilestone, updateMilestone } =
    milestoneActionsAsync;

const { addProfile, deleteProfile, updateProfile } = profileActionsAsync;

const {
    addNonLaborExpenseToProject,
    deleteNonLaborExpenseFromProject,
    updateProjectNonLaborExpense,
} = projectNonLaborExpenseActionsAsync;

const {
    addCharacteristicToProject,
    addCommentToProject,
    addProject,
    addLabelToProject,
    addLOBToProject,
    deleteCommentFromProject,
    deleteProject,
    linkProjectToJira,
    removeCharacteristicFromProject,
    updateProject,
    updateProjectEstimatedCompletionDate,
    updateProjectEstimatedStartDate,
    updateProjectSchedule,
    importProjectsFromExcel,
    addAttachmentToProject,
    copyProject,
    removeProjectProfile,
    addProfileToProject,
    addSkilledResourceToProjectProfile,
} = projectActionsAsync;

const {
    addEpic,
    deleteEpic,
    moveEpicToExistingProject,
    updateEpic,
    upgradeToProject,
    updateEpicEstimatedCompletionDate,
    updateEpicEstimatedStartDate,
} = epicActionsAsync;

const {
    addWorkItemToProject,
    bulkDeleteWorkItem,
    bulkMoveWorkItem,
    bulkUpdateWorkItemEpic,
    bulkUpdateWorkItemPhase,
    bulkUpdateWorkItemStatus,
    deleteWorkItemAttachment,
    deleteWorkItemFromEpic,
    deleteWorkItemFromProject,
    updateEpicWorkItem,
    updateProjectWorkItem,
    updateWorkItemStatus,
    updateProjectWorkItemDescription,
    addLabelToWorkItem,
    removeLabelFromWorkItem,
    addCommentToWorkItem,
    addAttachmentToWorkItem,
    importWorkItemsFromExcel,
} = workItemActionsAsync;

const {
    activateResource,
    addResource,
    deactivateResource,
    deleteResource,
    updateResource,
    importResourcesFromExcel,
} = resourceActionsAsync;

const {
    addResourceTimelineToProject,
    deleteResourceTimelineFromProject,
    updateProjectResourceTimeline,
    updateResourceTimelineEndDate,
    updateResourceTimelineStartDate,
} = resourceTimelineActionsAsync;

const { addUnavailablePeriodToResource, updateUnavailablePeriod } =
    resourceUnavailablePeriodActionsAsync;

const { addTeam, deleteTeam, disableTeam, updateTeam } = teamActionsAsync;

const {
    changePassword,
    checkUsernameAvailability,
    confirmCookieConsent,
    completeNewPasswordRequirement,
    forgotPassword,
    forgotPasswordConfirmed,
    resendCode,
    resigninUser,
    requestDemoSubmission,
    setInitialLoginToFalse,
    signinUser,
    signoutUser,
    signupUser,
    signupUserVerification,
    updateCurrentUser,
    contactUsSubmission,
    addUserCharacteristic,
    addUserSentiment,
    deleteUserSentiment,
    getUserSentiments,
} = userActionsAsync;

const { addDashboard, deleteDashboard, updateDashboard, pinDashboard } =
    dashboardActionsAsync;

const {
    createIntegration,
    activateIntegration,
    deactivateIntegration,
    updateIntegration,
} = integrationActionsAsync;

const { createProjectPhase, disableProjectPhase, updateProjectPhase } =
    phaseActionsAsync;

const { cancelSubscription } = paymentActionsAsync;

const spinnerIgnoreList = [
    appStart,
    signinUser,
    resigninUser,
    handleError,
    requestAttachmentUploadLink,
];

const initialState = {
    message: '',
    isLoading: {},
    isMessageVisible: false,
    showGlobalFinancials: true,
    lastRecordedEvent: null,
    messageType: 'info', // info, success, error, warning
    visibilityOptions: {
        showProgress: true,
        showRisk: true,
        showAssignedResources: true,
    },
    walkThroughVisible: false,
    hasPendingRequest: false,
};

const setError = (state) => {
    state.isMessageVisible = true;
    state.messageType = 'error';
};

const setSuccess = (state) => {
    state.isMessageVisible = true;
    state.messageType = 'success';
};

const setWarning = (state) => {
    state.isMessageVisible = true;
    state.messageType = 'warning';
};

const createErrorString = (str, curr) => {
    if (curr.userExists) {
        return (str += curr.userExists + ', ');
    } else {
        return str;
    }
};

export const CoreSlice = createSlice({
    name: 'core',
    initialState: initialState,
    reducers: {
        changeVisibilityOptions(state, action) {
            state.visibilityOptions = action.payload;
        },
        hideMessage(state) {
            state.isMessageVisible = false;
        },
        resetSlice() {
            return initialState;
        },
        showMessage(state, action) {
            state.message = action.payload.message;
            state.isMessageVisible = true;
            state.messageType = action.payload.messageType;
        },
        toggleGlobalFinancials(state) {
            state.showGlobalFinancials = !state.showGlobalFinancials;
        },
        toggleWalkThroughVisible(state) {
            state.walkThroughVisible = !state.walkThroughVisible;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addAttachmentToProject.fulfilled, (state) => {
                state.message = 'Attachment uploaded successfully';
                setSuccess(state);
            })
            .addCase(activateResource.fulfilled, (state) => {
                state.message = 'Resource activated';
                setSuccess(state);
            })
            .addCase(activateResource.rejected, (state) => {
                state.message = 'Could not activate resource';
                setError(state);
            })
            .addCase(activateIntegration.fulfilled, (state) => {
                state.message = 'Integration activated';
                setSuccess(state);
            })
            .addCase(activateIntegration.rejected, (state) => {
                state.message = 'Could not activate resource';
                setError(state);
            })
            .addCase(addBudget.fulfilled, (state) => {
                state.message = 'Budget added';
                setSuccess(state);
            })
            .addCase(addBudget.rejected, (state) => {
                state.message = 'Could not add budget';
                setError(state);
            })
            .addCase(addCharacteristicToProject.fulfilled, (state) => {
                state.message = 'Characteristic added';
                setSuccess(state);
            })
            .addCase(addCommentToProject.rejected, (state) => {
                state.message = 'Could not add comment';
                setError(state);
            })
            .addCase(addDashboard.rejected, (state) => {
                state.message = 'Could not add dashboard';
                setError(state);
            })
            .addCase(addEpic.fulfilled, (state) => {
                state.message = 'Epic added';
                setSuccess(state);
            })
            .addCase(addLabelToProject.fulfilled, (state) => {
                state.message = 'Label added';
                setSuccess(state);
            })
            .addCase(addLOBToProject.fulfilled, (state) => {
                state.message = 'Department added';
                setSuccess(state);
            })
            .addCase(addMilestone.fulfilled, (state) => {
                state.message = 'Milestone added';
                setSuccess(state);
            })
            .addCase(addMilestone.rejected, (state) => {
                state.message = 'Could not add milestone';
                setError(state);
            })
            .addCase(addNonLaborExpenseToProject.fulfilled, (state) => {
                state.message = 'Additional cost added';
                setSuccess(state);
            })
            .addCase(addNonLaborExpenseToProject.rejected, (state) => {
                state.message = 'Could not add additional cost';
                setError(state);
            })
            .addCase(addProfile.fulfilled, (state) => {
                state.message = 'Job profile added';
                setSuccess(state);
            })
            .addCase(addProfile.rejected, (state) => {
                state.message = 'Could not add job profile';
                setError(state);
            })
            .addCase(addProject.fulfilled, (state) => {
                state.message = 'Project added';
                setSuccess(state);
            })
            .addCase(addProject.rejected, (state) => {
                state.message = 'Could not add project';
                setError(state);
            })
            .addCase(addResource.fulfilled, (state) => {
                state.message = 'Resource added';
                setSuccess(state);
            })
            .addCase(addResource.rejected, (state) => {
                state.message = 'Could not add resource';
                setError(state);
            })
            .addCase(addResourceTimelineToProject.fulfilled, (state) => {
                state.message = 'Resource timeline added to project';
                setSuccess(state);
            })
            .addCase(addResourceTimelineToProject.rejected, (state, action) => {
                if (Object.keys(action?.payload?.response?.data?.errors)) {
                    state.message = getErrorMessage(action?.payload?.response?.data?.errors);
                } else {
                    state.message = 'Could not add resource timeline to project';
                }
                setError(state);
            })
            .addCase(addWorkItemToProject.fulfilled, (state) => {
                state.message = 'Work item added';
                setSuccess(state);
            })
            .addCase(addAttachmentToWorkItem.fulfilled, (state) => {
                state.message = 'Attachment added to work item';
                setSuccess(state);
            })
            .addCase(addAttachmentToWorkItem.rejected, (state) => {
                state.message = 'Could not add attachment';
                setError(state);
            })
            .addCase(addUnavailablePeriodToResource.fulfilled, (state) => {
                state.message = 'Unavailable period added';
                setSuccess(state);
            })
            .addCase(addUnavailablePeriodToResource.rejected, (state) => {
                state.message = 'Could not add unavailable period';
                setError(state);
            })
            .addCase(addLabelToWorkItem.fulfilled, (state) => {
                state.message = 'Label added to work item';
                setSuccess(state);
            })
            .addCase(addLabelToWorkItem.rejected, (state) => {
                state.message = 'Could not add label';
                setError(state);
            })
            .addCase(addTeam.fulfilled, (state) => {
                state.message = 'Team added';
                setSuccess(state);
            })
            .addCase(addTeam.rejected, (state) => {
                state.message = 'Could not add team';
                setError(state);
            })
            .addCase(addUserCharacteristic.fulfilled, (state) => {
                state.message = 'Added user characteristic';
                setSuccess(state);
            })
            .addCase(addUserSentiment.fulfilled, (state) => {
                state.message = 'Added user sentiment';
                setSuccess(state);
            })
            .addCase(addUserSentiment.rejected, (state) => {
                state.message = 'Could not add user sentiment';
                setError(state);
            })
            .addCase(addCommentToWorkItem.fulfilled, (state) => {
                state.message = 'Added comment';
                setSuccess(state);
            })
            .addCase(addCommentToWorkItem.rejected, (state) => {
                state.message = 'Could not add comment';
                setError(state);
            })
            .addCase(bulkDeleteWorkItem.fulfilled, (state) => {
                state.message = 'Work items successfully deleted';
                setSuccess(state);
            })
            .addCase(bulkDeleteWorkItem.rejected, (state) => {
                state.message = 'Could not handle bulk delete request';
                setError(state);
            })
            .addCase(bulkMoveWorkItem.fulfilled, (state) => {
                state.message = 'Work items successfully moved';
                setSuccess(state);
            })
            .addCase(bulkMoveWorkItem.rejected, (state) => {
                state.message = 'Could not handle bulk move request';
                setError(state);
            })
            .addCase(bulkUpdateWorkItemEpic.fulfilled, (state) => {
                state.message = 'Work items successfully updated';
                setSuccess(state);
            })
            .addCase(bulkUpdateWorkItemEpic.rejected, (state) => {
                state.message = 'Could not handle bulk update request';
                setError(state);
            })
            .addCase(bulkUpdateWorkItemPhase.fulfilled, (state) => {
                state.message = 'Work items successfully updated';
                setSuccess(state);
            })
            .addCase(bulkUpdateWorkItemPhase.rejected, (state) => {
                state.message = 'Could not handle bulk update request';
                setError(state);
            })
            .addCase(bulkUpdateWorkItemStatus.fulfilled, (state) => {
                state.message = 'Work items successfully updated';
                setSuccess(state);
            })
            .addCase(bulkUpdateWorkItemStatus.rejected, (state) => {
                state.message = 'Could not handle bulk update request';
                setError(state);
            })
            .addCase(cancelSubscription.fulfilled, (state) => {
                state.message = 'Subscription canceled';
                setSuccess(state);
            })
            .addCase(cancelSubscription.rejected, (state) => {
                state.message = 'Could not cancel subscription';
                setError(state);
            })
            .addCase(changePassword.fulfilled, (state) => {
                state.message = 'Password updated!';
                setSuccess(state);
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.message = action.payload;
                setError(state);
            })
            .addCase(checkUsernameAvailability.rejected, (state) => {
                state.message = 'Could not check username availability';
                setError(state);
            })
            .addCase(completeNewPasswordRequirement.fulfilled, (state) => {
                state.message = 'Password changed!';
                setSuccess(state);
            })
            .addCase(completeNewPasswordRequirement.rejected, (state) => {
                state.message = 'Could not complete new password requirement';
                setError(state);
            })
            .addCase(confirmCookieConsent.rejected, (state) => {
                state.message = 'Could not confirm consent to cookies';
                setError(state);
            })
            .addCase(createSubscription.rejected, (state) => {
                state.message = 'Could not create subscription';
                setError(state);
            })
            .addCase(createIntegration.fulfilled, (state) => {
                state.message = 'Integration added';
                setSuccess(state);
            })
            .addCase(createIntegration.rejected, (state) => {
                state.message = 'Could not create integration';
                setError(state);
            })
            .addCase(createProjectPhase.fulfilled, (state) => {
                state.message = 'Phase added succesfully';
                setSuccess(state);
            })
            .addCase(createProjectPhase.rejected, (state) => {
                state.message = 'Could not create phase';
                setError(state);
            })
            .addCase(deactivateResource.fulfilled, (state) => {
                state.message = 'Resource deactivated';
                setSuccess(state);
            })
            .addCase(deactivateResource.rejected, (state) => {
                state.message = 'Could not deactivate resource';
                setError(state);
            })
            .addCase(deactivateIntegration.fulfilled, (state) => {
                state.message = 'Integration deactivated';
                setSuccess(state);
            })
            .addCase(deactivateIntegration.rejected, (state) => {
                state.message = 'Could not deactivate integration';
                setError(state);
            })
            .addCase(deleteBudget.fulfilled, (state) => {
                state.message = 'Budget removed';
                setSuccess(state);
            })
            .addCase(deleteBudget.rejected, (state) => {
                state.message = 'Could not remove budget';
                setError(state);
            })
            .addCase(deleteCommentFromProject.rejected, (state) => {
                state.message = 'Could not remove comment';
                setError(state);
            })
            .addCase(deleteDashboard.fulfilled, (state) => {
                state.message = 'Dashboard removed';
                setSuccess(state);
            })
            .addCase(deleteDashboard.rejected, (state) => {
                state.message = 'Could not remove dashboard';
                setError(state);
            })
            .addCase(deleteEpic.fulfilled, (state) => {
                state.message = 'Epic removed';
                setSuccess(state);
            })
            .addCase(deleteEpic.rejected, (state) => {
                state.message = 'Could not remove epic';
                setError(state);
            })
            .addCase(deleteMilestone.fulfilled, (state) => {
                state.message = 'Milestone removed';
                setSuccess(state);
            })
            .addCase(deleteMilestone.rejected, (state) => {
                state.message = 'Could not remove milestone';
                setError(state);
            })
            .addCase(deleteNonLaborExpenseFromProject.fulfilled, (state) => {
                state.message = 'Additional cost removed';
                setSuccess(state);
            })
            .addCase(deleteNonLaborExpenseFromProject.rejected, (state) => {
                state.message = 'Could not remove additional cost';
                setError(state);
            })
            .addCase(deleteProfile.fulfilled, (state) => {
                state.message = 'Job profile removed';
                setSuccess(state);
            })
            .addCase(deleteProfile.rejected, (state) => {
                state.message = 'Could not remove job profile';
                setError(state);
            })
            .addCase(deleteProject.fulfilled, (state) => {
                state.message = 'Project removed';
                setSuccess(state);
            })
            .addCase(deleteProject.rejected, (state) => {
                state.message = 'Could not remove project';
                setError(state);
            })
            .addCase(deleteResource.fulfilled, (state) => {
                state.message = 'Resource removed';
                setSuccess(state);
            })
            .addCase(deleteResource.rejected, (state) => {
                state.message = 'Could not remove resource';
                setError(state);
            })
            .addCase(deleteResourceTimelineFromProject.fulfilled, (state) => {
                state.message = 'Resource timeline removed from project';
                setSuccess(state);
            })
            .addCase(deleteResourceTimelineFromProject.rejected, (state) => {
                state.message =
                    'Could not remove resource timeline from project';
                setError(state);
            })
            .addCase(deleteTeam.fulfilled, (state) => {
                state.message = 'Team removed';
                setSuccess(state);
            })
            .addCase(deleteTeam.rejected, (state) => {
                state.message = 'Could not remove team';
                setError(state);
            })
            .addCase(disableTeam.fulfilled, (state) => {
                state.message = 'Team disabled';
                setSuccess(state);
            })
            .addCase(disableTeam.rejected, (state) => {
                state.message = 'Could not disable team';
                setError(state);
            })
            .addCase(disableProjectPhase.fulfilled, (state) => {
                state.message = 'Removed phase';
                setSuccess(state);
            })
            .addCase(disableProjectPhase.rejected, (state) => {
                state.message = 'Could not remove phase';
                setError(state);
            })
            .addCase(deleteUserSentiment.fulfilled, (state) => {
                state.message = 'Deleted user sentiment';
                setSuccess(state);
            })
            .addCase(deleteUserSentiment.rejected, (state) => {
                state.message = 'Could not delete user sentiment';
                setError(state);
            })
            .addCase(deleteWorkItemAttachment.fulfilled, (state) => {
                state.message = 'Attachment deleted';
                setSuccess(state);
            })
            .addCase(deleteWorkItemAttachment.rejected, (state) => {
                state.message = 'Attachment could not be deleted';
                setError(state);
            })
            .addCase(deleteWorkItemFromEpic.fulfilled, (state) => {
                state.message = 'Work item removed';
                setSuccess(state);
            })
            .addCase(deleteWorkItemFromEpic.rejected, (state) => {
                state.message = 'Could not remove work item';
                setError(state);
            })
            .addCase(deleteWorkItemFromProject.fulfilled, (state) => {
                state.message = 'Work item removed';
                setSuccess(state);
            })
            .addCase(deleteWorkItemFromProject.rejected, (state) => {
                state.message = 'Could not remove work item';
                setError(state);
            })
            .addCase(disableUser.fulfilled, (state) => {
                state.message = 'User disabled';
                setSuccess(state);
            })
            .addCase(disableUser.rejected, (state) => {
                state.message = 'Could not disable user';
                setError(state);
            })
            .addCase(enableUser.fulfilled, (state) => {
                state.message = 'User enabled';
                setSuccess(state);
            })
            .addCase(enableUser.rejected, (state) => {
                state.message = 'Could not enable user';
                setError(state);
            })
            .addCase(forgotPassword.rejected, (state) => {
                state.message = 'Could not complete forgot password';
                setError(state);
            })
            .addCase(forgotPasswordConfirmed.fulfilled, (state) => {
                state.message = 'Password is confirmed';
                setSuccess(state);
            })
            .addCase(forgotPasswordConfirmed.rejected, (state) => {
                state.message = 'Could not confirm forgot password';
                setError(state);
            })
            .addCase(generateSampleData.fulfilled, (state) => {
                state.message = 'Sample data generated';
                setSuccess(state);
            })
            .addCase(generateSampleData.rejected, (state) => {
                state.message = 'Could not generate sample data';
                setError(state);
            })
            .addCase(getUserSentiments.rejected, (state) => {
                state.message = 'Could not get user sentiments';
                setError(state);
            })
            .addCase(importProjectsFromExcel.fulfilled, (state) => {
                state.message = 'Projects imported from Excel';
                setSuccess(state);
            })
            .addCase(importProjectsFromExcel.rejected, (state) => {
                state.message = 'Could not import projects from Excel';
                setError(state);
            })
            .addCase(importResourcesFromExcel.fulfilled, (state) => {
                state.message = 'Resources imported from Excel';
                setSuccess(state);
            })
            .addCase(importResourcesFromExcel.rejected, (state) => {
                state.message = 'Could not import resources from Excel';
                setError(state);
            })
            .addCase(importWorkItemsFromExcel.fulfilled, (state) => {
                state.message = 'Work items imported from Excel';
                setSuccess(state);
            })
            .addCase(importWorkItemsFromExcel.rejected, (state) => {
                state.message = 'Could not import work items from Excel';
                setError(state);
            })
            .addCase(linkProjectToJira.fulfilled, (state) => {
                state.message = 'Project linked to Jira';
                setSuccess(state);
            })
            .addCase(linkProjectToJira.rejected, (state) => {
                state.message = 'Could not link project to Jira';
                setError(state);
            })
            .addCase(moveEpicToExistingProject.fulfilled, (state) => {
                state.message = 'Epic moved to new project';
                setSuccess(state);
            })
            .addCase(moveEpicToExistingProject.rejected, (state) => {
                state.message = 'Could not move epic';
                setError(state);
            })
            .addCase(pinDashboard.fulfilled, (state) => {
                state.message = 'Dashboard pinned';
                setSuccess(state);
            })
            .addCase(pinDashboard.rejected, (state) => {
                state.message = 'Could not pin dashboard';
                setError(state);
            })
            .addCase(removeCharacteristicFromProject.fulfilled, (state) => {
                state.message = 'Characteristic removed';
                setSuccess(state);
            })
            .addCase(removeCharacteristicFromProject.rejected, (state) => {
                state.message = 'Could not remove characteristic';
                setError(state);
            })
            .addCase(removeSampleData.fulfilled, (state) => {
                state.message = 'Sample data removed';
                setSuccess(state);
            })
            .addCase(removeSampleData.rejected, (state) => {
                state.message = 'Could not remove sample data';
                setError(state);
            })
            .addCase(removeLabelFromWorkItem.fulfilled, (state) => {
                state.message = 'Label removed';
                setSuccess(state);
            })
            .addCase(removeLabelFromWorkItem.rejected, (state) => {
                state.message = 'Could not remove label';
                setError(state);
            })
            .addCase(resendCode.fulfilled, (state) => {
                state.message = 'Code resent, check your email';
                setSuccess(state);
            })
            .addCase(resendCode.rejected, (state) => {
                state.message = 'Unable to resend code';
                setError(state);
            })
            .addCase(resendUserInvite.fulfilled, (state) => {
                state.message = 'Invitation resent';
                setSuccess(state);
            })
            .addCase(resendUserInvite.rejected, (state) => {
                state.message = 'Unable to resend invitation';
                setError(state);
            })
            .addCase(requestAttachmentUploadLink.rejected, (state) => {
                state.message = 'Unable to upload attachment';
                setError(state);
            })
            .addCase(setInitialLoginToFalse.rejected, (state) => {
                state.message = 'Could not update initial login';
                setError(state);
            })
            .addCase(signoutUser.rejected, (state) => {
                state.message = 'Could not signout user';
                setError(state);
            })
            .addCase(signupUser.rejected, (state) => {
                state.message = 'Could not signup new user';
                setError(state);
            })
            .addCase(signupUserVerification.fulfilled, (state) => {
                state.message = 'Email verified';
                setSuccess(state);
            })
            .addCase(signupUserVerification.rejected, (state, action) => {
                const message = action?.payload.split('|')[1];
                state.message = message;
                setError(state);
            })
            .addCase(updateBudget.fulfilled, (state) => {
                state.message = 'Budget updated';
                setSuccess(state);
            })
            .addCase(updateBudget.rejected, (state) => {
                state.message = 'Could not update budget';
                setError(state);
            })
            .addCase(updateCurrentUser.fulfilled, (state) => {
                state.message = 'User updated';
                setSuccess(state);
            })
            .addCase(updateCurrentUser.rejected, (state, action) => {
                state.message = action.payload;
                setError(state);
            })
            .addCase(updateDashboard.fulfilled, (state) => {
                state.message = 'Dashboard updated';
                setSuccess(state);
            })
            .addCase(updateDashboard.rejected, (state) => {
                state.message = 'Could not update dashboard';
                setError(state);
            })
            .addCase(updateEpic.fulfilled, (state) => {
                state.message = 'Epic updated';
                setSuccess(state);
            })
            .addCase(updateEpic.rejected, (state) => {
                state.message = 'Could not update epic';
                setError(state);
            })
            .addCase(updateMilestone.fulfilled, (state) => {
                state.message = 'Milestone updated';
                setSuccess(state);
            })
            .addCase(updateMilestone.rejected, (state) => {
                state.message = 'Could not update milestone';
                setError(state);
            })
            .addCase(updateProfile.fulfilled, (state) => {
                state.message = 'Job profile updated';
                setSuccess(state);
            })
            .addCase(updateProfile.rejected, (state) => {
                state.message = 'Could not update job profile';
                setError(state);
            })
            .addCase(updateProject.fulfilled, (state) => {
                state.message = 'Project updated';
                setSuccess(state);
            })
            .addCase(updateProject.rejected, (state) => {
                state.message = 'Could not update project';
                setError(state);
            })
            .addCase(updateEpicEstimatedCompletionDate.fulfilled, (state) => {
                state.message = 'Epic completion date updated';
                setSuccess(state);
            })
            .addCase(updateEpicEstimatedCompletionDate.rejected, (state) => {
                state.message = 'Could not update epic completion date';
                setError(state);
            })
            .addCase(updateEpicEstimatedStartDate.fulfilled, (state) => {
                state.message = 'Epic start date updated';
                setSuccess(state);
            })
            .addCase(updateEpicEstimatedStartDate.rejected, (state) => {
                state.message = 'Could not update epic start date';
                setError(state);
            })
            .addCase(
                updateProjectEstimatedCompletionDate.fulfilled,
                (state) => {
                    state.message = 'Project completion date updated';
                    setSuccess(state);
                },
            )
            .addCase(updateProjectEstimatedCompletionDate.rejected, (state) => {
                state.message = 'Could not update project completion date';
                setError(state);
            })
            .addCase(updateProjectEstimatedStartDate.fulfilled, (state) => {
                state.message = 'Project start date updated';
                setSuccess(state);
            })
            .addCase(updateProjectEstimatedStartDate.rejected, (state) => {
                state.message = 'Could not update project start date';
                setError(state);
            })
            .addCase(updateProjectSchedule.fulfilled, (state) => {
                state.message = 'Project schedule updated';
                setSuccess(state);
            })
            .addCase(updateProjectSchedule.rejected, (state, action) => {
                if (Object.keys(action?.payload?.response?.data?.errors)) {
                    state.message = getErrorMessage(action?.payload?.response?.data?.errors);
                } else {
                    state.message = 'Could not update project schedule';
                }
                setError(state);
            })
            .addCase(updateResourceTimelineEndDate.fulfilled, (state) => {
                state.message = 'Timeline completion date updated';
                setSuccess(state);
            })
            .addCase(updateResourceTimelineEndDate.rejected, (state) => {
                state.message = 'Could not update timeline completion date';
                setError(state);
            })
            .addCase(updateResourceTimelineStartDate.fulfilled, (state) => {
                state.message = 'Timeline start date updated';
                setSuccess(state);
            })
            .addCase(updateResourceTimelineStartDate.rejected, (state) => {
                state.message = 'Could not update timeline start date';
                setError(state);
            })
            .addCase(updateProjectNonLaborExpense.fulfilled, (state) => {
                state.message = 'Additional cost updated';
                setSuccess(state);
            })
            .addCase(updateProjectNonLaborExpense.rejected, (state) => {
                state.message = 'Could not update additional cost';
                setError(state);
            })
            .addCase(updateProjectResourceTimeline.fulfilled, (state) => {
                state.message = 'Resource timeline updated';
                setSuccess(state);
            })
            .addCase(updateProjectResourceTimeline.rejected, (state) => {
                state.message = 'Could not update resource timeline';
                setError(state);
            })
            .addCase(updateResource.fulfilled, (state) => {
                state.message = 'Resource updated';
                setSuccess(state);
            })
            .addCase(updateResource.rejected, (state) => {
                state.message = 'Could not update resource';
                setError(state);
            })
            .addCase(updateTeam.fulfilled, (state) => {
                state.message = 'Team updated';
                setSuccess(state);
            })
            .addCase(updateTeam.rejected, (state) => {
                state.message = 'Could not update team';
                setError(state);
            })
            .addCase(updateTenant.fulfilled, (state) => {
                state.message = 'Tenant updated';
                setSuccess(state);
            })
            .addCase(updateTenant.rejected, (state) => {
                state.message = 'Could not update tenant';
                setError(state);
            })
            .addCase(updateEpicWorkItem.fulfilled, (state) => {
                state.message = 'Work item updated';
                setSuccess(state);
            })
            .addCase(updateEpicWorkItem.rejected, (state) => {
                state.message = 'Could not update work item';
                setError(state);
            })
            .addCase(updateProjectWorkItem.fulfilled, (state) => {
                state.message = 'Work item updated';
                setSuccess(state);
            })
            .addCase(updateProjectWorkItem.rejected, (state) => {
                state.message = 'Could not update work item';
                setError(state);
            })
            .addCase(updateWorkItemStatus.fulfilled, (state) => {
                state.message = 'Work item status updated';
                setSuccess(state);
            })
            .addCase(updateWorkItemStatus.rejected, (state) => {
                state.message = 'Could not update work item status';
                setError(state);
            })
            .addCase(updateProjectWorkItemDescription.fulfilled, (state) => {
                state.message = 'Description updated';
                setSuccess(state);
            })
            .addCase(updateProjectWorkItemDescription.rejected, (state) => {
                state.message = 'Could not update description';
                setError(state);
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.message = 'User updated';
                setSuccess(state);
            })
            .addCase(updateUser.rejected, (state) => {
                state.message = 'Could not update user';
                setError(state);
            })
            .addCase(updateUnavailablePeriod.fulfilled, (state) => {
                state.message = 'Unavailable period updated';
                setSuccess(state);
            })
            .addCase(updateUnavailablePeriod.rejected, (state) => {
                state.message = 'Could not update unavailable period';
                setError(state);
            })
            .addCase(upgradeToProject.fulfilled, (state) => {
                state.message = 'Upgraded to project';
                setError(state);
            })
            .addCase(upgradeToProject.rejected, (state) => {
                state.message = 'Could not upgrade to project';
                setError(state);
            })
            .addCase(updateIntegration.fulfilled, (state) => {
                state.message = 'Integration updated';
                setSuccess(state);
            })
            .addCase(updateIntegration.rejected, (state) => {
                state.message = 'Could not update integration';
                setError(state);
            })
            .addCase(updateProjectPhase.fulfilled, (state) => {
                state.message = 'Phase updated';
                setSuccess(state);
            })
            .addCase(updateProjectPhase.rejected, (state) => {
                state.message = 'Could not update phase';
                setError(state);
            })
            .addCase(addCharacteristicToProject.rejected, (state, action) => {
                if (Object.keys(action.payload?.error)) {
                    state.message = getErrorMessage(action.payload.error);
                } else {
                    state.message = 'Could not add characteristic';
                }
                setError(state);
            })
            .addCase(addEpic.rejected, (state, action) => {
                if (Object.keys(action.payload?.error)) {
                    state.message = getErrorMessage(action.payload.error);
                } else {
                    state.message = 'Failed to add epic';
                }
                setError(state);
            })
            .addCase(addLabelToProject.rejected, (state, action) => {
                if (Object.keys(action.payload?.error)) {
                    state.message = getErrorMessage(action.payload.error);
                } else {
                    state.message = 'Could not add label';
                }
                setError(state);
            })
            .addCase(addLOBToProject.rejected, (state, action) => {
                if (Object.keys(action.payload?.error)) {
                    state.message = getErrorMessage(action.payload.error);
                } else {
                    state.message = 'Could not add Department';
                }
                setError(state);
            })
            .addCase(addWorkItemToProject.rejected, (state, action) => {
                if (Object.keys(action.payload?.error)) {
                    state.message = getErrorMessage(action.payload.error);
                } else state.message = 'Could not add work item';
                setError(state);
            })
            .addCase(addUserCharacteristic.rejected, (state, action) => {
                if (Object.keys(action.payload?.error)) {
                    state.message = getErrorMessage(action.payload.error);
                } else state.message = 'Could not add user characteristic';
                setError(state);
            })
            .addCase(inviteUsers.fulfilled, (state, action) => {
                // show a warning for any users that already existed
                if (action.payload.some((item) => item.userExists)) {
                    state.message = action.payload
                        .reduce(
                            createErrorString,
                            'Could not invite existing user(s): ',
                        )
                        .replace(/,\s*$/, ''); //this regex removes the trailing comma from the msg
                    setWarning(state);
                } else {
                    state.message = 'User(s) invited';
                    setSuccess(state);
                }
            })
            .addCase(inviteUsers.rejected, (state, action) => {
                if (Object.keys(action.payload?.error)) {
                    state.message = getErrorMessage(action.payload.error);
                } else state.message = 'Could not invite users';
                setError(state);
            })
            .addCase(contactUsSubmission.rejected, (state, action) => {
                state.message = action.payload
                    ? action.payload
                    : 'Could not send contact us message';
                setError(state);
            })
            .addCase(handleError.fulfilled, (state, action) => {
                state.message = action.payload.friendlyMessage;
                state.isMessageVisible = true;
                state.messageType = 'error';
            })
            .addCase(handleError.rejected, (state, action) => {
                console.warn(action);
            })
            .addCase(appStart.pending, (state, action) => {
                state.isLoading[trimActionType(action.type)] = true;
            })
            .addCase(appStart.fulfilled, (state, action) => {
                state.isLoading[trimActionType(action.type)] = false;
            })
            .addCase(appStart.rejected, (state, action) => {
                state.isLoading[trimActionType(action.type)] = false;
            })
            .addCase(resigninUser.pending, (state, action) => {
                state.isLoading[trimActionType(action.type)] = true;
            })
            .addCase(resigninUser.fulfilled, (state, action) => {
                state.isLoading[trimActionType(action.type)] = false;
            })
            .addCase(resigninUser.rejected, (state, action) => {
                state.isLoading[trimActionType(action.type)] = false;
            })
            .addCase(requestDemoSubmission.pending, (state, action) => {
                state.isLoading[trimActionType(action.type)] = true;
            })
            .addCase(requestDemoSubmission.fulfilled, (state) => {
                state.message = 'Request submitted successfully!';
                setSuccess(state);
            })
            .addCase(requestDemoSubmission.rejected, (state) => {
                state.message = 'Request could not be submitted';
                setError(state);
            })
            .addCase(signinUser.pending, (state, action) => {
                state.isLoading[trimActionType(action.type)] = true;
            })
            .addCase(signinUser.fulfilled, (state, action) => {
                state.isLoading[trimActionType(action.type)] = false;
            })
            .addCase(signinUser.rejected, (state, action) => {
                state.isLoading[trimActionType(action.type)] = false;
            })
            .addCase(copyProject.fulfilled, (state) => {
                state.message = 'Project Copied';
                setSuccess(state);
            })
            .addCase(copyProject.rejected, (state) => {
                state.message = 'Failed to copy project';
                setError(state);
            })
            .addCase(removeProjectProfile.fulfilled, (state) => {
                state.message = 'Project Profile Removed';
                setSuccess(state);
            })
            .addCase(removeProjectProfile.rejected, (state) => {
                state.message = 'Failed to remove profile from project';
                setError(state);
            })
            .addCase(addProfileToProject.fulfilled, (state) => {
                state.message = 'Project Profile Added';
                setSuccess(state);
            })
            .addCase(addProfileToProject.rejected, (state, action) => {
                if (Object.keys(action?.payload?.response?.data?.errors)) {
                    state.message = getErrorMessage(action?.payload?.response?.data?.errors);
                } else {
                    state.message = 'Failed to add profile to project';
                }
                setError(state);
            })
            .addCase(addSkilledResourceToProjectProfile.fulfilled, (state) => {
                state.message = 'Skilled Resource Added to Project Profile';
                setSuccess(state);
            })
            .addCase(addSkilledResourceToProjectProfile.rejected, (state) => {
                state.message =
                    'Failed to add skilled resource to project profile';
                setError(state);
            })
            .addMatcher(isFulfilled, (state) => {
                state.hasPendingRequest = false;
            })
            .addMatcher(isRejected, (state) => {
                state.hasPendingRequest = false;
            })
            .addMatcher(isPending, (state, action) => {
                const ignoredActions = spinnerIgnoreList.map(
                    (x) => `${x.typePrefix}/pending`,
                );
                if (!ignoredActions.includes(action?.type)) {
                    state.hasPendingRequest = true;
                }
            });
    },
});

export const coreActions = {
    ...CoreSlice.actions,
    ...coreActionsAsync,
};

export default CoreSlice.reducer;
