import { createSelector } from 'reselect';
import { selectorUtils } from 'Utilities/selector-utils';
import Project from 'Models/Project';
import { companySelectors } from '../CompanyState/selectors';
import { userSelectors } from "../UserState/selectors";
import { resourceUtils } from 'Utilities/resource-utility';
import WorkItem from 'Models/WorkItem';
import Attachment from 'Models/Attachment';
import Resource from 'Models/Resource';

const {
    groupProjectsByTeam,
    projectMapperWithFilter,
    workItemMapperWithFilter,
} = selectorUtils;

const { allLinesOfBusiness, allCharacteristics, tenant } = companySelectors;
const { loggedInUser } = userSelectors;

const projectState = (state) => state.projects;
const projectSelector = (state) => state.projects?.list;
const projectFilterOptions = (state) => state.projects?.filterOptions;
const selectedProjectId = (state) => state.projects?.selectedProject;
const previouslySelectedProjectId = (state) =>
    state.projects?.previouslySelectedProject;
const selectedNonLaborExpenseId = (state) =>
    state.projects?.selectedNonLaborExpense;
const selectedEpicId = (state) => state.projects?.selectedEpic;
const selectedEpicIdForWorkItemReporter = (state) => state.projects?.selectedEpicForWorkItemReporter;
const selectedResourceTimelineId = (state) =>
    state.projects?.selectedResourceTimeline;
const selectedWorkItemId = (state) => state.projects?.selectedWorkItem;
const selectedProjectPhaseId = (state) => state.projects.selectedProjectPhase;
const resources = (state) => state.resources?.list;
const teams = (state) => state.teams?.list;
const dateFilter = (state) => state.dashboards?.filterOptions;
const workItemFilterOptions = (state) => state.projects?.workItemFilterOptions;
const workItemWidgetFilterOptions = (state) =>
    state.dashboards?.workItemWidgetFilterOptions;
const projectWidgetFilterOptions = (state) =>
    state.dashboards?.projectWidgetFilterOptions;
const kanbanWidgetFilterOptions = (state) =>
    state.dashboards?.kanbanWidgetFilterOptions;

const latestProjectId = createSelector(
    projectState,
    (state) => state.latestProjectId,
);
const allLabels = createSelector(projectSelector, (projects) => {
    const labels = projects.map((p) => p.labels.map((l) => l.name)).flat();
    return [...new Set(labels)];
});

const allWorkItemLabels = createSelector(projectSelector, (projects) => {
    let labels = [];
    projects.forEach((project) => {
        project?.workItems.forEach((workItem) => {
            workItem?.labels.forEach((label) => {
                labels.push(label.name);
            });
        });
    });

    return [...new Set(labels)];
});

const widthOfDayInPixels = createSelector(
    projectState,
    (state) => state.widthOfDayInPixels,
);

// TODO: refactor filters, combine with backend filtering
const filteredProjects = createSelector(
    projectSelector,
    projectFilterOptions,
    teams,
    (projects, filterOptions, teams) =>
        projects?.length
            ? projectMapperWithFilter(projects, filterOptions, teams)
            : [],
);

const publicFilteredProjects = createSelector(
    projectSelector,
    projectFilterOptions,
    teams,
    (projects, filterOptions, teams) =>
        projects?.length
            ? projectMapperWithFilter(
                projects.filter((project) => !project.isPrivate),
                filterOptions,
                teams
            )
            : []
);

const allProjects = createSelector(projectSelector, loggedInUser, (projects, loggedInUser) =>
    projects?.length
        ? projects
            .slice()
            .filter((project) => (!project.isPrivate || project.createdBy == loggedInUser?.id))
            .sort((x, y) => x.rank - y.rank)
            .map((project) => new Project(project))
        : [],
);

const allPublicProjects = createSelector(projectSelector, loggedInUser, (projects, loggedInUser) =>
    projects?.length
        ? projects
            .slice()
            .filter((project) => (!project.isPrivate))
            .sort((x, y) => x.rank - y.rank)
            .map((project) => new Project(project))
        : [],
);

const allProjectsExceptSelectedProject = createSelector(
    projectSelector,
    selectedProjectId,
    (projects, selectedProjectId) =>
        projects?.length
            ? projects
                .slice()
                .filter((project) => project.id !== selectedProjectId)
                .sort((x, y) => x.rank - y.rank)
                .map((project) => new Project(project))
            : [],
);

const allProjectKeys = createSelector(allProjects, (projects) => {
    const projectKeysMap = {};
    projects.forEach((x) => (projectKeysMap[x.id] = x.projectKey));

    return projectKeysMap;
});

const allPublicProjectKeys = createSelector(allProjects, (projects) => {
    const projectKeysMap = {};
    projects.filter((project) => !project.isPrivate).forEach((x) => (projectKeysMap[x.id] = x.projectKey));

    return projectKeysMap;
});

const allEpics = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects.reduce(
            (acc, project) => (acc = [...acc, ...project.epics]),
            [],
        )
        : [],
);

const allPublicEpics = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects.filter((project) => !project.isPrivate).reduce(
            (acc, project) => (acc = [...acc, ...project.epics]),
            [],
        )
        : [],
);

const allPhases = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects?.reduce(
            (acc, project) => (acc = [...acc, ...project.projectPhases]),
            [],
        )
        : [],
);

const allPublicPhases = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects?.filter((project) => !project.isPrivate).reduce(
            (acc, project) => (acc = [...acc, ...project.projectPhases]),
            [],
        )
        : [],
);

const allNonLaborExpenses = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects.reduce(
            (acc, project) => (acc = [...acc, ...project.nonLaborExpenses]),
            [],
        )
        : [],
);

const allWorkItems = createSelector(
    allProjects,
    allEpics,
    (projects, epics) => {
        let projectWorkItems = [];
        let epicWorkItems = [];

        if (projects?.length)
            projectWorkItems = projects
                .reduce(
                    (acc, project) => (acc = [...acc, ...project.workItems]),
                    [],
                )
                .slice()
                .sort((x, y) => x.rank - y.rank);

        if (epics?.length)
            epicWorkItems = epics
                .reduce((acc, epic) => (acc = [...acc, ...epic.workItems]), [])
                .slice()
                .sort((x, y) => x.rank - y.rank);

        return [...projectWorkItems, ...epicWorkItems];
    },
);

const allPublicWorkItems = createSelector(
    allPublicProjects,
    allPublicEpics,
    (projects, epics) => {
        let projectWorkItems = [];
        let epicWorkItems = [];

        if (projects?.length)
            projectWorkItems = projects
                .reduce(
                    (acc, project) => (acc = [...acc, ...project.workItems]),
                    [],
                )
                .slice()
                .sort((x, y) => x.rank - y.rank);

        if (epics?.length)
            epicWorkItems = epics
                .reduce((acc, epic) => (acc = [...acc, ...epic.workItems]), [])
                .slice()
                .sort((x, y) => x.rank - y.rank);

        return [...projectWorkItems, ...epicWorkItems];
    },
);

const allWorkItemsWithFilter = createSelector(
    allWorkItems,
    workItemWidgetFilterOptions,
    allProjectKeys,
    (workItems, filterOptions, projectKeys) =>
        workItems?.length
            ? workItemMapperWithFilter(workItems, filterOptions, projectKeys)
            : [],
);

const allPublicWorkItemsWithFilter = createSelector(
    allPublicWorkItems,
    workItemWidgetFilterOptions,
    allPublicProjectKeys,
    (workItems, filterOptions, projectKeys) =>
        workItems?.length
            ? workItemMapperWithFilter(workItems, filterOptions, projectKeys)
            : [],
);

const allProjectsWithWidgetFilter = createSelector(
    allPublicProjects,
    projectWidgetFilterOptions,
    teams,
    (projects, filterOptions, teams) =>
        projects?.length
            ? projectMapperWithFilter(projects, filterOptions, teams)
            : [],
);

const allWorkItemsWithKanbanWidgetFilter = createSelector(
    allPublicWorkItems,
    kanbanWidgetFilterOptions,
    allPublicProjectKeys,
    (workItems, filterOptions, projectKeys) =>
        workItems?.length
            ? workItemMapperWithFilter(workItems, filterOptions, projectKeys)
            : [],
);

const allPhasesOnKanbanWidgetSelectedProjects = createSelector(
    allPublicProjects,
    kanbanWidgetFilterOptions,
    teams,
    allPublicPhases,
    (projects, filterOptions, teams) => {
        const filteredProjects = projects?.length
            ? projectMapperWithFilter(projects, filterOptions, teams)
            : [];

        const phases = filteredProjects?.reduce((acc, val, idx, currentArr) => {
            currentArr?.forEach((project) => {
                const phases = project.projectPhases;
                if (phases.length > 0) {
                    acc.push(...phases);
                }
            });

            return acc;
        }, []);

        return [...new Set(phases)];
    },
);

const allPhasesOnWorkItemTableWidget = createSelector(
    allPublicProjects,
    workItemWidgetFilterOptions,
    teams,
    allPublicPhases,
    (projects, filterOptions, teams) => {
        const filteredProjects = projects?.length
            ? projectMapperWithFilter(projects, filterOptions, teams)
            : [];

        const phases = filteredProjects?.reduce((acc, val, idx, currentArr) => {
            currentArr?.forEach((project) => {
                const phases = project.projectPhases;
                if (phases.length > 0) {
                    acc.push(...phases);
                }
            });

            return acc;
        }, []);

        return [...new Set(phases)];
    },
);

const allEpicsOnKanbanWidgetSelectedProjects = createSelector(
    allPublicProjects,
    kanbanWidgetFilterOptions,
    teams,
    allPublicEpics,
    (projects, filterOptions, teams) => {
        const filteredProjects = projects?.length
            ? projectMapperWithFilter(projects, filterOptions, teams)
            : [];

        const epics = filteredProjects?.reduce((acc, val, idx, currentArr) => {
            currentArr?.forEach((project) => {
                const epics = project.epics;
                if (epics.length > 0) {
                    acc.push(...epics);
                }
            });

            return acc;
        }, []);

        return [...new Set(epics)];
    },
);

const allEpicsOnWorkItemTableWidget = createSelector(
    allPublicProjects,
    workItemWidgetFilterOptions,
    teams,
    allPublicEpics,
    (projects, filterOptions, teams) => {
        const filteredProjects = projects?.length
            ? projectMapperWithFilter(projects, filterOptions, teams)
            : [];

        const epics = filteredProjects?.reduce((acc, val, idx, currentArr) => {
            currentArr?.forEach((project) => {
                const epics = project.epics;
                if (epics.length > 0) {
                    acc.push(...epics);
                }
            });

            return acc;
        }, []);

        return [...new Set(epics)];
    },
);

const allColors = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects.map((project) => project.color).filter((n) => n)
        : null,
);

const groupedProjects = createSelector(
    allProjects,
    projectFilterOptions,
    (projects, filterOptions) =>
        filterOptions?.selectedGroupBy?.toLowerCase() === 'team'
            ? groupProjectsByTeam(projects)
            : null,
);

const filterOptions = createSelector(
    projectFilterOptions,
    (filterOptions) => filterOptions,
);

const selectedProjectForIdealProgressChart = createSelector(
    allProjects,
    selectedProjectId,
    (projects, projectId) => {
        const project = projects?.find((project) => project.id === projectId);
        if (!project) {
            return null;
        }

        return {
            ...project,
            totalEstimatedPoints: project.totalEstimatedPoints,
            totalHoursEstimate: project.totalHoursEstimate,
        };
    },
);

const selectedProject = createSelector(
    allProjects,
    resources,
    selectedProjectId,
    (projects, resources, projectId) => {
        if (projects?.length && projectId) {
            const project = projects.find(
                (project) => project.id === projectId,
            );

            const augmentedWorkItems = [];
            if (project?.workItems.length) {
                project.workItems.forEach((item) => {
                    const newWorkItem = new WorkItem({
                        ...item,
                        resourceNames: item.assignedSkilledResources?.length
                            ? item.assignedSkilledResources.map((id) =>
                                resourceUtils.getInitialsFromName(
                                    resources.find(
                                        (resource) => resource.id === id,
                                    )?.name,
                                ),
                            )
                            : [],
                    });
                    augmentedWorkItems.push(newWorkItem);
                });
                project.workItems = augmentedWorkItems;
            }

            return new Project(project);
        } else {
            return null;
        }
    },
);

const selectedProjectAttachments = createSelector(projectState, (state) =>
    state.selectedProjectAttachments.length
        ? state.selectedProjectAttachments.map(
            (attachment) => new Attachment(attachment),
        )
        : [],
);

const selectedWorkItemAttachments = createSelector(projectState, (state) =>
    state.selectedWorkItemAttachments.length
        ? state.selectedWorkItemAttachments.map(
            (attachment) => new Attachment(attachment),
        )
        : [],
);

const selectedProjectAssignedResources = createSelector(
    selectedProject,
    resources,
    (project, resources) => {
        const activeResources = resources?.length
            ? resources.filter((x) => !x.isTerminated)
            : [];
        if (activeResources) {
            const resourcesOnProject = [];
            activeResources?.forEach((r) => {
                const foundWorkItems = project?.workItems?.find((workItem) =>
                    workItem?.assignedSkilledResources.some(
                        (assignee) => assignee === r.id,
                    ),
                );
                if (foundWorkItems) {
                    const isAlreadyInList = resourcesOnProject.find(
                        (x) => x.id === r.id,
                    );
                    if (!isAlreadyInList) {
                        resourcesOnProject.push(new Resource(r));
                    }
                }
            });

            return resourcesOnProject;
        } else return [];
    },
);

const inlineAttachment = createSelector(
    projectState,
    (state) => state.inlineAttachment,
);

const selectedProjectEpics = createSelector(
    selectedProject,
    (project) => project?.epics ?? null,
);

const selectedProjectResourceTimelines = createSelector(
    selectedProject,
    (project) => project?.resourceTimelines ?? null,
);

const selectedProjectIntegrationKey = createSelector(
    selectedProject,
    (project) => project?.integrationKey ?? null,
);

const selectedProjectNonLaborExpenses = createSelector(
    selectedProject,
    (project) => project?.nonLaborExpenses ?? null,
);

const selectedProjectWorkItems = createSelector(
    selectedProject,
    (project) => project?.workItems.sort((x, y) => x.rank - y.rank) ?? null,
);

const workItemFilters = createSelector(
    workItemFilterOptions,
    (filterOptions) => filterOptions,
);

const selectedProjectFilteredWorkItems = createSelector(
    selectedProjectWorkItems,
    workItemFilterOptions,
    allProjectKeys,
    (workItems, filterOptions, projectKeys) =>
        workItems?.length
            ? workItemMapperWithFilter(workItems, filterOptions, projectKeys)
            : [],
);

const selectedPublicProjectFilteredWorkItems = createSelector(
    selectedProjectWorkItems,
    workItemFilterOptions,
    allPublicProjectKeys,
    (workItems, filterOptions, projectKeys) =>
        workItems?.length
            ? workItemMapperWithFilter(workItems, filterOptions, projectKeys)
            : [],
);

const allTodoStatusWorkItems = createSelector(
    allWorkItemsWithKanbanWidgetFilter,
    (workItems) =>
        workItems.length
            ? workItems.filter(
                (workItem) => workItem.status === WorkItem.todoStatusId,
            )
            : [],
);

const todoStatusWorkItems = createSelector(
    selectedProjectFilteredWorkItems,
    (workItems) =>
        workItems.length
            ? workItems.filter(
                (workItem) => workItem.status === WorkItem.todoStatusId,
            )
            : [],
);

const allInProgressStatusWorkItems = createSelector(
    allWorkItemsWithKanbanWidgetFilter,
    (workItems) =>
        workItems.length
            ? workItems.filter(
                (workItem) => workItem.status === WorkItem.inProgressId,
            )
            : [],
);

const inProgressStatusWorkItems = createSelector(
    selectedProjectFilteredWorkItems,
    (workItems) =>
        workItems.length
            ? workItems.filter(
                (workItem) => workItem.status === WorkItem.inProgressId,
            )
            : [],
);

const allDoneStatusWorkItems = createSelector(
    allWorkItemsWithKanbanWidgetFilter,
    tenant,
    (workItems, tenant) =>
        workItems.length
            ? workItems.filter(
                (workItem) =>
                    workItem.status === WorkItem.doneStatusId &&
                    tenant.completedWorkItemArchiveDate <
                    new Date(workItem.resolutionDate),
            )
            : [],
);

const doneStatusWorkItems = createSelector(
    selectedProjectFilteredWorkItems,
    tenant,
    (workItems, tenant) =>
        workItems.length
            ? workItems.filter(
                (workItem) =>
                    workItem.status === WorkItem.doneStatusId &&
                    tenant.completedWorkItemArchiveDate <
                    new Date(workItem.resolutionDate),
            )
            : [],
);

//work items that are not assigned to a phase
const unassignedWorkItems = createSelector(
    selectedProjectFilteredWorkItems,
    (workItems) =>
        workItems?.length
            ? workItems.filter((workItem) => workItem?.projectPhaseId === null)
            : [],
);

const selectedProjectPhases = createSelector(
    selectedProject,
    (project) => project?.projectPhases.sort((x, y) => x.rank - y.rank) ?? null,
);

const selectedProjectKeyResults = createSelector(
    selectedProject,
    (project) =>
        project?.keyResults?.slice().sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        }) ?? null,
);

const selectedEpic = createSelector(allEpics, selectedEpicId, (epics, id) =>
    epics?.length ? epics.find((epic) => epic.id === id) : null,
);

const selectedEpicForWorkItemReporter = createSelector(allEpics, selectedEpicIdForWorkItemReporter, (epics, id) =>
    epics?.length ? epics.find((epic) => epic.id === id) : null,
);

const selectedNonLaborExpense = createSelector(
    allNonLaborExpenses,
    selectedNonLaborExpenseId,
    (costs, id) =>
        costs?.length ? costs.find((cost) => cost.id === id) : null,
);

const selectedPhase = createSelector(
    selectedProjectPhases,
    selectedProjectPhaseId,
    (projectPhases, id) =>
        projectPhases?.length && id
            ? projectPhases.find((phase) => phase.id === id)
            : null,
);

const selectedWorkItem = createSelector(
    allWorkItems,
    selectedWorkItemId,
    (workItems, id) =>
        workItems?.length && id
            ? workItems.find((item) => item.id === id)
            : null,
);

const hasSelectedWorkItemDescriptionChanged = createSelector(
    projectState,
    (state) => state.hasSelectedWorkItemDescriptionChanged,
);

const selectedWorkItemComments = createSelector(
    selectedWorkItem,
    (workItem) => {
        if (workItem?.comments) {
            return workItem.comments.slice().sort((a, b) => {
                if (a.created < b.created) {
                    return 1;
                }
                if (a.created > b.created) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        }
    },
);

const selectedWorkItemHistory = createSelector(selectedWorkItem, (workItem) => {
    if (workItem?.history) {
        return workItem.history.slice().sort((a, b) => {
            if (a.created < b.created) {
                return 1;
            }
            if (a.created > b.created) {
                return -1;
            }
            return 0;
        });
    }
});

const allResourceTimelines = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects.reduce(
            (acc, project) => (acc = [...acc, ...project.resourceTimelines]),
            [],
        )
        : [],
);

const allPublicResourceTimelines = createSelector(allProjects, (projects) =>
    projects?.length
        ? projects.filter((project) => !project.isPrivate).reduce(
            (acc, project) => (acc = [...acc, ...project.resourceTimelines]),
            [],
        )
        : [],
);

const selectedResourceTimeline = createSelector(
    allResourceTimelines,
    selectedResourceTimelineId,
    (timelines, id) =>
        timelines?.length && id
            ? timelines.find((timeline) => timeline.id === id)
            : null,
);

const selectedProjectHistory = createSelector(
    selectedProject,
    (project) => project?.history,
);

// Comments sorted in reverse chronological order (newest first)
const selectedProjectComments = createSelector(selectedProject, (project) => {
    if (project?.comments) {
        return project.comments.slice().sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });
    }
});

const businessValueOptions = createSelector(
    (state) => state.projects.businessValueOptions,
    (options) => options,
);

const levelOfEffortOptions = createSelector(
    (state) => state.projects.levelOfEffortOptions,
    (options) => options,
);

const riskOptions = createSelector(
    (state) => state.projects.riskOptions,
    (options) => options,
);

const statusOptions = createSelector(
    (state) => state.projects.statusOptions,
    (options) => options,
);

const workItemTypeOptions = createSelector(
    (state) => state.projects.workItemTypeOptions,
    (options) => options,
);

const workItemStatusOptions = createSelector(
    (state) => state.projects.workItemStatusOptions,
    (options) => options,
);

const selectedLinesOfBusiness = createSelector(selectedProject, (project) =>
    project?.linesOfBusiness?.length ? project.linesOfBusiness : [],
);

const selectedCharacteristics = createSelector(selectedProject, (project) =>
    project?.characteristics?.length ? project.characteristics : [],
);

const selectedLabels = createSelector(selectedProject, (project) =>
    project?.labels?.length ? project.labels : [],
);

const availableLabelNamesForSelectedProject = createSelector(
    allLabels,
    selectedLabels,
    (all, selected) =>
        all?.length && selected?.length
            ? all.filter((a) => !selected.find((b) => b.name === a))
            : all,
);

const availableLOBNamesForSelectedProject = createSelector(
    allLinesOfBusiness,
    selectedLinesOfBusiness,
    (all, selected) =>
        all?.length && selected?.length
            ? all
                .filter((a) => !selected.find((b) => b.id === a.id))
                ?.map((c) => c.name)
            : all?.map((c) => c.name),
);

const availableCharacteristicsNamesForSelectedProject = createSelector(
    allCharacteristics,
    selectedCharacteristics,
    (all, selected) =>
        all?.length && selected?.length
            ? all
                .filter((a) => !selected.find((b) => b.id === a.id))
                ?.map((c) => c.name)
            : all?.map((c) => c.name),
);

const selectedProjectTags = createSelector(selectedProject, (project) => {
    if (!project) return null;
    const tags = [];
    const { characteristics, labels, linesOfBusiness } = project;

    characteristics?.forEach((tag) =>
        tags.push({ tag, type: 'characteristic' }),
    );
    labels?.forEach((tag) => tags.push({ tag, type: 'label' }));
    linesOfBusiness?.forEach((tag) =>
        tags.push({ tag, type: 'lineOfBusiness' }),
    );

    return tags;
});

const selectedProjectChanged = createSelector(
    selectedProjectId,
    previouslySelectedProjectId,
    (previous, current) => previous !== current,
);

const currentWorkItemsView = createSelector(projectState, (projects) => {
    return projects.workItemsView;
});

const isNewEpicForWorkItem = createSelector(
    projectState,
    (state) => state.isNewEpicForWorkItem,
);

const newEpicModalIsForWorkItemReporter = createSelector(
    projectState,
    (state) => state.newEpicModalIsForWorkItemReporter,
);

const projectTableData = createSelector(
    allProjectsWithWidgetFilter,
    dateFilter,
    (projects, dateFilter) => {
        if (projects.length) {
            const data = [];
            projects.forEach((project) => {
                data.push({
                    ...project,
                    totalPointsRemaining: project.totalPointsRemaining,
                    totalPointsComplete: project.totalPointsComplete,
                    percentageCompleteByPoints:
                        project.percentageCompleteByPoints,
                    formattedLabels: project.formattedLabels,
                    formattedLinesOfBusiness: project.formattedLinesOfBusiness,
                    estimatedStartDate:
                        project.estimatedStartDateForProjectTable(dateFilter),
                    estimatedCompletionDate:
                        project.estimatedCompletionDateForProjectTable(
                            dateFilter,
                        ),
                    totalPoints: project.totalPointsWithDateFilter(dateFilter),
                    estimatedCost:
                        project.estimatedCostWithDateFilter(dateFilter),
                    estimatedValue:
                        project.estimatedValueWithDateFilter(dateFilter),
                    estimatedMarginAmount:
                        project.estimatedMarginAmountWithDateFilter(dateFilter),
                    plannedCost: project.plannedCostWithDateFilter(dateFilter),
                    actualCost: project.actualCostWithDateFilter(dateFilter),
                });
            });
            return data;
        } else return [];
    },
);

const allProjectCharacteristics = createSelector(projectSelector, (projects) =>
    projects
        .slice()
        .sort((x, y) => x.rank - y.rank)
        .map(
            (x) =>
            ({
                id: x.id,
                name: x.name,
                characteristics: x.characteristics,
            } || []),
        ),
);

const allWorkItemsForNavPopper = createSelector(projectSelector, (projects) => {
    const workItemsMap = new Map();

    projects?.forEach((proj) =>
        proj.workItems.forEach((wi) =>
            workItemsMap.set(wi.id, {
                id: wi.id,
                projectId: wi.projectId,
                name: wi.name,
                workItemKey: `${wi.projectKey}-${wi.number}`,
            }),
        ),
    );

    return workItemsMap;
});

const allWorkItemsMap = createSelector(projectSelector, (projects) => {
    const workItemsMap = new Map();

    projects?.forEach((proj) =>
        proj.workItems.forEach((wi) => workItemsMap.set(wi.id, wi)),
    );

    return workItemsMap;
});

const allNonArchivedWorkItemsMap = createSelector(
    projectSelector,
    tenant,
    (projects, tenant) => {
        const nonArchivedWorkItemsMap = new Map();
        const cutoffDate = tenant?.completedWorkItemArchiveDate;

        projects?.forEach((proj) =>
            proj?.workItems?.forEach((wi) => {
                const isDoneStatus = wi.status === WorkItem.doneStatusId;
                const isAfterCutOffDate =
                    !!wi.resolutionDate &&
                    cutoffDate >= new Date(wi.resolutionDate);

                if (!isDoneStatus || !isAfterCutOffDate) {
                    nonArchivedWorkItemsMap.set(wi.id, wi);
                }
            }),
        );

        return nonArchivedWorkItemsMap;
    },
);

export const projectSelectors = {
    allColors,
    allEpics,
    allPublicEpics,
    allPhases,
    allPublicPhases,
    allLabels,
    allLinesOfBusiness,
    allNonLaborExpenses,
    allProjects,
    allPublicProjects,
    allProjectsExceptSelectedProject,
    allPhasesOnKanbanWidgetSelectedProjects,
    allEpicsOnKanbanWidgetSelectedProjects,
    allPhasesOnWorkItemTableWidget,
    allEpicsOnWorkItemTableWidget,
    allProjectKeys,
    allPublicProjectKeys,
    allResourceTimelines,
    allPublicResourceTimelines,
    allWorkItems,
    allWorkItemsWithFilter,
    availableCharacteristicsNamesForSelectedProject,
    availableLabelNamesForSelectedProject,
    availableLOBNamesForSelectedProject,
    businessValueOptions,
    groupedProjects,
    inlineAttachment,
    filteredProjects,
    filterOptions,
    levelOfEffortOptions,
    selectedCharacteristics,
    selectedEpic,
    selectedEpicForWorkItemReporter,
    selectedNonLaborExpense,
    selectedLinesOfBusiness,
    selectedProjectForIdealProgressChart,
    selectedProject,
    selectedProjectAttachments,
    selectedWorkItemAttachments,
    selectedProjectEpics,
    selectedProjectAssignedResources,
    selectedProjectId,
    previouslySelectedProjectId,
    selectedProjectChanged,
    selectedProjectIntegrationKey,
    selectedProjectKeyResults,
    selectedProjectNonLaborExpenses,
    selectedProjectTags,
    selectedProjectResourceTimelines,
    selectedProjectWorkItems,
    selectedProjectFilteredWorkItems,
    workItemFilters,
    allTodoStatusWorkItems,
    todoStatusWorkItems,
    allInProgressStatusWorkItems,
    inProgressStatusWorkItems,
    allDoneStatusWorkItems,
    doneStatusWorkItems,
    unassignedWorkItems,
    selectedProjectPhases,
    selectedProjectHistory,
    selectedProjectComments,
    selectedResourceTimeline,
    selectedPhase,
    selectedWorkItem,
    selectedWorkItemComments,
    selectedWorkItemHistory,
    riskOptions,
    statusOptions,
    widthOfDayInPixels,
    workItemTypeOptions,
    workItemStatusOptions,
    latestProjectId,
    allWorkItemLabels,
    currentWorkItemsView,
    isNewEpicForWorkItem,
    newEpicModalIsForWorkItemReporter,
    projectTableData,
    allProjectCharacteristics,
    hasSelectedWorkItemDescriptionChanged,
    allWorkItemsForNavPopper,
    allWorkItemsMap,
    allNonArchivedWorkItemsMap,
    publicFilteredProjects,
    selectedPublicProjectFilteredWorkItems,
    allPublicWorkItems,
    allPublicWorkItemsWithFilter,
};
