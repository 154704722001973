import { Button } from '@mui/material';

export default function SelectButton({ isSelected, onClick }) {
    const handleClick = () => {
        onClick();
    };
    return isSelected ? (
        <Button
            sx={{
                width: '50%',
                background: 'lightGrey',
                '&:hover': {
                    background: 'lightGrey',
                },
            }}
            variant="contained"
            disabled
        >
            Current Plan
        </Button>
    ) : (
        <Button onClick={handleClick} variant="outlined">
            Select Plan
        </Button>
    );
}
