import { useFormik } from 'formik';
import { Suspense, lazy } from 'react';
import * as yup from 'yup';
import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    Divider,
    Stack,
    TextField,
    Typography,
    Tooltip,
} from '@mui/material';
import { phoneRegExp } from 'Utilities/phoneRegExp';
import SectionHeader from 'System/Modals/Components/SectionHeader';
import Form from 'System/Forms/Components/Form';
import { useSelector } from 'react-redux';
import { coreSelectors } from 'System/State/CoreState/selectors';
import TrackProjectProgressPicker from '../../UI/Pickers/TrackProjectProgressPicker/TrackProjectProgressPicker';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CompletedWorkItemArchivePicker from 'System/UI/Pickers/CompletedWorkItemArchivePicker/CompletedWorkItemArchivePicker';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import { PaymentTier } from 'Models/PaymentTiers';

const PhoneNumberInput = lazy(() =>
    import('System/UI/Components/PhoneNumberInput/PhoneNumberInput'),
);

export default function CompanyForm({ tenant, onSubmit }) {
    const showFinancials = useSelector(
        coreSelectors.showGlobalFinancialsIfAllowed,
    );
    const billingFlag = useSelector(featureFlagSelectors.billingFlag);

    const tenantSchema = yup.object().shape({
        name: yup.string().required('Required'),
        phoneNumber: yup.string().matches(phoneRegExp, 'Invalid Phone Number'),
        isRevenueTracked: yup.bool(),
        trackProjectsByPoints: yup.bool(),
        archiveCompletedWorkItemThresholdInDays: yup.number(),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: tenant,
        validationSchema: tenantSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return tenant ? (
        <Form onSubmit={handleSubmit} formId="company-form">
            <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                    <TextField
                        name="name"
                        label="Name"
                        value={values.name}
                        fullWidth
                        required
                        error={showError('name')}
                        helperText={getError('name')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <Divider />

                <SectionHeader text="Contact" />

                <Stack direction="row" spacing={1}>
                    <TextField
                        name="website"
                        label="Website"
                        fullWidth
                        value={values.website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Suspense fallback={<div>Loading...</div>}>
                        <PhoneNumberInput
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone Number"
                            onBlur={handleBlur}
                            onChange={handleChange('phoneNumber')}
                            value={
                                (values.phoneNumber =
                                    values.phoneNumber.replace(
                                        /-| |\(|\)/g,
                                        '',
                                    ))
                            }
                            error={showError('phoneNumber')}
                            helperText={getError('phoneNumber')}
                            size="small"
                        ></PhoneNumberInput>
                    </Suspense>
                </Stack>

                <Divider />

                <SectionHeader text="Address" />

                <TextField
                    name="street1"
                    label="Street 1"
                    fullWidth
                    value={values.street1}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextField
                    name="street2"
                    label="Street 2"
                    fullWidth
                    value={values.street2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Stack direction="row" spacing={1}>
                    <TextField
                        name="city"
                        label="City"
                        fullWidth
                        value={values.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="state"
                        label="State"
                        value={values.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <Stack direction="row" spacing={1}>
                    <TextField
                        name="zipCode"
                        label="Postal Code"
                        fullWidth
                        value={values.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="country"
                        label="Country"
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                {showFinancials &&
                ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
                    !billingFlag) ? (
                    <>
                        <Divider sx={{ root: { marginTop: 0 } }} />

                        <SectionHeader
                            text="Financials"
                            tooltipText="Fairview always tracks cost, but enabling revenue tracking gives visibility into revenue and margin at the resource, project and company levels."
                        />

                        <Stack direction="row" spacing={1}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isRevenueTracked"
                                            checked={values.isRevenueTracked}
                                            onChange={handleChange}
                                        ></Checkbox>
                                    }
                                    label={
                                        <Typography variant="body1">
                                            I&apos;d like to track my
                                            company&apos;s revenue
                                        </Typography>
                                    }
                                />
                            </FormGroup>
                        </Stack>
                    </>
                ) : null}

                <Divider />

                <SectionHeader text="Projects" />

                <Stack direction={'column'} spacing={1}>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={1}
                        >
                            <Typography variant="body1">
                                How do you track progress?
                            </Typography>

                            <Tooltip
                                title={
                                    'Set the default for tracking progress completion by points, hours, or work items. Can be overridden at the project level.'
                                }
                                arrow
                                placement="top-start"
                            >
                                <InfoOutlinedIcon
                                    fontSize="small"
                                    sx={{ color: '#999' }}
                                />
                            </Tooltip>
                        </Stack>

                        <TrackProjectProgressPicker
                            trackProjectsBy={values.trackProjectsByPoints}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                        />
                    </Stack>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            spacing={1}
                        >
                            <Typography variant="body1">
                                Archive completed work items after...
                            </Typography>

                            <Tooltip
                                title={
                                    'Completed work items that get archived after the selected amount of time will fall off the ‘Done’ column on the Work Item Kanban board. These items can still be found within the Project Work Item list.'
                                }
                            >
                                <InfoOutlinedIcon
                                    fontSize="small"
                                    sx={{ color: '#999' }}
                                />
                            </Tooltip>
                        </Stack>

                        <CompletedWorkItemArchivePicker
                            archiveWorkItemsAfter={
                                values?.archiveCompletedWorkItemThresholdInDays
                            }
                            onChange={(e) => {
                                setFieldValue(
                                    'archiveCompletedWorkItemThresholdInDays',
                                    e.target.value,
                                    false,
                                );
                                handleChange(e);
                            }}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Form>
    ) : null;
}
