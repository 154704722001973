import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from '../../State/ModalState/selectors';
import { modalActions } from '../../State/ModalState/reducer';
import CopyProjectForm from '../../Forms/ProjectForms/CopyProjectForm';
import { projectSelectors } from '../../State/ProjectState/selectors';
import { projectActions } from '../../State/ProjectState/reducer';
import { useNavigate } from 'react-router-dom';

export default function CopyProjectModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isModalOpen = useSelector(modalSelectors.copyProjectModal);
    const selectedProject = useSelector(projectSelectors.selectedProject);

    const handleClose = () => dispatch(modalActions.close('copyProjectModal'));
    const handleSubmit = (project) => {
        dispatch(
            projectActions.copyProject({
                originalProjectId: selectedProject?.id,
                project: {
                    ...project,
                    estimatedCompletionDate:
                        project.estimatedCompletionDate || null,
                    estimatedStartDate: project.estimatedStartDate || null,
                },
            }),
        ).then((pld) => {
            const newProjectId = pld?.payload?.res?.data;
            if (!newProjectId) {
                return;
            }
            handleClose();
            navigate(`/projects/${newProjectId}`);
        });
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle>Copy Project</DialogTitle>

            <DialogContent>
                <CopyProjectForm
                    originalProjectName={selectedProject?.name}
                    onSubmit={handleSubmit}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="copy-project-form"
                >
                    Copy Project
                </Button>
            </DialogActions>
        </Dialog>
    );
}
