import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Typography,
    Stack,
    Box,
    Link,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { paneActions } from 'System/State/PaneState/reducer';
import { paymentSelectors } from 'System/State/PaymentState/selectors';
import { companySelectors } from 'System/State/CompanyState/selectors';
import moment from 'moment';
import { paymentActions } from 'System/State/PaymentState/reducer';

export default function CancelSubscriptionModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const open = useSelector(modalSelectors.cancelSubscriptionModal) || false;
    const activeUsers = useSelector(companySelectors.activeUsersList);
    const subscriptionInfo = useSelector(paymentSelectors.subscriptionInfo);

    const currentPaymentPeriodEnd = subscriptionInfo?.currentPeriodEnd
        ? moment(subscriptionInfo?.currentPeriodEnd).format('MMMM Do, YYYY')
        : moment().format('MMMM Do, YYYY');

    const handleClose = () => {
        dispatch(modalActions.close('cancelSubscriptionModal'));
    };

    const handleContactUs = () => {
        handleClose();
        dispatch(paneActions.closeAll());
        navigate('/contact-us');
    };

    const handleConfirmCancel = () => {
        dispatch(paymentActions.cancelSubscription());
        handleClose();
    };

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle>Change Plan</DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={3}>
                    <Typography variant="h3" fontWeight={600}>
                        We&apos;re sorry to see you go!
                    </Typography>
                    <Box bgcolor="#F7D8AF">
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            m={2}
                        >
                            <ErrorOutlineIcon sx={{ fill: 'red' }} />
                            <Typography variant="body2">
                                Are you sure? After {currentPaymentPeriodEnd}{' '}
                                you will not have the ability to invite more
                                users or use integrations.{' '}
                                <b> {activeUsers?.length - 1}</b> current users
                                will lose access to their accounts.
                            </Typography>
                        </Stack>
                    </Box>
                    <Typography variant="body2">
                        If you would like to proceed with canceling your
                        subscription, please select <br /> &quot;Cancel
                        Subscription&quot;.
                    </Typography>
                    <Typography variant="body2">
                        Questions or feedback?{' '}
                        <Link
                            onClick={handleContactUs}
                            sx={{ cursor: 'pointer', color: '#FFAB00' }}
                        >
                            Contact Us.
                        </Link>{' '}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmCancel}
                >
                    Cancel Subscription
                </Button>
            </DialogActions>
        </Dialog>
    );
}
