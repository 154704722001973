const getInitialsFromName = (name) =>
    name
        ?.trim()
        .split(' ')
        .reduce((acc, curr, index, arr) => {
            if (index === 0 || index === arr.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');

const getOverlappingTimelines = (resourceTimelines, timelineA) => {
    let overlapping = [];
    resourceTimelines?.forEach((timelineB) => {
        if (_dateRangesOverlap(timelineA, timelineB)) {
            overlapping.push(timelineB);
        }
    });
    overlapping = [...overlapping, timelineA];

    return overlapping.sort((a, b) => {
        return (a.startDate > b.startDate) - (a.startDate < b.startDate);
    });
};

const getOverlappingPeriods = (timelines) => {
    const overlaps = [];

    for (let i = 0; i < timelines.length; i++) {
        const timelineA = timelines[i];

        for (let j = i + 1; j < timelines.length; j++) {
            const timelineB = timelines[j];

            if (
                _dateRangesOverlap(timelineA, timelineB) &&
                _isOverAllocated(timelineA, timelineB)
            ) {
                const overlapStart = new Date(
                    Math.max(timelineA.startDate, timelineB.startDate),
                );
                const overlapEnd = new Date(
                    Math.min(timelineA.endDate, timelineB.endDate),
                );
                const overlapId = `${overlapStart.toISOString()}|${overlapEnd.toISOString()}`;

                if (!overlaps.some((o) => o.id === overlapId)) {
                    const overlap = {
                        startDate: overlapStart,
                        endDate: overlapEnd,
                        id: overlapId,
                    };

                    overlaps.push(overlap);
                }
            }
        }
    }

    return _mergeOverlappingDateRanges(overlaps);
};

const getCurrentOverlappingTimelines = (timelines) => {
    const currentDate = new Date();

    return timelines
        .filter((timelineA, index) => {
            let overlapCount = 0;

            if (_isCurrentTimeline(timelineA, currentDate)) {
                // Check if timeline overlaps with any other timeline over today's date
                for (let i = 0; i < timelines.length; i++) {
                    if (i !== index) {
                        const timelineB = timelines[i];

                        if (
                            _dateRangesOverlap(timelineA, timelineB) &&
                            _isCurrentTimeline(timelineB, currentDate)
                        ) {
                            overlapCount++;
                        }
                    }
                }
            }

            return overlapCount > 0; // Return true if there are overlaps, false otherwise
        })
        .sort((a, b) => {
            return (a.startDate > b.startDate) - (a.startDate < b.startDate);
        });
};

// ~~~~~ Helper functions ~~~~~

const _isCurrentTimeline = (timeline, currentDate) =>
    timeline.startDate <= currentDate && timeline.endDate >= currentDate;

const _dateRangesOverlap = (timelineA, timelineB) =>
    timelineA.startDate <= timelineB.endDate &&
    timelineA.endDate >= timelineB.startDate;

const _isOverAllocated = (timelineA, timelineB) =>
    timelineA.percentageAllocated + timelineB.percentageAllocated > 100;

const _mergeOverlappingDateRanges = (dateRanges) => {
    const sorted = dateRanges.sort(
        (a, b) => a.startDate.getTime() - b.startDate.getTime(),
    );

    const merged = sorted.reduce((acc, curr) => {
        if (acc.length === 0) {
            return [curr];
        }

        const prev = acc.pop();

        if (curr.endDate <= prev.endDate) {
            return [...acc, prev];
        }

        if (curr.startDate <= prev.endDate) {
            const endDate = new Date(
                Math.max(prev.endDate.getTime(), curr.endDate.getTime()),
            );
            const mergedRange = {
                startDate: prev.startDate,
                endDate: endDate,
                id: `${prev.startDate.toISOString()}|${endDate.toISOString()}`,
            };
            return [...acc, mergedRange];
        }

        return [...acc, prev, curr];
    }, []);

    return merged;
};

export const resourceUtils = {
    getInitialsFromName,
    getOverlappingPeriods,
    getOverlappingTimelines,
    getCurrentOverlappingTimelines,
};
