import { Paper, Typography, Stack, Box, Tooltip } from '@mui/material';

export default function SubscriptionHeader({ planInfo, isCurrent = false }) {
    return (
        <Paper
            elevation={2}
            sx={{
                width: '100%',
                height: '250px',
            }}
        >
            <div style={{ height: '10%' }}>
                <Typography variant="body3" pl={3}>
                    {isCurrent ? 'Current' : 'New'} plan
                </Typography>
            </div>
            <Box
                sx={{
                    background: planInfo?.color,
                    height: '80%',
                }}
            >
                <Stack
                    height="100%"
                    direction="column"
                    justifyContent="center"
                    padding={3}
                >
                    <Typography
                        variant="h2"
                        fontWeight={600}
                        mb={0}
                        mt={0}
                        pt={0}
                    >
                        Fairview
                    </Typography>
                    <Typography
                        variant="h2"
                        fontWeight={600}
                        mb={0}
                        mt={0}
                        pt={0}
                    >
                        {planInfo?.name}
                    </Typography>
                    {planInfo?.pricePerUser !== 0 && (
                        <Typography pt={2} fontSize={20}>
                            ${planInfo?.pricePerUser}/mo{' '}
                            <Tooltip title="Fairview will prorate your users by the duration they were active during the month.">
                                <span
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                >
                                    per user
                                </span>
                            </Tooltip>
                        </Typography>
                    )}
                </Stack>
            </Box>
            <div style={{ height: '10%' }}></div>
        </Paper>
    );
}
