import { createSlice } from '@reduxjs/toolkit';
import { paymentActionsAsync } from './actions';

const {
    createCheckoutSession,
    createCustomerPortalSession,
    getPrices,
    getSubscriptionInfo,
    updateSubscriptionTier,
} = paymentActionsAsync;

const initialState = {
    prices: [],
    subscriptionInfo: {},
    upgradePromptText: '',
    isLoading: false,
};

export const PaymentSlice = createSlice({
    name: 'payments',
    initialState: initialState,
    reducers: {
        setUpgradePromptText(state, action) {
            state.upgradePromptText = action.payload;
        },
        clearUpgradePromptText(state) {
            state.upgradePromptText = null;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
    extraReducers: {
        [createCheckoutSession.fulfilled]: (state, action) => {
            if (action?.payload) window.location.replace(action.payload);
        },
        [createCustomerPortalSession.fulfilled]: (state, action) => {
            if (action?.payload) window.location.replace(action.payload);
        },
        [getPrices.fulfilled]: (state, action) => {
            state.prices = action.payload;
        },
        [getSubscriptionInfo.fulfilled]: (state, action) => {
            state.subscriptionInfo = action.payload;
        },
        [updateSubscriptionTier.rejected]: (state, action) => {
            state.isLoading = false;
        },
    },
});

export const paymentActions = {
    setUpgradePromptText: PaymentSlice.actions.setUpgradePromptText,
    clearUpgradePromptText: PaymentSlice.actions.clearUpgradePromptText,
    setIsLoading: PaymentSlice.actions.setIsLoading,
    ...paymentActionsAsync,
};

export default PaymentSlice.reducer;
