import { createSlice } from '@reduxjs/toolkit';
import User from 'Models/User';
import { userActionsAsync } from './actions';
import { workItemActions } from '../ProjectState/reducer';
import {
    addToOrUpdateInList,
    removeIdFromIdList,
} from 'Utilities/reducer-utils';

const {
    addUserSentiment,
    confirmCookieConsent,
    completeNewPasswordRequirement,
    forgotPassword,
    forgotPasswordConfirmed,
    resigninUser,
    exchangeAuthCodeForToken,
    signinUser,
    signoutUser,
    signupUser,
    signupUserVerification,
    updateCurrentUser,
    updateTableSettings,
    setInitialLoginToFalse,
    deleteUserSentiment,
    getUserSentiments,
} = userActionsAsync;

const { selectWorkItem } = workItemActions;

const initialState = {
    cognitoUserForForcedPassword: null,
    forcePasswordChange: false,
    forgotPasswordUsername: '',
    isVerificationRequired: false,
    loggedInUser: null,
    registrationErrors: null,
    message: '',
    isMessageVisible: false,
    userSentiments: [],
    mostRecentWorkItemIds: [],
};

export const UserSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        forcePasswordChange(state, action) {
            state.forcePasswordChange = true;
            state.cognitoUserForForcedPassword = action.payload;
        },
        hideMessage(state) {
            state.isMessageVisible = false;
        },
        resetSlice() {
            return initialState;
        },
        setPhaseExpanded(state, action) {
            const expandedPhaseIds =
                state.loggedInUser.personalizations.expandedPhaseIds || [];
            state.loggedInUser.personalizations.expandedPhaseIds =
                addToOrUpdateInList(expandedPhaseIds, action.payload);
        },
        unsetPhaseExpanded(state, action) {
            const expandedPhaseIds =
                state.loggedInUser.personalizations.expandedPhaseIds || [];
            state.loggedInUser.personalizations.expandedPhaseIds =
                removeIdFromIdList(expandedPhaseIds, action.payload);
        },
    },
    extraReducers: {
        [addUserSentiment.fulfilled]: (state, action) => {
            const sentimentList = [...state.loggedInUser.sentiments];
            const updatedSentimentList = sentimentList.map((sentiment) => {
                sentiment.canBeDeleted = false;

                return sentiment;
            });

            const updatedUser = {
                ...state.loggedInUser,
                sentiments: [...updatedSentimentList, action.payload],
            };

            state.loggedInUser = updatedUser;
        },
        [completeNewPasswordRequirement.fulfilled]: (state, action) => {
            state.forcePasswordChange = false;
            state.cognitoUserForForcedPassword = null;
        },
        [confirmCookieConsent.fulfilled]: (state, action) => {
            if (state.loggedInUser) {
                state.loggedInUser.isCookieConsent = true;
            }
        },
        [forgotPassword.fulfilled]: (state, action) => {
            state.forgotPasswordUsername = action.payload;
        },
        [forgotPasswordConfirmed.fulfilled]: (state, action) => {
            state.forgotPasswordUsername = '';
        },
        [resigninUser.fulfilled]: (state, action) => {
            state.loggedInUser = new User(action.payload);
            state.mostRecentWorkItemIds =
                action.payload?.personalizations?.mostRecentSelectedWorkItems?.split(
                    '|',
                ) || [];
        },
        [setInitialLoginToFalse.fulfilled]: (state) => {
            state.loggedInUser.isInitialLogin = false;
        },
        [exchangeAuthCodeForToken.fulfilled]: (state, action) => {
            state.loggedInUser = new User(action.payload.user);
            if (state.isVerificationRequired) {
                state.isVerificationRequired = false;
            }
        },
        [exchangeAuthCodeForToken.rejected]: (state, action) => {
            console.log('UH OH', state, action);
        },
        [signinUser.fulfilled]: (state, action) => {
            state.message = '';
            if (action?.payload.code === 'CHANGE_PASSWORD') {
                state.cognitoUserForForcedPassword = action.payload.user;
            } else if (action?.payload.code === 'CONFIRMATION_REQUIRED') {
                return;
            } else {
                state.loggedInUser = new User(action.payload.user);
                state.mostRecentWorkItemIds =
                    action.payload?.user?.personalizations?.mostRecentSelectedWorkItems?.split(
                        '|',
                    ) || [];
                if (state.isVerificationRequired) {
                    state.isVerificationRequired = false;
                }
            }
        },
        [signinUser.rejected]: (state, action) => {
            const parsedErrorMessage = action?.payload?.split('|')[1];
            if (
                action?.error.name === 'UserNotFoundException' ||
                action?.error.name === 'NotAuthorizedException'
            ) {
                state.message = action.error.message;
            } else {
                state.message = parsedErrorMessage;
            }
            state.isMessageVisible = true;
        },
        [signoutUser.fulfilled]: (state) => {
            state.loggedInUser = null;
        },
        [signupUser.fulfilled]: (state, action) => {
            state.isVerificationRequired = true;
        },
        [signupUser.rejected]: (state, action) => {
            state.registrationErrors = action.payload;
        },
        [signupUserVerification.fulfilled]: (state, action) => {
            state.isVerificationRequired = false;
            state.isInitialLogin = false;
        },
        [signupUserVerification.rejected]: (state, action) => {
            state.registrationErrors = action.payload;
        },
        [updateCurrentUser.fulfilled]: (state, action) => {
            state.loggedInUser = new User(action.payload);
            state.mostRecentWorkItemIds =
                action.payload?.personalizations?.mostRecentSelectedWorkItems?.split(
                    '|',
                ) || [];
        },
        [updateTableSettings.fulfilled]: (state, action) => {
            state.loggedInUser = {
                ...state.loggedInUser,
                personalizations: {
                    ...state.loggedInUser.personalizations,
                    [`${action.payload.type}`]:
                        action.payload.updatedTableSettings,
                },
            };
        },
        [deleteUserSentiment.fulfilled]: (state, action) => {
            const sentimentList = [...state.loggedInUser.sentiments];
            const updatedSentimentList = sentimentList.filter(
                (sentiment) => sentiment.id !== action.payload,
            );

            const updatedUser = {
                ...state.loggedInUser,
                sentiments: [...updatedSentimentList],
            };

            state.loggedInUser = updatedUser;
        },
        [getUserSentiments.fulfilled]: (state, action) => {
            state.userSentiments = action.payload;
        },
        [selectWorkItem.fulfilled]: (state, action) => {
            state.mostRecentWorkItemIds = [
                action.payload,
                ...state.mostRecentWorkItemIds
                    .filter((x) => x !== action.payload)
                    .slice(0, 2),
            ];
        },
    },
});

export const userActions = {
    ...UserSlice.actions,
    ...userActionsAsync,
};

export default UserSlice.reducer;
