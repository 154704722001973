import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack, Typography, Box, Link } from '@mui/material';
import { useCookies } from 'react-cookie';
import { makeStyles } from '@mui/styles';
import { userSelectors } from 'System/State/UserState/selectors';
import { userActions } from 'System/State/UserState/reducer';

const cookieConsentStyles = makeStyles({
    cookieConsent: {
        marginTop: '1rem',
        padding: '1rem',
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 5,
        left: 0,
        fontSize: '20px',
        borderRadius: '5px',
        border: '1px solid #FFAB00',
        marginLeft: '0.25rem',
        zIndex: 100000,
        '& a, & a:link, & a:visited, & a:hover, & a:active': {
            color: 'black',
        },
        '& .MuiButton-root': {
            width: '100%',
        },
    },
    cookieConsentHidden: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s linear 300ms, opacity 900ms',
    },
    cookieConsentVisible: {
        visibility: 'visible',
        opacity: 0.95,
        transition: 'visibility 0s linear 0s, opacity 300ms',
    },
});

export default function CookieConsent() {
    const classes = cookieConsentStyles();

    const dispatch = useDispatch();
    const user = useSelector(userSelectors.loggedInUser);
    const isVerificationRequired = useSelector(
        userSelectors.isVerificationRequired,
    );
    const [isCookieConsentHidden, setIsCookieConsentHidden] = useState(false);
    const [cookies, setCookie] = useCookies(['cookieConsent']);
    const [cookieConsent] = useState(
        user ? user.isCookieConsent : cookies?.cookieConsent,
    );

    // set the cookie to expire in a year
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    const cookieSetOptions = {
        path: '/',
        expires: expirationDate,
    };

    if (!isVerificationRequired && cookieConsent == null) {
        return (
            <Box
                className={`${classes.cookieConsent} ${
                    isCookieConsentHidden
                        ? classes.cookieConsentHidden
                        : classes.cookieConsentVisible
                }`}
            >
                <Stack direction="column" spacing={2}>
                    <Typography variant="body1">
                        We use cookies to build a better experience for our
                        users.
                        <br />
                        <Link
                            target="_blank"
                            href="https://www.fairviewapp.com/privacy-policy"
                            rel="noopener noreferrer"
                            underline="always"
                        >
                            Privacy Policy
                        </Link>{' '}
                        |{' '}
                        <Link
                            target="_blank"
                            href="https://www.fairviewapp.com/terms-and-conditions"
                            rel="noopener noreferrer"
                            underline="always"
                        >
                            Terms &amp; Conditions
                        </Link>
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Button
                            color="tertiary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                                setCookie('cookieConsent', false, {
                                    path: '/',
                                });

                                if (user) {
                                    dispatch(
                                        userActions.confirmCookieConsent(false),
                                    );
                                }

                                setIsCookieConsentHidden(true);
                            }}
                        >
                            No thanks, decline!
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                                setCookie(
                                    'cookieConsent',
                                    true,
                                    cookieSetOptions,
                                );

                                if (user) {
                                    dispatch(
                                        userActions.confirmCookieConsent(true),
                                    );
                                }

                                setIsCookieConsentHidden(true);
                            }}
                        >
                            Got it, accept!
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        );
    } else {
        return null;
    }
}
