import { createSelector } from 'reselect';
import User from 'Models/User';
import { companySelectors } from '../CompanyState/selectors';

const { allCharacteristics } = companySelectors;

const userSelector = (state) => state.user;
const usersSelector = (state) => state.company.users;

const cognitoUserForForcedPassword = createSelector(userSelector, (user) => {
    if (user?.cognitoUserForForcedPassword) {
        return user.cognitoUserForForcedPassword;
    } else {
        return null;
    }
});

const forcePasswordChange = createSelector(
    userSelector,
    (user) => user.forcePasswordChange,
);

const forgotPasswordUsername = createSelector(
    userSelector,
    (user) => user.forgotPasswordUsername,
);

const isVerificationRequired = createSelector(
    userSelector,
    (user) => user?.isVerificationRequired,
);

const loggedInUser = createSelector(userSelector, (user) =>
    user?.loggedInUser ? new User(user.loggedInUser) : null,
);

const loggedInUserCharacteristics = createSelector(
    loggedInUser,
    usersSelector,
    (loggedInUser, users) =>
        loggedInUser && users?.length
            ? users.find((user) => user.id === loggedInUser.id)?.characteristics
            : [],
);

const loggedInUserCharacteristicsByType = createSelector(
    loggedInUserCharacteristics,
    (characteristics) => {
        const byType = { loved: [], wanted: [], skilled: [], dreaded: [] };
        characteristics?.forEach((c) => {
            const key = c.type?.toLowerCase();
            if (!Object.hasOwn(byType, key)) {
                byType[key] = [];
            }
            byType[key].push(c);
        });
        return byType;
    },
);

const availableCharacteristicNamesForLoggedInUserByType = createSelector(
    allCharacteristics,
    loggedInUserCharacteristicsByType,
    (all, byType) => {
        if (all?.length && byType) {
            const availableByType = {};

            for (const type in byType) {
                const selected = byType[type];
                const availableNames = all
                    ?.filter(
                        (a) =>
                            !selected?.find((b) => b.characteristicId === a.id),
                    )
                    ?.map((c) => c.name);
                availableByType[type] = availableNames;
            }

            return availableByType;
        }
        return {};
    },
);

const message = createSelector(userSelector, (user) => user?.message ?? '');

const registrationErrors = createSelector(
    userSelector,
    (user) => user.registrationErrors,
);

const isAllowedFinancials = createSelector(loggedInUser, (user) =>
    user ? user.isAllowedFinancials : null,
);

const isAllowedContribute = createSelector(loggedInUser, (user) =>
    user ? user.isAllowedContribute : null,
);

const isAllowedAdmin = createSelector(loggedInUser, (user) =>
    user ? user.isAllowedAdmin : null,
);

const isMessageVisible = createSelector(
    userSelector,
    (user) => user.isMessageVisible,
);

const userSentiments = createSelector(
    loggedInUser,
    (user) =>
        user?.sentiments.sort((a, b) => {
            if (a.created > b.created) {
                return -1;
            }
            if (a.created < b.created) {
                return 1;
            }
            return 0;
        }) ?? [],
);

const projectFilterSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.projectFilterSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.projectFilterSettings,
        );
        return settingsJson;
    }
    return;
});

const workItemFilterSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.workItemFilterSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.workItemFilterSettings,
        );
        return settingsJson;
    }
    return;
});

const resourceFilterSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.resourceFilterSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.resourceFilterSettings,
        );
        return settingsJson;
    }
    return;
});

const resourceTimelineTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.resourceTimelineTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.resourceTimelineTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }

    const settingsJson = {
        columns: {
            columnVisibilityModel: {
                default: true,
                skilledResourceName: true,
                sentiments: false,
                projectName: true,
                hoursPerDay: true,
                actualHourlyCostRate: false,
                actualHourlyBillRate: false,
                startDate: true,
                endDate: true,
                totalDays: true,
                totalCost: true,
                totalRevenue: true,
                totalMargin: true,
                totalCostToDate: false,
                totalRevenueToDate: false,
                totalMarginToDate: false,
                deleteIcon: false,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settingsJson;
});

const resourceTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.resourceTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.resourceTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }

    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                name: true,
                profileName: true,
                teamName: true,
                userId: false,
                hoursPerDay: false,
                defaultAverageVelocity: false,
                hireDate: false,
                terminationDate: false,
                startDateOfFirstAssignedWork: false,
                endDateOfLastAssignedWork: false,
                totalDays: false,
                company: false,
                created: false,
                lastUpdated: false,
                actualSalary: true,
                defaultHourlyCostRate: true,
                totalCost: false,
                defaultHourlyBillRate: true,
                defaultHourlyMargin: true,
                status: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const resourceWorkItemTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.resourceWorkItemTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.resourceWorkItemTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                type: true,
                name: true,
                pointsEstimate: false,
                hoursComplete: true,
                hoursEstimate: false,
                resourceNames: true,
                statusName: true,
                created: true,
                lastUpdated: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const projectTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.projectTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.projectTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }

    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                rank: true,
                name: true,
                formattedLabels: false,
                formattedLinesOfBusiness: false,
                estimatedStartDate: true,
                estimatedCompletionDate: true,
                totalPoints: false,
                totalPointsComplete: false,
                totalPointsRemaining: false,
                percentageCompleteByPoints: true,
                actualStartDate: false,
                actualCompletionDate: false,
                estimatedBusinessValue: true,
                estimatedLevelOfEffort: true,
                created: false,
                lastUpdated: false,
                estimatedCost: true,
                estimatedValue: true,
                estimatedMarginAmount: true,
                plannedCost: true,
                actualCost: false,
                statusName: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const workItemTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.workItemTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.workItemTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                type: true,
                number: true,
                name: true,
                projectName: true,
                resourceNames: true,
                status: true,
                pointsEstimate: true,
                hoursComplete: true,
                hoursEstimate: true,
                created: false,
                lastUpdated: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const labelSummaryTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.labelSummaryTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.labelSummaryTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
            },
        },
        visibilitySettings: {
            id: false,
            name: true,
            estimatedStartDate: true,
            estimatedCompletionDate: true,
            totalPoints: false,
            totalPointsComplete: false,
            totalPointsRemaining: false,
            percentageCompleteByPoints: false,
            actualStartDate: false,
            actualCompletionDate: false,
            estimatedCost: true,
            estimatedValue: true,
            estimatedMarginAmount: true,
            plannedCost: true,
            plannedRevenue: true,
            plannedMarginAmount: true,
            plannedMarginPercentage: true,
            plannedCostToDate: true,
            actualCost: false,
            actualValue: false,
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const userTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.userTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.userTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                id: false,
                emailAddress: true,
                formattedName: true,
                group: true,
                isActive: true,
                lastLogin: false,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const teamTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.teamTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.teamTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    const settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                name: true,
                resources: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const projectProfileTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.projectProfileTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.projectProfileTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    const settingsJson = {
        columns: {
            columnVisibilityModel: {
                default: true,
                expand: true,
                profileName: true,
                profileQuantityNeeded: true,
                sentiments: true,
                projectName: false,
                hoursPerDay: true,
                startDate: true,
                endDate: true,
                totalDays: true,
                actualHourlyCostRate: false,
                actualHourlyBillRate: false,
                totalCost: true,
                totalRevenue: true,
                totalMargin: false,
                totalCostToDate: false,
                totalRevenueToDate: false,
                totalMarginToDate: false,
                deleteIcon: false,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settingsJson;
});

const projectEpicTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.projectEpicTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.projectEpicTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    const settingsJson = {
        columns: {
            columnVisibilityModel: {
                default: true,
                detailPane: true,
                name: true,
                formattedTeams: true,
                formattedLabels: true,
                formattedLinesOfBusiness: true,
                estimatedStartDate: true,
                estimatedCompletionDate: true,
                totalPoints: true,
                totalPointsComplete: true,
                totalPointsRemaining: false,
                percentageCompleteByPoints: false,
                totalHoursEstimate: true,
                totalHoursComplete: true,
                totalHoursRemaining: false,
                percentageCompleteByHours: false,
                actualStartDate: false,
                actualCompletionDate: false,
                statusName: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settingsJson;
});

const projectMilestoneTableSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.projectMilestoneTableSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.projectMilestoneTableSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    const settingsJson = {
        columns: {
            columnVisibilityModel: {
                default: true,
                name: true,
                targetEventDate: true,
                isAchieved: true,
                financialValue: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settingsJson;
});

const projectNonLaborExpenseTableSettings = createSelector(
    loggedInUser,
    (user) => {
        if (user.personalizations.projectNonLaborExpenseTableSettings) {
            const settingsJson = JSON.parse(
                user.personalizations.projectNonLaborExpenseTableSettings,
            );
            if ('columns' in settingsJson) {
                const hasColumnVisibilityModel =
                    'columnVisibilityModel' in settingsJson.columns;
                if (!hasColumnVisibilityModel) {
                    settingsJson.columns.columnVisibilityModel = {
                        default: true,
                    };
                }
                delete settingsJson.preferencePanel;
                return settingsJson;
            }
        }
        const settingsJson = {
            columns: {
                columnVisibilityModel: {
                    default: true,
                    description: true,
                    effectiveDate: true,
                    amountPerEach: true,
                    quantity: true,
                    totalCost: true,
                },
            },
            filterSettings: {
                items: [],
                linkOperator: 'and',
                quickFilterLogicOperator: 'and',
                quickFilterValues: [],
            },
            sortSettings: [],
        };
        return settingsJson;
    },
);

const projectTableWidgetSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.projectTableWidgetSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.projectTableWidgetSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                rank: true,
                name: true,
                formattedLabels: false,
                formattedLinesOfBusiness: false,
                estimatedStartDate: true,
                estimatedCompletionDate: true,
                totalPoints: false,
                totalPointsComplete: false,
                totalPointsRemaining: false,
                percentageCompleteByPoints: true,
                actualStartDate: false,
                actualCompletionDate: false,
                estimatedBusinessValue: true,
                estimatedLevelOfEffort: true,
                created: false,
                lastUpdated: false,
                estimatedCost: true,
                estimatedValue: true,
                estimatedMarginAmount: true,
                plannedCost: true,
                actualCost: false,
                statusName: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const resourceTableWidgetSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.resourceTableWidgetSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.resourceTableWidgetSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                name: true,
                profileName: true,
                teamName: true,
                userId: false,
                hoursPerDay: false,
                defaultAverageVelocity: false,
                hireDate: false,
                terminationDate: false,
                startDateOfFirstAssignedWork: false,
                endDateOfLastAssignedWork: false,
                totalDays: false,
                company: false,
                created: false,
                lastUpdated: false,
                actualSalary: true,
                defaultHourlyCostRate: true,
                totalCost: false,
                defaultHourlyBillRate: true,
                defaultHourlyMargin: true,
                status: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const workItemTableWidgetSettings = createSelector(loggedInUser, (user) => {
    if (user.personalizations.workItemTableWidgetSettings) {
        const settingsJson = JSON.parse(
            user.personalizations.workItemTableWidgetSettings,
        );
        if ('columns' in settingsJson) {
            const hasColumnVisibilityModel =
                'columnVisibilityModel' in settingsJson.columns;
            if (!hasColumnVisibilityModel) {
                settingsJson.columns.columnVisibilityModel = { default: true };
            }
            delete settingsJson.preferencePanel;
            return settingsJson;
        }
    }
    let settings = {
        columns: {
            columnVisibilityModel: {
                default: true,
                type: true,
                number: true,
                name: true,
                projectName: true,
                resourceNames: true,
                status: true,
                pointsEstimate: true,
                hoursComplete: true,
                hoursEstimate: true,
                created: false,
                lastUpdated: true,
            },
        },
        filterSettings: {
            items: [],
            linkOperator: 'and',
            quickFilterLogicOperator: 'and',
            quickFilterValues: [],
        },
        sortSettings: [],
    };
    return settings;
});

const mostRecentWorkItemIds = createSelector(
    userSelector,
    (state) => state.mostRecentWorkItemIds,
);

const expandedPhaseIds = createSelector(
    userSelector,
    (state) => state.loggedInUser?.personalizations?.expandedPhaseIds || [],
);

export const userSelectors = {
    availableCharacteristicNamesForLoggedInUserByType,
    cognitoUserForForcedPassword,
    expandedPhaseIds,
    forcePasswordChange,
    forgotPasswordUsername,
    isAllowedAdmin,
    isAllowedFinancials,
    isAllowedContribute,
    isMessageVisible,
    isVerificationRequired,
    loggedInUser,
    loggedInUserCharacteristicsByType,
    message,
    registrationErrors,
    userSentiments,
    resourceTimelineTableSettings,
    resourceTableSettings,
    resourceWorkItemTableSettings,
    projectTableSettings,
    workItemTableSettings,
    labelSummaryTableSettings,
    userTableSettings,
    teamTableSettings,
    mostRecentWorkItemIds,
    projectProfileTableSettings,
    projectEpicTableSettings,
    projectMilestoneTableSettings,
    projectNonLaborExpenseTableSettings,
    projectTableWidgetSettings,
    resourceTableWidgetSettings,
    workItemTableWidgetSettings,
    projectFilterSettings,
    resourceFilterSettings,
    workItemFilterSettings,
};
