import {
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    IconButton,
    Grid,
    Box,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import CloseIcon from '@mui/icons-material/Close';
import { PaymentTier } from 'Models/PaymentTiers';
import SubscriptionTier from './Components/SubscriptionTier';
import { paymentSelectors } from 'System/State/PaymentState/selectors';
import { paymentActions } from 'System/State/PaymentState/reducer';
import ChangePlanModal from '../ChangePlanModal/ChangePlanModal';
import { useState } from 'react';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';

export default function SubscriptionModal() {
    const dispatch = useDispatch();
    const [isChangePlanModalOpen, setIsChangePlanModalOpen] = useState(false);
    const [upgradeTierId, setUpgradeTierId] = useState(null);
    const open = useSelector(modalSelectors.subscriptionModal) || false;

    const handleClose = () => {
        dispatch(modalActions.close('subscriptionModal'));
        dispatch(paymentActions.clearUpgradePromptText());
    };

    const proPrice = useSelector(paymentSelectors.professionalPrice);
    const enterprisePrice = useSelector(paymentSelectors.enterprisePrice);

    const tenant = useSelector(companySelectors.tenant);

    const upgradePrompt = useSelector(paymentSelectors.upgradePromptText);
    const selectedTier = tenant?.tier ? tenant.tier : PaymentTier.Free;

    const showEnterpriseFlag = useSelector(
        featureFlagSelectors.enterpriseEnabledFlag,
    );

    const handleSelectTier = (tierId) => {
        if (selectedTier == PaymentTier.Free) {
            dispatch(paymentActions.createCheckoutSession(tierId));
            handleClose();
        } else if (
            selectedTier !== PaymentTier.Free &&
            selectedTier !== tierId
        ) {
            setUpgradeTierId(tierId);
            setIsChangePlanModalOpen(true);
        }
    };

    const handleDowngradeToFree = () => {
        setIsChangePlanModalOpen(true);
        setUpgradeTierId(PaymentTier.Free);
    };

    const handleConfirmUpgrade = () => {
        handleCloseChangePlanModal();
        if (upgradeTierId === PaymentTier.Free) {
            dispatch(modalActions.open('cancelSubscriptionModal'));
        } else {
            dispatch(paymentActions.setIsLoading(true));
            dispatch(paymentActions.updateSubscriptionTier(upgradeTierId));
        }

        handleClose();
    };

    const handleCloseChangePlanModal = () => {
        setUpgradeTierId(null);
        setIsChangePlanModalOpen(false);
    };

    const subscriptionTiers = () => {
        let tiers = [];
        if (selectedTier === PaymentTier.Free) {
            tiers.push({
                id: 1,
                tierId: PaymentTier.Free,
                plan: 'Free',
                description:
                    'Fairview Free is for small projects and small teams.',
                tagline: '',
                color: 'lightGrey',
                pricePerUser: 0,
                featureList: [
                    { id: 1, text: 'Unlimited projects', bold: false },
                    { id: 2, text: 'Unlimited resources', bold: false },
                    { id: 3, text: 'Unlimited Users', bold: false },
                    { id: 4, text: 'Community support', bold: false },
                ],
                exclude: selectedTier !== PaymentTier.Free,
            });
        }

        tiers.push(
            {
                id: 2,
                tierId: PaymentTier.Pro,
                plan: 'Professional',
                description:
                    'Fairview Professional is for mid-sized companies and diverse teams.',
                tagline: 'Everything in Free, Plus:',
                color: '#f7edab',
                pricePerUser: proPrice,
                featureList: [
                    { id: 1, text: 'Integrations enabled', bold: false },
                    { id: 2, text: 'Reports', bold: false },
                    { id: 3, text: 'Email support', bold: false },
                ],
                exclude: false,
            },
            // {
            //     id: 3,
            //     tierId: PaymentTier.Enterprise,
            //     plan: 'Enterprise',
            //     description:
            //         'Fairview Enterprise is for large scale companies and expansive teams.',
            //     tagline: ' Everything in Pro, plus:',
            //     color: '#F7D8AF',
            //     pricePerUser: enterprisePrice,
            //     featureList: [
            //         { id: 1, text: 'Single Sign-on security', bold: false },
            //         { id: 2, text: 'Compliance audit history', bold: false },
            //         { id: 3, text: 'Custom domain', bold: false },
            //     ],
            //     exclude: false,
            // },
        );

        if (showEnterpriseFlag) {
            tiers.push({
                id: 3,
                tierId: PaymentTier.Enterprise,
                plan: 'Enterprise',
                description:
                    'Fairview Enterprise is for large scale companies and expansive teams.',
                tagline: ' Everything in Pro, plus:',
                color: '#F7D8AF',
                pricePerUser: enterprisePrice,
                featureList: [
                    { id: 1, text: 'Compliance audit history', bold: false },
                    { id: 2, text: 'Custom domain', bold: false },
                    { id: 3, text: 'Priority email support', bold: false },
                    { id: 4, text: 'Financials', bold: false },
                ],
                exclude: false,
            });
        }

        if (selectedTier === PaymentTier.Enterprise) {
            return tiers.reverse();
        } else {
            return tiers;
        }
    };

    return (
        <>
            <Dialog
                open={open}
                scroll="paper"
                fullWidth={true}
                maxWidth={subscriptionTiers()?.length === 2 ? 'md' : 'sm'}
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="h3">
                            {' '}
                            Available Plans/Subscriptions
                        </Typography>

                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    {upgradePrompt && (
                        <Stack>
                            <Typography fontWeight={600} color="#FF6633">
                                {upgradePrompt}
                            </Typography>
                        </Stack>
                    )}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        {subscriptionTiers()?.map((subscription) => (
                            <SubscriptionTier
                                key={subscription.id}
                                subscriptionDetails={subscription}
                                isSelected={
                                    selectedTier === subscription?.tierId
                                }
                                onSelect={handleSelectTier}
                                gridSize={
                                    subscriptionTiers()?.length === 2 ? 6 : 12
                                }
                            />
                        ))}
                        <Grid item xs={12}>
                            <Box ml={1}>
                                {selectedTier !== PaymentTier.Free &&
                                    !tenant.isSubscriptionCanceled && (
                                        <Typography
                                            sx={{ cursor: 'pointer' }}
                                            variant="body2"
                                            onClick={handleDowngradeToFree}
                                        >
                                            Actually, I want to downgrade
                                        </Typography>
                                    )}
                                <Typography variant="body3">
                                    We use Stripe to manage payments. Selecting
                                    a plan will direct you to the custom payment
                                    portal.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <ChangePlanModal
                isOpen={isChangePlanModalOpen}
                handleClick={handleConfirmUpgrade}
                closeModal={handleCloseChangePlanModal}
                oldTier={selectedTier}
                newTier={upgradeTierId}
            />
        </>
    );
}
