import FullStory from 'react-fullstory';
import { userSelectors } from '../../State/UserState/selectors';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

export default function FullStoryAnalytics() {
    const loggedInUser = useSelector(userSelectors.loggedInUser);

    if (
        process.env.NODE_ENV === 'production' &&
        (loggedInUser?.isCookieConsent === true ||
            useCookies['cookieConsent'] === 'true')
    ) {
        return <FullStory org="SGJC7" />;
    } else {
        return null;
    }
}
