import { TextField, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';

export default function BusinessValuePicker({
    fullWidth,
    selectedBusinessValue: selectedBusinessValue,
    onChange,
}) {
    const options = useSelector(projectSelectors.businessValueOptions);

    return (
        <TextField
            name="estimatedBusinessValue"
            value={selectedBusinessValue}
            label="Est. Business Impact"
            select
            fullWidth={fullWidth}
            SelectProps={{
                labelId: 'business-value-label',
                displayEmpty: true,
            }}
            onChange={onChange}
        >
            {options?.map((option) => (
                <MenuItem
                    key={option.businessValue}
                    value={option.businessValue}
                >
                    {option.businessValueName}
                </MenuItem>
            ))}
        </TextField>
    );
}
