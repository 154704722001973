import { useFormik } from 'formik';
import * as yup from 'yup';
import { Grid, Stack, Typography } from '@mui/material';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import Form from 'System/Forms/Components/Form';
import TrackProjectProgressPicker from '../../UI/Pickers/TrackProjectProgressPicker/TrackProjectProgressPicker';

export default function ProjectScheduleForm({ project, onSubmit }) {
    const projectScheduleSchema = yup.object().shape({
        estimatedStartDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .default(null),
        estimatedCompletionDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .min(yup.ref('estimatedStartDate'), 'Must be after Est. Start Date')
            .default(null),
        actualStartDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .default(null),
        actualCompletionDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .default(null)
            .min(yup.ref('actualStartDate'), 'Must be after Actual Start Date'),
        trackProjectsBy: yup.string(),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: project,
        validationSchema: projectScheduleSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (values) => onSubmit(values),
    });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return project ? (
        <Form onSubmit={handleSubmit} formId="project-schedule-form">
            <Stack spacing={2}>
                <Typography variant="body1">
                    When do you now <i>plan</i> on starting and ending this
                    project?
                </Typography>

                <Stack direction="row" spacing={1}>
                    <DatePicker
                        name="estimatedStartDate"
                        format="MM/DD/YYYY"
                        label="Est. Start"
                        fullWidth
                        error={showError('estimatedStartDate')}
                        helperText={getError('estimatedStartDate')}
                        value={values.estimatedStartDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <DatePicker
                        name="estimatedCompletionDate"
                        format="MM/DD/YYYY"
                        label="Est. Completion"
                        fullWidth
                        error={showError('estimatedCompletionDate')}
                        helperText={getError('estimatedCompletionDate')}
                        value={values.estimatedCompletionDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <Typography variant="body1">
                    When did you <i>actually</i> start and end working on this
                    project?
                </Typography>

                <Stack direction="row" spacing={1}>
                    <DatePicker
                        name="actualStartDate"
                        format="MM/DD/YYYY"
                        label="Actual Start"
                        fullWidth
                        error={showError('actualStartDate')}
                        helperText={getError('actualStartDate')}
                        value={values.actualStartDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <DatePicker
                        name="actualCompletionDate"
                        format="MM/DD/YYYY"
                        label="Actual Completion"
                        fullWidth
                        error={showError('actualCompletionDate')}
                        helperText={getError('actualCompletionDate')}
                        value={values.actualCompletionDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <Typography variant="body1">
                    How do you track progress?
                </Typography>

                <Grid item xs={6} sm={4}>
                    <TrackProjectProgressPicker
                        trackProjectsBy={values.trackProjectsBy}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                    />
                </Grid>
            </Stack>
        </Form>
    ) : null;
}
