import { useState } from 'react';
import { Button, Stack, Typography, Box, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    mobileBanner: {
        marginTop: '1rem',
        padding: '1rem',
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        fontSize: '20px',
        borderRadius: '5px',
        border: '1px solid #FFAB00',
        margin: '0.25rem',
        zIndex: 100000,
        '& a, & a:link, & a:visited, & a:hover, & a:active': {
            color: 'black',
        },
        '& .MuiButton-root': {
            width: '100%',
        },
    },
    mobileBannerHidden: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s linear 300ms, opacity 900ms',
    },
    mobileBannerVisible: {
        visibility: 'visible',
        opacity: 0.95,
        transition: 'visibility 0s linear 0s, opacity 300ms',
    },
});

export default function MobileBanner() {
    const classes = useStyles();
    const [isHidden, setIsHidden] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    if (isMobile) {
        return (
            <>
                <Box
                    className={`${classes.mobileBanner} ${
                        isHidden
                            ? classes.mobileBannerHidden
                            : classes.mobileBannerVisible
                    }`}
                >
                    <Stack direction="column" spacing={2}>
                        <Typography variant="body1">
                            Fairview is best experienced on a desktop, we
                            recommend you switch to a device that can support a
                            larger view. Stay posted for a Fairview mobile app
                            in the near future.
                        </Typography>
                        <Stack direction="row" spacing={1}>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => setIsHidden(true)}
                            >
                                Dismiss
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </>
        );
    } else {
        return null;
    }
}
