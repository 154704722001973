import RestAPI from '../rest-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import User from 'Models/User';

const addProfile = createAsyncThunk('profiles/addProfile', async (profile) => {
    record('addProfile', { id: profile.id, name: profile.name });
    await RestAPI.post('/profiles', { body: profile });
    return { ...profile };
});

const deleteProfile = createAsyncThunk(
    'profiles/deleteProfile',
    async (profile) => {
        record('deleteProfile', { id: profile.id, name: profile.name });
        await RestAPI.del(`/profiles/${profile.id}`);
        return profile.id;
    },
);

const getProfileDetail = createAsyncThunk(
    'profiles/getProfileDetail',
    async (profileId, { getState, rejectWithValue }) => {
        try {
            const { loggedInUser } = getState().user || {};
            const user = loggedInUser ? new User(loggedInUser) : null;

            const endpoint = user?.isAllowedFinancials
                ? `/profiles/withFinancials/${profileId}/`
                : `/profiles/${profileId}`;
            return await RestAPI.get(endpoint);
        } catch (error) {
            // TODO: remove console log by Feb 2024
            console.log('getProfileDetail rejected error:', error);
            return rejectWithValue(profileId);
        }
    },
);

const updateProfile = createAsyncThunk(
    'profiles/updateProfile',
    async (profile) => {
        record('updateProfile', { id: profile.id, name: profile.name });
        await RestAPI.put(`/profiles/${profile.id}`, { body: profile });
        return { ...profile };
    },
);

export const profileActionsAsync = {
    addProfile,
    deleteProfile,
    getProfileDetail,
    updateProfile,
};
