import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import RestAPI from 'System/State/rest-api';
import * as signalR from '@microsoft/signalr';
import { projectActionsAsync } from '../ProjectState/actions';
import { epicActionsAsync } from '../ProjectState/epic-actions';
import { resourceActionsAsync } from '../ResourceState/actions';
import { companyActionsAsync } from '../CompanyState/actions';
import { milestoneActionsAsync } from '../MilestoneState/actions';
import { profileActionsAsync } from '../ProfileState/actions';
import { teamActionsAsync } from '../TeamState/actions';
import { resourceTimelineActionsAsync } from '../ProjectState/resource-timeline-actions';
import { workItemActionsAsync } from '../ProjectState/work-item-actions';
import { userActionsAsync } from '../UserState/actions';
import { projectNonLaborExpenseActionsAsync } from '../ProjectState/non-labor-expense-actions';
import { resourceUnavailablePeriodActionsAsync } from '../ResourceState/resource-unavailable-period-actions';
import { integrationActionsAsync } from '../CompanyState/integration-actions';
import { phaseActionsAsync } from '../ProjectState/phases-actions';
import User from 'Models/User';
import { insightsActionsAsync } from '../InsightsState/actions';
import cognitoUtility from 'Utilities/cognito-utility';
import { userActions } from '../UserState/reducer';
import { paymentActions } from '../PaymentState/reducer';
import { getTreatments } from '@splitsoftware/splitio-redux';
import { FEATURE_FLAGS } from 'Utilities/constants';

const appStart = createAsyncThunk(
    'core/appStart',
    async (_, { dispatch, getState }) => {
        let loggedInUser = getState().user.loggedInUser;
        if (loggedInUser) {
            loggedInUser = new User(loggedInUser);
        } else {
            dispatch(
                handleError({
                    errorMessage: 'state.user.loggedInUser was null',
                    friendlyMessage: 'User is not logged in',
                }),
            );
        }

        dispatch(setupSubscriptions());
        dispatch(
            getTreatments({
                splitNames: Object.values(FEATURE_FLAGS),
                key: loggedInUser?.tenantId?.toLowerCase(),
                evalOnUpdate: true,
            }),
        );

        const endpoint = loggedInUser?.isAllowedFinancials
            ? '/offline/withFinancials'
            : '/offline';
        return await RestAPI.get(endpoint, { timeout: 30000 });
    },
);

const offline = createAsyncThunk('core/offline', async (_, { getState }) => {
    let loggedInUser = getState().user.loggedInUser;
    if (loggedInUser) {
        loggedInUser = new User(loggedInUser);
    }
    const endpoint = loggedInUser?.isAllowedFinancials
        ? '/offline/withFinancials'
        : '/offline';
    return await RestAPI.get(endpoint, { timeout: 30000 });
});

const setupSubscriptions = createAsyncThunk(
    'core/setupSubscriptions',
    async (_, { dispatch, getState }) => {
        const baseURL = process.env.REACT_APP_API_URL;
        let loggedInUser = getState().user.loggedInUser;
        if (loggedInUser) {
            loggedInUser = new User(loggedInUser);
        } else {
            dispatch(
                handleError({
                    errorMessage: 'state.user.loggedInUser was null',
                    friendlyMessage: 'User is not logged in',
                }),
            );
        }

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${baseURL}/bus`, {
                accessTokenFactory: async () => {
                    const cognitoUser =
                        await cognitoUtility.getCurrentUserWithSession();
                    return cognitoUser.signInUserSession.idToken.jwtToken;
                },
                withCredentials: false,
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        const start = async () => {
            try {
                await connection.start();
                console.log('SignalR Connected.');
            } catch (err) {
                console.log(err);
                setTimeout(start, 5000);
            }
        };

        connection.onclose(async () => {
            await start();
        });
        connection.on('Notify', (message) => {
            switch (message.entityName) {
                case 'Budget':
                    dispatch(
                        companyActionsAsync.getBudgetDetail(message.entityId),
                    );
                    break;
                case 'Milestone':
                    dispatch(
                        milestoneActionsAsync.getMilestone(message.entityId),
                    );
                    break;
                case 'Profile':
                    dispatch(
                        profileActionsAsync.getProfileDetail(message.entityId),
                    );
                    break;
                case 'Project':
                    dispatch(
                        projectActionsAsync.getProjectDetail(message.entityId),
                    );
                    break;
                case 'WorkItemBulk':
                    dispatch(
                        projectActionsAsync.getProjectDetail(message.entityId),
                    );
                    break;
                case 'ProjectCharacteristic':
                    dispatch(
                        projectActionsAsync.getProjectCharacteristicDetail({
                            characteristicId: message.entityId,
                            projectId: message.parentId,
                        }),
                    );
                    break;
                case 'ProjectComment':
                    dispatch(
                        projectActionsAsync.getProjectCommentDetail({
                            commentId: message.entityId,
                            projectId: message.parentId,
                        }),
                    );
                    break;
                case 'ProjectEpic':
                    dispatch(
                        epicActionsAsync.getEpicDetail({
                            epicId: message.entityId,
                            projectId: message.parentId,
                        }),
                    );
                    break;
                case 'ProjectNonLaborExpense':
                    dispatch(
                        projectNonLaborExpenseActionsAsync.getProjectNonLaborExpenseDetail(
                            {
                                costId: message.entityId,
                                projectId: message.parentId,
                            },
                        ),
                    );
                    break;
                case 'ProjectPhase':
                    dispatch(
                        phaseActionsAsync.getProjectPhaseDetail({
                            phaseId: message.entityId,
                            projectId: message.parentId,
                        }),
                    );
                    break;
                case 'ProjectProfile':
                    dispatch(
                        projectActionsAsync.getProjectProfileDetail({
                            projectProfileId: message.entityId,
                            projectId: message.parentId,
                        }),
                    );
                    break;
                case 'SkilledResource':
                    dispatch(
                        resourceActionsAsync.getResourceDetail(
                            message.entityId,
                        ),
                    );
                    break;
                case 'SkilledResourceTimeline':
                    dispatch(
                        resourceTimelineActionsAsync.getProjectResourceTimelineDetail(
                            {
                                resourceTimelineId: message.entityId,
                                projectId: message.parentId,
                            },
                        ),
                    );
                    break;
                case 'SkilledResourceUnavailablePeriod':
                    dispatch(
                        resourceUnavailablePeriodActionsAsync.getUnavailablePeriods(
                            message.parentId,
                        ),
                    );
                    break;
                case 'Subscription':
                    dispatch(paymentActions.setIsLoading(false));
                    dispatch(coreActionsAsync.offline());
                    break;
                case 'Team':
                    dispatch(teamActionsAsync.getTeamDetail(message.entityId));
                    break;
                case 'User':
                    if (loggedInUser.id === message.entityId) {
                        dispatch(userActions.getLoggedInUserDetail());
                    } else {
                        if (loggedInUser.isAllowedAdmin) {
                            dispatch(
                                companyActionsAsync.getUserDetail(
                                    message.entityId,
                                ),
                            );
                        }
                    }
                    break;
                case 'UserCharacteristic':
                    dispatch(
                        userActionsAsync.getUserCharacteristicDetail({
                            userCharacteristicId: message.entityId,
                            userId: message.parentId,
                        }),
                    );

                    break;
                case 'UserSentiment':
                    dispatch(
                        companyActionsAsync.getUserSentimentById({
                            userId: message.entityId,
                            sentimentId: message.parentId,
                        }),
                    );

                    break;
                case 'WorkItem':
                    dispatch(
                        workItemActionsAsync.getWorkItemDetail({
                            workItemId: message.entityId,
                            projectId: message.parentId,
                        }),
                    );
                    dispatch(
                        workItemActionsAsync.getWorkItemAttachments(
                            message.entityId,
                        ),
                    );
                    break;

                case 'Integration':
                    dispatch(
                        integrationActionsAsync.getIntegrationDetails(
                            message.entityId,
                        ),
                    );
                    break;

                case 'Insight':
                    dispatch(
                        insightsActionsAsync.getInsightDetail(message.entityId),
                    );
                    break;
                default:
                    break;
            }
        });

        start();
    },
);

const handleError = createAsyncThunk('core/handleError', async (params) => {
    const { errorMessage, friendlyMessage } = params;

    // console log
    console.error(errorMessage);

    // report analytics
    record('error', { title: friendlyMessage, error: errorMessage });

    return params;
});

const requestAttachmentUploadLink = createAsyncThunk(
    'core/requestAttachmentUploadLink',
    async (attachment) => {
        const response = await RestAPI.post(`/attachments`, {
            body: attachment,
        });

        return { ...response };
    },
);

const requestAttachmentDownloadLink = createAsyncThunk(
    'core/requestAttachmentDownloadLink',
    async (attachment) => {
        const response = await RestAPI.get(
            `/attachments/${attachment.attachmentId}`,
        );

        return { ...response };
    },
);

const requestAttachmentThumbnail = createAsyncThunk(
    'core/requestAttachmentThumbnail',
    async (attachment) => {
        const response = await RestAPI.get(
            `/attachments/thumbnail/${attachment.attachmentId}`,
        );

        return { ...response };
    },
);

const appPurge = createAction('core/appPurge');

export const coreActionsAsync = {
    appPurge,
    appStart,
    offline,
    handleError,
    requestAttachmentUploadLink,
    requestAttachmentDownloadLink,
    requestAttachmentThumbnail,
};
