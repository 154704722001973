import { createAsyncThunk } from '@reduxjs/toolkit';
import RestAPI from '../rest-api';

//Generates secure url for stripe hosted payment page
const createCheckoutSession = createAsyncThunk(
    'payments/createCheckoutSession',
    async (tierId) => {
        const response = await RestAPI.post(
            '/payment/create-checkout-session',
            {
                body: {
                    Tier: tierId,
                },
            },
        );
        return response.data;
    },
);

const createCustomerPortalSession = createAsyncThunk(
    'payments/createCustomerPortalSession',
    async () => {
        const response = await RestAPI.post(
            '/payment/create-customer-portal-session',
        );
        return response.data;
    },
);

const updateSubscriptionTier = createAsyncThunk(
    'payments/updateSubscriptionTier',
    async (tierId) => {
        const response = await RestAPI.post(
            '/payment/update-subscription-tier',
            {
                body: {
                    Tier: tierId,
                },
            },
        );
        return response.data;
    },
);

const getPrices = createAsyncThunk('payments/getPrices', async () => {
    const response = await RestAPI.get('/payment/prices');
    return response.data;
});

const getSubscriptionInfo = createAsyncThunk(
    'payments/getSubscriptionInfo',
    async () => {
        const response = await RestAPI.get('/payment/subscription-info');
        return response.data;
    },
);

const cancelSubscription = createAsyncThunk(
    'payments/cancelSubscription',
    async () => {
        await RestAPI.post('/payment/cancel-subscription');
    },
);

export const paymentActionsAsync = {
    createCheckoutSession,
    createCustomerPortalSession,
    updateSubscriptionTier,
    getPrices,
    getSubscriptionInfo,
    cancelSubscription,
};
