import { selectTreatmentWithConfig } from '@splitsoftware/splitio-redux';

const getFeatureFlag = (state, flagName, tenantId) => {
    const treatmentData = selectTreatmentWithConfig(state, flagName, tenantId);
    return treatmentData.treatment === 'on';
};

const featureFlagUtils = {
    getFeatureFlag,
};

export default featureFlagUtils;
