import { createSelector } from 'reselect';
import Tenant from 'Models/Tenant';
import User from 'Models/User';
import Budget from 'Models/Budget';
import Integration from 'Models/Integration';

const companySelector = (state) => state.company;
// const integrationSelector = (state) => state.company.integrations;
const integrationSelector = (state) => state.company?.tenant;
const resourceListSelector = (state) => state.resources?.list;
const selectedResourceIdSelector = (state) => state.resources?.selectedResource;

const allBudgets = createSelector(companySelector, (company) => {
    if (company?.budgets?.length) {
        const { budgets } = company;
        return budgets
            .slice()
            .sort((a, b) => {
                if (a.startDate < b.startDate) {
                    return -1;
                }
                if (a.startDate > b.startDate) {
                    return 1;
                }
                return 0;
            })
            .map((budget) => new Budget(budget));
    }
    return [];
});

const allCharacteristics = createSelector(companySelector, (company) =>
    company?.characteristics?.length ? company.characteristics : [],
);

const allLinesOfBusiness = createSelector(companySelector, (company) =>
    company?.linesOfBusiness?.length ? company.linesOfBusiness : [],
);

const nonResourceUsers = createSelector(
    companySelector,
    resourceListSelector,
    selectedResourceIdSelector,
    (company, resourceList, selectedResourceId) => {
        const resourceListUserIds = resourceList?.map(
            (resource) => resource.userId,
        );
        const selectedUserId = resourceList
            ?.filter((resource) => resource.id == selectedResourceId)
            .map((resource) => resource.userId);
        if (company?.users) {
            return company.users
                .filter(
                    (user) =>
                        user.id == selectedUserId ||
                        !resourceListUserIds.includes(user.id),
                )
                .map((user) => {
                    return new User(user);
                });
        } else {
            return [];
        }
    },
);

const selectedBudget = createSelector(companySelector, (company) => {
    if (company?.selectedBudget && company.budgets?.length) {
        const found = company.budgets.find(
            (budget) => budget.id === company.selectedBudget,
        );
        return new Budget(found);
    } else {
        return null;
    }
});

const selectedUser = createSelector(companySelector, (company) => {
    if (company?.selectedUser) {
        const found = company.users.find(
            (user) => user.id === company.selectedUser,
        );
        return new User(found);
    } else {
        return null;
    }
});

const tenant = createSelector(companySelector, (company) => {
    if (company?.tenant) {
        return new Tenant(company.tenant);
    } else {
        return null;
    }
});

const totalBudget = createSelector(allBudgets, (budgets) => {
    return budgets.map((budget) => budget.amount).reduce((a, b) => a + b, 0);
});

const usersList = createSelector(companySelector, (company) => {
    if (company?.users) {
        return company.users
            .slice()
            .sort((a, b) => {
                if (a.firstName < b.firstName) {
                    return -1;
                }
                if (a.firstName > b.firstName) {
                    return 1;
                }
                return 0;
            })
            .map((user) => {
                return new User(user);
            });
    } else {
        return [];
    }
});

const activeUsersList = createSelector(companySelector, (company) => {
    if (company?.users) {
        return company.users
            .slice()
            .filter((user) => {
                return user.isActive == true;
            })
            .sort((a, b) => {
                if (a.firstName < b.firstName) {
                    return -1;
                }
                if (a.firstName > b.firstName) {
                    return 1;
                }
                return 0;
            })
            .map((user) => {
                return new User(user);
            });
    } else {
        return [];
    }
});

const allUserSentiments = createSelector(activeUsersList, (users) => {
    if (users.length) {
        let sentiments = [];
        users.forEach((user) => {
            if (user.sentiments.length) {
                user.sentiments.forEach((sentiment) => {
                    sentiments.push(sentiment);
                });
            }
        });

        return sentiments.sort((a, b) => {
            if (a.created < b.created) {
                return -1;
            }
            if (a.created > b.created) {
                return 1;
            }
            return 0;
        });
    } else return [];
});

const allActiveUserSentiments = createSelector(activeUsersList, (users) => {
    if (users.length) {
        let sentiments = [];
        users.forEach((user) => {
            if (user.sentiments.length) {
                user.sentiments.forEach((sentiment) => {
                    if (sentiment.isActive) {
                        sentiments.push(sentiment);
                    }
                });
            }
        });

        return sentiments.sort((a, b) => {
            if (a.created < b.created) {
                return -1;
            }
            if (a.created > b.created) {
                return 1;
            }
            return 0;
        });
    } else return [];
});

const isRevenueTracked = createSelector(
    tenant,
    (tenant) => tenant?.isRevenueTracked ?? null,
);

// Integrations

// const jiraIntegration = createSelector(integrationSelector, (integrations) =>
//     integrations?.length
//         ? new Integration(integrations.find((i) => i?.integrationType === 1))
//         : null,
// );

const jiraIntegration = createSelector(integrationSelector, (tenant) =>
    tenant?.integrations?.length
        ? new Integration(
              tenant?.integrations?.find((i) => i?.integrationType === 'Jira'),
          )
        : null,
);

const allUserCharacteristics = createSelector(
    companySelector,
    resourceListSelector,
    (company, resources) => {
        const userToSkilledResourceMap = {};
        resources
            .filter((x) => x.userId)
            .forEach((x) => {
                userToSkilledResourceMap[x.userId] = {
                    skilledResourceId: x.id,
                    profileId: x.profileId,
                    teamId: x.teamId,
                };
            });

        return company.users.map((x) => {
            const userCharacteristics = {};
            x.characteristics.forEach((x) => {
                if (userCharacteristics[x.characteristicId]) {
                    userCharacteristics[x.characteristicId][x.type] = true;
                    return;
                }

                userCharacteristics[x.characteristicId] = {
                    name: x.name,
                    [x.type]: true,
                };
            });

            return {
                id: x.id,
                firstName: x.firstName,
                lastName: x.lastName,
                characteristics: userCharacteristics,
                ...userToSkilledResourceMap[x.id],
            };
        });
    },
);

const availableCharacteristics = createSelector(companySelector, (company) => {
    const characteristicsMap = new Map();
    company?.characteristics?.forEach((char) => {
        if (characteristicsMap.get(char.id)) {
            return;
        }

        characteristicsMap.set(char.id, char.name);
    });

    company?.users?.forEach((user) => {
        user?.characteristics?.forEach((char) => {
            if (characteristicsMap.get(char.characteristicId)) {
                return;
            }

            characteristicsMap.set(char.characteristicId, char.name);
        });
    });

    return new Map(
        [...characteristicsMap.entries()].sort((a, b) =>
            b[1].toLowerCase() < a[1].toLowerCase() ? 1 : -1,
        ),
    );
});

export const companySelectors = {
    allBudgets,
    allCharacteristics,
    allLinesOfBusiness,
    allUserSentiments,
    allActiveUserSentiments,
    nonResourceUsers,
    selectedBudget,
    selectedUser,
    tenant,
    totalBudget,
    activeUsersList,
    usersList,
    isRevenueTracked,
    jiraIntegration,
    allUserCharacteristics,
    availableCharacteristics,
};
