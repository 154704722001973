import { useState } from 'react';
import { Popover, Stack, TextField, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { EditorState, RichUtils } from 'draft-js';

export function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
        );
    }, callback);
}

export function Link(props) {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <>
            <Tooltip title={`${url}`}>
                <a href={url} rel="noreferrer" target="_blank">
                    {props.children}
                </a>
            </Tooltip>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
        maxWidth: 350,
        zIndex: 100000,
    },
    button: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

export default function LinkPopover({
    anchor,
    setAnchor,
    editorState,
    setEditorState,
}) {
    const [url, setUrl] = useState('');
    const classes = useStyles();

    const handleClose = () => {
        setUrl('');
        setAnchor(null);
    };

    const onAddLink = () => {
        if (url) {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
                'LINK',
                'MUTABLE',
                { url: url },
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
                currentContent: contentStateWithEntity,
            });

            setEditorState(
                RichUtils.toggleLink(
                    newEditorState,
                    newEditorState.getSelection(),
                    entityKey,
                ),
            );
            handleClose();
        }
    };

    return (
        <Popover
            anchorEl={anchor}
            open={anchor !== null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box className={classes.root}>
                <Stack>
                    <TextField
                        fullWidth
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        label="URL"
                    />
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <IconButton onClick={handleClose}>
                        <CloseIcon
                            fontSize="small"
                            className={classes.button}
                        />
                    </IconButton>
                    <IconButton onClick={onAddLink}>
                        <DoneIcon fontSize="small" className={classes.button} />
                    </IconButton>
                </Stack>
            </Box>
        </Popover>
    );
}
