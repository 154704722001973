import { useFormik } from 'formik';
import * as yup from 'yup';
import Profile from 'Models/Profile';
import { Stack, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { coreSelectors } from 'System/State/CoreState/selectors';
import FinancialField from 'System/UI/Components/FinancialField/FinancialField';
import ColorPicker from 'System/UI/Pickers/ColorPicker/ColorPicker';
import Form from 'System/Forms/Components/Form';
import { featureFlagSelectors } from 'System/State/FeatureFlagState/selectors';
import { PaymentTier } from 'Models/PaymentTiers';

export default function ProfileForm({ profile = new Profile(), onSubmit }) {
    const isRevenueTracked = useSelector(companySelectors.isRevenueTracked);
    const showFinancials = useSelector(
        coreSelectors.showGlobalFinancialsIfAllowed,
    );
    const tenant = useSelector(companySelectors.tenant);
    const billingFlag = useSelector(featureFlagSelectors.billingFlag);

    const profileSchema = yup.object().shape({
        name: yup.string().required('Required'),
        standardHourlyCostRate: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be greater than zero')
            .nullable()
            .required('Required'),
        standardHourlyBillRate: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be greater than zero')
            .nullable(),
        standardMinimumSalary: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be greater than zero')
            .nullable()
            .required('Required'),
        standardMaximumSalary: yup
            .number()
            .typeError('Must be a number')
            .min(0, 'Must be greater than zero')
            .nullable(),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: profile,
            validationSchema: profileSchema,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return profile ? (
        <Form onSubmit={handleSubmit} formId="profile-form">
            <Stack spacing={2}>
                <Stack spacing={1} direction="row">
                    <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        error={showError('name')}
                        helperText={getError('name')}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        autoFocus
                        required
                    />
                    <ColorPicker
                        name="color"
                        selectedColor={values.color}
                        onChange={handleChange}
                    />
                </Stack>

                <Stack spacing={1}>
                    {showFinancials && ((billingFlag && tenant?.tier === PaymentTier.Enterprise) ||
                        (!billingFlag)) ? (
                        <>
                            <Stack spacing={1} direction="row">
                                <FinancialField
                                    name="standardHourlyCostRate"
                                    label="Standard Cost/Hr"
                                    value={values.standardHourlyCostRate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={showError('standardHourlyCostRate')}
                                    helperText={getError(
                                        'standardHourlyCostRate',
                                    )}
                                />

                                {isRevenueTracked ? (
                                    <FinancialField
                                        name="standardHourlyBillRate"
                                        label="Standard Billable/Hr"
                                        value={values.standardHourlyBillRate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={showError(
                                            'standardHourlyBillRate',
                                        )}
                                        helperText={getError(
                                            'standardHourlyBillRate',
                                        )}
                                    />
                                ) : null}
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <FinancialField
                                    name="standardMinimumSalary"
                                    label="Standard Min. Salary"
                                    value={values.standardMinimumSalary}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={showError('standardMinimumSalary')}
                                    helperText={getError(
                                        'standardMinimumSalary',
                                    )}
                                />

                                <FinancialField
                                    name="standardMaximumSalary"
                                    label="Standard Max. Salary"
                                    value={values.standardMaximumSalary}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={showError('standardMaximumSalary')}
                                    helperText={getError(
                                        'standardMaximumSalary',
                                    )}
                                />
                            </Stack>
                        </>
                    ) : null}
                </Stack>
            </Stack>
        </Form>
    ) : null;
}
