import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, TextField } from '@mui/material';
import BusinessValuePicker from 'System/UI/Pickers/BusinessValuePicker/BusinessValuePicker';
import LevelOfEffortPicker from 'System/UI/Pickers/LevelOfEffortPicker/LevelOfEffortPicker';
import KeyResults from 'System/UI/Components/KeyResults/KeyResults';
import Form from 'System/Forms/Components/Form';

export default function ProjectDetailForm({ project, onSubmit }) {
    const projectDetailSchema = yup.object().shape({
        objective: yup.string().max(5000),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: project,
            validationSchema: projectDetailSchema,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return project ? (
        <Form onSubmit={handleSubmit} formId="project-detail-form">
            <Stack spacing={2}>
                <Stack direction="row" spacing={1}>
                    <BusinessValuePicker
                        name="estimatedBusinessValue"
                        id="estimatedBusinessValue"
                        fullWidth
                        selectedBusinessValue={values.estimatedBusinessValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <LevelOfEffortPicker
                        name="estimatedLevelOfEffort"
                        id="estimatedLevelOfEffort"
                        selectedLevelOfEffort={values.estimatedLevelOfEffort}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </Stack>

                <TextField
                    name="objective"
                    multiline
                    rows={8}
                    label="Objective"
                    error={showError('objective')}
                    helperText={getError('objective')}
                    value={values.objective}
                    placeholder="The project objective"
                    inputProps={{ maxLength: 5000 }}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <KeyResults
                    name="keyResults"
                    value={values.keyResults}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Stack>
        </Form>
    ) : null;
}
