import { useState } from 'react';
import {
    Stack,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { resourceSelectors } from 'System/State/ResourceState/selectors';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { resourceActions } from 'System/State/ResourceState/reducer';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';
import ResourceForm from 'System/Forms/ResourceForm/ResourceForm';
import { teamActions } from 'System/State/TeamState/reducer';
import { profileActions } from 'System/State/ProfileState/reducer';
import Resource from 'Models/Resource';

export default function ResourceDetailModal() {
    const dispatch = useDispatch();
    const resource = useSelector(resourceSelectors.selectedResource);
    const open = useSelector(modalSelectors.resourceDetailModal) || false;

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleSelectProfile = (id) => {
        dispatch(profileActions.selectProfile(id));
    };

    const handleSelectTeam = (id) => {
        dispatch(teamActions.selectTeam(id));
    };

    const handleClose = () => {
        dispatch(modalActions.close('resourceDetailModal'));
        // dispatch(resourceActions.unselectResource());
        dispatch(teamActions.unselectTeam());
        dispatch(profileActions.unselectProfile());
    };

    const toggleActive = (resource) => {
        const newResource = new Resource({
            ...resource,
            isTerminated: !resource.isTerminated,
        });

        resource.isTerminated
            ? dispatch(resourceActions.activateResource(newResource))
            : dispatch(resourceActions.deactivateResource(newResource));
    };

    const handleSubmit = (resource) => {
        dispatch(resourceActions.updateResource(resource));
        handleClose();
    };

    return resource ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Resource Details"
            aria-describedby="Update resource details"
        >
            <DialogTitle>Resource Details</DialogTitle>
            <DialogContent>
                <ResourceForm
                    resource={resource}
                    onSelectProfile={handleSelectProfile}
                    onSelectTeam={handleSelectTeam}
                    onSubmit={handleSubmit}
                />

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="Resource"
                    customTitle="Confirm Termination"
                    customMessage="Are you sure you want to terminate this Resource?"
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        toggleActive(resource);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    {!resource?.isTerminated ? (
                        <Button
                            variant="outlined"
                            color="tertiary"
                            onClick={() => {
                                setDeleteModalOpen(true);
                            }}
                        >
                            Terminate
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            color="tertiary"
                            onClick={() => {
                                toggleActive(resource);
                            }}
                        >
                            Reactivate
                        </Button>
                    )}

                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            form="resource-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
