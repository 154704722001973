import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    summaryIsVisible: false,
    optionsToggleValue: 'projects',
    filtersAreVisible: false,
};

export const SummarySlice = createSlice({
    name: 'summary',
    initialState: initialState,
    reducers: {
        resetSlice() {
            return initialState;
        },
        toggleSummaryFilterVisible(state) {
            state.filtersAreVisible = !state.filtersAreVisible;
        },
        toggleSummaryVisible(state) {
            state.summaryIsVisible = !state.summaryIsVisible;
        },
        toggleOptionsValue(state, action) {
            state.optionsToggleValue = action.payload;
        },
    },
});

export const summaryActions = {
    ...SummarySlice.actions,
};

export default SummarySlice.reducer;
