import { useTheme, useMediaQuery } from '@mui/material';

export default function HeroSection() {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));

    const viewBoxValues = isTablet ? "800 0 800 1473" : "0 0 1625 1473";

    return (
        <svg viewBox={viewBoxValues} fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio='none' style={{ width: '100%', height: '1200px', position: 'absolute' }}>
            <g clipPath="url(#clip0_147_2)">
                <path opacity="0.85" d="M1625 0H0V1473H1625V0Z" fill="url(#paint0_linear_147_2)"/>
                <path d="M-278 -413C-278 -413 136.9 96 832.8 96C1528.7 96 1934 -413 1934 -413" stroke="#F9CE29" strokeWidth="240" strokeMiterlimit="10"/>
                <path d="M-283 -95C-283 -95 131.9 332 827.8 332C1523.7 332 1929 -95 1929 -95" stroke="#F6AE39" strokeWidth="240" strokeMiterlimit="10"/>
                <path d="M-287 492C-287 492 129.6 813 828.3 813C1527 813 1934 492 1934 492" stroke="#EF753E" strokeWidth="256" strokeMiterlimit="10"/>
                <path d="M-282 200C-282 200 132.7 570 828.3 570C1523.8 570 1929 200 1929 200" stroke="#F2903C" strokeWidth="240" strokeMiterlimit="10"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_147_2" x1="812.5" y1="-1011.31" x2="812.5" y2="1473" gradientUnits="userSpaceOnUse">
                    <stop offset="0.713542" stopColor="#12ACEE"/>
                    <stop offset="0.854167" stopColor="#56DFFF"/>
                    <stop offset="1" stopColor="white"/>
                </linearGradient>
                <clipPath id="clip0_147_2">
                    <rect width="1625" height="1473" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}