import { Typography } from '@mui/material';

const ErrorMessage = ({ error }) =>
    error ? (
        <Typography
            sx={{
                color: (theme) => theme.palette.atRisk.main,
                marginBlockStart: 0,
                marginBlockEnd: 0,
            }}
            variant="label"
        >
            {error}
        </Typography>
    ) : null;

export default ErrorMessage;
