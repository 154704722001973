import {
    TextField,
    Autocomplete,
    Stack,
    IconButton,
    Tooltip,
    MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { resourceSelectors } from 'System/State/ResourceState/selectors';
import { userSelectors } from 'System/State/UserState/selectors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { resourceActions } from 'System/State/ResourceState/reducer';

export default function ResourcePicker({ disabled, selectedResource }) {
    const dispatch = useDispatch();
    const availableResources = useSelector(resourceSelectors.activeResources);
    const isAllowedContribute = useSelector(userSelectors.isAllowedContribute);

    return availableResources ? (
        <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Autocomplete
                options={availableResources}
                value={selectedResource}
                getOptionLabel={(option) => option.name || ''}
                id="auto-complete"
                onChange={(_, val) =>
                    dispatch(resourceActions.selectResource(val?.id))
                }
                autoComplete
                required
                noOptionsText={
                    isAllowedContribute ? (
                        <MenuItem
                            onMouseDown={() =>
                                dispatch(modalActions.open('newResourceModal'))
                            }
                        >
                            Add Resources
                        </MenuItem>
                    ) : (
                        'No Options Found'
                    )
                }
                fullWidth
                includeInputInList
                renderInput={(params) => (
                    <TextField {...params} fullWidth label="Resources" />
                )}
                disabled={disabled}
            />
            {isAllowedContribute ? (
                <IconButton
                    onClick={() =>
                        dispatch(modalActions.open('newResourceModal'))
                    }
                >
                    <Tooltip
                        title="Can't find who you're looking for? Click here to add a new resource to this company."
                        aria-label="add-new-resource"
                        enterDelay={300}
                        placement="top"
                    >
                        <AddCircleOutlineIcon />
                    </Tooltip>
                </IconButton>
            ) : null}
        </Stack>
    ) : null;
}
