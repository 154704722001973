import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
} from '@mui/material';
import ConfirmDeleteModal from 'System/Modals/ConfirmDeleteModal/ConfirmDeleteModal';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { teamSelectors } from 'System/State/TeamState/selectors';
import { teamActions } from 'System/State/TeamState/reducer';
import PersonIcon from '@mui/icons-material/Person';
import TeamForm from 'System/Forms/TeamForm/TeamForm';

const SkilledResourceList = ({ skilledResources = [] }) => (
    <List
        sx={{
            paddingTop: '1.5rem',
            paddingLeft: '1rem',
        }}
    >
        {skilledResources.map((resource) => (
            <ListItem key={resource.id}>
                <ListItemAvatar>
                    <PersonIcon />
                </ListItemAvatar>
                <ListItemText>{resource.name}</ListItemText>
            </ListItem>
        ))}
    </List>
);

export default function TeamDetailModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.teamDetailModal) || false;
    const team = useSelector(teamSelectors.selectedTeam);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleClose = () => {
        dispatch(modalActions.close('teamDetailModal'));
    };

    const handleSubmit = (team) => {
        dispatch(teamActions.updateTeam(team));
        handleClose();
    };

    const handleDelete = (team) => {
        dispatch(teamActions.disableTeam(team));
        handleClose();
    };

    return team ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Team Details"
            aria-describedby="Update team details"
        >
            <DialogTitle>Team Details</DialogTitle>
            <DialogContent>
                <TeamForm team={team} onSubmit={handleSubmit} />

                {team?.skilledResources?.length ? (
                    <SkilledResourceList
                        skilledResources={team?.skilledResources}
                    />
                ) : null}

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="Team"
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        handleDelete(team);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    width="100%"
                >
                    <Button
                        variant="outlined"
                        color="tertiary"
                        onClick={() => {
                            setDeleteModalOpen(true);
                        }}
                    >
                        Delete
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <Button color="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            form="team-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
