import { MenuItem, TextField } from "@mui/material";

export default function CompletedWorkItemArchivePicker({
    archiveWorkItemsAfter,
    onChange,
}) {
    const archiveOptions = [
        { name: '2 weeks', value: 14 },
        { name: '4 weeks', value: 28 },
        { name: '6 weeks', value: 42 },
        { name: '8 weeks', value: 56 },
        { name: '10 weeks', value: 70 },
        { name: '12 weeks', value: 84 },
    ];

    return (
        <TextField
            sx={{ minWidth: '155px' }}
            value={archiveWorkItemsAfter}
            select
            onChange={onChange}
        >
            {archiveOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
}
