import { useState } from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Stack,
    TextField,
} from '@mui/material';

export default function ConfirmDeleteModal(props) {
    const {
        isModalOpen,
        confirmDelete,
        cancelDelete,
        customMessage,
        customTitle,
        textConfirmation,
        parentType,
        parentName,
    } = props;

    const [helperText, setHelperText] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);

    const handleChange = (e) => {
        const { value } = e.target;

        if (value === parentName) {
            setIsConfirmed(true);
            setHelperText('');
        } else {
            setIsConfirmed(false);
        }
    };

    const handleClick = () => {
        if (isConfirmed || !textConfirmation) {
            confirmDelete();
        } else {
            setHelperText(`Must match ${parentType} name`);
        }
    };

    const handleClose = () => {
        cancelDelete();
    };

    const getText = () => {
        if (customMessage) return customMessage;
        if (textConfirmation)
            return (
                <>
                    Please type &apos;{parentName}&apos; below to delete this{' '}
                    {parentType}.
                </>
            );
        return <>Are you sure you want to delete this {parentType}?</>;
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby="Confirm Delete"
            aria-describedby="Confirm the request to delete"
        >
            <DialogTitle>
                {customTitle ? customTitle : 'Confirm Delete'}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant="body1">{getText()}</Typography>

                    {textConfirmation ? (
                        <TextField
                            fullWidth
                            autoFocus
                            onChange={handleChange}
                            helperText={helperText}
                            error={Boolean(helperText)}
                        />
                    ) : null}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelDelete}>Cancel</Button>

                <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleClick}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
