import { MenuItem, Menu } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { workItemActions } from 'System/State/ProjectState/reducer';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WorkItemHighestPriority from '../../../assets/work_item_highest_priority.svg';
import WorkItemHighPriority from '../../../assets/work_item_high_priority.svg';
import WorkItemMediumPriority from '../../../assets/work_item_medium_priority.svg';
import WorkItemLowPriority from '../../../assets/work_item_low_priority.svg';
import WorkItemLowestPriority from '../../../assets/work_item_lowest_priority.svg';

const useStyles = makeStyles(() => ({
    menu: {
        borderRadius: '8px',
    },
    menuItem: {
        paddingTop: 2.5,
        paddingBottom: 2.5,
        paddingLeft: 5,
        paddingRight: 5,
        borderBottom: '0.5px solid rgb(0,0,0,0.05)',
    },
    chip: {
        background: '#FFF1E1',
        borderRadius: '16px',
        padding: '5px 7.5px ',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        zIndex: 2,
        marginBottom: '5px',
        gap: '5px',
        // boxShadow: '1px 0px 8px rgb(0,0,0,0.2)',
        fontSize: '14px',
        fontWeight: 500,
    },
    iconImg: {
        width: '15px',
    },
}));

export function GetPriorityType(
    priorityId,
    isMenuItem = false,
    isTextDisplayed,
) {
    const classes = useStyles();

    switch (priorityId) {
        case 0:
            return (
                <div className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img
                            className={classes.iconImg}
                            src={WorkItemHighestPriority}
                        />
                        {isTextDisplayed && <span>Highest Priority</span>}
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 1:
            return (
                <div className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img
                            className={classes.iconImg}
                            src={WorkItemHighPriority}
                        />
                        {isTextDisplayed && <span>High Priority</span>}
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 2:
            return (
                <div className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img
                            className={classes.iconImg}
                            src={WorkItemMediumPriority}
                        />
                        {isTextDisplayed && <span>Medium Priority</span>}
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 3:
            return (
                <div className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img
                            className={classes.iconImg}
                            src={WorkItemLowPriority}
                        />
                        {isTextDisplayed && <span>Low Priority</span>}
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
        case 4:
            return (
                <div className={classes.chip}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <img
                            className={classes.iconImg}
                            src={WorkItemLowestPriority}
                        />
                        {isTextDisplayed && <span>Lowest Priority</span>}
                    </div>
                    {!isMenuItem && <ArrowDropDownIcon />}
                </div>
            );
    }
}

export default function WorkItemPriority({ workItem, isLabelDisplayed }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSwitchStatus = (priorityId) => {
        if (priorityId === workItem.priority) {
            handleClose();
        } else {
            let updatedWorkItem = {
                ...workItem,
                priority: priorityId,
            };
            dispatch(workItemActions.updateProjectWorkItem(updatedWorkItem));
            handleClose();
        }
    };
    return (
        <div>
            <div
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={classes.selectedChip}
            >
                {GetPriorityType(workItem?.priority)}
            </div>

            <Menu
                pt={3}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className={classes.menu}
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                    },
                }}
            >
                <MenuItem
                    onClick={() =>
                        handleSwitchStatus(workItem.highestPriorityId)
                    }
                    className={classes.menuItem}
                >
                    {GetPriorityType(workItem.highestPriorityId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() => handleSwitchStatus(workItem.highPriorityId)}
                    className={classes.menuItem}
                >
                    {GetPriorityType(workItem.highPriorityId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        handleSwitchStatus(workItem.mediumPriorityId)
                    }
                    className={classes.menuItem}
                >
                    {GetPriorityType(workItem.mediumPriorityId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() => handleSwitchStatus(workItem.lowPriorityId)}
                    className={classes.menuItem}
                >
                    {GetPriorityType(workItem.lowPriorityId, true)}
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        handleSwitchStatus(workItem.lowestPriorityId)
                    }
                    className={classes.menuItem}
                >
                    {GetPriorityType(workItem.lowestPriorityId, true)}
                </MenuItem>
            </Menu>
        </div>
    );
}
