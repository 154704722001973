import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { projectActions } from 'System/State/ProjectState/reducer';
import ChangeProjectNameForm from '../../Forms/ChangeProjectNameForm/ChangeProjectNameForm';

export default function EditProjectNameModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.editProjectNameModal) || false;
    const project = useSelector(projectSelectors.selectedProject);

    const handleClose = () =>
        dispatch(modalActions.close('editProjectNameModal'));

    const handleSubmit = ({ name, projectKey }) =>
        dispatch(
            projectActions.updateProject({
                ...project,
                name,
                projectKey,
            }),
        ).then(() => handleClose());

    if (!project) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Edit Project Name"
            aria-describedby="Edit the name of a project"
        >
            <DialogTitle>Edit Project Name</DialogTitle>
            <DialogContent>
                <ChangeProjectNameForm
                    name={project?.name}
                    projectKey={project?.projectKey}
                    onSubmit={handleSubmit}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="change-project-name-form"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
