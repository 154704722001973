import RestAPI from 'System/State/rest-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import actionUtils from 'Utilities/action-utils';
import { createChangeSchedulePayload } from 'Utilities/api-transform-utility';
import { saveAs } from 'file-saver';
import { coreActionsAsync } from '../CoreState/actions';
import axios from 'axios';
import FileSaver from 'file-saver';
import User from 'Models/User';

const {
    changeProjectEstimatedCompletionDate,
    changeProjectEstimatedStartDate,
} = actionUtils;

const addAttachmentToProject = createAsyncThunk(
    'projects/addAttachmentToProject',
    async (attachment, { dispatch }) => {
        const presignedUrl = await dispatch(
            coreActionsAsync.requestAttachmentUploadLink(attachment),
        );

        if (presignedUrl?.payload.data) {
            var options = {
                headers: {
                    'Content-Type': attachment.mimeType,
                },
            };

            await axios.put(
                presignedUrl?.payload.data,
                attachment.attachment,
                options,
            );

            await RestAPI.post(
                `/projects/${attachment.businessEntityId}/attachments`,
                {
                    body: attachment,
                },
            );

            dispatch(getProjectAttachments(attachment.businessEntityId));
        }
    },
);

const downloadProjectAttachment = createAsyncThunk(
    'projects/downloadProjectAttachment',
    async (attachment, { dispatch }) => {
        const presignedUrl = await dispatch(
            coreActionsAsync.requestAttachmentDownloadLink(attachment),
        );
        if (presignedUrl.payload.data) {
            await axios.get(presignedUrl.payload.data);
            await FileSaver.saveAs(
                presignedUrl.payload.data,
                attachment.filename,
            );

            return attachment.id;
        }
    },
);

const addCommentToProject = createAsyncThunk(
    'projects/addCommentToProject',
    async (comment) => {
        record('addCommentToProject', {
            projectId: comment.projectId,
            newComment: comment.comment,
        });

        await RestAPI.post(`/projects/${comment.projectId}/comments`, {
            body: comment,
        });

        return { ...comment };
    },
);

const addLabelToProject = createAsyncThunk(
    'projects/addLabelToProject',
    async (label, { rejectWithValue }) => {
        try {
            const response = await RestAPI.post(
                `/projects/${label.projectId}/labels`,
                { body: label },
            );

            return { ...label, id: response.data };
        } catch (error) {
            return rejectWithValue({
                error: error.response?.data?.errors,
            });
        }
    },
);

const addLOBToProject = createAsyncThunk(
    'projects/addLOBToProject',
    async (LOB, { rejectWithValue }) => {
        try {
            const response = await RestAPI.post(
                `/projects/${LOB.projectId}/linesofbusiness`,
                { body: LOB },
            );

            return { ...LOB, id: response.data };
        } catch (error) {
            return rejectWithValue({
                error: error.response?.data?.errors,
            });
        }
    },
);

const addCharacteristicToProject = createAsyncThunk(
    'projects/addCharacteristicToProject',
    async (characteristic, { rejectWithValue }) => {
        try {
            const response = await RestAPI.post(
                `/projects/${characteristic.projectId}/characteristics`,
                { body: characteristic },
            );

            return { ...characteristic, id: response.data };
        } catch (error) {
            return rejectWithValue({
                error: error.response?.data?.errors,
            });
        }
    },
);

const addProfileToProject = createAsyncThunk(
    'projects/addProfileToProject',
    async (input, { rejectWithValue }) => {
        try {
            const response = await RestAPI.post(
                `/projects/${input.projectId}/projectprofiles`,
                { body: input },
            );
            return { id: response.data };
        } catch (ex) {
            return rejectWithValue(ex);
        }
    },
);

const addSkilledResourceToProjectProfile = createAsyncThunk(
    'projects/addSkilledResourceToProjectProfile',
    async (input, { rejectWithValue }) => {
        try {
            const response = await RestAPI.post(
                `/projects/${input.projectId}/projectprofiles/addskilledresource`,
                { body: input },
            );
            return { id: response.data };
        } catch (ex) {
            return rejectWithValue(ex);
        }
    },
);


const removeProjectProfile = createAsyncThunk(
    'projects/removeProjectProfile',
    async (input, { rejectWithValue }) => {
        try {
            const response = await RestAPI.del(
                `/projects/${input.projectId}/projectprofiles/${input.projectProfileId}`,
            );
            return { id: response.data };
        } catch (ex) {
            return rejectWithValue(ex);
        }
    },
);

const addProject = createAsyncThunk('projects/addProject', async (project) => {
    record('addProject', { id: project.id, name: project.name });

    await RestAPI.post('/projects', { body: project });

    return { ...project };
});

const changeProjectRank = createAsyncThunk(
    'projects/changeProjectRank',
    async ({ project, newRank }) => {
        record('changeProjectRank', {
            id: project.id,
            name: project.name,
            rank: newRank,
        });

        var response = await RestAPI.put(
            `/projects/${project.id}/change-rank`,
            { body: { rank: newRank } },
        );

        return {
            project: { ...project, rank: newRank },
            affectedProjects: response.data,
        };
    },
);

const changeProjectStatus = createAsyncThunk(
    'projects/changeProjectStatus',
    async (project) => {
        record('changeProjectStatus', { id: project.id, name: project.name });

        await RestAPI.put(`/projects/${project.id}/change-status`, {
            body: { status: project.status },
        });

        return structuredClone(project);
    },
);

const deleteCommentFromProject = createAsyncThunk(
    'projects/deleteCommentFromProject',
    async (comment) => {
        record('deleteCommentFromProject', {
            projectId: comment.projectId,
            comment: comment.comment,
        });

        await RestAPI.del(
            `/projects/${comment.projectId}/comments/${comment.id}`,
        );

        return { ...comment };
    },
);

const deleteProject = createAsyncThunk(
    'projects/deleteProject',
    async (project) => {
        record('deleteProject', { id: project.id, name: project.name });

        await RestAPI.del(`/projects/${project.id}`);

        return project.id;
    },
);

const linkProjectToJira = createAsyncThunk(
    'projects/linkProjectToJira',
    async (project) => {
        record('linkProjectToJira', { id: project.projectId });

        await RestAPI.put(`/projects/${project.projectId}/link-integration`, {
            body: project,
        });

        return { ...project };
    },
);

const getProjectCharacteristicDetail = createAsyncThunk(
    'projects/getProjectCharacteristicDetail',
    async ({ projectId, characteristicId }, { rejectWithValue }) => {
        try {
            return await RestAPI.get(
                `projects/${projectId}/characteristics/${characteristicId}`,
            );
        } catch (error) {
            return rejectWithValue({
                projectId,
                characteristicId,
            });
        }
    },
);

const getProjectCommentDetail = createAsyncThunk(
    'projects/getProjectCommentDetail',
    async ({ projectId, commentId }, { rejectWithValue }) => {
        try {
            return await RestAPI.get(
                `projects/${projectId}/comments/${commentId}`,
            );
        } catch (error) {
            return rejectWithValue({
                projectId,
                commentId,
            });
        }
    },
);

const getProjectDetail = createAsyncThunk(
    'projects/getProjectDetail',
    async (projectId, { getState, rejectWithValue }) => {
        try {
            const { loggedInUser } = getState().user || {};
            const user = loggedInUser ? new User(loggedInUser) : null;

            const endpoint = user?.isAllowedFinancials
                ? `/projects/withFinancials/${projectId}/`
                : `/projects/${projectId}`;
            return await RestAPI.get(endpoint);
        } catch (error) {
            // TODO: remove console log by Feb 2024
            console.log('getProjectDetail rejected error: ', error);
            return rejectWithValue(projectId);
        }
    },
);

const getProjectAttachments = createAsyncThunk(
    'projects/getProjectAttachments',
    async (projectId, { rejectWithValue }) => {
        try {
            return await RestAPI.get(`projects/${projectId}/attachments`);
        } catch (error) {
            return rejectWithValue({ projectId });
        }
    },
);

const removeLabelFromProject = createAsyncThunk(
    'projects/removeLabelFromProject',
    async (label) => {
        await RestAPI.del(`projects/${label.projectId}/labels/${label.id}`);
        return label;
    },
);

const removeLOBFromProject = createAsyncThunk(
    'projects/removeLOBFromProject',
    async (LOB) => {
        await RestAPI.del(
            `projects/${LOB.projectId}/linesofbusiness/${LOB.id}`,
        );
        return LOB;
    },
);

const removeCharacteristicFromProject = createAsyncThunk(
    'projects/removeCharacteristicFromProject',
    async (characteristic) => {
        await RestAPI.del(
            `projects/${characteristic.projectId}/characteristics/${characteristic.id}`,
        );
        return characteristic;
    },
);

const updateProject = createAsyncThunk(
    'projects/updateProject',
    async (project) => {
        record('updateProject', {
            id: project.id,
            name: project.name,
            projectKey: project.key,
        });

        await RestAPI.put(`/projects/${project.id}`, {
            body: {
                id: project.id,
                actualCompletionDate: project.actualCompletionDate,
                actualCost: project.actualCost,
                actualStartDate: project.actualStartDate,
                actualValue: project.actualValue,
                baselineStartDate: project.baselineStartDate,
                baselineCompletionDate: project.baselineCompletionDate,
                color: project.color,
                description: project.description,
                estimatedBusinessValue: project.estimatedBusinessValue,
                estimatedCompletionDate: project.estimatedCompletionDate,
                estimatedCost: project.estimatedCost,
                estimatedLevelOfEffort: project.estimatedLevelOfEffort,
                estimatedValue: project.estimatedValue,
                estimatedStartDate: project.estimatedStartDate,
                hoursComplete: project.hoursComplete,
                hoursEstimate: project.hoursEstimate,
                integrationKey: project.integrationKey,
                trackProjectBy: project.trackProjectBy,
                keyResults: project.keyResults,
                labels: project.labels,
                linesOfBusiness: project.linesOfBusiness,
                name: project.name,
                objective: project.objective,
                pointsComplete: project.pointsComplete,
                pointsEstimate: project.pointsEstimate,
                rank: project.rank,
                risk: project.risk,
                status: project.status,
                projectKey: project.projectKey,
                isPrivate: project.isPrivate,
            },
        });

        return structuredClone(project);
    },
);

const updateProjectSchedule = createAsyncThunk(
    'projects/updateProjectSchedule',
    async (project, { rejectWithValue }) => {
        record('updateProjectSchedule', {
            id: project.id,
            name: project.name,
        });

        if (project.estimatedStartDate) {
            changeProjectEstimatedStartDate(
                project,
                project.estimatedStartDate,
            );
        }

        if (project.estimatedCompletionDate) {
            changeProjectEstimatedCompletionDate(
                project,
                project.estimatedCompletionDate,
            );
        }

        try {
            await RestAPI.put(`/projects/${project.id}/change-schedule`, {
                body: createChangeSchedulePayload(project),
            });
        } catch (ex) {
            return rejectWithValue(ex);
        }

        return { ...project };
    },
);

const updateProjectEstimatedCompletionDate = createAsyncThunk(
    'projects/updateProjectEstimatedCompletionDate',
    async ({ project, newDate }) => {
        record('updateProjectEstimatedCompletionDate', {
            id: project.id,
            name: project.name,
        });

        changeProjectEstimatedCompletionDate(project, newDate);

        await RestAPI.put(`/projects/${project.id}/change-schedule`, {
            body: createChangeSchedulePayload(project),
        });

        return { ...project };
    },
);

const updateProjectEstimatedStartDate = createAsyncThunk(
    'projects/updateProjectEstimatedStartDate',
    async ({ project, newDate }) => {
        record('updateProjectEstimatedStartDate', {
            id: project.id,
            name: project.name,
        });

        changeProjectEstimatedStartDate(project, newDate);

        await RestAPI.put(`/projects/${project.id}/change-schedule`, {
            body: createChangeSchedulePayload(project),
        });

        return { ...project };
    },
);

const importProjectsFromExcel = createAsyncThunk(
    'projects/bulkProjectImport',
    async ({ file, errorCallback, loadingCallback }) => {
        record('importProjectsFromExcel', { file: file.name });

        const formData = new FormData();
        formData.append('files', file);

        return await RestAPI.postFormData(
            '/projects/bulkprojectimport',
            {
                body: formData,
            },
            errorCallback,
            loadingCallback,
        );
    },
);

const getProjectsImportTemplate = createAsyncThunk(
    'projects/bulkProjectImportTemplate',
    async (thunkAPI) => {
        try {
            const response = await RestAPI.getFile(
                '/projects/bulkprojectimporttemplate',
            );

            saveAs(new Blob([response.data]), 'fairview-project-template.xlsx');
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

const copyProject = createAsyncThunk(
    'projects/copyProject',
    async ({ originalProjectId, project }) => {
        record('copyProject', { id: originalProjectId });

        const response = await RestAPI.post(
            `/projects/${originalProjectId}/copy`,
            { body: project },
        );

        return {
            res: response,
        };
    },
);

const getProjectProfileDetail = createAsyncThunk(
    'projects/getProjectProfile',
    async ({ projectProfileId, projectId }, { getState, rejectWithValue }) => {
        try {
            const endpoint = getState().user?.loggedInUser?.isAllowedFinancials
                ? `/projects/${projectId}/project-profiles/withFinancials/${projectProfileId}`
                : `/projects/${projectId}/project-profiles/${projectProfileId}`;
            return await RestAPI.get(endpoint);
        } catch (error) {
            return rejectWithValue({ projectProfileId, projectId });
        }
    },
);

export const projectActionsAsync = {
    addAttachmentToProject,
    addCommentToProject,
    addProject,
    addLabelToProject,
    addLOBToProject,
    addCharacteristicToProject,
    addProfileToProject,
    addSkilledResourceToProjectProfile,
    removeProjectProfile,
    changeProjectRank,
    changeProjectStatus,
    deleteCommentFromProject,
    deleteProject,
    downloadProjectAttachment,
    getProjectCharacteristicDetail,
    getProjectCommentDetail,
    getProjectAttachments,
    getProjectDetail,
    linkProjectToJira,
    removeCharacteristicFromProject,
    removeLabelFromProject,
    removeLOBFromProject,
    updateProject,
    updateProjectEstimatedCompletionDate,
    updateProjectEstimatedStartDate,
    updateProjectSchedule,
    importProjectsFromExcel,
    getProjectsImportTemplate,
    copyProject,
    getProjectProfileDetail,
};
