import * as yup from 'yup';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Divider, Grid, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { resourceActions } from 'System/State/ResourceState/reducer';
import Form from 'System/Forms/Components/Form';
import ResourceUnavailableTypePicker from 'System/UI/Pickers/ResourceUnavailablePeriodTypePicker/ResourceUnavailablePeriodTypePicker';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import ResoruceTotalUnavailableDays from 'System/UI/Components/ResourceTotalUnavailableDays/ResourceTotalUnavailableDays';
import { UnavailablePeriodDeleteButton } from 'System/Modals/UnavailablePeriodModal/UnavailablePeriodModal';
import { useState } from 'react';

export default function ResourceUnavailablePeriodForm({
    resource,
    unavailablePeriod,
}) {
    const dispatch = useDispatch();
    const [valuesChanged, setValuesChanged] = useState(false);

    const resourceUnavailablePeriodSchema = yup.object().shape({
        skilledResourceId: yup.string().required('Required'),
        reason: yup.string().required('Required'),
        startDate: yup
            .date()
            .typeError('Please enter a start date')
            .required('Required'),
        endDate: yup
            .date()
            .typeError('Please enter an end date')
            .required('Required'),
    });

    const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
        useFormik({
            initialValues: unavailablePeriod,
            validationSchema: resourceUnavailablePeriodSchema,
            validateOnBlur: false,
            validateOnChange: false,
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    const handleSave = () => {
        dispatch(resourceActions.updateUnavailablePeriod(values));
        setValuesChanged(false);
    };

    useEffect(() => {
        if (resource) {
            setFieldValue('skilledResourceId', resource.id, false);
        }
    }, [resource]);

    useEffect(() => {
        if (
            values.startDate !== unavailablePeriod.startDate ||
            values.endDate !== unavailablePeriod.endDate ||
            values.reason !== unavailablePeriod.reason
        ) {
            setValuesChanged(true);
        }
    }, [values]);

    return (
        <>
            <Form formId="resource-unavailable-period-detail-form">
                <Grid container>
                    <Grid item xs={3}>
                        <ResourceUnavailableTypePicker
                            onChange={(e) => handleChange(e, 'reason')}
                            value={values.reason}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker
                            name="startDate"
                            format="MM/DD/YYYY"
                            label="Start Date"
                            fullWidth
                            required
                            value={values.startDate}
                            onChange={(e) => handleChange(e, 'startDate')}
                            error={showError('startDate')}
                            helperText={getError('startDate')}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker
                            name="endDate"
                            format="MM/DD/YYYY"
                            label="End Date"
                            fullWidth
                            required
                            value={values.endDate}
                            onChange={(e) => handleChange(e, 'endDate')}
                            onBlur={handleBlur}
                            error={showError('endDate')}
                            helperText={getError('endDate')}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <ResoruceTotalUnavailableDays
                            startDate={values.startDate}
                            endDate={values.endDate}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {valuesChanged ? (
                            <Button
                                onClick={handleSave}
                                size="small"
                                variant="outlined"
                            >
                                Save
                            </Button>
                        ) : (
                            <UnavailablePeriodDeleteButton
                                unavailablePeriod={unavailablePeriod}
                            />
                        )}
                    </Grid>
                </Grid>
            </Form>
            <Divider sx={{ paddingTop: 1 }} />
        </>
    );
}
