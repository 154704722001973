import { Grid, Paper, Stack, Typography, Box, Tooltip } from '@mui/material';
import SelectButton from '../Components/SelectButton';
import FeatureListItem from '../Components/FeatureListItem';

export default function SubscriptionTier({
    subscriptionDetails,
    onSelect,
    isSelected,
    gridSize,
}) {
    const handleClick = () => {
        onSelect(subscriptionDetails.tierId);
    };

    if (subscriptionDetails?.exclude) {
        return null;
    } else
        return (
            <Grid item xs={12} lg={gridSize}>
                <Paper
                    elevation={isSelected ? 10 : 2}
                    sx={{
                        width: '100%',
                        height: '475px',
                        ':hover': {
                            boxShadow: 10,
                        },
                    }}
                >
                    <Box
                        sx={{
                            background: subscriptionDetails?.color,
                            height: '42%',
                        }}
                    >
                        <Stack
                            height="100%"
                            direction="column"
                            justifyContent="center"
                            padding={3}
                        >
                            <Typography
                                variant="h2"
                                fontWeight={600}
                                mb={0}
                                mt={0}
                                pt={0}
                            >
                                Fairview
                            </Typography>
                            <Typography
                                variant="h2"
                                fontWeight={600}
                                mb={0}
                                mt={0}
                                pt={0}
                            >
                                {subscriptionDetails?.plan}
                            </Typography>
                            {subscriptionDetails?.pricePerUser !== 0 && (
                                <Typography pt={2} fontSize={20}>
                                    ${subscriptionDetails?.pricePerUser}/mo{' '}
                                    <Tooltip title="Fairview will prorate your users by the duration they were active during the month.">
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            per user
                                        </span>
                                    </Tooltip>
                                </Typography>
                            )}
                        </Stack>
                    </Box>
                    <Stack
                        direction="column"
                        paddingLeft={2}
                        paddingRight={2}
                        paddingTop={1}
                        spacing={0.5}
                    >
                        <Typography>
                            {subscriptionDetails?.description}
                        </Typography>
                        {subscriptionDetails?.tagline && (
                            <Typography pt={0.5} pb={0.5}>
                                {subscriptionDetails.tagline}
                            </Typography>
                        )}

                        {subscriptionDetails?.featureList.map((item) => (
                            <FeatureListItem
                                key={item.id}
                                text={item.text}
                                bold={item.bold}
                            />
                        ))}
                        <Stack direction="row" justifyContent="center" pt={2}>
                            <SelectButton
                                onClick={handleClick}
                                isSelected={isSelected}
                            />
                        </Stack>
                    </Stack>
                </Paper>
            </Grid>
        );
}
