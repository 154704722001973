import { identify } from 'react-fullstory';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import { userActions } from './reducer';
import RestAPI from '../rest-api';
import { coreActionsAsync } from '../CoreState/actions';
import {
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoIdToken,
    CognitoAccessToken,
    CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import cognitoUtility from 'Utilities/cognito-utility';

function clearAllTokens() {
    for (const key in localStorage) {
        if (key.startsWith('CognitoIdentityServiceProvider')) {
            localStorage.removeItem(key);
        }
    }
}

const addUserCharacteristic = createAsyncThunk(
    'user/addUserCharacteristic',
    async (characteristic, { getState, rejectWithValue }) => {
        const loggedInUser = getState().user.loggedInUser;

        record('addCharacteristicToUser', {
            user: loggedInUser.emailAddress,
            characteristic: characteristic,
        });

        const characteristicId = getState().company.characteristics.find(
            (c) => c.name.toLowerCase() === characteristic.name.toLowerCase(),
        );

        try {
            const { data } = await RestAPI.post('/me/characteristics', {
                body: characteristic,
            });

            return {
                ...characteristic,
                id: data,
                userId: loggedInUser.id,
                characteristicId,
            };
        } catch (error) {
            return rejectWithValue({ error: error.response?.data?.errors });
        }
    },
);

const changePassword = createAsyncThunk(
    'user/changePassword',
    async (params, { getState, rejectWithValue }) => {
        const { oldPassword, newPassword } = params;
        const loggedInUser = getState().user.loggedInUser;

        record('changePassword', { user: loggedInUser.emailAddress });

        try {
            return await cognitoUtility.changePassword(
                oldPassword,
                newPassword,
            );
        } catch (err) {
            if (err && err.code === 'LimitExceededException') {
                return rejectWithValue(
                    'Too many attempts! Please try again later',
                );
            } else if (err && err.code === 'NotAuthorizedException') {
                return rejectWithValue('Old Password was invalid');
            } else {
                return rejectWithValue('Could not change password');
            }
        }
    },
);

const checkUsernameAvailability = createAsyncThunk(
    'user/checkUsernameAvailability',
    async (username) => {
        return await RestAPI.get('/signup/check-username-availability', {
            params: {
                username,
            },
        });
    },
);

const confirmCookieConsent = createAsyncThunk(
    'user/confirmCookieConsent',
    async (user) => {
        record('confirmCookieConsent', { username: user?.id });

        return await RestAPI.post('/me/set-cookie-consent', {
            isCookieConsent: user.isCookieConsent,
        });
    },
);

const completeNewPasswordRequirement = createAsyncThunk(
    'user/completeNewPasswordRequirement',
    async (params) => {
        const { cognitoUser, newPassword, attributes } = params;
        record('completeNewPasswordRequirement', cognitoUser);

        let response;

        response = await cognitoUtility.completeNewPasswordRequirement(
            cognitoUser,
            newPassword,
            attributes,
        );

        return response;
    },
);

const contactUsSubmission = createAsyncThunk(
    'user/contactUsSubmission',
    async (params, { rejectWithValue }) => {
        const { submissionFields, isAuthenticated, recaptchaToken } = params;

        if (!isAuthenticated) {
            if (!recaptchaToken) {
                return rejectWithValue('Recaptcha not verified!');
            }

            submissionFields.recaptchaToken = recaptchaToken;
        } else {
            submissionFields.recaptchaToken = 'USER_AUTHENTICATED';
        }

        record('contactUsSubmission', { submissionFields });

        await RestAPI.post('/contact-us', { body: submissionFields });

        return submissionFields;
    },
);

const requestDemoSubmission = createAsyncThunk(
    'user/requestDemoSubmission',
    async (requestDemoInformation, { rejectWithValue }) => {
        record('requestDemoSubmission', requestDemoInformation);

        await RestAPI.post('/request-demo', { body: requestDemoInformation });

        return requestDemoInformation;
    },
);

const getLoggedInUserDetail = createAsyncThunk(
    'user/getLoggedInUserDetail',
    async (_) => {
        const { data: user } = await RestAPI.get('/me');

        // todo
        return { ...user };
    },
);

const deleteUserCharacteristic = createAsyncThunk(
    'user/deleteUserCharacteristic',
    async (characteristic, { getState }) => {
        const loggedInUser = getState().user.loggedInUser;

        record('deleteCharacteristicFromUser', {
            user: loggedInUser.emailAddress,
            characteristic: characteristic,
        });

        await RestAPI.del('/me/characteristics/' + characteristic.id);

        return { ...characteristic };
    },
);

const forgotPassword = createAsyncThunk(
    'user/forgotPassword',
    async (username) => {
        record('forgotPassword', { username: username });

        await cognitoUtility.forgotPassword(username);

        return username;
    },
);

const forgotPasswordConfirmed = createAsyncThunk(
    'user/forgotPasswordConfirmed',
    async (params, { dispatch }) => {
        const { username, code, newPassword } = params;
        record('forgotPasswordConfirmed', { username: username });

        await cognitoUtility.forgotPasswordConfirmed(
            username,
            code,
            newPassword,
        );

        return dispatch(
            signinUser({
                credentials: { username: username, password: newPassword },
            }),
        );
    },
);

const getUserCharacteristicDetail = createAsyncThunk(
    'user/getUserCharacteristicDetail',
    async ({ userId, userCharacteristicId }, { rejectWithValue }) => {
        try {
            return await RestAPI.get(
                `users/${userId}/characteristics/${userCharacteristicId}`,
            );
        } catch (error) {
            return rejectWithValue({
                userId,
                userCharacteristicId,
            });
        }
    },
);

const resendCode = createAsyncThunk('user/resendCode', async (username) => {
    record('resendCode', { username: username });

    await cognitoUtility.resendCode(username);

    return username;
});

const resigninUser = createAsyncThunk('user/resigninUser', async () => {
    const cognitoUser = await cognitoUtility.getCurrentUserWithSession();

    record('resigninUser', { id: cognitoUser.username });

    const groupClaim =
        cognitoUser.signInUserSession.idToken.payload['cognito:groups'];
    let group =
        groupClaim && groupClaim.length ? groupClaim[0] : 'ReadOnlyNoFinance';

    await RestAPI.put('/me/record-login');
    const { data: user } = await RestAPI.get('/me');

    identify(user.emailAddress, {
        displayName: user.firstName + ' ' + user.lastName,
        email: user.emailAddress,
    });

    return {
        ...user,
        group,
    };
});

const setInitialLoginToFalse = createAsyncThunk(
    'user/setInitialLoginToFalse',
    async (_, { getState }) => {
        const currentUser = getState().user.loggedInUser;
        record('setInitialLoginToFalse', { username: currentUser.username });

        return await RestAPI.post('/me/set-initial-login-false');
    },
);

const exchangeAuthCodeForToken = createAsyncThunk(
    'user/exchangeAuthCodeForToken',
    async ({ code }, { dispatch, rejectWithValue }) => {
        const { data } = await RestAPI.get(`me/auth-code/${code}`);
        const { id_token, access_token, refresh_token } = data;

        const userSession = new CognitoUserSession({
            IdToken: new CognitoIdToken({ IdToken: id_token }),
            AccessToken: new CognitoAccessToken({ AccessToken: access_token }),
            RefreshToken: new CognitoRefreshToken({
                RefreshToken: refresh_token,
            }),
        });

        const userData = {
            Username: userSession.getIdToken().payload.email,
            Pool: cognitoUtility.idpCognitoUserPool,
        };

        const cognitoUser = new CognitoUser(userData);

        if (!cognitoUser) return rejectWithValue('Unable To Login');

        cognitoUser.setSignInUserSession(userSession);

        await RestAPI.put('/me/record-login');
        const { data: user } = await RestAPI.get('/me');

        const groupClaim =
            cognitoUser.signInUserSession.idToken.payload['cognito:groups'];
        let group =
            groupClaim && groupClaim.length
                ? groupClaim[0]
                : 'ReadOnlyNoFinance';

        const result = {
            ...user,
            group,
        };

        return { user: result };
    },
);

const signinUser = createAsyncThunk(
    'user/signinUser',
    async (params, { dispatch, rejectWithValue }) => {
        const { credentials } = params;
        record('signinUser', { username: credentials?.username });

        const authenticationData = {
            Username: credentials?.username,
            Password: credentials?.password,
        };

        const authenticationDetails = new AuthenticationDetails(
            authenticationData,
        );

        const userPool = cognitoUtility.cognitoUserPool;

        const userData = {
            Username: credentials?.username,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.emailAddress = credentials?.username;

        if (!cognitoUser) return rejectWithValue('Unable To Login');

        let userSession;
        try {
            userSession = await cognitoUtility.authenticateUser(
                cognitoUser,
                authenticationDetails,
            );
        } catch (err) {
            const errorMessage = `${err?.code}|${err?.message}`;
            return rejectWithValue(errorMessage);
        }

        if (userSession === 'CONFIRMATION_REQUIRED') {
            return { code: 'CONFIRMATION_REQUIRED', user: cognitoUser };
        }

        if (userSession === 'NEW_PASSWORD_REQUIRED')
            return { code: 'CHANGE_PASSWORD', user: cognitoUser };

        cognitoUser.setSignInUserSession(userSession);

        const userAttributes = await cognitoUtility.getUserAttributes(
            cognitoUser,
        );
        cognitoUser.attributes = userAttributes;

        await RestAPI.put('/me/record-login');
        const { data: user } = await RestAPI.get('/me');

        identify(user.emailAddress, {
            displayName: user.firstName + ' ' + user.lastName,
            email: user.emailAddress,
        });

        if (user.isCookieConsent) {
            dispatch(userActions.confirmCookieConsent(user));
        }

        const groupClaim =
            cognitoUser.signInUserSession.idToken.payload['cognito:groups'];
        let group =
            groupClaim && groupClaim.length
                ? groupClaim[0]
                : 'ReadOnlyNoFinance';

        const result = {
            ...user,
            group,
        };

        if (user.isInitialLogin) {
            return { code: 'GET_STARTED', user: result };
        } else {
            return { code: 'NORMAL', user: result };
        }
    },
);

const signoutUser = createAsyncThunk(
    'user/signoutUser',
    async (_, { dispatch }) => {
        const cognitoUser = cognitoUtility.getCurrentUser();
        if (cognitoUser) cognitoUser.signOut();

        // Explicity clear auth tokens on sign out
        clearAllTokens();

        dispatch(coreActionsAsync.appPurge());
    },
);

const signupUser = createAsyncThunk('user/signupUser', async (params) => {
    const { registrant } = params;

    record('signupUser', {
        registrant: registrant.tenantName,
        email: registrant.userEmail,
    });

    const response = await RestAPI.post('/signup', { body: registrant });

    return {
        ...response.data.userDetail,
        tenantId: response.data.tenantId,
        password: registrant.userPassword,
    };
});

const signupUserVerification = createAsyncThunk(
    'user/signupUserVerification',
    async (params, { rejectWithValue }) => {
        const { username, code } = params;
        record('signupUserVerification', { username });

        try {
            await cognitoUtility.confirmSignUp(username, code, null);
        } catch (err) {
            const errorMessage = `${err?.code}|${err?.message}`;
            return rejectWithValue(errorMessage);
        }
    }
);

const updateCurrentUser = createAsyncThunk(
    'user/updateCurrentUser',
    async (updatedUserData, { getState }) => {
        const currentUser = getState().user.loggedInUser;

        record('updateCurrentUser', { id: currentUser.id });

        await RestAPI.put('/me/record-login');
        await RestAPI.put('/me', { body: updatedUserData });

        return {
            ...currentUser,
            firstName: updatedUserData.firstName,
            lastName: updatedUserData.lastName,
            phoneNumber: updatedUserData.phoneNumber,
        };
    },
);

const updateTableSettings = createAsyncThunk(
    'users/updateTableSettings',
    async (payload) => {
        await RestAPI.patch('/me/personalize/update-table-settings', {
            body: payload,
        });

        return payload;
    },
);

const addUserSentiment = createAsyncThunk(
    'user/addUserSentiment',
    async (sentiment, { getState, dispatch }) => {
        const loggedInUser = getState().user.loggedInUser;

        record('addUserSentiment', {
            user: loggedInUser.emailAddress,
            sentiment: sentiment.value,
        });

        const response = await RestAPI.post('/me/sentiment', {
            body: sentiment,
        });

        return response.data;
    },
);

const deleteUserSentiment = createAsyncThunk(
    'user/deleteUserSentiment',
    async (sentimentId, { getState }) => {
        const loggedInUser = getState().user.loggedInUser;

        record('deleteUserSentiment', {
            user: loggedInUser.emailAddress,
            sentiment: sentimentId,
        });

        await RestAPI.del(`/me/sentiment/${sentimentId}`, {
            body: sentimentId,
        });

        return sentimentId;
    },
);

const getUserSentiments = createAsyncThunk(
    'user/getUserSentiments',
    async (_, { getState }) => {
        const loggedInUser = getState().user.loggedInUser;

        record('getUserSentiments', {
            user: loggedInUser.emailAddress,
        });

        const { data } = await RestAPI.get(`/me/sentiments`);

        return data;
    },
);

export const userActionsAsync = {
    addUserCharacteristic,
    changePassword,
    checkUsernameAvailability,
    confirmCookieConsent,
    completeNewPasswordRequirement,
    deleteUserCharacteristic,
    forgotPassword,
    forgotPasswordConfirmed,
    getLoggedInUserDetail,
    getUserCharacteristicDetail,
    resendCode,
    resigninUser,
    requestDemoSubmission,
    setInitialLoginToFalse,
    exchangeAuthCodeForToken,
    signinUser,
    signoutUser,
    signupUser,
    signupUserVerification,
    updateCurrentUser,
    updateTableSettings,
    contactUsSubmission,
    addUserSentiment,
    deleteUserSentiment,
    getUserSentiments,
};
