import Basic from './Basic';

export default class Budget extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.name = obj.name || '';

        this.startDate = obj.startDate
            ? new Date(obj.startDate)
            : new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0);

        this.endDate = obj.endDate
            ? new Date(obj.endDate)
            : new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0, 0);

        this.amount = obj.amount ? obj.amount : 0;
    }
}
