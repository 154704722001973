import { MenuItem, TextField } from '@mui/material';

export default function TrackProjectProgressPicker({
    trackProjectsBy,
    onChange,
}) {
    const progressTrackingOptions = [
        { name: 'Points', value: 'Points' },
        { name: 'Hours', value: 'Hours' },
        { name: 'Work Items', value: 'WorkItems' },
        { name: 'Schedule', value: 'Schedule' },
    ];

    return (
        <TextField
            sx={{ minWidth: '155px' }}
            value={trackProjectsBy}
            name="trackProjectsBy"
            select
            onChange={onChange}
        >
            {progressTrackingOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.name}
                </MenuItem>
            ))}
        </TextField>
    );
}
