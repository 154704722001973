import NewMilestoneModal from 'System/Modals/NewMilestoneModal/NewMilestoneModal';
import NewProfileModal from 'System/Modals/NewProfileModal/NewProfileModal';
import NewProjectModal from 'System/Modals/NewProjectModal/NewProjectModal';
import NewResourceModal from 'System/Modals/NewResourceModal/NewResourceModal';
import NewTeamModal from 'System/Modals/NewTeamModal/NewTeamModal';
import ResourceDetailModal from 'System/Modals/ResourceDetailModal/ResourceDetailModal';
import ResourceTimelineDetailModal from 'System/Modals/ResourceTimelineDetailModal/ResourceTimelineDetailModal';
import NewProjectResourceTimelineModal from 'System/Modals/NewProjectResourceTimelineModal/NewProjectResourceTimelineModal';
import EpicDetailModal from 'System/Modals/EpicDetailModal/EpicDetailModal';
import MilestoneDetailModal from 'System/Modals/MilestoneDetailModal/MilestoneDetailModal';
import NewEpicModal from 'System/Modals/NewEpicModal/NewEpicModal';
import ScheduleDetailModal from 'System/Modals/ScheduleDetailModal/ScheduleDetailModal';
import CostDetailModal from 'System/Modals/CostDetailModal/CostDetailModal';
import ProfileDetailModal from 'System/Modals/ProfileDetailModal/ProfileDetailModal';
import ProjectDetailModal from 'System/Modals/ProjectDetailModal/ProjectDetailModal';
import NewWorkItemModal from 'System/Modals/NewWorkItemModal/NewWorkItemModal';
import NewNonLaborExpenseModal from 'System/Modals/NewNonLaborExpenseModal/NewNonLaborExpenseModal';
import NonLaborExpenseDetailModal from 'System/Modals/NonLaborExpenseDetailModal/NonLaborExpenseDetailModal';
import NewDashboardModal from 'System/Modals/NewDashboardModal/NewDashboardModal';
import EditProjectNameModal from 'System/Modals/EditProjectNameModal/EditProjectNameModal';
import BudgetDetailModal from 'System/Modals/BudgetDetailModal/BudgetDetailModal';
import NewBudgetModal from 'System/Modals/NewBudgetModal/NewBudgetModal';
import InviteUsersModal from 'System/Modals/InviteUsersModal/InviteUsersModal';
import TeamDetailModal from 'System/Modals/TeamDetailModal/TeamDetailModal';
import CompanyDetailModal from 'System/Modals/CompanyDetailModal/CompanyDetailModal';
import UserDetailModal from 'System/Modals/UserDetailModal/UserDetailModal';
import AppLoadingModal from './AppLoadingModal/AppLoadingModal';
import MyProfileModal from './MyProfileModal/MyProfileModal';
import LinkProjectToJiraModal from './LinkProjectToJiraModal/LinkProjectToJiraModal';
import ResendCodeModal from './ResendCodeModal/ResendCodeModal';
import UnavailablePeriodModal from './UnavailablePeriodModal/UnavailablePeriodModal';
import EditWorkItemNameModal from './EditWorkItemNameModal/EditWorkItemNameModal';
import ActionWarningModal from './ActionWarningModal/ActionWarningModal';
import BulkImportFailureModal from './BulkImportFailureModal/BulkImportFailureModal';
import NewProjectPhaseModal from './NewProjectPhaseModal/NewProjectPhaseModal';
import EditProjectPhaseModal from './EditProjectPhaseModal/EditProjectPhaseModal';
import SubscriptionModal from './SubscriptionModal/SubscriptionModal';
import CancelSubscriptionModal from './CancelSubscriptionModal/CancelSubscriptionModal';
import TransactionCanceledModal from './TransactionCanceledModal/TransactionCanceledModal';
import UpgradeSuccessfulModal from './UpgradeSuccessfulModal/UpgradeSuccessfulModal';
import ConfirmLogoutModal from './ConfirmLogOutModal/ConfirmLogoutModal';
import CopyProjectModal from './CopyProjectModal/CopyProjectModal';
import MakePrivateModal from './MakePrivateModal/MakePrivateModal';

const Modals = () => (
    <>
        <AppLoadingModal open={false} />
        <BudgetDetailModal />
        <CompanyDetailModal />
        <ConfirmLogoutModal />
        <CostDetailModal />
        <EditProjectNameModal />
        <EditProjectPhaseModal />
        <EpicDetailModal />
        <InviteUsersModal />
        <LinkProjectToJiraModal />
        <MilestoneDetailModal />
        <MyProfileModal />
        <NewBudgetModal />
        <NewDashboardModal />
        <NewEpicModal />
        <NewMilestoneModal />
        <NewNonLaborExpenseModal />
        <NewProfileModal />
        <NewProjectModal />
        <NewProjectResourceTimelineModal />
        <NewResourceModal />
        <NewTeamModal />
        <NewWorkItemModal />
        <NewProjectPhaseModal />
        <NonLaborExpenseDetailModal />
        <ProfileDetailModal />
        <ProjectDetailModal />
        <ResendCodeModal />
        <ResourceDetailModal />
        <ResourceTimelineDetailModal />
        <ScheduleDetailModal />
        <TeamDetailModal />
        <UnavailablePeriodModal />
        <UserDetailModal />
        <EditWorkItemNameModal />
        <ActionWarningModal />
        <BulkImportFailureModal />
        <SubscriptionModal />
        <TransactionCanceledModal />
        <UpgradeSuccessfulModal />
        <CancelSubscriptionModal />
        <CopyProjectModal />
        <MakePrivateModal />
    </>
);

export default Modals;
