import {
    Avatar,
    AvatarGroup,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { resourceSelectors } from 'System/State/ResourceState/selectors';
import { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { WorkItemAssigneeListPopper } from 'System/Pages/WorkItemDetailPage/Components/WorkItemAssigneeListPopper';

export default function ResourceFilter({ onChange, name, selectedResources }) {
    const resources = useSelector(resourceSelectors.activeResources);
    const assignedResources = resources.filter((x) =>
        selectedResources.includes(x.id),
    );
    const unassignedResources = resources.filter(
        (x) => !selectedResources.includes(x.id),
    );

    const [anchor, setAnchor] = useState(null);

    const handleClick = (e) => {
        setAnchor(anchor ? null : e?.currentTarget);
    };

    const handleInputChange = (updatedList) => {
        onChange({
            target: {
                name: name,
                value: updatedList,
            },
        });
    };

    const handleResourceAssigned = (resourceId) => {
        const updatedList = [...selectedResources, resourceId];
        handleInputChange(updatedList);
    };

    const handleResourceUnassigned = (resourceId) => {
        const updatedList = selectedResources.filter((x) => x !== resourceId);
        handleInputChange(updatedList);
    };

    if (resources) {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h6">Assignee(s)</Typography>

                <IconButton variant="contained" onClick={handleClick}>
                    <AddCircleOutlineIcon
                        sx={{
                            borderRadius: '100%',
                            // backgroundColor: '#ccc',
                        }}
                    ></AddCircleOutlineIcon>
                </IconButton>
                <WorkItemAssigneeListPopper
                    anchor={anchor}
                    assignedResources={assignedResources}
                    unassignedResources={unassignedResources}
                    onResourceAssigned={handleResourceAssigned}
                    onResourceUnassigned={handleResourceUnassigned}
                    handleClose={() => handleClick(null)}
                ></WorkItemAssigneeListPopper>
                <AvatarGroup
                    max={4}
                    sx={{
                        '& .MuiAvatar-root': {
                            width: '35px',
                            height: '35px',
                            fontSize: '12px',
                        },
                    }}
                >
                    {assignedResources.map((resource) => (
                        <Avatar key={resource.id} alt={resource.name}>
                            <Tooltip title={resource.name}>
                                <span>{resource.initials}</span>
                            </Tooltip>
                        </Avatar>
                    ))}
                </AvatarGroup>
            </Stack>
        );
    } else {
        return null;
    }
}
