import { createSelector } from 'reselect';
import Team from 'Models/Team';

const selectedTeamId = (state) => state.teams?.selectedTeam;

const allTeams = createSelector(
    (state) => state.teams?.list,
    (teams) =>
        teams?.length
            ? teams
                  .slice()
                  .sort((a, b) => {
                      if (a.name < b.name) {
                          return -1;
                      }
                      if (a.name > b.name) {
                          return 1;
                      }
                      return 0;
                  })
                  .map((team) => new Team(team))
            : [],
);

const selectedTeam = createSelector(allTeams, selectedTeamId, (teams, teamId) =>
    teams?.length && teamId ? teams.find((team) => team.id === teamId) : null,
);

export const teamSelectors = {
    allTeams,
    selectedTeam,
};
