import { Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import NonLaborExpense from 'Models/NonLaborExpense';
import FinancialField from 'System/UI/Components/FinancialField/FinancialField';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import Form from 'System/Forms/Components/Form';

export default function NonLaborExpenseForm({
    nonLaborExpense = new NonLaborExpense(),
    onSubmit,
}) {
    const nonLaborExpenseSchema = yup.object().shape({
        description: yup.string().required('Required'),
        amountPerEach: yup
            .number()
            .typeError('Must be a number')
            .required('Required')
            .min(1, 'Must be greater than zero'),
        quantity: yup
            .number()
            .typeError('Must be a number')
            .required('Required')
            .min(1, 'Must be greater than zero'),
        effectiveDate: yup
            .date()
            .typeError('Invalid Date')
            .required('Required'),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: nonLaborExpense,
            validationSchema: nonLaborExpenseSchema,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return nonLaborExpense ? (
        <Form onSubmit={handleSubmit} formId="additional-cost-form">
            <Stack spacing={2}>
                <TextField
                    name="description"
                    label="Description"
                    required
                    fullWidth
                    autoFocus
                    error={showError('description')}
                    helperText={getError('description')}
                    value={values.description || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={{ xs: 2, sm: 1 }}
                >
                    <FinancialField
                        name="amountPerEach"
                        label="Price"
                        value={values.amountPerEach || ''}
                        required
                        isError={showError('amountPerEach')}
                        errorMsg={getError('amountPerEach')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <TextField
                        name="quantity"
                        label="Quantity"
                        type="number"
                        required
                        fullWidth
                        error={showError('quantity')}
                        helperText={getError('quantity')}
                        value={values.quantity || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <DatePicker
                        name="effectiveDate"
                        format="MM/DD/YYYY"
                        label="Effective Date"
                        required
                        error={showError('effectiveDate')}
                        helperText={getError('effectiveDate')}
                        value={values.effectiveDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Stack>
            </Stack>
        </Form>
    ) : null;
}
