import {
    TextField,
    Autocomplete,
    Stack,
    IconButton,
    Tooltip,
    MenuItem,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { userSelectors } from 'System/State/UserState/selectors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { profileSelectors } from 'System/State/ProfileState/selectors';
import { profileActions } from 'System/State/ProfileState/reducer';

export default function ProjectProfilePicker({ selectedProfile, disabled, quantity, setQuantity, showError, getError }) {
    const dispatch = useDispatch();
    const availableProfiles = useSelector(profileSelectors.allProfiles);
    const isAllowedContribute = useSelector(userSelectors.isAllowedContribute);

    return availableProfiles ? (
        <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Autocomplete
                options={availableProfiles}
                value={selectedProfile}
                getOptionLabel={(option) => option.name || ''}
                id="auto-complete"
                onChange={(_, val) =>
                    dispatch(profileActions.selectProfile(val?.id))
                }
                autoComplete
                required
                noOptionsText={
                    isAllowedContribute ? (
                        <MenuItem
                            onMouseDown={() =>
                                dispatch(modalActions.open('newProfileModal'))
                            }
                        >
                            Add Profile
                        </MenuItem>
                    ) : (
                        'No Options Found'
                    )
                }
                fullWidth
                includeInputInList
                renderInput={(params) => (
                    <TextField {...params} fullWidth label="Profiles" />
                )}
                disabled={disabled}
            />
            {isAllowedContribute ? (
                <IconButton
                    onClick={() =>
                        dispatch(modalActions.open('newProfileModal'))
                    }
                >
                    <Tooltip
                        title="Can't find what you're looking for? Click here to add a new profile to this company."
                        aria-label="add-new-resource"
                        enterDelay={300}
                        placement="top"
                    >
                        <AddCircleOutlineIcon />
                    </Tooltip>
                </IconButton>
            ) : null}
            <TextField
                name="quantity"
                label="# Needed"
                value={quantity}
                type='number'
                error={showError('quantity')}
                helperText={getError('quantity')}
                placeholder="0"
                disabled={disabled}
                onChange={(e) => setQuantity(e.target.value)}
            />
        </Stack>
    ) : null;
}
