import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Form from 'System/Forms/Components/Form';

export default function ResendCodeForm({ onSubmit }) {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: { username: '' },
            validationSchema: yup.object().shape({
                username: yup
                    .string()
                    .email('Must be a valid email address')
                    .required("Email address can't be empty"),
            }),
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return (
        <Form onSubmit={handleSubmit} formId="resend-code-form">
            <TextField
                name="username"
                label="Email Address"
                value={values.username}
                fullWidth
                required
                autoComplete="username"
                autoFocus
                error={showError('username')}
                helperText={getError('username')}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </Form>
    );
}
