import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Form from 'System/Forms/Components/Form';

export default function LinkProjectToJiraForm({
    jiraIntegrationKey = '',
    onSubmit,
}) {
    const linkProjectToJiraSchema = yup.object().shape({
        jiraIntegrationKey: yup.string().required('Required'),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: { jiraIntegrationKey },
            validationSchema: linkProjectToJiraSchema,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return (
        <Form onSubmit={handleSubmit} formId="link-project-to-jira-form">
            <TextField
                name="jiraIntegrationKey"
                label="Project's Jira Integration Key"
                required
                fullWidth
                autoFocus
                error={showError('jiraIntegrationKey')}
                helperText={getError('jiraIntegrationKey')}
                value={values.jiraIntegrationKey}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </Form>
    );
}
