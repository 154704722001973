import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { useDispatch } from 'react-redux';
import { milestoneActions } from 'System/State/MilestoneState/reducer';
import MilestoneForm from 'System/Forms/MilestoneForm/MilestoneForm';
import { paneActions } from 'System/State/PaneState/reducer';
import InfoTooltip from 'System/UI/Components/InfoTooltip/InfoTooltip';
import { ProjectDetailTabs } from 'Models/ProjectDetailTabs';

export default function NewMilestoneModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.newMilestoneModal) || false;

    const handleClose = () => dispatch(modalActions.close('newMilestoneModal'));

    const handleSubmit = (milestone) => {
        dispatch(milestoneActions.addMilestone(milestone));
        dispatch(paneActions.setProjectDetailSelectedTab(ProjectDetailTabs.Milestone));
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Add Milestone"
            aria-describedby="Add a new milestone to the project"
        >
            <DialogTitle>
                {' '}
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography variant="h3">Add Milestone</Typography>
                    <InfoTooltip
                        toolTipText={
                            'Milestones serve as checkpoints to evaluate progress and determine if the project is on track.'
                        }
                    />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <MilestoneForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit" form="milestone-form">
                    Save Milestone
                </Button>
            </DialogActions>
        </Dialog>
    );
}
