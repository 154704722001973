import Basic from './Basic';

export default class Comment extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.isActive,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        // the actual comment
        this.comment = obj.comment || '';

        this.isActive = obj.isActive;

        this.projectId = obj.projectId;
    }

    get initials() {
        return this.createdByName
            ?.trim()
            .split(' ')
            .reduce((acc, curr, index, arr) => {
                if (index === 0 || index === arr.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
    }
}
