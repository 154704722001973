import { createSlice } from '@reduxjs/toolkit';
import { dashboardActionsAsync } from './actions';
import {
    addItemToList,
    addToOrUpdateInList,
    getUpdatedList,
    removeItemFromListById,
} from 'Utilities/reducer-utils';
import { coreActionsAsync } from 'System/State/CoreState/actions';
import { userActionsAsync } from 'System/State/UserState/actions';

const {
    addDashboard,
    deleteDashboard,
    disableDashboard,
    getDashboardDetail,
    selectDashboard,
    updateDashboard,
    pinDashboard,
} = dashboardActionsAsync;

const { appStart, offline } = coreActionsAsync;

const { resigninUser, signinUser, exchangeAuthCodeForToken } = userActionsAsync;

const initialState = {
    filterOptions: {
        startDate: null,
        endDate: null,
        selectedFilter: '',
    },
    projectWidgetFilterOptions: {
        selectedProjects: [],
        selectedStatuses: [0, 1],
        selectedRisks: [],
        selectedLabels: [],
        selectedTeams: [],
        selectedLinesOfBusiness: [],
        selectedResources: [],
        selectedProfiles: [],
        selectedColors: [],
        search: '',
    },
    workItemWidgetFilterOptions: {
        selectedTypes: [],
        selectedStatuses: [],
        selectedLabels: [],
        selectedAssignees: [],
        selectedPhases: [],
        selectedEpics: [],
        selectedProjects: [],
        search: '',
    },
    kanbanWidgetFilterOptions: {
        selectedTypes: [],
        selectedLabels: [],
        selectedAssignees: [],
        selectedPhases: [],
        selectedEpics: [],
        selectedProjects: [],
        search: '',
    },
    list: [],
    widgets: [],
    selectedDashboardId: null,
    selectedDashboardEdit: null,
};

export const DashboardSlice = createSlice({
    name: 'dashboards',
    initialState: initialState,
    reducers: {
        setSelectedDashboardEdit(state, action) {
            state.selectedDashboardEdit = action.payload;
        },
        addLayoutItemToDashboardEdit(state, action) {
            state.selectedDashboardEdit.dashboardLayoutItems = [
                ...state.selectedDashboardEdit.dashboardLayoutItems,
                action.payload,
            ];
        },
        changeKanbanWidgetFilters(state, action) {
            state.kanbanWidgetFilterOptions = action.payload;
        },
        changeProjectWidgetFilters(state, action) {
            state.projectWidgetFilterOptions = action.payload;
        },
        changeWorkItemWidgetFilters(state, action) {
            state.workItemWidgetFilterOptions = action.payload;
        },
        removeLayoutItemFromDashboardEdit(state, action) {
            state.selectedDashboardEdit.dashboardLayoutItems =
                removeItemFromListById(
                    state.selectedDashboardEdit.dashboardLayoutItems,
                    action.payload,
                );
        },
        resetSlice() {
            return initialState;
        },
        unselectDashboard(state) {
            state.selectedDashboardId = null;
        },
        changeDashboardFilters(state, action) {
            state.filterOptions = action.payload;
        },
    },
    extraReducers: {
        [addDashboard.fulfilled]: (state, action) => {
            state.list = addItemToList(state.list, action.payload);
            state.selectedDashboardId = action.payload.id;
            state.selectedDashboardEdit = action.payload;
        },
        [appStart.fulfilled]: (state, action) => {
            const { dashboards, widgets } = action.payload.data;
            if (dashboards) state.list = dashboards;
            if (dashboards.length && !state.selectedDashboardId) {
                let overviewDashboard = dashboards.find(
                    (dashboard) => dashboard.name === 'Overview',
                );
                state.selectedDashboardId = overviewDashboard
                    ? overviewDashboard.id
                    : dashboards[0].id;
            }
            if (widgets) state.widgets = widgets;
        },
        [offline.fulfilled]: (state, action) => {
            const { dashboards, widgets } = action.payload.data;
            if (dashboards) state.list = dashboards;
            if (dashboards.length && !state.selectedDashboardId) {
                let overviewDashboard = dashboards.find(
                    (dashboard) => dashboard.name === 'Overview',
                );
                state.selectedDashboardId = overviewDashboard
                    ? overviewDashboard.id
                    : dashboards[0].id;
            }
            if (widgets) state.widgets = widgets;
        },
        [deleteDashboard.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [disableDashboard.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [selectDashboard.fulfilled]: (state, action) => {
            state.selectedDashboardId = action.payload.id;
            state.selectedDashboardEdit = action.payload;
        },
        [getDashboardDetail.fulfilled]: (state, action) => {
            state.list = addToOrUpdateInList(state.list, action.payload.data);
        },
        [resigninUser.fulfilled]: (state, action) => {
            if (action.payload.personalizations?.lastSelectedDashboardId) {
                state.selectedDashboardId =
                    action.payload.personalizations.lastSelectedDashboardId;
            }
        },
        [exchangeAuthCodeForToken.fulfilled]: (state, action) => {
            if (action.payload.personalizations?.lastSelectedDashboardId) {
                state.selectedDashboardId =
                    action.payload.personalizations.lastSelectedDashboardId;
            }
        },
        [signinUser.fulfilled]: (state, action) => {
            if (action.payload.personalizations?.lastSelectedDashboardId) {
                state.selectedDashboardId =
                    action.payload.personalizations.lastSelectedDashboardId;
            }
        },
        [updateDashboard.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [pinDashboard.fulfilled]: (state, action) => {
            state.list.forEach((dashboard) => {
                dashboard.isPinned = false;
            });
            state.list.find(
                (dashboard) => dashboard.id === action.payload,
            ).isPinned = true;
        },
    },
});

export const dashboardActions = {
    ...DashboardSlice.actions,
    ...dashboardActionsAsync,
    changeKanbanWidgetFilters: DashboardSlice.actions.changeKanbanWidgetFilters,
    changeProjectWidgetFilters:
        DashboardSlice.actions.changeProjectWidgetFilters,
    changeWorkItemWidgetFilters:
        DashboardSlice.actions.changeWorkItemWidgetFilters,
};

export default DashboardSlice.reducer;
