export const FEATURE_FLAGS = {
    Billing: 'billing',
    ChatGPTWorkItemDescription: 'chatgpt_generate_workitem_description',
    Insights: 'insights',
    FirstImpressions: 'first_impressions',
    EnterpriseEnabled: 'enterprise_enabled',
    PeopleModule: 'people_module',
};

export const COGNITO = {
    UserPoolId: 'us-east-2_RJrET9eb0',
    ClientId: '76qp7c5g0ld953ub8rbf3qvn32',
    IdpClientId: '6e4kuiialjcfd8uk8eqaslqhmj',
    Domain: 'https://fairview-prod.auth.us-east-2.amazoncognito.com',
};

export const SPLIT_SDK_BROWSER_CONFIG = {
    core: {
        authorizationKey: process.env.REACT_APP_SPLITIO_KEY,
        key: 'fairview_init',
    },
};

export const ENTERPRISE_ONLY_WIDGET_IDS = [
    '7c188cd2-fd2d-4381-949f-c7b1789bf962', // resource financials
    'a44d9ced-b7d0-49f3-a3b4-02157c7bdaa5', // financials over time
];
