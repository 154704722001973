import { event } from 'react-fullstory';
import ReactGA from 'react-ga4';

export const record = (name, msg) => {
    if (process.env.NODE_ENV === 'production') {
        // log to full story
        event(name, msg);

        // log to google analytics
        ReactGA.event({
            category: 'App',
            action: name,
        });
    }
};
