import { TextField, InputAdornment } from '@mui/material';
const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 0,
});

const ReadOnlyFinancialField = ({ value = '', label }) => (
    <TextField
        label={label}
        fullWidth
        sx={{
            '& .MuiInputBase-root': {
                '& > fieldset': {
                    borderColor: 'rgb(0,0,0,0) !important',
                },
            },
        }}
        value={formatter.format(value)}
        InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            readOnly: true,
        }}
    />
);

export default ReadOnlyFinancialField;
