import Basic from './Basic';
import LayoutItem from './LayoutItem';

export default class Dashboard extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.name = obj.name || '';

        this.isPinned = obj.isPinned || false;
        this.rank = obj.rank || 0;

        this.dashboardLayoutItems = obj.dashboardLayoutItems?.length
            ? obj.dashboardLayoutItems.map((item) => new LayoutItem(item))
            : [];
    }

    asBackendDashboard(dashboard) {
        const { dashboardLayoutItems } = dashboard;

        const updated = dashboardLayoutItems.map((item) =>
            this.asBackendItem(item),
        );

        return {
            ...dashboard,
            dashboardLayoutItems: updated,
        };
    }

    asBackendItem(item = {}) {
        return {
            id: item.id,
            name: item.name,
            widgetId: item.widgetId,
            dashboardId: item.dashboardId,
            width: item.w,
            height: item.h,
            x: item.x,
            y: item.y,
        };
    }
}
