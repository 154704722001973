export const createChangeSchedulePayload = (project) => {
    let payload = {
        estimatedStartDate: null,
        estimatedCompletionDate: null,
        actualStartDate: null,
        actualCompletionDate: null,
        epics: project.epics.map((epic) => ({
            id: epic.id,
            startDate: epic.estimatedStartDate ? new Date(epic.estimatedStartDate) : null,
            endDate: epic.estimatedCompletionDate ? new Date(epic.estimatedCompletionDate) : null,
        })),
        resourceTimelines: project.resourceTimelines.map((timeline) => ({
            id: timeline.id,
            startDate: new Date(timeline.startDate),
            endDate: new Date(timeline.endDate),
        })),
    };

    if (project.estimatedStartDate) {
        payload.estimatedStartDate = project.estimatedStartDate;
    }

    if (project.estimatedCompletionDate) {
        payload.estimatedCompletionDate = project.estimatedCompletionDate;
    }

    if (project.actualStartDate) {
        payload.actualStartDate = project.actualStartDate;
    }

    if (project.actualCompletionDate) {
        payload.actualCompletionDate = project.actualCompletionDate;
    }

    payload.trackProjectsBy = project.trackProjectsBy;

    return payload;
};
