import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField } from '@mui/material';
import Team from 'Models/Team';
import Form from 'System/Forms/Components/Form';

export default function TeamForm({ team = new Team(), onSubmit }) {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: team,
            validationSchema: yup.object().shape({
                name: yup.string().required('Required'),
            }),
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return team ? (
        <Form onSubmit={handleSubmit} formId="team-form">
            <TextField
                name="name"
                label="Name"
                required
                fullWidth
                error={showError('name')}
                helperText={getError('name')}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </Form>
    ) : null;
}
