import { lazy, Suspense } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Stack, TextField } from '@mui/material';
import { phoneRegExp } from 'Utilities/phoneRegExp';
import UserGroupPicker from 'System/UI/Pickers/UserGroupPicker/UserGroupPicker';
import Form from 'System/Forms/Components/Form';

const PhoneNumberInput = lazy(() =>
    import('System/UI/Components/PhoneNumberInput/PhoneNumberInput'),
);

export default function UserForm({ user, onSubmit }) {
    const userSchema = yup.object().shape({
        firstName: yup.string().required('Required'),
        lastName: yup.string().required('Required'),
        group: yup.string().required('Required'),
        phoneNumber: yup
            .string()
            .matches(phoneRegExp, 'Invalid Phone Number')
            .nullable(),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: user,
            validationSchema: userSchema,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return user ? (
        <Form onSubmit={handleSubmit} formId="user-form">
            <Stack spacing={2}>
                <TextField
                    name="firstName"
                    label="First Name"
                    error={showError('firstName')}
                    helperText={getError('firstName')}
                    value={values.firstName}
                    placeholder="First Name"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <TextField
                    name="lastName"
                    label="Last Name"
                    error={showError('lastName')}
                    helperText={getError('lastName')}
                    value={values.lastName}
                    placeholder="Last Name"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                <Stack direction="row" alignItems="center" spacing={1}>
                    <UserGroupPicker
                        name="group"
                        fullWidth
                        selectedGroup={values.group}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />

                    <Suspense fallback={<div>Loading...</div>}>
                        <PhoneNumberInput
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone Number"
                            onBlur={handleBlur('phoneNumber')}
                            onChange={handleChange('phoneNumber')}
                            value={
                                (values.phoneNumber = values.phoneNumber
                                    ?.slice()
                                    .replace(/-| |\(|\)/g, ''))
                            }
                            error={showError('phoneNumber')}
                            helperText={getError('phoneNumber')}
                        ></PhoneNumberInput>
                    </Suspense>
                </Stack>
            </Stack>
        </Form>
    ) : null;
}
