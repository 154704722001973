import { EditorState, RichUtils } from 'draft-js';
import { Stack, Tooltip, IconButton } from '@mui/material';
import { useState } from 'react';

import LinkPopover from '../Popovers/LinkPopover';
import ImagePopover from '../Popovers/ImagePopover';
import VideoPopover from '../Popovers/VideoPopover';

import TitleIcon from '@mui/icons-material/Title';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import HighlightIcon from '@mui/icons-material/Highlight';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CodeIcon from '@mui/icons-material/Code';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ImageIcon from '@mui/icons-material/Image';
import MovieIcon from '@mui/icons-material/Movie';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';

export default function Toolbar({
    editorState,
    setEditorState,
    focusEditor,
    handleAddedFiles,
}) {
    const [linkAnchor, setLinkAnchor] = useState(null);
    const [imageAnchor, setImageAnchor] = useState(null);
    const [videoAnchor, setVideoAnchor] = useState(null);

    const applyStyle = (e, style, method) => {
        e.preventDefault();
        switch (method) {
            case 'block':
                setEditorState(RichUtils.toggleBlockType(editorState, style));
                focusEditor();
                break;
            case 'inline':
                setEditorState(RichUtils.toggleInlineStyle(editorState, style));
                focusEditor();
                break;
            case 'undo':
                setEditorState(EditorState.undo(editorState));
                focusEditor();
                break;
            case 'redo':
                setEditorState(EditorState.redo(editorState));
                focusEditor();
                break;
            case 'link':
                //ensures link form is only opened if text is selected
                if (!editorState.getSelection().isCollapsed()) {
                    setLinkAnchor(e.currentTarget);
                }
                break;
            case 'image':
                setImageAnchor(e.currentTarget);
                break;
            case 'video':
                setVideoAnchor(e.currentTarget);
                break;
            default:
                break;
        }
    };

    const isActive = (style, method) => {
        if (method === 'block') {
            const selection = editorState?.getSelection();
            const blockType = editorState
                .getCurrentContent()
                .getBlockForKey(selection.getStartKey())
                .getType();
            return blockType === style;
        } else {
            const currentStyle = editorState.getCurrentInlineStyle();
            return currentStyle.has(style);
        }
    };

    const tools = [
        {
            label: 'title',
            tooltipText: 'Title Case',
            style: 'header-two',
            icon: <TitleIcon />,
            method: 'block',
        },
        {
            label: 'bold',
            tooltipText: 'Bold',
            style: 'BOLD',
            icon: <FormatBoldIcon />,
            method: 'inline',
        },
        {
            label: 'italicize',
            tooltipText: 'Italicize',
            style: 'ITALIC',
            icon: <FormatItalicIcon />,
            method: 'inline',
        },
        {
            label: 'underline',
            tooltipText: 'Underline',
            style: 'UNDERLINE',
            icon: <FormatUnderlinedIcon />,
            method: 'inline',
        },
        {
            label: 'strike-through',
            tooltipText: 'Strike-Through',
            style: 'STRIKETHROUGH',
            icon: <StrikethroughSIcon />,
            method: 'inline',
        },
        {
            label: 'highlight',
            tooltipText: 'Highlight',
            style: 'HIGHLIGHT',
            icon: <HighlightIcon />,
            method: 'inline',
        },
        {
            label: 'blockQuote',
            tooltipText: 'Quote',
            style: 'blockQuote',
            icon: <FormatQuoteIcon />,
            method: 'block',
        },
        {
            label: 'codeblock',
            tooltipText: 'Code Block',
            style: 'CODEBLOCK',
            icon: <CodeIcon />,
            method: 'inline',
        },
        {
            label: 'orderedList',
            tooltipText: 'Ordered List',
            style: 'ordered-list-item',
            icon: <FormatListNumberedIcon />,
            method: 'block',
        },
        {
            label: 'unorderedList',
            tooltipText: 'Unordered List',
            style: 'unordered-list-item',
            icon: <FormatListBulletedIcon />,
            method: 'block',
        },

        {
            label: 'link',
            tooltipText: 'Link',
            style: 'link',
            icon: <AddLinkIcon />,
            method: 'link',
        },
        {
            label: 'image',
            tooltipText: 'Upload Image',
            style: 'image',
            icon: <ImageIcon />,
            method: 'image',
        },
        {
            label: 'video',
            tooltipText: 'Upload Video',
            style: 'video',
            icon: <MovieIcon />,
            method: 'video',
        },
        {
            label: 'undo',
            tooltipText: 'Undo',
            style: 'undo',
            icon: <UndoIcon />,
            method: 'undo',
        },
        {
            label: 'redo',
            tooltipText: 'Redo',
            style: 'redo',
            icon: <RedoIcon />,
            method: 'redo',
        },
    ];

    return (
        <Stack direction="row" alignItems="center" flexWrap="wrap">
            {tools.map((item) => (
                <Stack key={item.label}>
                    <Tooltip title={item.tooltipText}>
                        <IconButton
                            onMouseDown={(e) =>
                                applyStyle(e, item.style, item.method)
                            }
                            sx={{
                                color: isActive(item.style, item.method)
                                    ? '#FFAB00'
                                    : 'rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            {item.icon}
                        </IconButton>
                    </Tooltip>
                </Stack>
            ))}
            <LinkPopover
                anchor={linkAnchor}
                setAnchor={setLinkAnchor}
                editorState={editorState}
                setEditorState={setEditorState}
            />
            <ImagePopover
                anchor={imageAnchor}
                setAnchor={setImageAnchor}
                editorState={editorState}
                setEditorState={setEditorState}
                handleAddedFiles={handleAddedFiles}
            />

            <VideoPopover
                anchor={videoAnchor}
                setAnchor={setVideoAnchor}
                handleAddedFiles={handleAddedFiles}
            />
        </Stack>
    );
}
