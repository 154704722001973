import { useEffect } from 'react';

export function useLocalStorageTokensChangeListener(callback) {
    useEffect(() => {
        const debounceTime = 500;
        let timeoutId;

        const handleStorageChange = (event) => {
            if (event.key.startsWith('CognitoIdentityServiceProvider')) {
                // Clear the previous timeout
                clearTimeout(timeoutId);

                // Set a new timeout with the specified debounceTime
                timeoutId = setTimeout(() => {
                    callback(event);
                }, debounceTime);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            clearTimeout(timeoutId);
        };
    }, [callback]);
}
