import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { dashboardActions } from 'System/State/DashboardState/reducer';
import { useNavigate } from 'react-router-dom';
import DashboardForm from 'System/Forms/DashboardForm/DashboardForm';

export default function NewDashboardModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const open = useSelector(modalSelectors.newDashboardModal) || false;

    const handleClose = () => {
        dispatch(modalActions.close('newDashboardModal'));
    };

    const handleSubmit = (values) => {
        dispatch(dashboardActions.addDashboard(values)).then((response) => {
            handleClose();
            navigate('/dashboard-edit');
        });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Add Dashboard"
            aria-describedby="Add a new dashboard"
        >
            <DialogTitle>Add Dashboard</DialogTitle>
            <DialogContent>
                <DashboardForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" type="submit" form="dashboard-form">
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
}
