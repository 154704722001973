import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { projectActions } from 'System/State/ProjectState/reducer';
import { projectSelectors } from '../../State/ProjectState/selectors';

export default function MakePrivateModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.makePrivateModal) || false;
    const selectedProject = useSelector(projectSelectors.selectedProject);

    const handleClose = () => dispatch(modalActions.close('makePrivateModal'));

    const handleMakePublic = () => {
        handleClose();
        selectedProject.isPrivate = false;

        dispatch(projectActions.updateProject(selectedProject));
    };
    const handleMakePrivate = () => {
        handleClose();
        selectedProject.isPrivate = true;

        dispatch(projectActions.updateProject(selectedProject));
    };

    return (
        <Dialog open={open} fullWidth onClose={handleClose}>
            {selectedProject?.isPrivate && (
                <DialogTitle>Change to Public Project</DialogTitle>
            )}

            {!selectedProject?.isPrivate && (
                <DialogTitle>Change to Private Project</DialogTitle>
            )}
            <DialogContent>
                {selectedProject?.isPrivate && (
                    <DialogContentText>
                        Are you sure you&apos;d like to change this project to
                        public? All project elements will be visible in company
                        wide dashboards and pages.
                    </DialogContentText>
                )}
                {!selectedProject?.isPrivate && (
                    <DialogContentText>
                        Are you sure you&apos;d like to change this project to
                        private? All project elements will be hidden from
                        company wide dashboards and pages.
                    </DialogContentText>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {selectedProject?.isPrivate && (
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleMakePublic}
                    >
                        Make Public
                    </Button>
                )}
                {!selectedProject?.isPrivate && (
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleMakePrivate}
                    >
                        Make Private
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
