import Basic from './Basic';

export default class UnavailablePeriod extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.reason = obj.reason || '';

        this.startDate = obj.startDate ? new Date(obj.startDate) : null;

        this.endDate = obj.endDate ? new Date(obj.endDate) : null;

        this.skilledResourceId = obj.skilledResourceId || '';

        this.isActive = obj.isActive || false;
    }
}
