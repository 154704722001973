import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { userActions } from 'System/State/UserState/reducer';
import ResendCodeForm from 'System/Forms/SignUpForms/ResendCodeForm';

export default function ResendCodeModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.resendCodeModal) || false;

    const handleClose = () => dispatch(modalActions.close('resendCodeModal'));

    const handleSubmit = ({ username }) => {
        dispatch(userActions.resendCode(username)).then(() => handleClose());
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Resend Code"
            aria-describedby="Resend signup code"
        >
            <DialogTitle>Resend Signup Code</DialogTitle>
            <DialogContent>
                <ResendCodeForm onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="resend-code-form"
                >
                    Resend Code
                </Button>
            </DialogActions>
        </Dialog>
    );
}
