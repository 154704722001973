import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ButtonGroup,
    Stack,
    Typography,
    Divider,
    IconButton,
    Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { resourceSelectors } from 'System/State/ResourceState/selectors';
import { resourceActions } from 'System/State/ResourceState/reducer';
import { makeStyles } from '@mui/styles';
import Calculators from 'Utilities/calculators';
import NewResourceUnavailablePeriodForm from 'System/Forms/ResourceUnavailablePeriodForm/NewResourceUnavailablePeriodForm';
import ResourceUnavailablePeriodForm from 'System/Forms/ResourceUnavailablePeriodForm/ResourceUnavailablePeriodForm';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDeleteModal from '../ConfirmDeleteModal/ConfirmDeleteModal';

const useStyles = makeStyles(() => ({
    selectedButton: {
        backgroundColor: 'rgba(224, 202, 119, 0.2)',
        borderColor: '#FDCA08',
    },
    label: {
        fontSize: '0.7rem',
        marginBottom: 0,
        color: 'grey',
    },
    text: {
        fontSize: '1.25rem',
        color: 'grey',
    },
}));

export function UnavailablePeriodDeleteButton({ unavailablePeriod }) {
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const dispatch = useDispatch();

    const onDelete = () => {
        dispatch(resourceActions.deleteUnavailablePeriod(unavailablePeriod));
    };

    return (
        <>
            <IconButton onClick={() => setDeleteModalOpen(true)}>
                <CloseIcon />
            </IconButton>
            <ConfirmDeleteModal
                isModalOpen={isDeleteModalOpen}
                parentType="Unavailable Period"
                textConfirmation={false}
                confirmDelete={() => {
                    setDeleteModalOpen(false);
                    onDelete(unavailablePeriod);
                }}
                cancelDelete={() => {
                    setDeleteModalOpen(false);
                }}
            />
        </>
    );
}

export function PassedUnavailablePeriod({ unavailablePeriod }) {
    const classes = useStyles();
    const totalUnavailableDays = () => {
        return Calculators.durationInWorkDays(
            unavailablePeriod.startDate,
            unavailablePeriod.endDate,
        );
    };
    return (
        <>
            <Grid container>
                <Grid item xs={3}>
                    <Stack direction="column">
                        <Typography className={classes.label}>
                            Reason
                        </Typography>
                        <Typography className={classes.text}>
                            {unavailablePeriod.reason}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="column">
                        <Typography className={classes.label}>
                            Start Date
                        </Typography>
                        <Typography className={classes.text}>
                            {unavailablePeriod.startDate.toLocaleDateString()}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <Stack direction="column">
                        <Typography className={classes.label}>
                            End Date
                        </Typography>
                        <Typography className={classes.text}>
                            {unavailablePeriod.endDate.toLocaleDateString()}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack direction="column">
                        <Typography className={classes.label}>
                            Total Days
                        </Typography>
                        <Typography className={classes.text}>
                            {totalUnavailableDays()}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={1}>
                    <UnavailablePeriodDeleteButton
                        unavailablePeriod={unavailablePeriod}
                    />
                </Grid>
            </Grid>

            <Divider />
        </>
    );
}

export default function UnavailablePeriodModal() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [toggle, setToggle] = useState(true);

    const open = useSelector(modalSelectors.unavailablePeriodModal) || false;
    const selectedResource = useSelector(resourceSelectors.selectedResource);
    const upcomingUnavailablePeriods = useSelector(
        resourceSelectors.upcomingUnavailablePeriods,
    );
    const passedUnavailablePeriods = useSelector(
        resourceSelectors.passedUnavailablePeriods,
    );

    const handleClose = () => {
        dispatch(modalActions.close('unavailablePeriodModal'));
        setToggle(true);
    };

    const handleSubmit = (values) => {
        dispatch(resourceActions.addUnavailablePeriodToResource(values));
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="Unavailable Days"
            aria-describedby="Set and view unavailable days"
        >
            <DialogTitle mb={0}>
                {`${selectedResource?.name}'s Unavailable Days`}
                {toggle && (
                    <Typography
                        variant="body2"
                        sx={{ margin: 0, paddingLeft: '3px' }}
                    >
                        To add unavailable days to this resource, select a type
                        of absence in the dropdown to then specify a date range.
                    </Typography>
                )}
            </DialogTitle>

            <DialogContent>
                <ButtonGroup
                    variant="outlinedText"
                    aria-label="outlined primary button group"
                >
                    <Button
                        onClick={() => setToggle(true)}
                        className={toggle ? classes.selectedButton : ''}
                    >
                        Upcoming
                    </Button>
                    <Button
                        onClick={() => setToggle(false)}
                        className={!toggle ? classes.selectedButton : ''}
                    >
                        Passed
                    </Button>
                </ButtonGroup>

                {toggle ? (
                    <>
                        {upcomingUnavailablePeriods &&
                            upcomingUnavailablePeriods.map(
                                (unavailablePeriod) => (
                                    <ResourceUnavailablePeriodForm
                                        key={unavailablePeriod.id}
                                        resource={selectedResource}
                                        unavailablePeriod={unavailablePeriod}
                                    />
                                ),
                            )}
                        <NewResourceUnavailablePeriodForm
                            onSubmit={handleSubmit}
                            resource={selectedResource}
                        />
                    </>
                ) : (
                    <>
                        {passedUnavailablePeriods &&
                            passedUnavailablePeriods.map(
                                (unavailablePeriod) => (
                                    <PassedUnavailablePeriod
                                        key={unavailablePeriod.id}
                                        unavailablePeriod={unavailablePeriod}
                                    />
                                ),
                            )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button
                    variant="contained"
                    type="submit"
                    form="resource-unavailable-period-form"
                    disabled={!toggle}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
