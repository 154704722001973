import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    Stack,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { userSelectors } from 'System/State/UserState/selectors';
import MyProfileForm from 'System/Forms/MyProfileForm/MyProfileForm';
import { userActions } from 'System/State/UserState/reducer';

export default function MyProfileModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.myProfileModal) || false;
    const user = useSelector(userSelectors.loggedInUser);

    const handleClose = () => dispatch(modalActions.close('myProfileModal'));

    const handleSubmit = (user) => {
        dispatch(userActions.updateCurrentUser(user));
        handleClose();
    };

    return user ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="My Profile Details"
            aria-describedby="My profile details"
        >
            <DialogTitle>
                <Stack spacing={1}>
                    <Typography variant="h4">My Profile Details</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <MyProfileForm user={user} onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        form="my-profile-form"
                    >
                        Save Details
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
