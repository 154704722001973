import { useState } from 'react';
import {
    Stack,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
} from '@mui/material';
import ConfirmDeleteModal from 'System/Modals/ConfirmDeleteModal/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { companyActions } from 'System/State/CompanyState/reducer';
import { companySelectors } from 'System/State/CompanyState/selectors';
import BudgetForm from 'System/Forms/BudgetForm/BudgetForm';

export default function BudgetDetailModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.budgetDetailModal) || false;
    const budget = useSelector(companySelectors.selectedBudget);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleClose = () => {
        dispatch(modalActions.close('budgetDetailModal'));
    };

    const handleSubmit = (budget) => {
        dispatch(companyActions.updateBudget(budget));
        handleClose();
    };

    const handleDelete = (budget) => {
        dispatch(companyActions.deleteBudget(budget));
        handleClose();
    };

    return budget ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Budget Details"
            aria-describedby="Update budget details"
        >
            <DialogTitle>Budget Details</DialogTitle>
            <DialogContent>
                <BudgetForm budget={budget} onSubmit={handleSubmit} />

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="Budget"
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        handleDelete(budget);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                >
                    <Button
                        variant="outlined"
                        color="tertiary"
                        onClick={() => {
                            setDeleteModalOpen(true);
                        }}
                    >
                        Delete
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            form="budget-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
