import {
    addItemToList,
    addToOrUpdateInList,
    getUpdatedList,
    removeItemFromListById,
} from 'Utilities/reducer-utils';
import { createSlice } from '@reduxjs/toolkit';
import { milestoneActionsAsync } from './actions';
import { coreActionsAsync } from 'System/State/CoreState/actions';
import { projectActions } from '../ProjectState/reducer';

const { appStart, offline } = coreActionsAsync;

const { addMilestone, deleteMilestone, getMilestone, updateMilestone } =
    milestoneActionsAsync;

const { getProjectDetail } = projectActions;

const initialState = {
    list: [],
    selectedMilestone: null,
};

export const MilestoneSlice = createSlice({
    name: 'milestones',
    initialState: initialState,
    reducers: {
        addMilestoneSync(state, action) {
            state.list = addItemToList(state.list, action.payload);
        },
        deleteMilestoneSync(state, action) {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        resetSlice() {
            return initialState;
        },
        selectMilestone(state, action) {
            state.selectedMilestone = action.payload;
        },
        updateMilestoneSync(state, action) {
            state.list = getUpdatedList(state.list, action.payload);
        },
    },
    extraReducers: {
        [addMilestone.fulfilled]: (state, action) => {
            state.list = addItemToList(state.list, action.payload);
        },
        [appStart.fulfilled]: (state, action) => {
            const { milestones } = action.payload.data;
            if (milestones) state.list = milestones;
        },
        [offline.fulfilled]: (state, action) => {
            const { milestones } = action.payload.data;
            if (milestones) state.list = milestones;
        },
        [deleteMilestone.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [getMilestone.fulfilled]: (state, action) => {
            state.list = addToOrUpdateInList(state.list, action.payload.data);
        },
        [getMilestone.rejected]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload);
        },
        [updateMilestone.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
        },
        [getProjectDetail.fulfilled]: (state, action) => {
            if (action?.payload?.data?.milestones?.length < 1) {
                return;
            }

            state.list = action?.payload?.data?.milestones?.reduce(
                (acc, cur) => addToOrUpdateInList(acc, cur),
                state.list.slice(),
            );
        },
    },
});

export const milestoneActions = {
    ...milestoneActionsAsync,
    selectMilestone: MilestoneSlice.actions.selectMilestone,
    addMilestoneSync: MilestoneSlice.actions.addMilestoneSync,
    deleteMilestoneSync: MilestoneSlice.actions.deleteMilestoneSync,
    updateMilestoneSync: MilestoneSlice.actions.updateMilestoneSync,
};

export default MilestoneSlice.reducer;
