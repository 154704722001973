import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { modalActions } from 'System/State/ModalState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import NameForm from 'System/Forms/GenericNameForm/NameForm';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { workItemActions } from 'System/State/ProjectState/reducer';

export default function EditWorkItemNameModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.editWorkItemNameModal) || false;
    const workItem = useSelector(projectSelectors.selectedWorkItem);

    const handleClose = () =>
        dispatch(modalActions.close('editWorkItemNameModal'));

    const handleSubmit = ({ name }) => {
        dispatch(
            workItemActions.updateProjectWorkItem({
                ...workItem,
                name,
            }),
        );
        handleClose();
    };

    if (!workItem) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Edit Work Item Name"
            aria-describedby="Edit the name of a work item"
        >
            <DialogTitle>Edit Work Item Name</DialogTitle>
            <DialogContent>
                <NameForm name={workItem.name} onSubmit={handleSubmit} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="edit-name-form"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
