import {
    CompositeDecorator,
    EditorState,
    convertFromRaw,
    ContentState,
} from 'draft-js';

import { findLinkEntities, Link } from './Components/Popovers/LinkPopover';
import {
    findImageEntities,
    ImageBlock,
    HistoryImageBlock,
} from './Components/Popovers/ImagePopover';
import {
    findVideoEntities,
    VideoBlock,
    HistoryVideoBlock,
} from './Components/Popovers/VideoPopover';

export default class RichTextEditorUtils {
    static decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
        {
            strategy: findImageEntities,
            component: ImageBlock,
        },
        {
            strategy: findVideoEntities,
            component: VideoBlock,
        },
    ]);

    static readOnlyDecorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
        {
            strategy: findImageEntities,
            component: HistoryImageBlock,
        },
        {
            strategy: findVideoEntities,
            component: HistoryVideoBlock,
        },
    ]);

    static convertToEditorState(description, decorator) {
        try {
            //parses json into js object
            const parsed = JSON.parse(description);
            // converts raw js into draft js content state
            const contentState = convertFromRaw(parsed);
            //content state is converted into editor state
            return EditorState.createWithContent(contentState, decorator);
        } catch (error) {
            //Json parse failed, fix data and create clean content state
            //Original cause of problem - Jira integration
            const contentState = ContentState.createFromText(
                description?.replace(/\\n/g, '\n'),
            );
            const editorState = EditorState.createWithContent(
                contentState,
                decorator,
            );

            return editorState;
        }
    }
}
