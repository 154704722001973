import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { projectNonLaborExpenseActions } from 'System/State/ProjectState/reducer';
import NonLaborExpenseForm from 'System/Forms/NonLaborExpenseForm/NonLaborExpenseForm';
import { paneActions } from 'System/State/PaneState/reducer';
import { ProjectDetailTabs } from 'Models/ProjectDetailTabs';

export default function NewNonLaborExpenseModal() {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(modalSelectors.newNonLaborExpenseModal);
    const selectedProject = useSelector(projectSelectors.selectedProject);

    const handleClose = () =>
        dispatch(modalActions.close('newNonLaborExpenseModal'));

    const handleSubmit = (cost) => {
        cost.projectId = selectedProject.id;
        dispatch(
            projectNonLaborExpenseActions.addNonLaborExpenseToProject(cost),
        );
        dispatch(paneActions.setProjectDetailSelectedTab(ProjectDetailTabs.NonLaborExpense));
        handleClose();
    };

    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="New Additional Cost"
            aria-describedby="Add a new additional cost"
        >
            <DialogTitle>Add Additional Cost to Project</DialogTitle>

            <DialogContent>
                <NonLaborExpenseForm onSubmit={handleSubmit} />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    form="additional-cost-form"
                >
                    Save Cost
                </Button>
            </DialogActions>
        </Dialog>
    );
}
