import { useSelector } from 'react-redux';
import { CircularProgress, Box, Fade } from '@mui/material';
import { coreSelectors } from 'System/State/CoreState/selectors';

export default function LoadingSpinner() {
    const isSpinnerVisible = useSelector(coreSelectors.hasPendingRequest);

    return isSpinnerVisible ? (
        <Box
            sx={{
                zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: () => `translate(-50%, -50%)`,
            }}
        >
            <Fade in={isSpinnerVisible} timeout={750}>
                <CircularProgress disableShrink={true} size={90} />
            </Fade>
        </Box>
    ) : null;
}
