import {
    Avatar,
    Button,
    Link,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import resourceLinkIcon from '../../../assets/resource-link-icon.svg';
import { useSelector } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    badge: {
        backgroundColor: '#D9D9D9',
        border: '1px solid #CCC',
        boxShadow: '0 0 0 2px #FFF inset',
    },
    linkedUser: {
        border: '1px solid #FFA800',
    },
}));

const TooltipTitle = ({ name, email }) => (
    <>
        <Typography
            sx={{ fontSize: '14px', fontWeight: 700, marginBlockEnd: '5px' }}
        >
            {`${name} (linked user)`}
        </Typography>
        <Typography sx={{ fontSize: '14px' }}>{name}</Typography>
        <Typography sx={{ fontSize: '14px' }}>{email}</Typography>
    </>
);

export default function ResourceAvatar({
    showName,
    resource,
    badgeSizeInPixels,
    onSelectResource,
    showContactCard,
}) {
    const classes = useStyles();
    const linkedUser = resource?.userId !== null;
    const users = useSelector(companySelectors.usersList);
    const email = users.find((x) => x.id === resource?.userId)?.emailAddress;

    if (resource) {
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar
                    sx={
                        badgeSizeInPixels
                            ? {
                                  width: badgeSizeInPixels,
                                  height: badgeSizeInPixels,
                              }
                            : {}
                    }
                    key={resource.id}
                    onClick={
                        onSelectResource
                            ? onSelectResource.bind(this, resource)
                            : null
                    }
                    alt={resource.name}
                    className={[
                        classes.badge,
                        resource.isLinkedToUser ? classes.linkedUser : null,
                    ].join()}
                >
                    <Button>{resource.initials}</Button>
                </Avatar>
                {showName && (
                    <Link
                        component={RouterLink}
                        to={`/resources/${resource.id}`}
                    >
                        {resource.name}
                    </Link>
                )}
                {!!(showContactCard && linkedUser) && (
                    <Tooltip
                        title={
                            <TooltipTitle name={resource.name} email={email} />
                        }
                        placement="bottom-start"
                    >
                        <div>
                            <img
                                src={resourceLinkIcon}
                                alt="Linked user icon"
                            />
                        </div>
                    </Tooltip>
                )}
            </Stack>
        );
    } else {
        return <></>;
    }
}
