import Basic from './Basic';

export default class Attachment extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.attachmentId = obj.attachmentId || null;

        this.businessEntityId = obj.businessEntityId || null;

        this.filename = obj.filename || '';

        this.location = obj.location || '';

        this.mimeType = obj.mimeType || '';

        this.isLoading = obj.isLoading || false;
    }
}
