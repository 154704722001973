import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    Stack,
    Typography,
} from '@mui/material';
import ConfirmDeleteModal from 'System/Modals/ConfirmDeleteModal/ConfirmDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { companyActions } from 'System/State/CompanyState/reducer';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import UserForm from 'System/Forms/UserForms/UserForm';

export default function UserDetailModal() {
    const dispatch = useDispatch();
    const open = useSelector(modalSelectors.userDetailModal) || false;
    const user = useSelector(companySelectors.selectedUser) || {};

    const handleClose = () => dispatch(modalActions.close('userDetailModal'));

    const handleSubmit = (user) => {
        dispatch(companyActions.updateUser(user));
        handleClose();
    };

    const handleDelete = (user) => {
        // Commented out for now, waiting for team decision on how to handle user deletion
        // dispatch(companyActions.deleteUser(user));
        // handleClose();
    };

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    return user ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="User Details"
            aria-describedby="Update user details"
        >
            <DialogTitle>
                <Stack spacing={1}>
                    <Typography variant="h4">
                        User Details: {user?.emailAddress}
                    </Typography>
                    <Typography variant="h5">
                        Created: {user?.created?.toLocaleDateString()}
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <UserForm user={user} onSubmit={handleSubmit} />

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="User"
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        handleDelete(user);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <Button
                        onClick={() =>
                            dispatch(companyActions.resendUserInvite(user.id))
                        }
                        variant="text"
                    >
                        Resend Invite
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            form="user-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
