export const getUpdatedList = (list, updatedItem) => {
    if (list?.length && updatedItem) {
        return list.map((item) => {
            return item.id === updatedItem.id ? updatedItem : item;
        });
    } else {
        return list;
    }
};

export const addToOrUpdateInList = (list, item) => {
    const found = getItemByItemId(list, item.id);
    return found ? getUpdatedList(list, item) : [...list, item];
};

export const getUpdatedSelectedItem = (selectedItem, updatedItem) => {
    if (selectedItem && updatedItem) {
        return selectedItem.id === updatedItem.id ? updatedItem : selectedItem;
    } else {
        return null;
    }
};

export const removeItemFromListById = (list, id) => {
    if (list?.length && id) {
        return list.filter((item) => item.id !== id);
    } else {
        return list;
    }
};

export const removeItemFromListByPropName = (list, id, propName) => {
    if (list?.length && id) {
        return list.filter((item) => item[propName] !== id);
    } else {
        return list;
    }
};

export const removeIdFromIdList = (list, id) => {
    return list?.length && id ? list.filter((listId) => listId !== id) : list;
};

export const removeIfSelectedItem = (selectedItem, itemToDelete) => {
    return selectedItem.id === itemToDelete.id ? null : selectedItem;
};

export const sortItems = (items) => {
    if (!items?.length) {
        return [];
    }

    return items.slice().sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        } else {
            return -1;
        }
    });
};

export const getItemByItemId = (items, itemId) => {
    if (items?.length && itemId) {
        return items.find((item) => item.id === itemId);
    } else {
        return null;
    }
};

export const addItemToList = (items, newItem) => {
    if (items && newItem) {
        if (!getItemByItemId(items, newItem.id)) {
            return [...items, newItem];
        }
        return items;
    }
    return items;
};

export const addItemsToList = (items, ...newItems) => {
    if (items && newItems.length > 0) {
        const uniqueNewItems = newItems.filter(
            (newItem) => !getItemByItemId(items, newItem.id),
        );
        return [...items, ...uniqueNewItems];
    }
    return items;
};

export const addItemToListWithFinancials = (items, newItem) => {
    if (items && newItem) {
        let item = getItemByItemId(items, newItem.id);

        if (!item) {
            return [...items, newItem];
        } else if (
            item &&
            (newItem.standardHourlyCostRate ||
                newItem.standardHourlyBillRate ||
                newItem.defaultHourlyCostRate ||
                newItem.defaultHourlyBillRate)
        ) {
            let newItemIndex = items.indexOf(item);
            items.splice(newItemIndex, 1, newItem);
            return items;
        }
        return items;
    }
    return items;
};

export const getProjectByEpicId = (projects, epicId) => {
    return projects.find((project) =>
        project.epics.some((epic) => epic.id === epicId),
    );
};

export const collectLabelsFromItem = (currentLabels, item) => {
    let labels = [...currentLabels];

    if (item.labels?.length > 0) {
        item.labels.forEach((label) => {
            if (label !== '') {
                labels.push(label);
            }
        });
    }
    return labels;
};

export const collectLinesOfBusinessFromItem = (
    currentLinesOfBusiness,
    item,
) => {
    let linesOfBusiness = [...currentLinesOfBusiness];

    if (item.linesOfBusiness?.length > 0) {
        item.linesOfBusiness.forEach((lineOfBusiness) => {
            if (lineOfBusiness !== '') {
                linesOfBusiness.push(lineOfBusiness);
            }
        });
    }

    return linesOfBusiness;
};

export const getAvailableLabels = (projects) => {
    let labels = [];

    if (projects?.length) {
        projects.forEach((project) => {
            labels = collectLabelsFromItem(labels, project);

            if (project.epics && project.epics.length > 0) {
                project.epics.forEach((epic) => {
                    labels = collectLabelsFromItem(labels, epic);

                    if (epic.workItems && epic.workItems.length > 0) {
                        epic.workItems.forEach((workItem) => {
                            labels = collectLabelsFromItem(labels, workItem);
                        });
                    }
                });
            }

            if (project.workItems && project.workItems.length > 0) {
                project.workItems.forEach((workItem) => {
                    labels = collectLabelsFromItem(labels, workItem);
                });
            }

            if (
                project.resourceTimelines &&
                project.resourceTimelines.length > 0
            ) {
                project.resourceTimelines.forEach((resourceTimeline) => {
                    labels = collectLabelsFromItem(labels, resourceTimeline);
                });
            }
        });
    }

    return [...new Set(labels)];
};

export const getAvailableLinesOfBusiness = (projects) => {
    let linesOfBusiness = [];

    if (projects?.length) {
        projects.forEach((project) => {
            linesOfBusiness = collectLinesOfBusinessFromItem(
                linesOfBusiness,
                project,
            );
        });
    }

    return [...new Set(linesOfBusiness)];
};

export const addEpicUtility = (state, epic) => {
    const foundProject = getItemByItemId(state.list, epic.projectId);

    const updatedProject = {
        ...foundProject,
        epics: addItemToList(foundProject.epics, epic),
    };

    state.list = getUpdatedList(state.list, updatedProject);
};

export const removeEpicUtility = (state, epic) => {
    const foundProject = getItemByItemId(state.list, epic.projectId);

    const updatedProject = {
        ...foundProject,
        epics: removeItemFromListById(foundProject.epics, epic.id),
    };

    state.list = getUpdatedList(state.list, updatedProject);
};

export const trimActionType = (type) =>
    type?.split(/pending|fulfilled|rejected/g)[0];

export const getErrorMessage = (error) => {
    const err = Object.values(error)[0];
    const message = err[0];
    return `${message}`;
};

export const sortAffectedWorkItemsIntoProjectCollections = (affectedItems) => {
    return affectedItems.reduce((collections, item) => {
        const { projectId } = item;
        if (!collections[projectId]) {
            collections[projectId] = { projectId: projectId, items: [] };
        }
        collections[projectId].items.push(item);
        return collections;
    }, {});
};
