import { TextField, MenuItem } from '@mui/material';

export default function ResourceUnavailableTypePicker({ value, onChange }) {
    return (
        <TextField
            name="reason"
            value={value ? value : 'none'}
            label="Reason"
            select
            fullWidth
            SelectProps={{
                labelId: 'status-label',
                displayEmpty: true,
            }}
            onChange={onChange}
        >
            <MenuItem value="none" disabled>
                Add New Reason
            </MenuItem>
            <MenuItem value="PTO">PTO</MenuItem>
            <MenuItem value="Vacation">Vacation</MenuItem>
            <MenuItem value="Other">Other</MenuItem>

            {/* {statusOptions?.map((option) => (
                <MenuItem key={option.status} value={option.status}>
                    {option.statusName}
                </MenuItem>
            ))} */}
        </TextField>
    );
}
