import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { projectActions } from 'System/State/ProjectState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import ProjectScheduleForm from 'System/Forms/ProjectForms/ProjectScheduleForm';

export default function ScheduleDetailModal() {
    const dispatch = useDispatch();

    const open = useSelector(modalSelectors.scheduleDetailModal);
    const project = useSelector(projectSelectors.selectedProject);

    const handleClose = () => {
        dispatch(modalActions.close('scheduleDetailModal'));
    };

    const handleSubmit = (project) => {
        dispatch(projectActions.updateProjectSchedule(project));
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Schedule Details"
            aria-describedby="View project schedule"
        >
            <DialogTitle>Schedule Details</DialogTitle>
            <DialogContent>
                <ProjectScheduleForm
                    project={project}
                    onSubmit={handleSubmit}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    variant="contained"
                    type="submit"
                    form="project-schedule-form"
                >
                    Save Details
                </Button>
            </DialogActions>
        </Dialog>
    );
}
