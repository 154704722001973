import { Fade, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        outline: 'none',
    },
}));

export const ImagePreview = ({ src, fileName, open = false, setOpen }) => {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            className={classes.modal}
            onClose={() => setOpen(false)}
            closeAfterTransition
        >
            <Fade in={open} timeout={250} className={classes.img}>
                <img
                    src={src}
                    alt={fileName}
                    style={{ maxHeight: '95%', maxWidth: '95%' }}
                />
            </Fade>
        </Modal>
    );
};
