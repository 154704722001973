import {
    TextField,
    MenuItem,
    Stack,
    Autocomplete,
    IconButton,
    Tooltip,
} from '@mui/material';
import { teamSelectors } from 'System/State/TeamState/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from 'System/State/ModalState/reducer';
import { userSelectors } from 'System/State/UserState/selectors';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { teamActions } from 'System/State/TeamState/reducer';

export default function TeamPickerSingle(props) {
    const { selectedTeam } = props;

    const dispatch = useDispatch();
    const availableTeams = useSelector(teamSelectors.allTeams);
    const isAllowedAdmin = useSelector(userSelectors.isAllowedAdmin);

    return availableTeams ? (
        <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}
            width="100%"
        >
            <Autocomplete
                options={availableTeams}
                value={selectedTeam}
                getOptionLabel={(option) => option.name || ''}
                id="auto-complete"
                onChange={(_, team) =>
                    dispatch(teamActions.selectTeam(team?.id))
                }
                autoComplete
                noOptionsText={
                    isAllowedAdmin ? (
                        <MenuItem
                            onMouseDown={() =>
                                dispatch(modalActions.open('newTeamModal'))
                            }
                        >
                            Add Team
                        </MenuItem>
                    ) : (
                        'No Options Found'
                    )
                }
                fullWidth
                includeInputInList
                renderInput={(params) => (
                    <TextField {...params} fullWidth label="Team" />
                )}
            />
            {isAllowedAdmin ? (
                <IconButton
                    onClick={() => dispatch(modalActions.open('newTeamModal'))}
                >
                    <Tooltip
                        title="Can't find what you're looking for? Click here to add a new team to this company."
                        aria-label="add-new-team"
                        enterDelay={300}
                        placement="top"
                    >
                        <AddCircleOutlineIcon />
                    </Tooltip>
                </IconButton>
            ) : null}
        </Stack>
    ) : null;
}
