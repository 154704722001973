import Calc from '../Utilities/calculators';
import Basic from './Basic';
export default class ResourceTimeline extends Basic {
    constructor(obj = {}) {
        super(
            obj.id,
            obj.created,
            obj.createdBy,
            obj.createdByName,
            obj.lastUpdated,
            obj.lastUpdatedBy,
        );

        this.isActive = obj.isActive || false;

        // assign the resource id that this resource timeline belongs to
        this.skilledResourceId = obj.skilledResourceId || '';

        // assign the resource name (this is just a copy and should always be in sync)
        this.skilledResourceName = obj.skilledResourceName || '';

        // assign the profile id that this resource timeline belongs to
        this.profileId = obj.profileId;

        // assign the profile name (this is just a copy and should always be in sync)
        this.profileName = obj.profileName;

        // assign the project id that this resource timeline belongs to
        this.projectId = obj.projectId;

        // assign the project name (this is just a copy and should always be in sync)
        this.projectName = obj.projectName;

        // the typical number of hours per day this resource works
        // during this time period
        // Note: deprecated underscore backer vars for front-end validation
        this.hoursPerDay = obj.hoursPerDay || 8;

        // the typical number of days per week this resource works
        // during this time period
        // Note: deprecated underscore backer vars for front-end validation
        this.daysPerWeek = obj.daysPerWeek || 5;

        this.actualHourlyBillRate = obj.actualHourlyBillRate || 0;

        // the actual hourly rate negotiated for this resource
        // during this time period
        // Note: deprecated underscore backer vars for front-end validation
        this.actualHourlyCostRate = obj.actualHourlyCostRate || 0;

        this.averageVelocity = obj.averageVelocity || 0;

        // allow labels to be applied to a resource
        this.labels = obj.labels || [];

        // specifies whether the resource timeline should pin to it's parent (a project or epic)
        // start and end date with changes to the epic's if it is pinned
        this.isPinned = obj.isPinned || false;

        // the start date for this time period of work
        this.startDate = obj.startDate ? new Date(obj.startDate) : null;

        // the end date for this time period of work
        this.endDate = obj.endDate ? new Date(obj.endDate) : null;

        this.history = obj.history;

        // the percentage allocated for this resource for this time period of work
        // Note: deprecated underscore backer vars for front-end validation
        this.percentageAllocated = obj.percentageAllocated || 100;

        // which team the resource timeline is associated to
        this.teamId = obj.teamId || null;

        this.teamName = obj.teamName || '';
    }

    // total cost for this time period of work based on rate and total hours
    get totalCost() {
        if (this.totalHours && this.actualHourlyCostRate) {
            return this.totalHours * this.actualHourlyCostRate;
        } else {
            return 0;
        }
    }

    // total cost for this time period up to today
    get totalCostToDate() {
        if (!this.startDate || !this.endDate) {
            return 0;
        }

        var total = 0;
        var today = new Date();

        // if today is past the end date, cap today at the end date
        if (today > this.endDate) {
            today = this.endDate;
        }

        var durationInHoursWorkedPerDay = 0;

        // check if the start date is in the past and we should count it
        if (this.startDate <= today) {
            var durationInDays = 0;

            // check if the end is past and include all of it
            if (this.endDate <= today) {
                durationInDays = Calc.durationInWorkDays(
                    this.startDate,
                    this.endDate,
                );
            } else {
                durationInDays = Calc.durationInWorkDays(this.startDate, today);
            }

            // need to account for days worked per week
            const numberOfWeeksWorkedInDuration =
                Math.floor(durationInDays / 5) +
                (durationInDays % 5) / this.daysPerWeek;
            const numberOfWorkDaysInDuration =
                numberOfWeeksWorkedInDuration * this.daysPerWeek;

            // get duration in hours
            durationInHoursWorkedPerDay =
                numberOfWorkDaysInDuration * this.hoursPerDay;
        }

        // get total cost for days
        total =
            durationInHoursWorkedPerDay *
            (this.percentageAllocated / 100) *
            this.actualHourlyCostRate;

        return total;
    }

    get totalRevenue() {
        if (this.totalHours && this.actualHourlyBillRate) {
            return this.totalHours * this.actualHourlyBillRate;
        } else {
            return 0;
        }
    }

    get totalRevenueToDate() {
        if (!this.startDate || !this.endDate) {
            return 0;
        }

        var total = 0;
        var today = new Date();

        // if today is past the end date, cap today at the end date
        if (today > this.endDate) {
            today = this.endDate;
        }

        var durationInHoursWorkedPerDay = 0;

        // check if the start date is in the past and we should count it
        if (this.startDate <= today) {
            var durationInDays = 0;

            // check if the end is past and include all of it
            if (this.endDate <= today) {
                durationInDays = Calc.durationInWorkDays(
                    this.startDate,
                    this.endDate,
                );
            } else {
                durationInDays = Calc.durationInWorkDays(this.startDate, today);
            }

            // need to account for days worked per week
            const numberOfWeeksWorkedInDuration =
                Math.floor(durationInDays / 5) +
                (durationInDays % 5) / this.daysPerWeek;
            const numberOfWorkDaysInDuration =
                numberOfWeeksWorkedInDuration * this.daysPerWeek;

            // get duration in billed hours
            durationInHoursWorkedPerDay =
                numberOfWorkDaysInDuration * this.hoursPerDay;
        }

        // get total revenue for days
        total =
            durationInHoursWorkedPerDay *
            (this.percentageAllocated / 100) *
            this.actualHourlyBillRate;

        return total;
    }

    get totalMarginToDate() {
        return this.totalRevenueToDate - this.totalCostToDate;
    }

    // total number of days worked (based on days per week and duration)
    get totalDays() {
        if (this.startDate && this.endDate) {
            const durationInDays = Calc.durationInWorkDays(
                this.startDate,
                this.endDate,
            );

            // console.log(this.startDate, this.endDate, durationInDays);
            // need to account for days worked per week
            const numberOfWeeksWorkedInDuration =
                Math.floor(durationInDays / 5) +
                (durationInDays % 5) / this.daysPerWeek;
            return numberOfWeeksWorkedInDuration * this.daysPerWeek;
        } else {
            return 0;
        }
    }

    // total number of hours worked (based on hours per day and duration)
    get totalHours() {
        return (
            this.hoursPerDay * this.totalDays * (this.percentageAllocated / 100)
        );
    }

    get totalMargin() {
        return this.totalRevenue - this.totalCost;
    }

    // duration in calendar days
    get durationInCalendarDays() {
        if (this.startDate && this.endDate) {
            return Calc.durationInCalendarDays(this.startDate, this.endDate);
        } else {
            return 0;
        }
    }

    get initials() {
        return this.skilledResourceName
            ?.trim()
            .split(' ')
            .reduce((acc, curr, index, arr) => {
                if (index === 0 || index === arr.length - 1) {
                    acc = `${acc}${curr.charAt(0).toUpperCase()}`;
                }
                return acc;
            }, '');
    }

    plannedCostBetweenTwoDates(startDate, endDate) {
        // check if the start date is out of bounds from the timeline
        if (startDate > this.endDate) {
            return 0;
        }

        // check if the end date is out of bounds from the timeline
        if (endDate < this.startDate) {
            return 0;
        }

        let overlappedStartDate, overlappedEndDate;
        // decide which start date to use to determine day duration
        if (startDate <= this.startDate) {
            overlappedStartDate = this.startDate;
        } else {
            overlappedStartDate = startDate;
        }

        // decide which end date to use to determine day duration
        if (endDate >= this.endDate) {
            overlappedEndDate = this.endDate;
        } else {
            overlappedEndDate = endDate;
        }

        // get duration in days between two dates
        const durationInDays = Calc.durationInWorkDays(
            overlappedStartDate,
            overlappedEndDate,
        );

        // need to account for days worked per week
        const numberOfWeeksWorkedInDuration =
            Math.floor(durationInDays / 5) +
            (durationInDays % 5) / this.daysPerWeek;

        const numberOfWorkDaysInDuration =
            numberOfWeeksWorkedInDuration * this.daysPerWeek;

        // get duration in billed hours
        const durationInHoursWorkedPerDay =
            numberOfWorkDaysInDuration * this.hoursPerDay;

        // return cost for duration in days
        return (
            durationInHoursWorkedPerDay *
            this.actualHourlyCostRate *
            (this.percentageAllocated / 100)
        );
    }

    plannedRevenueBetweenTwoDates(startDate, endDate) {
        // check if the start date is out of bounds from the timeline
        if (startDate > this.endDate) {
            return 0;
        }

        // check if the end date is out of bounds from the timeline
        if (endDate < this.startDate) {
            return 0;
        }

        let overlappedStartDate, overlappedEndDate;
        // decide which start date to use to determine day duration
        if (startDate <= this.startDate) {
            overlappedStartDate = this.startDate;
        } else {
            overlappedStartDate = startDate;
        }

        // decide which end date to use to determine day duration
        if (endDate >= this.endDate) {
            overlappedEndDate = this.endDate;
        } else {
            overlappedEndDate = endDate;
        }

        // get duration in days between two dates
        const durationInDays = Calc.durationInWorkDays(
            overlappedStartDate,
            overlappedEndDate,
        );

        // need to account for days worked per week
        // get number of full weeks; for partial week, add fraction of weeks based on timeline's daysPerWeek
        // NOTE: this will still count fractions of weeks that may not apply when daysPerWeek is less than 5
        // for example, if a resource works Mon-Thurs but the date filter includes a Friday, that Friday will be included
        const numberOfWeeksWorkedInDuration =
            Math.floor(durationInDays / 5) +
            (durationInDays % 5) / this.daysPerWeek;

        const numberOfWorkDaysInDuration =
            numberOfWeeksWorkedInDuration * this.daysPerWeek;

        // get duration in billed hours
        const durationInHoursWorkedPerDay =
            numberOfWorkDaysInDuration * this.hoursPerDay;

        // return cost for duration in days
        return (
            durationInHoursWorkedPerDay *
            this.actualHourlyBillRate *
            (this.percentageAllocated / 100)
        );
    }

    get plannedMarginAmountWithDateFilter() {
        return (dateFilter) => {
            if (dateFilter.startDate && dateFilter.endDate) {
                const plannedRevenue = this.plannedRevenueBetweenTwoDates(
                    dateFilter.startDate,
                    dateFilter.endDate,
                );
                const plannedCost = this.plannedCostBetweenTwoDates(
                    dateFilter.startDate,
                    dateFilter.endDate,
                );
                if (plannedRevenue && plannedCost) {
                    return plannedRevenue - plannedCost;
                }

                return 0;
            } else {
                const plannedRevenue = this.totalRevenue;
                const plannedCost = this.totalCost;
                if (plannedRevenue && plannedCost) {
                    return plannedRevenue - plannedCost;
                }

                return 0;
            }
        };
    }

    get plannedMarginPercentageWithDateFilter() {
        return (dateFilter) => {
            if (dateFilter.startDate && dateFilter.endDate) {
                const plannedRevenue = this.plannedRevenueBetweenTwoDates(
                    dateFilter.startDate,
                    dateFilter.endDate,
                );
                const plannedMargin =
                    this.plannedMarginAmountWithDateFilter(dateFilter);
                if (plannedRevenue && plannedMargin) {
                    return Math.round((plannedMargin / plannedRevenue) * 100);
                } else return 0;
            }
        };
    }
}
