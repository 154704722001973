import {
    FormControl,
    Input,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import User from 'Models/User';
import { useSelector } from 'react-redux';
import { companySelectors } from 'System/State/CompanyState/selectors';
import { userSelectors } from 'System/State/UserState/selectors';

const useStyles = makeStyles({
    groupForm: {
        margin: '1.8rem 0 1.5rem 0',
    },
});

export default function GroupSelector(props) {
    const { onChange, selectedGroup, name, required, autoFocus } = props;

    const classes = useStyles();
    const user = useSelector(userSelectors.loggedInUser);
    const groups = User.groups;
    const owners = useSelector(companySelectors.usersList).filter(
        (user) => user.isOwner,
    );

    const handleInputChange = (e) => {
        onChange({
            target: {
                name: name,
                value: e.target.value,
            },
        });
    };

    return (
        <FormControl fullWidth className={classes.groupForm}>
            <InputLabel id="backgroundColor-label">Group</InputLabel>
            <Select
                name="group"
                autoFocus={autoFocus}
                value={selectedGroup || ''}
                labelId="group-label"
                label="Group"
                required={required}
                onChange={handleInputChange}
                input={<Input id="group" />}
            >
                {user.isAllowedAdmin && (
                    <MenuItem value={groups.Admin}>
                        Admin (With Financials)
                    </MenuItem>
                )}
                <MenuItem
                    disabled={user.isAllowedAdmin && owners?.length > 0}
                    value={groups.Customer}
                >
                    Owner (With Financials)
                </MenuItem>
                {user.isAllowedContribute && user.isAllowedFinancials && (
                    <MenuItem value={groups.Contributor}>
                        Contributor (With Financials)
                    </MenuItem>
                )}
                {user.isAllowedFinancials && (
                    <MenuItem value={groups.ReadOnly}>
                        Read Only (With Financials)
                    </MenuItem>
                )}
                {user.isAllowedContribute && (
                    <MenuItem value={groups.ContributorNoFinance}>
                        Contributor (No Financials)
                    </MenuItem>
                )}
                <MenuItem value={groups.ReadOnlyNoFinance}>
                    Read Only (No Financials)
                </MenuItem>
            </Select>
        </FormControl>
    );
}
