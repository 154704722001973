import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function InfoTooltip({ toolTipText }) {
    return (
        <Tooltip title={toolTipText} placement="top" arrow>
            <InfoOutlinedIcon sx={{ fontSize: 20, fill: '#FFAB00' }} />
        </Tooltip>
    );
}
