import { createSelector } from 'reselect';
import { FEATURE_FLAGS } from 'Utilities/constants';
import featureFlagUtils from 'Utilities/feature-flag-utility';

const featureFlagState = (state) => state?.featureFlags;
const tenantId = (state) => state?.company?.tenant?.id;

const isReady = createSelector(featureFlagState, (state) => !!state?.isReady);

const allFeatureFlags = createSelector(
    featureFlagState,
    (featureFlags) => featureFlags.treatments,
);

const billingFlag = createSelector(
    [featureFlagState, tenantId],
    (allFeatureFlags, tenantId) =>
        featureFlagUtils.getFeatureFlag(
            allFeatureFlags,
            FEATURE_FLAGS.Billing,
            tenantId,
        ),
);

const chatGPTWorkItemDescriptionFlag = createSelector(
    [featureFlagState, tenantId],
    (allFeatureFlags, tenantId) =>
        featureFlagUtils.getFeatureFlag(
            allFeatureFlags,
            FEATURE_FLAGS.ChatGPTWorkItemDescription,
            tenantId,
        ),
);

const insightsFlag = createSelector(
    [featureFlagState, tenantId],
    (allFeatureFlags, tenantId) =>
        featureFlagUtils.getFeatureFlag(
            allFeatureFlags,
            FEATURE_FLAGS.Insights,
            tenantId,
        ),
);

const enterpriseEnabledFlag = createSelector(
    [featureFlagState, tenantId],
    (allFeatureFlags, tenantId) =>
        featureFlagUtils.getFeatureFlag(
            allFeatureFlags,
            FEATURE_FLAGS.EnterpriseEnabled,
            tenantId,
        ),
);

const peopleModuleFlag = createSelector(
    [featureFlagState, tenantId],
    (allFeatureFlags, tenantId) =>
        featureFlagUtils.getFeatureFlag(
            allFeatureFlags,
            FEATURE_FLAGS.PeopleModule,
            tenantId,
        ),
);

export const featureFlagSelectors = {
    isReady,
    allFeatureFlags,
    chatGPTWorkItemDescriptionFlag,
    billingFlag,
    enterpriseEnabledFlag,
    insightsFlag,
    peopleModuleFlag,
};
