import moment from 'moment';

const checkIsItemWithinBudgetDates = (item, budget) => {
    // checks for any overlapping dates between work items and budgets
    return (
        moment(item?.estimatedStartDate).isBetween(
            budget?.estimatedStartDate,
            budget?.endDate,
            undefined,
            '[]',
        ) ||
        moment(item?.estimatedCompletionDate).isBetween(
            budget?.estimatedStartDate,
            budget?.endDate,
            undefined,
            '[]',
        ) ||
        moment(budget?.estimatedStartDate).isBetween(
            item?.estimatedStartDate,
            item?.estimatedCompletionDate,
            undefined,
            '[]',
        ) ||
        moment(budget?.endDate).isBetween(
            item?.estimatedStartDate,
            item?.estimatedCompletionDate,
            undefined,
            '[]',
        )
    );
};

const applyBudgetFilter = (item, selectedBudgets) => {
    if (selectedBudgets?.length) {
        return selectedBudgets.some((budget) => {
            return checkIsItemWithinBudgetDates(item, budget);
        });
    }
    return true;
};

const applyFilters = (item, filterOptions, allTeams) => {
    let itemPassesFilters = true;
    // colors
    if (filterOptions?.selectedColors?.length) {
        itemPassesFilters = filterOptions.selectedColors.includes(item.color);
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // labels
    if (filterOptions?.selectedLabels?.length) {
        itemPassesFilters = item.labels?.some((label) =>
            filterOptions.selectedLabels.includes(label.name),
        );
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // lines of business
    if (filterOptions?.selectedLinesOfBusiness?.length) {
        itemPassesFilters = item.linesOfBusiness?.some((lineOfBusiness) =>
            filterOptions.selectedLinesOfBusiness.includes(lineOfBusiness.id),
        );
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // risks
    if (filterOptions?.selectedRisks?.length) {
        itemPassesFilters = filterOptions.selectedRisks.some(
            (risk) => risk === item.risk,
        );
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // resources
    if (filterOptions?.selectedResources?.length) {
        itemPassesFilters =
            // Project Resources
            item.resourceTimelines &&
            item.resourceTimelines?.some(
                (resourceTimeline) =>
                    resourceTimeline &&
                    filterOptions.selectedResources.includes(
                        resourceTimeline.skilledResourceId,
                    ),
            );

        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // teams
    const skilledResourceIds = item.resourceTimelines?.map(
        (timeline) => timeline.skilledResourceId,
    );

    let relevantTeamIds = [];
    allTeams?.forEach((team) => {
        if (team.skilledResources.length) {
            team.skilledResources.forEach((skilledResource) => {
                if (skilledResourceIds.includes(skilledResource.id)) {
                    relevantTeamIds.push(skilledResource.teamId);
                }
            });
        }
    });

    if (filterOptions?.selectedTeams?.length) {
        itemPassesFilters = relevantTeamIds.some((id) =>
            filterOptions.selectedTeams.some(
                (selectedTeamId) => selectedTeamId === id,
            ),
        );
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // status
    if (filterOptions?.selectedStatuses?.length) {
        itemPassesFilters = filterOptions.selectedStatuses.includes(
            item.status,
        );
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // profiles
    if (filterOptions?.selectedProfiles?.length) {
        itemPassesFilters =
            // Project Resources
            item.resourceTimelines &&
            item.resourceTimelines?.some(
                (resourceTimeline) =>
                    resourceTimeline &&
                    filterOptions.selectedProfiles.includes(
                        resourceTimeline.profileId,
                    ),
            );
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // projects
    if (filterOptions?.selectedProjects?.length) {
        itemPassesFilters =
            item &&
            filterOptions.selectedProjects.includes(
                item.id,
            );
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }

    // search
    const search = filterOptions?.search?.toLowerCase();
    if (search && search.length > 0) {
        itemPassesFilters =
            item.name?.toLowerCase().includes(search) ||
            item.objective?.toLowerCase().includes(search);
        if (!itemPassesFilters) {
            return itemPassesFilters;
        }
    }
    return itemPassesFilters;
};

const applyWorkItemFilters = (workItem, filterOptions, projectKeys) => {
    let searchPassesFilters = true;
    if (filterOptions?.search) {
        searchPassesFilters =
            workItem.name
                ?.toLowerCase()
                .includes(filterOptions?.search.toLowerCase()) ||
            `${projectKeys[workItem?.projectId]?.toLowerCase()}-${workItem.number
                }`?.includes(filterOptions?.search.toLowerCase());
    }

    const typesPassesFilter =
        !filterOptions?.selectedTypes?.length ||
        filterOptions?.selectedTypes?.some((type) => type === workItem?.type);

    const statusPassesFilter =
        !filterOptions?.selectedStatuses?.length ||
        filterOptions.selectedStatuses?.some(
            (status) => status === workItem?.status,
        );

    const labelPassesFilter =
        !filterOptions?.selectedLabels?.length ||
        workItem.labels?.some((label) =>
            filterOptions.selectedLabels?.some(
                (x) => x.toLowerCase() === label.name?.toLowerCase(),
            ),
        );

    const assigneePassesFilter =
        !filterOptions?.selectedAssignees?.length ||
        filterOptions?.selectedAssignees?.some((x) => {
            if (x === 'unassigned') return workItem?.assignedSkilledResources.length === 0;
            return workItem?.assignedSkilledResources?.includes(x);
        });

    const phasesPassesFilter =
        !filterOptions?.selectedPhases?.length ||
        filterOptions?.selectedPhases?.some(
            (phaseId) => phaseId === workItem?.projectPhaseId,
        );

    const epicPassesFilter =
        !filterOptions?.selectedEpics?.length ||
        filterOptions?.selectedEpics?.some(
            (epicId) => epicId == workItem?.projectEpicId,
        );
    
    const projectPassesFilter =
        !filterOptions?.selectedProjects?.length ||
        filterOptions?.selectedProjects?.some(
            (projectId) => projectId == workItem?.projectId,
        );

    return (
        searchPassesFilters &&
        typesPassesFilter &&
        statusPassesFilter &&
        labelPassesFilter &&
        assigneePassesFilter &&
        phasesPassesFilter &&
        epicPassesFilter &&
        projectPassesFilter
    );
};

export const filterUtils = {
    applyBudgetFilter,
    applyFilters,
    applyWorkItemFilters,
};
