import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { modalActions } from 'System/State/ModalState/reducer';
import { userActions } from 'System/State/UserState/reducer';
import { modalSelectors } from 'System/State/ModalState/selectors';

export default function ConfirmLogoutModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const open = useSelector(modalSelectors.confirmLogoutModal) || false;

    const handleClose = (_, reason) => {
        if (reason !== 'backdropClick')
            dispatch(modalActions.close('confirmLogoutModal'));
    };

    const handleCancel = () => {
        handleClose();
        navigate('/');
    };

    const handleConfirm = () => {
        dispatch(userActions.signoutUser())
            .unwrap()
            .then(() => {
                handleClose();
                navigate('/signup');
            });
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="Confirm log out"
            aria-describedby="Log out to create a new account"
        >
            <DialogTitle>Sign up for a new account</DialogTitle>
            <DialogContent>
                You are already logged in. Would you like to log out to create a
                new account?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Return to Dashboard</Button>
                <Button variant="contained" onClick={handleConfirm}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
}
