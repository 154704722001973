import RestAPI from '../rest-api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';

const addTeam = createAsyncThunk('teams/addTeam', async (team) => {
    record('addTeam', { id: team.id, name: team.name });
    await RestAPI.post('/teams', { body: team });
    return { ...team };
});

const deleteTeam = createAsyncThunk('teams/deleteTeam', async (team) => {
    record('deleteTeam', { id: team.id, name: team.name });
    await RestAPI.del(`/teams/${team.id}/permanent`);
    return team.id;
});

const disableTeam = createAsyncThunk('teams/disableTeam', async (team) => {
    record('disableTeam', { id: team.id, name: team.name });
    await RestAPI.del(`/teams/${team.id}`);
    return team.id;
});

const getTeamDetail = createAsyncThunk(
    'teams/getTeamDetail',
    async (teamId, { rejectWithValue }) => {
        try {
            return await RestAPI.get(`/teams/${teamId}`);
        } catch (error) {
            return rejectWithValue(teamId);
        }
    },
);

const updateTeam = createAsyncThunk('teams/updateTeam', async (team) => {
    record('updateTeam', { id: team.id, name: team.name });
    await RestAPI.put(`/teams/${team.id}`, { body: team });
    return { ...team };
});

export const teamActionsAsync = {
    addTeam,
    deleteTeam,
    disableTeam,
    getTeamDetail,
    updateTeam,
};
