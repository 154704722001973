import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Stack,
    Grid,
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';

export default function BulkImportFailureModal({
    isModalOpen,
    fileSelectedInParent,
    cancelAction,
    onImportClick,
    errors,
}) {
    // Hotfix for undefined isModalOpen; we need to find the offending source eventually
    if (!isModalOpen) {
        isModalOpen = false;
    }

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileInputKey, setFileInputKey] = useState(0);
    const [errorsForDisplay, setErrorsForDisplay] = useState([]);

    useEffect(() => {
        let repackagedErrors = [];
        if (!errors) {
            return;
        }
        for (const err in errors) {
            const errorIndexMatch = err.match(/.+\[(\d+)\]/);
            if (!errorIndexMatch) {
                continue;
            }
            const row = parseInt(errorIndexMatch[1]) + 2;
            const errorMessages = errors[err].join(' ');
            repackagedErrors.push({
                row: row,
                errorMessage: errorMessages,
            });
        }
        setErrorsForDisplay(repackagedErrors);
        setSelectedFile(fileSelectedInParent);
    }, [errors]);

    const handleChooseFileClick = () => {
        fileInputRef.current.click();
    };

    const handleClose = () => {
        cancelAction();
    };

    const handleImportClick = () => {
        if (selectedFile) {
            cancelAction();
            onImportClick(selectedFile);
            setSelectedFile(null);
            setFileInputKey((key) => key + 1);
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };

    return (
        <Dialog open={isModalOpen} onClose={handleClose}>
            <DialogTitle
                sx={{
                    paddingLeft: 2,
                    fontSize: '1.2rem',
                    color: 'red',
                    paddingBottom: '0 !important',
                }}
            >
                Import Failed
            </DialogTitle>
            <Typography variant="body1" sx={{ px: 2, py: 0 }}>
                Please correct the following errors and re-upload the import
                document.
            </Typography>
            <Stack direction="row" py={2}>
                <Button
                    onClick={() => handleChooseFileClick()}
                    sx={{
                        border: '1px solid #ccc',
                        bgcolor: '#FAF9F6',
                        py: '2px',
                        px: '4px',
                        mx: '16px',
                        borderRadius: '4px',
                    }}
                >
                    <input
                        type="file"
                        accept=".xls, .xlsx, .xlsm"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        key={fileInputKey}
                        onChange={handleFileInputChange}
                    />
                    Choose File
                </Button>
                <Typography
                    sx={{
                        marginRight: '10px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {selectedFile ? selectedFile.name : 'No file chosen'}
                </Typography>
            </Stack>
            <DialogContent sx={{ paddingLeft: 4, paddingRight: 2 }}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        border: '1px solid black',
                        borderBottom: 'none',
                    }}
                >
                    <Grid
                        item
                        xs={1}
                        sx={{
                            borderRight: '1px solid black',
                            paddingLeft: '0 !important',
                            paddingTop: '0 !important',
                        }}
                    >
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                borderBottom: '1px solid black',
                            }}
                        >
                            Row
                        </Typography>
                        {errorsForDisplay.map((error, index) => (
                            <Typography
                                key={index}
                                variant="body1"
                                align="center"
                                sx={{ borderBottom: '1px solid black' }}
                            >
                                {error.row}
                            </Typography>
                        ))}
                    </Grid>
                    <Grid
                        item
                        xs={11}
                        sx={{
                            paddingLeft: '0 !important',
                            paddingTop: '0 !important',
                        }}
                    >
                        <Typography
                            variant="h6"
                            align="left"
                            sx={{
                                borderBottom: '1px solid black',
                                paddingLeft: 1,
                            }}
                        >
                            Error Message
                        </Typography>
                        {errorsForDisplay.map((error, index) => (
                            <Typography
                                key={index}
                                variant="body1"
                                align="left"
                                sx={{
                                    borderBottom: '1px solid black',
                                    paddingLeft: 1,
                                }}
                            >
                                {error.errorMessage}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ marginBottom: 1 }}>
                <Button onClick={cancelAction}>Cancel</Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleImportClick()}
                >
                    Import
                </Button>
            </DialogActions>
        </Dialog>
    );
}
