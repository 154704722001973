import { createAsyncThunk } from '@reduxjs/toolkit';
import { record } from 'Utilities/record-utility';
import RestAPI from '../rest-api';

const addUnavailablePeriodToResource = createAsyncThunk(
    'resources/addUnavailablePeriodToResource',
    async (unavailablePeriod) => {
        record('addUnavailablePeriod', { body: unavailablePeriod });
        await RestAPI.post(
            `/skilled-resources/${unavailablePeriod.skilledResourceId}/unavailable-periods`,
            { body: unavailablePeriod },
        );

        return { ...unavailablePeriod };
    },
);

const getUnavailablePeriodDetail = createAsyncThunk(
    'resources/getUnavailablePeriodDetail',
    async (unavailablePeriod) => {
        const response = await RestAPI.get(
            `/skilled-resources/${unavailablePeriod.skilledResourceId}/unavailable-period/${unavailablePeriod.id}`,
        );

        return { ...response };
    },
);

const getUnavailablePeriods = createAsyncThunk(
    'resources/getUnavailablePeriods',
    async (skilledResourceId) => {
        const response = await RestAPI.get(
            `/skilled-resources/${skilledResourceId}/unavailable-periods`,
        );

        return { ...response };
    },
);

const updateUnavailablePeriod = createAsyncThunk(
    'resources/updateUnavailablePeriod',
    async (unavailablePeriod) => {
        record('updateUnavailablePeriod', { body: unavailablePeriod });
        const response = await RestAPI.put(
            `skilled-resources/${unavailablePeriod?.skilledResourceId}/unavailable-periods/${unavailablePeriod?.id}`,
            { body: unavailablePeriod },
        );
        return { ...response };
    },
);

const deleteUnavailablePeriod = createAsyncThunk(
    'resources/deleteUnavailablePeriod',
    async (unavailablePeriod) => {
        record('deleteUnavailablePeriod', { body: unavailablePeriod });
        await RestAPI.del(
            `skilled-resources/${unavailablePeriod.skilledResourceId}/unavailable-periods/${unavailablePeriod.id}`,
        );
    },
);

export const resourceUnavailablePeriodActionsAsync = {
    addUnavailablePeriodToResource,
    getUnavailablePeriodDetail,
    getUnavailablePeriods,
    updateUnavailablePeriod,
    deleteUnavailablePeriod,
};
