import { useFormik } from 'formik';
import * as yup from 'yup';
import Phase from 'Models/ProjectPhase';
import DatePicker from 'System/UI/Components/DatePicker/DatePicker';
import { Stack, TextField } from '@mui/material';
import Form from 'System/Forms/Components/Form';

export default function NewProjectPhaseForm({ onSubmit }) {
    const newPhaseSchema = yup.object().shape({
        name: yup.string().required('Required'),
        startDate: yup.date().typeError('Invalid Date').nullable(),
        endDate: yup
            .date()
            .typeError('Invalid Date')
            .nullable()
            .min(yup.ref('startDate'), 'Must be after Start Date'),
    });

    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
        useFormik({
            initialValues: new Phase(),
            validationSchema: newPhaseSchema,
            validateOnBlur: false,
            validateOnChange: false,
            onSubmit: (values) => onSubmit(values),
        });

    const showError = (fieldName) =>
        Boolean(errors[fieldName] && touched[fieldName]);

    const getError = (fieldName) =>
        showError(fieldName) ? errors[fieldName] : '';

    return (
        <Form onSubmit={handleSubmit} formId="new-project-phase-form">
            <Stack spacing={2}>
                <TextField
                    name="name"
                    label="Name"
                    error={showError('name')}
                    helperText={getError('name')}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    autoFocus
                    required
                    placeholder="Enter Phase Name"
                />
                <Stack direction="row" spacing={1}>
                    <DatePicker
                        name="startDate"
                        format="MM/DD/YYYY"
                        label="Est. Start"
                        error={showError('startDate')}
                        helperText={getError('startDate')}
                        value={values.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                    <DatePicker
                        name="endDate"
                        format="MM/DD/YYYY"
                        label="Est. Completion"
                        error={showError('endDate')}
                        helperText={getError('endDate')}
                        value={values.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                    />
                </Stack>
            </Stack>
        </Form>
    );
}
