import { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    Stack,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { projectSelectors } from 'System/State/ProjectState/selectors';
import { modalSelectors } from 'System/State/ModalState/selectors';
import { modalActions } from 'System/State/ModalState/reducer';
import { epicActions } from 'System/State/ProjectState/reducer';
import ConfirmDeleteModal from 'System/Modals/ConfirmDeleteModal/ConfirmDeleteModal';
import EpicForm from 'System/Forms/EpicForm/EpicForm';

export default function EpicDetailModal() {
    const dispatch = useDispatch();
    const epic = useSelector(projectSelectors.selectedEpic);
    const open = useSelector(modalSelectors.epicDetailModal) || false;
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleClose = () => {
        dispatch(modalActions.close('epicDetailModal'));
    };

    const handleDelete = () => {
        dispatch(epicActions.deleteEpic(epic));
        handleClose();
    };

    const handleSubmit = (epic) => {
        dispatch(epicActions.updateEpic(epic));
        handleClose();
    };

    return epic ? (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth={'sm'}
            aria-labelledby="Epic Details"
            aria-describedby="Update epic details"
        >
            <DialogTitle>Epic Details</DialogTitle>
            <DialogContent>
                <EpicForm epic={epic} onSubmit={handleSubmit} />

                <ConfirmDeleteModal
                    isModalOpen={isDeleteModalOpen}
                    parentType="Epic"
                    textConfirmation={false}
                    confirmDelete={() => {
                        setDeleteModalOpen(false);
                        handleDelete(epic);
                    }}
                    cancelDelete={() => {
                        setDeleteModalOpen(false);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ width: '100%' }}
                >
                    <Button
                        variant="outlined"
                        color="tertiary"
                        onClick={() => {
                            setDeleteModalOpen(true);
                        }}
                    >
                        Delete
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            form="epic-form"
                        >
                            Save Details
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    ) : null;
}
