import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Stack,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

export default function ActionWarningModal({
    isModalOpen,
    customTitle,
    customMessage,
    confirmAction,
    cancelAction,
    parentAction,
    customCancelMessage,
    customProceedMessage,
}) {

    // Hotfix for undefined isModalOpen; we need to find the offending source eventually
    if (!isModalOpen) {
        isModalOpen = false;
    }

    const handleClick = () => {
        confirmAction();
    };

    const handleClose = () => {
        cancelAction();
    };

    const getText = () => {
        if (customMessage) return customMessage;
        return <>Are you sure you want to {parentAction}?</>;
    };
    
    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby="Confirm Action"
            aria-describedby="Confirm the request"
        >
            <DialogTitle>
                <Stack spacing={2}>
                    <WarningIcon sx={{fill: "red"}} fontSize = "large"/>
                </Stack>
                {customTitle ? customTitle : 'Confirm Action'}
            </DialogTitle>
            <DialogContent sx={{ paddingTop: '0 !important' }}>
                <Stack spacing={2}>
                    <Typography variant="body1">{getText()}</Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ marginBottom: 1 }}>
                <Button 
                    onClick={cancelAction}
                >
                    {customCancelMessage ? customCancelMessage : 'Cancel'}
                </Button>

                <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleClick}
                >
                    {customProceedMessage ? customProceedMessage : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}