import { createSelector } from 'reselect';

const paymentSelector = (state) => state.payments;
const prices = createSelector(paymentSelector, (payments) =>
    payments.prices.length ? payments.prices : [],
);

const professionalPrice = createSelector(prices, (prices) => {
    if (prices.length) {
        return prices?.find((price) => price.tier === 'Pro').price / 100;
    } else {
        return 10;
    }
});

const enterprisePrice = createSelector(prices, (prices) => {
    if (prices.length) {
        return prices?.find((price) => price.tier === 'Enterprise').price / 100;
    } else {
        return 20;
    }
});

const subscriptionInfo = createSelector(
    paymentSelector,
    (paymentState) => paymentState.subscriptionInfo,
);

const upgradePromptText = createSelector(
    paymentSelector,
    (paymentState) => paymentState.upgradePromptText,
);

const isLoading = createSelector(
    paymentSelector,
    (paymentState) => paymentState.isLoading,
);

export const paymentSelectors = {
    prices,
    professionalPrice,
    enterprisePrice,
    subscriptionInfo,
    upgradePromptText,
    isLoading,
};
